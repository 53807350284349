import { List, ListSubheader } from "@mui/material"
import SidebarListItems from "./SidebarListItems"

export default function SidebarList(props) {
  const { menus } = props

  return Object.keys(menus).map((menuTitle) => {
    const items = menus[menuTitle]
    return (
      <List
        key={"sidebarList" + menuTitle}
        sx={{ mx: 1 }}
        data-cy={"sidebarList" + menuTitle}
        aria-labelledby={"sidebarList" + menuTitle}
        subheader={
          <ListSubheader
            sx={{ textAlign: "left", fontWeight: "bold", color: "black" }}
            id={"sidebarList" + menuTitle}
          >
            {menuTitle.toUpperCase()}
          </ListSubheader>
        }
      >
        <SidebarListItems menuTitle={menuTitle} items={items} />
      </List>
    )
  })
}
