import { Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel } from "@mui/material"

export default function BFInput({ label, text, name, important, onChange, error }) {
  return (
    <FormControl sx={{ mb: -1 }} error={!!error} required={!!important}>
      <FormLabel sx={{ fontWeight: "bold" }}>{label}</FormLabel>
      <FormControlLabel
        label={text}
        sx={{ ml: 1 }}
        control={<Checkbox onChange={onChange} name={name} value={"ok"} />}
      />

      <FormHelperText color={"error"} sx={{ mt: -1 }}>
        {error ?? " "}
      </FormHelperText>
    </FormControl>
  )
}
