import { TableCell, TableRow } from "@mui/material"

import { useDispatch } from "react-redux"

import { max } from "lodash"
import { setAttribute } from "../../../../../../redux/slices/registrationsSlice"

import { STATUSES_COLOR, STATUSES_ICON } from "../../../../../../utils/constants/statuses"

const ABR = "8px"

export default function StatsRow({ stats, statusName, statusNum }) {
  const dispatch = useDispatch()

  const maxCols = max(Object.values(stats).map((stat) => Object.keys(stat).length))

  const navigateToWorkshop = () => {
    dispatch(setAttribute({ key: "selectedStatus", value: statusNum }))
  }

  return (
    <TableRow
      key={`row-${statusName}`}
      sx={{
        height: "30px",
        "&> td": { backgroundColor: `${STATUSES_COLOR[statusName]}.bg`, cursor: "pointer" },
        "&> td:first-of-type": { borderRadius: `${ABR} 0 0 ${ABR}` },
        "&> td:last-child": { borderRadius: `0 ${ABR} ${ABR} 0` },
      }}
      onClick={navigateToWorkshop}
    >
      {/* STATUS ICON CELL */}
      <TableCell padding="checkbox" sx={{ pl: "8px!important" }} align="left">
        {STATUSES_ICON[statusName]}
      </TableCell>
      {/* 1 COL OR SUM COLS ("T") */}
      {maxCols === 1 ? (
        <TableCell padding="none" align="center">
          {stats[statusNum][1]}
        </TableCell>
      ) : (
        <>
          <TableCell padding="none" align="center">
            {Object.values(stats[statusNum]).reduce((a, b) => a + b)}
          </TableCell>
          {/* <= 4 OR > 4 */}
          {maxCols <= 4 ? (
            Object.values(stats[statusNum]).map((num, numIndex) => (
              <TableCell padding="none" key={`${statusName}-${numIndex}`} sx={{ px: 1 }} align="center">
                {num}
              </TableCell>
            ))
          ) : (
            <>
              <TableCell padding="none" align="center">
                {stats[statusNum][1]}
              </TableCell>
              <TableCell padding="none" align="center">
                {stats[statusNum][2]}
              </TableCell>
              <TableCell padding="none" align="center">
                {stats[statusNum][3]}
              </TableCell>
              <TableCell padding="none" align="center">
                {Object.values(stats[statusNum])
                  .splice(0, 3)
                  .reduce((a, b) => a + b)}
              </TableCell>
            </>
          )}
        </>
      )}
    </TableRow>
  )
}
