import { Box, Card, CardContent, CardHeader, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import { HiveRounded, NumbersRounded, TextFieldsRounded } from "@mui/icons-material"
import { useDispatch, useSelector } from "react-redux"
import { getEventLocal, setAttribute } from "../../../redux/slices/eventSlice"
import { useState } from "react"
import { useQuery } from "@tanstack/react-query"
import apiClient from "../../../configs/axiosCfg"
import { useKeycloak } from "@react-keycloak/web"
import { useSnackbar } from "notistack"
import { addInputError, getInputsError, getUserContext, removeInputError } from "../../../redux/slices/appSlice"
import _ from "lodash"

export default function AttributesInputs() {
  const { keycloak } = useKeycloak()
  const { enqueueSnackbar } = useSnackbar()
  const event = useSelector(getEventLocal)
  const userRole = useSelector(getUserContext).role
  const dispatch = useDispatch()
  const [activities, setActivities] = useState([{ _id: "", name: "Loading..." }])
  const inputsError = useSelector(getInputsError)

  // const getActivities = useQuery(
  useQuery(
    ["getActivities"],
    () =>
      apiClient.get("/activities", {
        headers: { Authorization: "Bearer " + keycloak.token },
      }),
    {
      onError: (error) => {
        console.error(error)
        enqueueSnackbar("Une erreur est survenue en récupérant les activités jeunes", {
          variant: "error",
        })
      },
      onSuccess: (result) => {
        if (result.data) {
          dispatch(setAttribute({ key: "activityId", value: result.data[0]._id }))
          setActivities(result.data.map(({ _id, name }) => ({ _id, name })))
        }
      },
      enabled: userRole <= 1,
    }
  )

  const handleChange = (event) => {
    switch (event.target.name) {
      case "name":
        if (!event.target.value) dispatch(addInputError("name"))
        else dispatch(removeInputError("name"))
        break
      case "selections":
        event.target.value = Math.round(event.target.value)
        if (!event.target.value) dispatch(addInputError("selections"))
        else if (event.target.value < 1) dispatch(addInputError("selections"))
        else dispatch(removeInputError("selections"))
        break
      case "validations":
        event.target.value = Math.round(event.target.value)
        if (!event.target.value) dispatch(addInputError("validations"))
        else if (event.target.value < 1) dispatch(addInputError("validations"))
        else dispatch(removeInputError("validations"))
        break
      default:
        break
    }
    dispatch(setAttribute({ key: event.target.name, value: event.target.value }))
  }

  return (
    <Card sx={{ p: 1 }}>
      <CardHeader title={"Attributs"} />
      {_.isArray(inputsError) ? (
        <CardContent sx={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
          <Box sx={{ flex: 1, display: "flex", alignItems: "flex-end" }}>
            <TextFieldsRounded
              sx={{
                color: inputsError.includes("name") ? "error.main" : "primary.main",
                mr: 1,
                my: 0.5,
              }}
            />
            <TextField
              id="newEvent-name"
              label="Nom de l'évènement"
              name="name"
              value={event.name ?? ""}
              onChange={handleChange}
              type="text"
              variant="standard"
              fullWidth
              error={inputsError.includes("name")}
            />
          </Box>
          {userRole <= 1 && (
            <Box sx={{ flex: 1, display: "flex", alignItems: "flex-end" }}>
              <HiveRounded sx={{ color: "primary.main", mr: 1, my: 0.5 }} />
              <FormControl variant="standard" fullWidth>
                <InputLabel id="IL_activity_select">Activité jeunes</InputLabel>
                <Select name="activityId" value={event.activityId ?? ""} onChange={handleChange} label="Activité jeunes" labelId="IL_activity_select">
                  {activities.map(({ _id, name }) => (
                    <MenuItem key={_id} value={_id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
          <Box sx={{ flex: 1, display: "flex", alignItems: "flex-end" }}>
            <NumbersRounded
              sx={{
                color: inputsError.includes("selections") ? "error.main" : "primary.main",
                mr: 1,
                my: 0.5,
              }}
            />
            <TextField
              label="Nombre de choix max."
              name="selections"
              value={event.selections ?? 3}
              inputProps={{ step: 1, min: 0 }}
              onChange={handleChange}
              type="number"
              variant="standard"
              fullWidth
              error={inputsError.includes("selections")}
            />
          </Box>
          <Box sx={{ flex: 1, display: "flex", alignItems: "flex-end" }}>
            <NumbersRounded
              sx={{
                color: inputsError.includes("validations") ? "error.main" : "primary.main",
                mr: 1,
                my: 0.5,
              }}
            />
            <TextField
              label="Nombre de validation(s) max."
              name="validations"
              inputProps={{ step: 1, min: 0 }}
              value={event.validations ?? 1}
              onChange={handleChange}
              type="number"
              variant="standard"
              fullWidth
              error={inputsError.includes("validations")}
            />
          </Box>
        </CardContent>
      ) : (
        <CardContent>
          <Typography textAlign={"center"}>Loading...</Typography>
        </CardContent>
      )}
    </Card>
  )
}
