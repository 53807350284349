import { Box, Button, Card, CardActions, CardHeader, Container, Typography, useMediaQuery, useTheme } from "@mui/material"
import * as React from "react"

export default function StartCard({ eventName, setStarted }) {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("sm"))

  const handleStartClick = () => {
    setStarted(true)
    sessionStorage.setItem("mainFormStarted", "true")
  }

  return (
    <Container maxWidth={"sm"} sx={{ p: 3, display: "flex", flexDirection: "column", gap: 2 }}>
      <Card sx={{ p: 1 }}>
        <CardHeader
          title={
            <>
              <Typography variant={matches ? "inherit" : "h6"} align="center">
                {`Ateliers ${eventName}`}
              </Typography>
            </>
          }
        />
        <Box sx={{ p: 1, px: 4 }}>
          <Typography variant="body1" align="center" gutterBottom>
            {`Ce formulaire permet de faire une demande d'inscription aux ateliers ${eventName} pour un ou plusieurs enfants.`}
          </Typography>
        </Box>
        <CardActions sx={{ justifyContent: "center" }}>
          <Button variant="contained" onClick={handleStartClick}>
            Commencer
          </Button>
        </CardActions>
      </Card>
    </Container>
  )
}
