import { Card, CardContent, CardHeader, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { getActivityUsers } from "../../../../redux/slices/usersSlice"
import UserCard from "./userCard/UserCard"
import { isEmpty } from "lodash"

export default function ManagersList() {
  const { managers } = useSelector(getActivityUsers)

  return (
    <Card elevation={0}>
      <CardHeader sx={{ px: 1, filter: "none", background: "white" }} title={`Managers`} />
      <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {!isEmpty(managers) ? (
          managers.map((manager, index) => <UserCard key={index} userIndex={index} isManager={true} />)
        ) : (
          <Typography textAlign={"center"}>{"Aucun manager pour cet évènement"}</Typography>
        )}
      </CardContent>
    </Card>
  )
}
