import { Box, Icon, Typography } from "@mui/material"
import { isEmpty } from "lodash"

import { getMainForm } from "../../../../redux/slices/mainFormSlice"
import { useSelector } from "react-redux"

export default function ChoicesBlock({ choices, numValidWanted }) {
  const {
    config: {
      workshops,
      event: { validations },
    },
  } = useSelector(getMainForm)

  const getWsName = (wsId) => workshops[workshops.map((ws) => ws._id).indexOf(wsId)].name

  return (
    <Box sx={{ ml: 1, display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography variant={"h6"} fontWeight={"bold"} color={"primary"}>
        Choix des ateliers
      </Typography>
      {validations > 1 && (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Icon color="primary" baseClassName="material-icons-round">
            {"navigate_next"}
          </Icon>
          <Typography fontWeight={"bold"}>{`Cet enfant veut participer à`}</Typography>
          <Typography fontWeight={"bolder"} color={"primary"}>
            {numValidWanted}
          </Typography>
          <Typography fontWeight={"bold"}>{`atelier${numValidWanted > 1 ? "s" : ""} `}</Typography>
        </Box>
      )}
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
        {!isEmpty(choices) ? (
          choices.map((wsId, key) => (
            <Box
              key={`choice-${key}`}
              sx={{
                display: "flex",
                gap: 1,
                backgroundColor: "primary.bg",
                borderRadius: 1,
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  borderRadius: 1,
                  backgroundColor: "primary.main",
                  color: "white!important",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "4ch",
                  width: `4ch`,
                }}
              >
                {key + 1}
              </Box>
              <Typography fontWeight={"bold"} sx={{ mr: 2 }}>
                {getWsName(wsId)}
              </Typography>
            </Box>
          ))
        ) : (
          <Typography>Pas de données à afficher...</Typography>
        )}
      </Box>
    </Box>
  )
}
