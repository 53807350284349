import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"
import { useKeycloak } from "@react-keycloak/web"
import { useSnackbar } from "notistack"
import { useDispatch } from "react-redux"
import { deleteActivity } from "../../../redux/slices/activitiesSlice"

export default function DeleteActivity(props) {
  const { open, handleClose, actId } = props
  const dispatch = useDispatch()
  const { keycloak } = useKeycloak()
  const { enqueueSnackbar } = useSnackbar()

  const handleConfirm = () => {
    dispatch(deleteActivity(actId, keycloak.token)).then((result) => {
      if (result.text === "error") {
        return enqueueSnackbar(`Une erreur est survenue au moment de supprimer l'activité`, {
          variant: "error",
        })
      }
      if (result.text === "ok") {
        return enqueueSnackbar(`Activité supprimée`, {
          variant: "success",
        })
      }
    })
    handleClose()
  }

  return (
    <Dialog open={open} keepMounted={false}>
      <DialogTitle variant="h5" color={"error.main"}>
        Supprimer une activité
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{`Souhaitez-vous vraiment supprimer l'activité ?`}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={handleClose}>
          Annuler
        </Button>
        <Button variant="text" color="error" onClick={handleConfirm}>
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  )
}
