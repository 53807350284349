import { Box, Divider, Typography, useMediaQuery, useTheme } from "@mui/material"
import * as React from "react"

export function FormHeader({ eventName, logos, title }) {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("sm"))

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: matches ? "row" : "column",
        alignItems: matches ? "center" : "unset",
        gap: matches ? 3 : 4,
        p: 2,
        overflow: "auto",
      }}
    >
      <Box sx={{ display: "flex", gap: 2 }}>
        {logos.includes("heig") && (
          <img
            style={{ height: matches ? "48px" : "32px", objectFit: "contain" }}
            src={"https://storage.googleapis.com/visual-identity/logo/2020-slim.svg"}
            alt="Logo HEIG-VD"
          />
        )}
        {logos.includes("cpnv") && (
          <img
            style={{ height: matches ? "48px" : "32px", objectFit: "contain", imageRendering: "high-quality" }}
            src={"https://storage.googleapis.com/visual-identity/logo/divers/cpnv.png"}
            alt="Logo HEIG-VD"
          />
        )}
      </Box>
      {matches && <Divider orientation="vertical" variant="middle" flexItem color={"black"} />}
      <Box sx={{ textAlign: matches ? "left" : "center" }}>
        <Typography variant="h4" noWrap>{`${eventName ?? "-"}`}</Typography>
        <Typography variant="sub5" noWrap>
          {title}
        </Typography>
      </Box>
    </Box>
  )
}
