import { Box, Divider, Typography } from "@mui/material"
import ObjectBlock from "./ObjectBlock"
import ChoicesBlock from "./ChoicesBlock"
import StringBlock from "./StringBlock"

export default function Section({ title, blocks = [] }) {
  /**
   * check if the blocks are valid and should be displayed
   *  block has to be a string or an Object
   *  if it's an Object, it has to have a "valid" property
   *  if it's a string, it has to be a key of mainForm
   *
   * @returns {JSX.Element[]}
   */
  const displayBlocks = () => {
    return blocks.map((block) => {
      const obj = block.obj[block.name]
      const key = `block-${block.name}`

      if (obj.constructor === Object && !(Object.keys(obj).length === 0)) return <ObjectBlock context={block.name} obj={block.obj} key={key} />
      else if (obj.constructor === String && !(obj.length === 0)) return <StringBlock context={block.name} obj={block.obj} key={key} />
      else if (block.name === "choices" && obj.constructor === Array && !(obj.length === 0))
        return <ChoicesBlock key={key} choices={block.obj.choices} numValidWanted={block.obj.numValidWanted} />
      else return null
    })
  }

  return (
    <>
      <Divider>
        <Typography variant="sub5" fontWeight={"bold"}>
          {title}
        </Typography>
      </Divider>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>{displayBlocks()}</Box>
    </>
  )
}
