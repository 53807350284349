import {
  AddRounded,
  ArchiveRounded,
  ContentCopyRounded,
  DeleteRounded,
  DynamicFormTwoTone,
  EditRounded,
  EmojiObjectsTwoTone,
  EventTwoTone,
  HighlightOffRounded,
  InfoRounded,
  InsertDriveFileTwoTone,
  RestoreFromTrashRounded,
  ThumbUpRounded,
  UnarchiveRounded,
} from "@mui/icons-material"

export const ACTIONS_LABEL = {
  edit: "Modifier",
  archive: "Archiver",
  unarchive: "Restorer",
  delete: "Supprimer",
  restore: "Restaurer",
  remove: "Retirer",
  example: "row dialogs here",
  new: "Créer",
  enable: "Activer",
  disable: "Désactiver",
  duplicate: "Dupliquer",
  info: "Détails",
}

export const ACTIONS_COLOR = {
  edit: "warning",
  archive: "info",
  unarchive: "success",
  delete: "error",
  restore: "success",
  remove: "error",
  example: "primary",
  new: "success",
  enable: "primary",
  disable: "error",
  duplicate: "primary",
  info: "info",
}

export const ACTIONS_ICON = {
  edit: <EditRounded />,
  archive: <ArchiveRounded />,
  unarchive: <UnarchiveRounded />,
  delete: <DeleteRounded />,
  restore: <RestoreFromTrashRounded />,
  remove: <HighlightOffRounded />,
  event: <EventTwoTone />,
  activity: <EmojiObjectsTwoTone />,
  form: <DynamicFormTwoTone />,
  export: <InsertDriveFileTwoTone />,
  new: <AddRounded />,
  example: <ThumbUpRounded />,
  duplicate: <ContentCopyRounded />,
  info: <InfoRounded />,
}
