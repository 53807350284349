import { Box, Button, Container, Typography } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { useNavigate } from "react-router-dom"

/**
 * Component NotFound
 *
 * display 404 page with go back to home button
 */
export default function ServerError({ code }) {
  let navigate = useNavigate()
  return (
    <Container id={"serverError_container"}>
      <Box sx={{ textAlign: "center", pt: 4 }}>
        <Typography variant="h1">500</Typography>
        <Typography variant="sub5">Server error</Typography>
        <br />
        <Typography variant="body2">{`Code : ${code}`}</Typography>
        <br />
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => {
            navigate(-1)
          }}
          sx={{ p: 1 }}
        >
          Go back
        </Button>
      </Box>
    </Container>
  )
}
