import { MoreVert } from "@mui/icons-material"
import { IconButton, Menu } from "@mui/material"
import PropTypes from "prop-types"
import { cloneElement, useState } from "react"

export default function MagicRowMenu(props) {
  const { items } = props

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => setAnchorEl(null)

  return (
    <div>
      <IconButton sx={{ p: 0 }} onClick={handleClick} onEnded={(e) => e.stopPropagation()}>
        <MoreVert />
      </IconButton>
      <Menu
        PaperProps={{ sx: { width: "auto" } }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {/*{items}*/}
        {items.map((item) => cloneElement(item, { handleCloseMenu: handleClose }))}
        {/*    CLONER LES ITEMS ET LEUR DONNER EN PROPS HANDLE CLOSE POUR QUE L'ON PUISSE REFERMER LE MENU APRÈS LE CLIC D'UN ITEM*/}
      </Menu>
    </div>
  )
}

MagicRowMenu.propTypes = {
  items: PropTypes.array,
}
