import { createSlice } from "@reduxjs/toolkit"
import apiClient from "../../configs/axiosCfg"
import { postWorkshop } from "./workshopsSlice"

const initialState = {
  db: {},
  local: {},
}

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    initEvent: (state, action) => {
      return { db: action.payload, local: action.payload }
    },
    setEvent: (state, action) => {
      return { ...state, local: action.payload }
    },
    setAttribute: (state, action) => {
      return { ...state, local: { ...state.local, [action.payload.key]: action.payload.value } }
    },
  },
})

export const { initEvent, setEvent, setAttribute } = eventSlice.actions

export const getEventLocal = (state) => state.event.local
export const getEventDb = (state) => state.event.db

export const getAll = (state) => state

export const fetchEvent = (eventId, kToken) => async (dispatch) =>
  apiClient
    .get(`/events/${eventId}`, {
      headers: { Authorization: "Bearer " + kToken },
    })
    .then((response) => {
      dispatch(initEvent(response.data))
      return { status: response.status, text: "ok" }
    })
    .catch((error) => {
      console.error(error.response.data)
      return { status: error.toJSON().status, text: "error" }
    })

export const postEvent = (kToken) => async (dispatch, getState) =>
  apiClient
    .post(
      `/events`,
      { event: getState().event.local },
      {
        headers: { Authorization: "Bearer " + kToken },
      }
    )
    .then((response) => {
      const eventId = response.data.id
      const workshopsBody = {
        workshops: getState()
          .workshops.local.filter((ws) => !ws.deleted)
          .map((ws) => ({
            ...ws,
            event: eventId,
          })),
      }
      return dispatch(postWorkshop(workshopsBody, kToken)).then((workshops) => {
        if (workshops === "error") return "error"
        return "ok"
      })
    })
    .catch((error) => {
      console.error(error.response.data)
      return "error"
    })

export const putEvent = (kToken) => async (dispatch, getState) =>
  apiClient
    .put(
      `/events/${getState().event.local._id}`,
      { event: getState().event.local },
      {
        headers: { Authorization: "Bearer " + kToken },
      }
    )
    .then((response) => {
      dispatch(initEvent(response.data))
      return "ok"
    })
    .catch((error) => {
      console.error(error.response.data)
      return "error"
    })

export const archiveEvent = (kToken, archived) => async (dispatch, getState) =>
  apiClient
    .put(
      `/events/${getState().event.local._id}`,
      { event: { archived: archived } },
      {
        headers: { Authorization: "Bearer " + kToken },
      }
    )
    .then(() => {
      return "ok"
    })
    .catch((error) => {
      console.error(error.response.data)
      return "error"
    })

export const deleteEvent = (kToken) => async (dispatch, getState) =>
  apiClient
    .delete(
      `/events/${getState().event.local._id}`,

      {
        headers: { Authorization: "Bearer " + kToken },
      }
    )
    .then(() => {
      return "ok"
    })
    .catch((error) => {
      console.error(error.response.data)
      return "error"
    })

export default eventSlice.reducer
