import { Chip, Container } from "@mui/material"
import PageHeader from "../../components/pageHeader/PageHeader"
import { matchRoutes, Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { routes } from "../../configs/router"
import { useDispatch, useSelector } from "react-redux"
import { getUserContext } from "../../redux/slices/appSlice"
import { useEffect } from "react"
import { setArchived } from "../../redux/slices/activitiesSlice"

export default function Activities() {
  const dispatch = useDispatch()
  const [search] = useSearchParams()
  const archived = !!search.get("archived")
  const location = useLocation()
  const navigate = useNavigate()
  const userRole = useSelector(getUserContext).role
  const { app_role, context } = matchRoutes(routes(), location).at(-1).route

  useEffect(() => {
    if (app_role > -1 && userRole > app_role) return navigate("../404", { replace: true })
  }, [userRole, app_role])

  useEffect(() => {
    dispatch(setArchived(archived))
  }, [archived])

  return (
    <Container>
      <PageHeader
        title={
          archived ? (
            <>
              {context.title} <Chip sx={{ fontSize: "1rem", ml: 1 }} label={"Archives"} />
            </>
          ) : (
            context.title
          )
        }
        nav={context.breadcrumbs}
        actions={context.actions}
      />
      <Outlet />
    </Container>
  )
}
