import { MuiTelInput } from "mui-tel-input"
import { translateRegLabel } from "../../utils/constants/registrations"

// eslint-disable-next-line no-unused-vars
export function MuiPhoneInput({ error, fields, form, field, required, sx }) {
  return (
    <MuiTelInput
      defaultCountry="CH"
      preferredCountries={["CH", "FR"]}
      value={fields[field]}
      label={translateRegLabel[field.split("_")[1]]}
      name={field}
      inputProps={{
        name: field,
        onChange: form.handleChangeEvent,
        onBlur: form.handleBlurEvent,
      }}
      required={required}
      sx={{ ...sx }}
      error={!!error}
      helperText={error ?? " "}
    />
  )
}
