import { Box, TableCell, TableSortLabel, Typography } from "@mui/material"
import { visuallyHidden } from "@mui/utils"
import { getRegistrationsState, setAttribute } from "../../redux/slices/registrationsSlice"
import { useDispatch, useSelector } from "react-redux"

export default function RTHeadCell({ index, align, cellData }) {
  const { sortBy, orderBy } = useSelector(getRegistrationsState)
  const dispatch = useDispatch()

  const colsSorting = (event, property) => {
    const isAsc = sortBy === property && orderBy === "asc"
    dispatch(setAttribute({ key: "orderBy", value: isAsc ? "desc" : "asc" }))
    dispatch(setAttribute({ key: "sortBy", value: property }))
  }

  const handleColsSorting = (property) => (event) => {
    colsSorting(event, property)
  }

  return (
    <TableCell
      key={`RTH-th-${index}`}
      align={align ?? "left"}
      sx={{ whiteSpace: "nowrap", alignItems: "center" }}
      sortDirection={sortBy === index ? orderBy : false}
    >
      {!!orderBy && !!colsSorting ? (
        <TableSortLabel active={sortBy === index} direction={sortBy === index ? orderBy : "asc"} onClick={handleColsSorting(index)}>
          <Typography fontWeight="bold" noWrap>
            {cellData}
          </Typography>
          {sortBy === index ? (
            <Box component="span" sx={visuallyHidden}>
              {orderBy === "desc" ? "sorted descending" : "sorted ascending"}
            </Box>
          ) : null}
        </TableSortLabel>
      ) : (
        { cellData }
      )}
    </TableCell>
  )
}
