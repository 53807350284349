import { isEqual } from "lodash"
import { cleanProperties } from "../forms"

export const translateRegLabel = {
  _id: "id",
  link_id: "id d'inscription",
  child: "enfant",
  firstname: "prénom",
  lastname: "nom",
  email: "email",
  phone: "téléphone",
  gender: "genre",
  birthdate: "naissance",
  parent: "parent",
  address: "adresse",
  line1: "ligne 1",
  line2: "ligne 2",
  zip: "NPA",
  city: "ville",
  school: "école",
  name: "nom",
  grade: "degré",
  path: "voie",
  class: "classe",
  choices: "choix",
  selected_choice: "choix sélectionné",
  status: "statut",
  newsletters: "newsletters",
  comments: "commentaires",
  tshirt_size: "t-shirt",
  transportation: "transport",
  diet: "régime alimentaire",
  risks_consent: "Risques",
  media_consent: "Médias",
  consent_filename: "formulaire de consentement",
  add_infos_url: "lien du formulaire de complément d'informations",
  unil_survey: "étude UNIL",
  admitted_status: "statut admission",
  eventId: "évènement",
  createdAt: "date d'inscription",
  updatedAt: "mis à jour le",
  age: "âge",
}

export const GENDER = ["", "Garçon", "Fille", "Autre"]

export const TSHIRT_SIZES = ["XS", "S", "M", "L", "XL"]

const objsToClean = ["child", "parent", "address", "school"]

export const getRegToSave = (registration, localRegistration) => {
  let regToSave = {}
  Object.keys(localRegistration).forEach((property) => {
    if (!isEqual(registration[property], localRegistration[property])) {
      regToSave[property] = objsToClean.includes(property) ? cleanProperties(localRegistration[property]) : localRegistration[property]
    }
  })
  return { registration: regToSave }
}
