import { Box, Card, CardContent, CardHeader, CircularProgress, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { getRegistration } from "../../../../redux/slices/registrationSlice"
import { isEmpty, isUndefined } from "lodash"
import { translateRegLabel } from "../../../../utils/constants/registrations"
import TypoField from "./TypoField"
import Field from "./Field"

const DETAILS_REG_INFOS_SCHEMA = [
  {
    name: undefined,
    fields: [
      { input: "risks_consent", type: "bool" },
      { input: "transportation", type: "bool" },
    ],
  },
  {
    name: undefined,
    fields: [
      { input: "media_consent", type: "bool" },
      { input: undefined, type: undefined },
    ],
  },
  {
    name: undefined,
    fields: [
      { input: "unil_survey", type: "bool" },
      { input: undefined, type: undefined },
    ],
  },
  {
    name: undefined,
    fields: [
      { input: "diet", type: "bool" },
      { input: undefined, type: undefined },
    ],
  },
]

export default function MoreInfos({ editMode = false }) {
  const registration = useSelector(getRegistration)

  if (isEmpty(registration)) {
    return <CircularProgress color="primary" />
  }

  const handleDataDisplay = ({ input, type }) => {
    const data = registration?.[input]
    if (type === "bool") return data ? "Oui" : "Non"
    return data || ""
  }

  return (
    <Card>
      <CardHeader title={"Champs fiche de consentement"} />
      <CardContent sx={{ pt: "8px!important", pb: "16px!important" }}>
        {!isEmpty(registration) ? (
          <Box
            sx={{
              display: "flex",
              borderRadius: 1,
              border: "1px solid whitesmoke",
              overflow: "clip",
            }}
          >
            {DETAILS_REG_INFOS_SCHEMA.map(({ name, fields }, index) => (
              <Box
                key={index}
                sx={{
                  flex: 1,
                  background: "white",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box>
                  {fields.map(({ input, type }, index) => (
                    <TypoField
                      editMode={editMode}
                      key={index}
                      title={translateRegLabel[input]}
                      content={editMode && !isUndefined(input) ? <Field field={{ input, type }} name={name} /> : handleDataDisplay({ input, type })}
                      bgColor={!(index % 2) ? "whitesmoke" : "#ffffff"}
                      borderColor={index % 2 ? "whitesmoke" : "#ffffff"}
                      titleWidth={input === "diet" ? "17ch" : undefined}
                    />
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        ) : (
          <Typography>Pas de données à afficher...</Typography>
        )}
      </CardContent>
    </Card>
  )
}
