import { Box, Card, CardContent, CardHeader, Tooltip, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { getEventUsers } from "../../../../redux/slices/usersSlice"
import UserCard from "./userCard/UserCard"
import AddUserCard from "./addUserCard/AddUserCard"
import { HelpRounded } from "@mui/icons-material"
import { isEmpty } from "lodash"

const action = (
  <Box sx={{ display: "flex", alignItems: "center", pr: 1 }}>
    <Tooltip
      color="shade"
      title={
        <Box sx={{ p: 1 }}>
          <Typography color={"#ffffff"}>
            {
              "Les utilisateurs ont accès uniquement aux demandes d'inscription. Seuls les éditeurs peuvent modifier le statut des demandes d'inscription."
            }
          </Typography>
        </Box>
      }
    >
      <HelpRounded />
    </Tooltip>
  </Box>
)

export default function UsersList() {
  const { users } = useSelector(getEventUsers)

  return (
    <Card elevation={0}>
      <CardHeader sx={{ px: 1, filter: "none", background: "white" }} title={`Utilisateurs`} action={action} />
      <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {!isEmpty(users) ? (
          users.map((user, index) => <UserCard key={index} userIndex={index} />)
        ) : (
          <Typography textAlign={"center"}>{"Aucun utilisateur pour cet évènement"}</Typography>
        )}
        <AddUserCard />
      </CardContent>
    </Card>
  )
}
