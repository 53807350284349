import { Box, DialogTitle, Typography } from "@mui/material"
import {
  STATUSES,
  STATUSES_COLOR,
  STATUSES_ICON,
  STATUSES_LABEL,
} from "../../../../utils/constants/statuses"

export default function ConfirmDialogTitle({ numStatus }) {
  return (
    <DialogTitle
      sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: 3 }}
    >
      <Typography sx={{ flex: 1 }} variant="inherit">{`Appliquer le statut`}</Typography>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 1,
          fontWeight: "bold",
        }}
      >
        <Typography
          variant="body1"
          fontWeight={"bold"}
          color={`${STATUSES_COLOR[STATUSES[numStatus]]}.main`}
        >
          {STATUSES_LABEL[STATUSES[numStatus]]}
        </Typography>
        {STATUSES_ICON[STATUSES[numStatus]]}
      </Box>
    </DialogTitle>
  )
}
