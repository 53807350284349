import { STATUSES, STATUSES_COLOR } from "../../../../../../../../utils/constants/statuses"
import { Box, Card, CardActionArea } from "@mui/material"
import { BR_LARGE } from "../../../../../../../../utils/theme/app"
import Choice from "./Choice"

import { useDispatch, useSelector } from "react-redux"
import { getRegsByStatus, setAttribute } from "../../../../../../../../redux/slices/registrationsSlice"
import Header from "./Header"

export default function StatusCard({ numStatus }) {
  const regsByStatus = useSelector(getRegsByStatus)
  const statusRegs = regsByStatus[numStatus]
  const dispatch = useDispatch()

  const status = STATUSES[numStatus]

  const onClickButton = () => {
    dispatch(setAttribute({ key: "selectedStatus", value: parseInt(numStatus) }))
  }

  return (
    <Card
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        backgroundColor: `${STATUSES_COLOR[status]}.bg`,
        borderRadius: BR_LARGE,
      }}
    >
      <CardActionArea onClick={onClickButton}>
        <Header numStatus={numStatus} />
        <Box
          id="StatsStatus-Content"
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "space-around",
            p: 1,
            pb: 2,
            overflow: "auto",
          }}
        >
          {Object.keys(statusRegs).length > 4 ? (
            <>
              <Choice numStatus={numStatus} number={"1"} />
              <Choice numStatus={numStatus} number={"2"} />
              <Choice numStatus={numStatus} number={"3"} />
              <Choice numStatus={numStatus} number={"N"} isN={true} />
            </>
          ) : (
            Object.keys(statusRegs).map((number) => <Choice key={number} numStatus={numStatus} number={number} />)
          )}
        </Box>
      </CardActionArea>
    </Card>
  )
}
