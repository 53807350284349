//Modules imports
import * as React from "react"
import { useEffect, useState } from "react"
import { Autocomplete, Box, CircularProgress, TextField, Typography } from "@mui/material"

// Requests imports
import { useQuery } from "@tanstack/react-query"
import apiADPeople from "../../configs/axiosAdPeopleCfg"
import { SearchRounded } from "@mui/icons-material"

/**
 * Component AutocompleteAdPeople
 *
 * Custom MUI Autocomplete to search in the AD people API (no multiselect)
 *
 * @param {*} props
 * -
 * - textInputLabel: string for the input label
 * - callback: to save new selected people
 * - apiAdPeopleToken: token for api requests
 *
 * @returns JSX.Element
 */
export default function AutocompleteAdPeople({ textInputLabel, callback, apiAdPeopleToken, variant = "standard", size = "medium" }) {
  // const [open, setOpen] = useState(false) // Autocomplete open state (bool)
  const [getErrors, setErrors] = useState("") // handles Autocomplete errors
  const [options, setOptions] = useState([]) // array of options for selection
  const [txError] = useState(false) // text error display state (bool)
  // Autocomplete input text
  const [getInputValue, setInputValue] = useState("")

  // concat project manager name
  const fullName = (person) => {
    return person.firstname && person.lastname ? person.firstname + " " + person.lastname : "undefined"
  }

  // PREPARE REQUEST HEADERS
  const config = {
    headers: { "x-api-key": apiAdPeopleToken },
  }

  // Request get persons by name and email
  const { isFetching, refetch: getPersons } = useQuery(
    ["getPersons" + textInputLabel],
    async () => {
      return await apiADPeople.get("/search?s=" + getInputValue, config)
    },
    {
      enabled: false,
      onSuccess: (res) => {
        setOptions(res.data)
      },
      onError: (err) => {
        setErrors(err)
        console.error(err)
      },
    }
  )

  // On change input value query call api
  useEffect(() => {
    if (getInputValue !== "" && getInputValue !== null) getPersons()
  }, [getInputValue])

  ///////////////////////// Component render /////////////////////////////
  return getErrors ? (
    <Typography>{getErrors.toString()}</Typography>
  ) : (
    <Autocomplete
      fullWidth
      isOptionEqualToValue={(option, value) => fullName(option) === fullName(value)}
      getOptionLabel={(option) => fullName(option)}
      options={options}
      filterOptions={(x) => x}
      loading={isFetching}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      onChange={(event, newValue) => {
        if (newValue) callback({ name: fullName(newValue), email: newValue.email })
      }}
      inputValue={getInputValue}
      renderOption={(props, option) => {
        return (
          <li {...props} key={textInputLabel + "_li_" + option.email}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Typography>{fullName(option)}</Typography>
              <Typography color={"gray"}>{" (" + option.email + ")"}</Typography>
            </Box>
          </li>
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={txError}
          variant={variant}
          size={size}
          placeholder={textInputLabel ? textInputLabel : "Person"}
          InputProps={{
            ...params.InputProps,
            sx: { gap: 1, height: size === "small" ? "49px" : "auto" },
            startAdornment: isFetching ? <CircularProgress color="inherit" size={20} sx={{ ml: 1 }} /> : <SearchRounded sx={{ ml: 1 }} />,
            // endAdornment: {params.InputProps.endAdornment,
          }}
        />
      )}
    />
  )
}
