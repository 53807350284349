import { Box, CircularProgress, Container } from "@mui/material"
import { Navigate, useParams } from "react-router-dom"
import { FormHeader } from "../FormHeader"
import { FormContent } from "./FormContent"
import { ObjectID } from "bson"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { fetchConfig, getAdForm, initState } from "../../redux/slices/adFormSlice"
import { isEmpty } from "lodash"
import ServerError from "../../components/ServerError"

export default function AdmissionForm() {
  const { formId, secret } = useParams()
  const { config, dbReg } = useSelector(getAdForm)
  const [error, setError] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    if (ObjectID.isValid(formId) && ObjectID.isValid(secret))
      dispatch(fetchConfig(formId, secret)).then((result) => {
        if (result.text === "error") setError(result.error)
      })
    return () => {
      dispatch(initState())
    }
  }, [formId, secret])

  if (!ObjectID.isValid(formId) || !ObjectID.isValid(secret)) {
    return <Navigate to={"/notFound"} />
  }

  if (!error && (isEmpty(config) || isEmpty(dbReg)))
    return (
      <Container maxWidth={"md"} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", py: 3 }}>
        <CircularProgress sx={{ m: "auto" }} color="heig" size={"3rem"} />
      </Container>
    )

  if (error && error.status >= 500) return <ServerError code={error.code} />
  if (error && error.status >= 400) return <Navigate to={"/404"} />

  return (
    <Box id="AdmissionFormContainer" sx={{ minHeight: "inherit", minWidth: "inherit", display: "flex", flexDirection: "column" }}>
      <FormHeader eventName={config?.event?.name} logos={config.logos} title={"Formulaire d'admission"} />
      <FormContent />
    </Box>
  )
}
