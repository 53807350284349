import { Box, Slider } from "@mui/material"

export default function RangeSlider({ value = [8, 12], onChange = () => {} }) {
  return (
    <Box sx={{ mx: 2, minWidth: "300px" }}>
      <Slider
        sx={{
          "& .MuiSlider-valueLabel": {
            fontSize: 16,
            fontWeight: "bold",
            top: 0,
            backgroundColor: "unset",
            color: "primary.main",
            "&:before": {
              display: "none",
            },
          },
        }}
        value={value}
        onChange={onChange}
        size="large"
        min={0}
        max={20}
        step={1}
        valueLabelDisplay={"on"}
      />
    </Box>
  )
}
