import { Box, Button, CircularProgress, Typography } from "@mui/material"
import { getLocalActivity, setLocalActivity } from "../../../redux/slices/activitiesSlice"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { AddManagers } from "./AddManagers"
import { AttributesInputs } from "./AttributesInputs"
import { useMutation } from "@tanstack/react-query"
import apiClient from "../../../configs/axiosCfg"
import { useKeycloak } from "@react-keycloak/web"
import { useSnackbar } from "notistack"

export default function NewActivity() {
  const { keycloak } = useKeycloak()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const localActivity = useSelector(getLocalActivity)
  const navigate = useNavigate()
  const [queriesProcessStatus, setQueriesProcessStatus] = useState(0)
  const [APMutations, setAPMutations] = useState([])
  const [statusMessage, setStatusMessage] = useState({ status: "", message: "" })

  useEffect(() => {
    dispatch(setLocalActivity({ name: "", managers: [] }))
  }, [])

  const handleClose = () => {
    dispatch(setLocalActivity({}))
    navigate("../list")
  }

  const handleConfirm = () => {
    if (!localActivity.name)
      return setStatusMessage({
        status: "error",
        message: "Vous devez renseigner un nom pour l'activité",
      })
    setQueriesProcessStatus(1)
    saveActivity()
  }

  // DB SAVE ACTIVITY REQUEST
  const postActivity = useMutation((body) =>
    apiClient.post("/activities", body, {
      headers: { Authorization: "Bearer " + keycloak.token },
    })
  )

  // DB SAVE PERMISSIONS REQUEST
  const postActivityPermission = useMutation(({ body, activityId }) => {
    return apiClient.post(`/activities/${activityId}/permissions`, body, {
      headers: { Authorization: "Bearer " + keycloak.token },
    })
  })

  // REQUESTS PROCESS
  const saveActivity = () => {
    // Try to save activity
    postActivity.mutate(
      { activity: { ...localActivity, archived: false, deleted: false } },
      {
        // On error => set error status and config message
        onError: (err) => {
          setStatusMessage({
            status: "error",
            message: `ERREUR: ${err.response.data.context} [code: ${err.response.data.code}]`,
          })
          setQueriesProcessStatus(-1)
        },
        // On success => start step 2 saving process
        onSuccess: (res) => {
          enqueueSnackbar("Nouvelle activité créée.", { variant: "success" })
          // if no managers to save, ending process and success status
          if (localActivity.managers.length < 1) {
            setQueriesProcessStatus(2)
            return handleClose()
          }
          // if managers to save, send save requests
          // For each request we store response in array to check each status
          localActivity.managers.forEach(async (user) => {
            // const resPA = await postActivityPermission.mutateAsync({ activityId: res.data, userCard })
            postActivityPermission
              .mutateAsync({ body: { user }, activityId: res.data })
              .then((resPA) => {
                if (resPA.status === 200 || resPA.status === 201) {
                  setAPMutations((prev) => [...prev, { status: 1, data: resPA.data }])
                } else {
                  setAPMutations((prev) => [...prev, { status: -1, user: user.name, data: resPA.data }])
                }
              })
              .catch((error) => setAPMutations((prev) => [...prev, { status: -1, user: user.name, data: error }]))
          })
        },
      }
    )
  }

  useEffect(() => {
    if (queriesProcessStatus === 1 && APMutations.length > 0) {
      if (APMutations.length === localActivity.managers.length) {
        if (APMutations.filter((mut) => mut.status === -1).length > 0) {
          setQueriesProcessStatus(-2)
        } else {
          enqueueSnackbar("Permissions attribuées.", { variant: "success" })
          setQueriesProcessStatus(2)
          handleClose()
        }
      }
    }
  }, [APMutations])

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
      {/* only show form when saving process not started*/}
      {queriesProcessStatus === 0 ? (
        <>
          <AttributesInputs />
          <AddManagers />
          <Box sx={{ mb: 4, display: "flex", justifyContent: "space-between", gap: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
              <Typography fontWeight={"bold"} color={`${statusMessage.status}.main`}>
                {statusMessage.message}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
              <Button variant="text" color="inherit" onClick={handleClose}>
                Annuler
              </Button>
              <Button variant="contained" onClick={handleConfirm} disabled={!postActivity.isIdle}>
                {"Créer l'activité"}
              </Button>
            </Box>
          </Box>
        </>
      ) : queriesProcessStatus === 1 ? (
        <CircularProgress color="heig" sx={{ alignSelf: "center" }} />
      ) : queriesProcessStatus === 2 ? (
        <Typography>{"L'activité a bien été créée."}</Typography>
      ) : queriesProcessStatus === -1 ? (
        <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: 2 }}>
          <Typography variant="h5" color={`${statusMessage.status}.main`}>
            {statusMessage.message}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button variant="contained" onClick={handleClose}>
              Fermer
            </Button>
          </Box>
        </Box>
      ) : queriesProcessStatus === -2 ? (
        <>
          <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: 2 }}>
            <Typography variant="h6" textAlign={"center"} color={`heig.main`} lineHeight={1.4} paragraph>
              {`Erreur ${(<br />)}
              L'activité a bien été créée mais les droits n'ont pas pu être attribué à : 
              ${APMutations.filter((mut) => mut.status === -1)
                .map((mut) => mut.user)
                .join(", ")}
              .${(<br />)}
              Essayez plus tard d'ajouter les droits sur l'activité dans le menu de modification
              d'une activité.`}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button variant="contained" onClick={handleClose}>
                Fermer
              </Button>
            </Box>
          </Box>
        </>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button variant="contained" onClick={handleClose}>
            Fermer
          </Button>
        </Box>
      )}
    </Box>
  )
}
