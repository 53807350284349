import { matchPath, matchRoutes, Outlet, useLocation, useNavigate, useParams } from "react-router-dom"
import { Container } from "@mui/material"
import PageHeader from "../../components/pageHeader/PageHeader"
import { routes } from "../../configs/router"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { getEventDb } from "../../redux/slices/eventSlice"
import { getWorkshop } from "../../redux/slices/workshopSlice"
import { getUserContext } from "../../redux/slices/appSlice"

export default function Events() {
  const location = useLocation()
  const params = useParams()
  const navigate = useNavigate()
  const userRole = useSelector(getUserContext).role

  const options = {
    eventName: useSelector(getEventDb).name,
    eventId: params?.eventId,
    workshopName: useSelector(getWorkshop).name,
    workshopId: params?.workshopId,
  }
  const { app_role, context } = matchRoutes(routes(options), location).at(-1).route

  useEffect(() => {
    if (location.pathname.split("/").length === 4 && app_role > -1 && userRole > app_role) navigate("/dashboard/events/404", { replace: true })
  }, [userRole, app_role])

  useEffect(() => {
    if (matchPath(location.pathname, "/dashboard/events")) {
      return navigate("list", { replace: true })
    }
  }, [location])

  return (
    <Container>
      <PageHeader title={context.title} nav={context.breadcrumbs} actions={context.actions} />
      <Outlet />
    </Container>
  )
}
