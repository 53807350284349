import { Button } from "@mui/material"
import { ArchiveRounded, UnarchiveRounded } from "@mui/icons-material"
import { archiveEvent, getEventDb } from "../../../../redux/slices/eventSlice"
import { useDispatch, useSelector } from "react-redux"
import { useState } from "react"
import BasicConfirm from "../../../../components/dialogs/BasicConfirm"
import { useKeycloak } from "@react-keycloak/web"
import { useSnackbar } from "notistack"
import { useNavigate } from "react-router-dom"

export default function ArchiveButton() {
  const { name, archived, _id } = useSelector(getEventDb)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const dispatch = useDispatch()
  const { keycloak } = useKeycloak()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const handleConfirmArchiveEvent = (confirm) => {
    if (confirm) {
      dispatch(archiveEvent(keycloak.token, !archived)).then((response) => {
        if (response === "error") {
          return enqueueSnackbar("Une erreur est survenue lors de l'archive de l'évènement", {
            variant: "error",
          })
        } else {
          enqueueSnackbar(`Évènement ${archived ? "remis" : "archivé"}`, { variant: "success" })
          navigate(`/dashboard/events/${_id}`)
        }
      })
    }
    setConfirmDialogOpen(false)
  }

  return (
    <>
      <Button
        onClick={() => setConfirmDialogOpen(true)}
        disabled={confirmDialogOpen}
        color="warning"
        startIcon={archived ? <UnarchiveRounded /> : <ArchiveRounded />}
      >{`${archived ? "Remettre" : "Archiver"}`}</Button>
      <BasicConfirm
        open={confirmDialogOpen}
        onClose={handleConfirmArchiveEvent}
        title={`${archived ? "Remettre" : "Archiver"} ${name}`}
        content={`Êtes-vous sûr de vouloir ${archived ? "remettre" : "archiver"} l'évènement ?`}
      />
    </>
  )
}
