import { HelpRounded, Search } from "@mui/icons-material"
import { Box, Chip, Divider, InputAdornment, TextField, Tooltip, Typography } from "@mui/material"
import { useDispatch } from "react-redux"
import { setAttribute } from "../../redux/slices/registrationsSlice"
import { useCallback, useEffect, useState } from "react"
import { debounce } from "lodash"

export default function SearchInput({ placeholder }) {
  const dispatch = useDispatch()
  const [localInputValue, setLocalInputValue] = useState("")

  const getSearchResults = useCallback(
    debounce((value) => {
      dispatch(setAttribute({ key: "searchText", value }))
    }, 800),
    []
  )

  useEffect(() => {
    getSearchResults(localInputValue)
  }, [localInputValue])

  return (
    <>
      <TextField
        id="SearchInput"
        key={"SearchInput"}
        placeholder={placeholder ?? "Search..."}
        value={localInputValue}
        onChange={(event) => setLocalInputValue(event.target.value)}
        size={"small"}
        color="shade"
        sx={{
          alignItems: "center",
          ml: 1,
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Tooltip title={"Search"}>
                <Search />
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
      <Tooltip
        arrow
        PopperProps={{
          sx: {
            maxWidth: "400px",
            "& .MuiTooltip-arrow	": {
              color: "white!important",
              ml: -0.5,
            },
            "& .MuiTooltip-tooltip": {
              mr: -1,
              background: "white",
              color: "primary.main",
              fontWeight: "bold",
              fontSize: "1rem",
              maxWidth: "none",
              p: 2,
              verticalAlign: "bottom",
              filter: "drop-shadow(0 0rem 1mm lightgray)",
            },
          },
        }}
        placement="bottom-end"
        title={
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Typography fontWeight={"bold"}>Recherche simple</Typography>
              <Typography textAlign={"justify"}>{"Toutes les données d'une inscription sont prises en compte."}</Typography>
            </Box>
            <Divider />
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Typography fontWeight={"bold"}>Recherche par attribut</Typography>
              <Typography textAlign={"justify"}>
                {"Permet de rechercher une inscription par rapport à un attribut d'une demande d'inscription."}
              </Typography>
              <Box sx={{ my: 1 }}>
                <Typography fontWeight={"bold"} color={"primary.main"}>
                  {"Syntaxe: [attribut]:[valeur]"}
                </Typography>
                <Typography>{"Exemples: age:18, risques:oui"}</Typography>
              </Box>
              <Typography variant="sub6">{"Attributs disponibles "}</Typography>
              <Box sx={{ mt: 1, display: "flex", flexWrap: "wrap", gap: 1 }}>
                {[
                  "enfant",
                  "genre",
                  "naissance",
                  "age",
                  "email",
                  "telephone",
                  "parent",
                  "rue",
                  "npa",
                  "ville",
                  "degre",
                  "voie",
                  "classe",
                  "ecole",
                  "ecole-npa",
                  "ecole-ville",
                  "statut",
                  "statut-admission",
                  "ateliers",
                  "t-shirt",
                  "regime",
                  "commentaires",
                  "transport",
                  "newsletters",
                  "medias",
                  "risques",
                  "unil",
                ].map((attribute, index) => (
                  <Chip key={index} label={attribute} />
                ))}
              </Box>
            </Box>
          </Box>
        }
      >
        <HelpRounded sx={{ ml: 0.5, color: "darkgray", "&:hover": { color: "gray!important" } }} />
      </Tooltip>
    </>
  )
}
