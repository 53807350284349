import { LinearProgress, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import { useSelector } from "react-redux"
import { getUserContext } from "../../redux/slices/appSlice"
import { useLocation, useNavigate } from "react-router-dom"

export default function SidebarListItems(props) {
  const { menuTitle, items, small = false } = props
  const userContext = useSelector(getUserContext)
  const navigate = useNavigate()
  const { pathname } = useLocation()

  if (!userContext) return <LinearProgress color="heig" />

  return Object.keys(items)
    .filter((itemTitle) => userContext.role <= items[itemTitle].user_role)
    .map((itemTitle) => {
      const item = items[itemTitle]
      return (
        <ListItemButton
          sx={{ mb: 1, color: "dimgray" }}
          key={"sidebarList" + menuTitle + "Item" + itemTitle}
          selected={pathname.split("/")[2] === item.route.split("/")[2]}
          onClick={() => navigate(item.route)}
        >
          <ListItemIcon sx={{ minWidth: "2rem" }}>{item.icon}</ListItemIcon>
          {small ? " " : <ListItemText primary={itemTitle} />}
        </ListItemButton>
      )
    })
}
