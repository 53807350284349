import { Checkbox, TableCell, TableRow } from "@mui/material"
import RTCell from "./RTCell"
import { formatDateCH } from "../../utils/tablesHelpers"
import { getColsAndOptions } from "./utils"
import { useDispatch, useSelector } from "react-redux"
import { addRegToSelections, getRegistrationsState, removeRegFromSelections, setAttribute } from "../../redux/slices/registrationsSlice"
import { CommentRounded, WarningRounded } from "@mui/icons-material"
import { isEmpty } from "lodash"

import { BR_LARGE as ABR } from "../../utils/theme/app"

function HighlightCell({ row }) {
  return (
    <TableCell align="center" padding="checkbox">
      {!isEmpty(row.warnings) ? (
        <WarningRounded color="warning" sx={{ verticalAlign: "middle" }} />
      ) : !isEmpty(row.comments) ? (
        <CommentRounded sx={{ verticalAlign: "middle" }} color="info" />
      ) : (
        <></>
      )}
    </TableCell>
  )
}

export default function RTRow({ row = null }) {
  const { selectedStatus, selectionsRegs } = useSelector(getRegistrationsState)
  const dispatch = useDispatch()

  const handleRowClick = () => {
    row.callbackClick(row.id)
  }

  const handleCheckboxClick = () => {
    if (selectionsRegs.indexOf(row.id) >= 0) dispatch(removeRegFromSelections(row.id))
    else dispatch(addRegToSelections(row.id))
  }

  const REGEX_DATETIME = new RegExp(
    "([\\+-]?\\d{4}(?!\\d{2}\\b))((-?)((0[1-9]|1[0-2])(\\3([12]\\d|0[1-9]|3[01]))?|W([0-4]\\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\\d|[12]\\d{2}|3([0-5]\\d|6[1-6])))([T\\s]((([01]\\d|2[0-3])((:?)[0-5]\\d)?|24\\:?00)([\\.,]\\d+(?!:))?)?(\\17[0-5]\\d([\\.,]\\d+)?)?([zZ]|([\\+-])([01]\\d|2[0-3]):?([0-5]\\d)?)?)?)"
  )

  const { colsOptions } = getColsAndOptions(selectedStatus)

  return (
    <TableRow
      sx={{
        "&:hover > td": { backgroundColor: "whitesmoke", cursor: "pointer" },
        "&> td:first-of-type": { borderRadius: `${ABR} 0 0 ${ABR}` },
        "&> td:last-child": { borderRadius: `0 ${ABR} ${ABR} 0` },
      }}
      onClick={handleRowClick}
    >
      <TableCell align="left" padding="checkbox">
        <Checkbox checked={selectionsRegs.indexOf(row.id) >= 0} onClick={handleCheckboxClick} />
      </TableCell>
      {!!row.cells &&
        row.cells.map((cell, index) => {
          return (
            <RTCell
              key={index}
              cell={REGEX_DATETIME.test(cell) ? formatDateCH(cell) : cell}
              align={colsOptions[index]?.align}
              onClickCell={() => dispatch(setAttribute({ key: "selectedReg", value: row.id }))}
            />
          )
        })}
      <HighlightCell row={row} />
    </TableRow>
  )
}

// rowExample = {
//   id: reg["id"],
//   cells: [
//     fullName(reg["child_firstname"], reg["child_lastname"]),
//     getAge(reg["child_birthdate"]),
//     `${reg["address_zip"]} ${reg["address_city"]}`,
//     reg["school_grade"],
//     reg["school_name"],
//     <AJSelect items={getChoices(reg["choices"])} keyStart={"Simon Boucher-choices"} />,
//     formatDateCH(reg["createdAt"]),
//   ],
//   subRow: <Typography>{reg["grade"]}</Typography>,
//   srOpen: openCollapse.includes(reg["id"]),
//   setSrOpen: () => handleSetOpenCollapse(reg["id"]),
// }

// actionsRow = [<Action action={"example"} />],
