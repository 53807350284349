import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material"

export default function GenderSF({ InputLabelProps, error, helperText, label, name, onchange, required, sx, value }) {
  return (
    <FormControl sx={sx} error={error} required={required} name={name}>
      <InputLabel id="inputGenre" {...InputLabelProps}>
        {label}
      </InputLabel>
      <Select labelId="inputGenre" label={label} value={value} name={name} onChange={onchange}>
        <MenuItem value={1}>Garçon</MenuItem>
        <MenuItem value={2}>Fille</MenuItem>
        <MenuItem value={3}>Autre</MenuItem>
      </Select>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  )
}
