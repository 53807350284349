import { Box, Card, CardContent, CircularProgress, Collapse, Typography } from "@mui/material"
import MagicTable from "../../../components/magicTable/MagicTable"
import { useKeycloak } from "@react-keycloak/web"
import { useDispatch, useSelector } from "react-redux"
import { getUserContext } from "../../../redux/slices/appSlice"
import MagicMenuItem from "../../../components/magicTable/MagicMenuItem"
import { ArchiveRounded, DeleteRounded, EditRounded, UnarchiveRounded } from "@mui/icons-material"
import { useEffect, useState } from "react"
import { fetchActivities, getActivities, setDbActivity } from "../../../redux/slices/activitiesSlice"
import ArchiveActivity from "./ArchiveActivity"
import DeleteActivity from "./DeleteActivity"
import { useSnackbar } from "notistack"
import EditActivityCard from "./edit/EditActivityCard"
import { managersCells } from "./ManagersCells"

export default function ActivitiesList() {
  const userRole = useSelector(getUserContext).role
  const { list, dbActivity, archived } = useSelector(getActivities)
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { keycloak } = useKeycloak()
  const [fetchStatus, setFetchStatus] = useState(0)
  const [showArchive, setShowArchive] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [selectedActID, setSelectedActID] = useState(null)

  useEffect(() => {
    setFetchStatus(0)
    dispatch(fetchActivities(keycloak.token, archived)).then((result) => {
      if (result.text === "error") {
        setFetchStatus(-1)
        return enqueueSnackbar("Une erreur est survenue lors de la récupération des activités", {
          variant: "error",
        })
      }
      if (result.text === "ok") {
        setFetchStatus(1)
      }
    })
  }, [archived])

  const handleArchive = (item) => {
    setSelectedActID(item._id)
    setShowArchive(true)
  }

  const handleDelete = (item) => {
    setSelectedActID(item._id)
    setShowDelete(true)
  }

  const handleClose = () => {
    setSelectedActID(null)
    if (showDelete) setShowDelete(false)
    if (showArchive) setShowArchive(false)
  }

  if (fetchStatus === -1) return <Typography color="heig.main">{`Une erreur est survenue lors de la récupération des activités jeunes`}</Typography>

  if (fetchStatus === 0) return <CircularProgress color="heig" />

  if (list.length < 1)
    return (
      <Box sx={{ display: "flex", alignItems: "start" }}>
        <Typography variant="h6" textAlign={"center"}>
          {` Aucune activité à afficher...`}
        </Typography>
      </Box>
    )

  // DELETE ONLY IN ARCHIVE LIST
  const actionsSetup = (act) => {
    const initActions = []

    if (userRole === 0) {
      initActions.push(<MagicMenuItem key={1} icon={<EditRounded />} label="Modifier" action={() => dispatch(setDbActivity(act))} />)
      initActions.push(
        <MagicMenuItem
          key={2}
          icon={archived ? <UnarchiveRounded /> : <ArchiveRounded />}
          label={archived ? "Remettre" : "Archiver"}
          action={() => handleArchive(act)}
        />
      )
      if (archived) {
        initActions.push(<MagicMenuItem key={3} icon={<DeleteRounded />} label="Supprimer" action={() => handleDelete(act)} />)
      }
    }

    return initActions
  }

  let rows = list.map((act) => ({
    id: act._id,
    cells: [act.name, managersCells(act.managers)],
    actions: actionsSetup(act),
  }))

  return (
    <Box sx={{ display: "flex", alignItems: "start" }}>
      <Card sx={{ flex: 1 }}>
        <CardContent>
          <MagicTable rows={rows} cols={["Nom", "Managers"]} />
        </CardContent>
      </Card>
      <Collapse orientation="horizontal" in={!!dbActivity} mountOnEnter unmountOnExit>
        <EditActivityCard />
        <Card />
      </Collapse>
      {showArchive && <ArchiveActivity open={showArchive} handleClose={handleClose} actId={selectedActID} />}
      {showDelete && <DeleteActivity open={showDelete} handleClose={handleClose} actId={selectedActID} />}
    </Box>
  )
}
