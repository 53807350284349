import { Dialog, DialogContent, IconButton } from "@mui/material"
import { useEffect, useState } from "react"
import { STATUSES, STATUSES_ICON } from "../../../../utils/constants/statuses"
import ConfirmDialogTitle from "./ConfirmDialogTitle"
import { WorkshopSelect } from "./WorkshopSelect"
import { SelectedRegistrations } from "./SelectedRegistrations"

import { useParams } from "react-router-dom"
import { ReasonTF } from "./ReasonTF"
import { ConfirmButtons } from "./ConfirmButtons"
import { isEmpty } from "lodash"

export default function StatusButton({ numStatus }) {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const { workshopId } = useParams()
  const [selectedWorkshopId, setSelectedWorkshopId] = useState(workshopId)
  const [reason, setReason] = useState("")
  const [disabledButton, setDisabledButton] = useState(true)

  const handleClose = () => {
    setConfirmDialogOpen(false)
    setReason("")
    setSelectedWorkshopId(workshopId)
  }
  useEffect(() => {
    if ([3, 4, 5].includes(numStatus) && isEmpty(reason)) setDisabledButton(true)
    else setDisabledButton(false)
  }, [reason, numStatus])

  return (
    <>
      <IconButton onClick={() => setConfirmDialogOpen(true)}>{STATUSES_ICON[STATUSES[numStatus]]}</IconButton>
      <Dialog open={confirmDialogOpen} fullWidth maxWidth={"xs"} keepMounted={false}>
        <ConfirmDialogTitle numStatus={numStatus} />
        <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {[1, 2].includes(numStatus) ? (
            <WorkshopSelect value={selectedWorkshopId} setValue={setSelectedWorkshopId} />
          ) : (
            <ReasonTF value={reason} setValue={setReason} numStatus={numStatus} />
          )}
          <SelectedRegistrations />
        </DialogContent>
        <ConfirmButtons
          numStatus={numStatus}
          setConfirmDialogOpen={handleClose}
          selectedWorkshopId={selectedWorkshopId}
          reason={reason}
          disabled={disabledButton}
        />
      </Dialog>
    </>
  )
}
