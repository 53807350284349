import { Box, Chip, CircularProgress } from "@mui/material"
import { AVAILABLE_COLOR, AVAILABLE_ICON } from "../../../../utils/constants/forms"
import { useSelector } from "react-redux"
import { getFormDb } from "../../../../redux/slices/formSlice"
import { isEmpty } from "lodash"
import { UpdateRounded } from "@mui/icons-material"
import { formatDateCH } from "../../../../utils/tablesHelpers"
import { useSnackbar } from "notistack"

export default function EventFormInfos() {
  const form = useSelector(getFormDb)
  const { enqueueSnackbar } = useSnackbar()

  if (!form || isEmpty(form)) return <CircularProgress color="heig" />

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`${window.location.origin}/forms/${form._id}`)
    enqueueSnackbar("Lien copié dans le presse-papier", { variant: "info" })
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <Chip
        color={AVAILABLE_COLOR(form.available)}
        icon={AVAILABLE_ICON(form.available)}
        component={"a"}
        onClick={copyToClipboard}
        label={`${window.location.origin}/forms/${form._id}`}
        sx={{
          px: 0.5,
          fontSize: "1rem",
          backgroundColor: `${AVAILABLE_COLOR(form.available)}.bg`,
          color: `${AVAILABLE_COLOR(form.available)}.main`,
          "&:hover": {
            color: `${AVAILABLE_COLOR(form.available)}.light`,
            backgroundColor: `${AVAILABLE_COLOR(form.available)}.bgLight`,
          },
        }}
        clickable
      />
      <Chip icon={<UpdateRounded />} label={`Dernière mise à jour : ${formatDateCH(form.updatedAt)}`} sx={{ px: 0.5, fontSize: "1rem" }} />
    </Box>
  )
}
