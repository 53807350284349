import { TableBody } from "@mui/material"
import { getRegistrationsState, setAttribute } from "../../redux/slices/registrationsSlice"
import { useDispatch, useSelector } from "react-redux"
import { isEmpty, isObject, join } from "lodash"
import { formatString, getComparator, getObjectComparator, regArrayForSearch } from "../../utils/tablesHelpers"
import { formatRegsListToRows } from "./utils"
import RTRow from "./RTRow"
import { useEffect, useState } from "react"
import { getEventDb } from "../../redux/slices/eventSlice"
import { getWorkshop } from "../../redux/slices/workshopSlice"

export default function RTBody() {
  const { start } = useSelector(getEventDb)
  const { start: startWS } = useSelector(getWorkshop)
  const { list, orderBy, sortBy, page, rowsPerPage, selectedStatus, searchText } = useSelector(getRegistrationsState)
  const dispatch = useDispatch()

  const [rows, setRows] = useState([])

  const getFilteredListToDisplay = () => {
    let filteredList = list
    if (selectedStatus >= 0) filteredList = filteredList.filter((reg) => reg.status === selectedStatus)

    if (searchText) {
      if (searchText.includes(":")) {
        const splittedSearchText = formatString(searchText).split(":")
        filteredList = filteredList.filter((reg) => {
          const regArray = regArrayForSearch(reg, start, startWS)
          const keyIndex = regArray.map((arr) => arr[0]).indexOf(formatString(splittedSearchText[0]))
          if (keyIndex === -1) return false
          else return regArray[keyIndex][1].includes(formatString(splittedSearchText[1]))
        })
      } else
        filteredList = filteredList.filter((reg) =>
          join(
            regArrayForSearch(reg, start, startWS).map((arr) => arr[1]),
            " "
          ).includes(formatString(searchText))
        )
    }
    return filteredList
  }

  useEffect(() => {
    dispatch(setAttribute({ key: "page", value: 0 }))
    if (list && !isEmpty(list)) setRows(formatRegsListToRows(getFilteredListToDisplay(), selectedStatus, () => {}, startWS || start))
  }, [list, selectedStatus, searchText, start, startWS])

  useEffect(() => {
    dispatch(setAttribute({ key: "count", value: rows.length }))
  }, [rows])

  return (
    <TableBody id="RTBody">
      {rows
        .sort(
          rows[0]
            ? isObject(rows[0]["cells"][sortBy])
              ? getObjectComparator(orderBy, sortBy)
              : getComparator(orderBy, sortBy)
            : getComparator(orderBy, sortBy)
        )
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {
          return <RTRow key={`RTB-row-${index}`} row={row} />
        })}
    </TableBody>
  )
}
