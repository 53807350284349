import { FormControl, MenuItem, Select } from "@mui/material"

export const TshirtSizeInput = ({ value, onChange, color }) => {
  return (
    <FormControl
      sx={{ pr: "1px", "& .MuiSvgIcon-root": { color: color } }}
      fullWidth
      size="small"
      variant={"standard"}
    >
      <Select
        disableUnderline
        size="small"
        style={{ marginBottom: "-5px" }}
        value={value ?? ""}
        onChange={(event) => onChange(event.target.value)}
        MenuProps={{ PaperProps: { sx: { minWidth: "0px!important" } } }}
      >
        <MenuItem value={"XS"}>XS</MenuItem>
        <MenuItem value={"S"}>S</MenuItem>
        <MenuItem value={"M"}>M</MenuItem>
        <MenuItem value={"L"}>L</MenuItem>
        <MenuItem value={"XL"}>XL</MenuItem>
      </Select>
    </FormControl>
  )
}
