import { Box, Card, CardContent, Chip, Typography } from "@mui/material"
import ResourceRoleSelect from "./ResourceRoleSelect"
import { useDispatch, useSelector } from "react-redux"
import {
  deleteUserPermission,
  getUser,
  updateUserPermission,
} from "../../../../../redux/slices/usersSlice"
import { useKeycloak } from "@react-keycloak/web"
import { useSnackbar } from "notistack"
import DeleteUserButton from "./DeleteUserButton"

const ROLES_LABEL = {
  0: "admin",
  1: "manager",
  2: "utilisateur",
}

export default function UserCard({ userIndex, isManager = false }) {
  const user = useSelector(getUser(userIndex, isManager))
  const dispatch = useDispatch()
  const { keycloak } = useKeycloak()
  const { enqueueSnackbar } = useSnackbar()

  const changeResourceRole = (value) => {
    dispatch(
      updateUserPermission({ ...user.permission, role: value }, userIndex, keycloak.token)
    ).then((result) => {
      if (result === "error")
        return enqueueSnackbar(
          "Une erreur est survenue lors de l'enregistrement du changement de rôle.",
          {
            variant: "error",
          }
        )
      else return enqueueSnackbar("Modification enregistrée", { variant: "success" })
    })
  }

  const onClickDeleteUser = () => {
    dispatch(deleteUserPermission(user.permission._id, userIndex, keycloak.token)).then(
      (result) => {
        if (result === "error")
          return enqueueSnackbar("Une erreur est survenue lors de la suppression des droits", {
            variant: "error",
          })
        else return enqueueSnackbar("Droits retirés à l'utilisateur", { variant: "warning" })
      }
    )
  }

  return (
    <Card>
      <CardContent
        sx={{
          px: "16px!important",
          py: "8px!important",
          height: "65px",
          display: "flex",
          flexWrap: "none",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", flexWrap: "none", gap: 2, alignItems: "center" }}>
          <Typography variant="h6" textTransform={"capitalize"} noWrap>
            {user?.name}
          </Typography>

          <Typography color={"shade"} noWrap>
            ({user?.email})
          </Typography>
          {!isManager && (
            <Chip sx={{ textTransform: "Capitalize" }} label={ROLES_LABEL[user?.role]} />
          )}
        </Box>
        <Box sx={{ display: "flex", flexWrap: "none", gap: 2, alignItems: "center" }}>
          {!isManager && (
            <ResourceRoleSelect value={user?.permission.role} setValue={changeResourceRole} />
          )}
          <DeleteUserButton onClick={onClickDeleteUser} isManager={isManager} />
        </Box>
      </CardContent>
    </Card>
  )
}
