import CreateConsentFile from "../../../../inputs/createConsentFile/CreateConsentFile"
import { useSelector } from "react-redux"
import { getRegistration } from "../../../../../redux/slices/registrationSlice"
import { getEventDb } from "../../../../../redux/slices/eventSlice"
import { getFormDb } from "../../../../../redux/slices/formSlice"
import { useEffect, useState } from "react"
import { Button } from "@mui/material"
import { DownloadOutlined } from "@mui/icons-material"
import { find, isUndefined } from "lodash"

export default function CreateFile() {
  const event = useSelector(getEventDb)
  const {
    logos,
    inputs: { unil_survey_doc },
  } = useSelector(getFormDb)
  const registration = useSelector(getRegistration)
  // firstRegWithAdmittedStatusMin1
  const [reg, setReg] = useState(undefined)

  useEffect(() => {
    setReg(
      registration.multi
        ? find([registration, ...registration.multi], (reg) => reg?.admitted_status >= 1 && reg?.status === 2)
        : registration?.admitted_status >= 1 && registration?.status === 2
        ? registration
        : undefined
    )
  }, [registration])

  return (
    <CreateConsentFile
      event={event}
      reg={reg}
      logos={logos}
      unilDoc={unil_survey_doc}
      button={
        <Button size="small" variant="outlined" startIcon={<DownloadOutlined />} disabled={isUndefined(reg)}>
          Vierge
        </Button>
      }
    />
  )
}
