import IconNumber from "./IconNumber"
import { STATUSES, STATUSES_COLOR } from "../../../../../../../../utils/constants/statuses"
import { Box, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { getRegsByStatus } from "../../../../../../../../redux/slices/registrationsSlice"

export default function Choice({ numStatus, number, isN = false }) {
  const statusRegs = useSelector(getRegsByStatus)[numStatus]

  let count = isN
    ? Object.values(statusRegs)
        .slice(3)
        .reduce((a, v) => a + v)
    : statusRegs[number]

  return (
    <Box sx={{ display: "flex", gap: 0.5 }}>
      <IconNumber num={number} color={STATUSES_COLOR[STATUSES[numStatus]]} />
      <Typography fontWeight="bold" textAlign={"center"} sx={{ width: "3ch" }}>
        {count ?? "-"}
      </Typography>
    </Box>
  )
}
