import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { getNotificationsLocal, toggleSendToContact } from "../../../../../redux/slices/notificationsSlice"
import EmailInput from "./EmailInput"

export const ContactEmail = () => {
  const dispatch = useDispatch()
  const { send_to_contact } = useSelector(getNotificationsLocal)

  const handleClick = () => dispatch(toggleSendToContact())

  return (
    <Box sx={{ ml: 2, display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ ml: -1, display: "flex", gap: 1, flexDirection: "column" }}>
        <Typography variant="h6">{"Adresse email de contact"}</Typography>
        <Typography sx={{ ml: 1 }} variant="sub6">
          {"Elle sera utilisée dans les emails de notifications."}
        </Typography>
      </Box>
      <EmailInput input={"contact_email"} placeholder={"Adresse email de contact"} />
      <FormControlLabel
        sx={{ mt: -1 }}
        control={<Checkbox />}
        label={"Recevoir les notifications des demandes d'inscription"}
        checked={send_to_contact}
        onClick={handleClick}
      />
    </Box>
  )
}
