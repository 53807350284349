import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { isEmpty } from "lodash"
import { useDispatch } from "react-redux"
import { setAttribute } from "../../../redux/slices/registrationsSlice"

export default function TableReg({ header = [], rows = [] }) {
  const dispatch = useDispatch()
  const onClickRow = (row) => {
    dispatch(setAttribute({ key: "selectedReg", value: row.regId }))
  }

  return (
    <TableContainer>
      <Table size="small" sx={{ borderCollapse: "separate", borderSpacing: "0px 4px", minWidth: "400px" }}>
        <TableHead>
          <TableRow>
            {header.map((h, index) => (
              <TableCell
                key={index}
                align={h.align ?? "left"}
                sx={{
                  backgroundColor: "whitesmoke",
                  borderRadius: index === 0 ? "12px 0 0 12px" : index === header.length - 1 ? "0 12px 12px 0" : "0",
                  fontWeight: "bold",
                }}
              >
                {h.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isEmpty(rows) ? (
            <TableRow>
              <TableCell colSpan={5}>{"Pas de rows"}</TableCell>
            </TableRow>
          ) : (
            rows.map((row, index) => (
              <TableRow
                sx={{ cursor: "pointer", "&:hover > td": { backgroundColor: "primary.bg" } }}
                key={index}
                onClick={() => onClickRow(row)}
                hover
              >
                {row.cells.map((cell, index) => (
                  <TableCell
                    key={index}
                    align={header[index].align ?? "left"}
                    sx={{
                      borderRadius: index === 0 ? "12px 0 0 12px" : index === header.length - 1 ? "0 12px 12px 0" : "0",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
