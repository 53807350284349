import { TableBody } from "@mui/material"
import MagicRow from "./MagicRow"
import { useEffect, useReducer } from "react"

// openCollapse reducer function
function rowCollapses(state, action) {
  switch (action.type) {
    case "switch":
      return !state.includes(action.value)
        ? (state = [...state, action.value])
        : (state = state.filter((item) => item !== action.value))
    case "reset":
      return []
    default:
      throw new Error()
  }
}

export default function MagicBody(props) {
  const {
    rows = null,
    colsOptions = null,
    resetOpenSR = false,
    openSRCallback,
    cellFullLength = false,
  } = props

  // Reducer that handle sub rows open/close state
  const [openCollapse, setOpenCollapse] = useReducer(rowCollapses, [], () => [])

  // send to MagicTable if at least one sub rows is opened
  useEffect(() => {
    openSRCallback(openCollapse.length > 0)
  }, [openCollapse])

  // if MagicTable ask to close all opened sub rows
  useEffect(() => {
    if (resetOpenSR && openCollapse.length > 0) {
      setOpenCollapse({ type: "reset" })
    }
  }, [resetOpenSR])

  return (
    <TableBody>
      {!!rows &&
        rows.map((row, index) => (
          <MagicRow
            key={`MRow-${index}`}
            row={row}
            actionsRow={row.actions ?? null}
            colsOptions={colsOptions}
            // open={isSubRow(row) ? openCollapse.includes(row.id) : null}
            openSR={openCollapse.includes(row.id)}
            setOpenSR={() => setOpenCollapse({ type: "switch", value: row.id })}
            cellFullLength={cellFullLength}
          />
        ))}
    </TableBody>
  )
}
