import { useSelector } from "react-redux"
import { getRegistrationsState } from "../../../../redux/slices/registrationsSlice"
import { Grid, Typography } from "@mui/material"

export function SelectedRegistrations() {
  const { list, selectionsRegs } = useSelector(getRegistrationsState)

  return (
    <Grid container spacing={1} columnSpacing={2}>
      <Grid item xs={12}>
        <Typography fontWeight={"bold"} noWrap overflow={"auto"} textOverflow={"ellipsis"} gutterBottom>
          {`Demande${selectionsRegs.length > 1 ? "s" : ""} sélectionnée${selectionsRegs.length > 1 ? "s" : ""} :`}
        </Typography>
      </Grid>

      {list
        .filter((reg) => selectionsRegs.includes(reg._id))
        .map((reg, index) => (
          <Grid item xs={6} key={index}>
            <Typography noWrap overflow={"auto"} textOverflow={"ellipsis"}>
              {`${reg.child.firstname} ${reg.child.lastname}`}{" "}
            </Typography>
          </Grid>
        ))}
    </Grid>
  )
}
