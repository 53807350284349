import { EventAvailable, EventBusy } from "@mui/icons-material"

export const AVAILABLE_LABEL = (available) => (available ? "Disponible" : "Indisponible")
export const AVAILABLE_ICON = (available) =>
  available ? (
    <EventAvailable color={AVAILABLE_COLOR(true)} />
  ) : (
    <EventBusy color={AVAILABLE_COLOR(false)} />
  )
export const AVAILABLE_COLOR = (available) => (available ? "primary" : "error")
