import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material"
import Stepper from "./Stepper"
import { useDispatch, useSelector } from "react-redux"
import { getMainForm, jumpState, setField } from "../../../redux/slices/mainFormSlice"
import { useFormInputValidation } from "react-form-input-validation"

export default function OthersStep() {
  const {
    config: { inputs },
    comments,
    newsletters,
  } = useSelector(getMainForm)
  const dispatch = useDispatch()

  const [fields, errors, form] = useFormInputValidation(
    {
      comments: comments ?? "",
      newsletters: newsletters ?? "",
    },
    {
      comments: "string|max:128",
      newsletters: `boolean${inputs["newsletters"] === 1 ? "|required" : ""}`,
    }
  )

  const onChange = (event) => form.validate(event)

  const nextStep = (event) => {
    form.validate(event).then((isValid) => {
      if (isValid) {
        Object.keys(fields).forEach((field) => {
          dispatch(
            setField({
              superField: null,
              field: field,
              newValue: fields[field],
            })
          )
        })

        dispatch(jumpState(1))
      }
    })
    // event
    // dispatch(jumpState(1))
  }
  return (
    <form onChange={onChange} noValidate autoComplete="off" onSubmit={nextStep}>
      <Stepper />
      <Box sx={{ my: 2 }} />
      <Card sx={{ p: 1 }}>
        <CardHeader title={"Informations supplémentaires"} />
        <CardContent sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
          {inputs["comments"] > 0 && (
            <TextField
              fullWidth
              name={"comments"}
              type={"text"}
              label={"Commentaires"}
              InputLabelProps={{
                sx: { textTransform: "capitalize" },
                shrink: !!fields["comments"],
              }}
              value={fields["comments"]}
              onBlur={form.handleBlurEvent}
              onChange={form.handleChangeEvent}
              error={!!errors["comments"]}
              helperText={errors["comments"] ?? " "}
            />
          )}
          {inputs["newsletters"] > 0 && (
            <FormControl error={!!errors["newsletters"]} required={inputs["newsletters"] === 1}>
              <Divider
                textAlign={"left"}
                sx={
                  errors["newsletters"]
                    ? {
                        "&::before, &::after": {
                          borderColor: "error.main",
                        },
                      }
                    : {}
                }
              >
                <FormLabel id={"radiosButtonsNewsletters"}>Newsletters</FormLabel>
              </Divider>
              <RadioGroup sx={{ pl: "14.5px", alignItems: "center", gap: 4 }} value={fields["newsletters"]} row>
                <Typography>Souhaitez-vous recevoir des emails pour être informé des futurs ateliers ?</Typography>
                <Box>
                  <FormControlLabel control={<Radio name="newsletters" onChange={form.handleChangeEvent} value={true} />} label={"Oui"} />
                  <FormControlLabel control={<Radio name="newsletters" onChange={form.handleChangeEvent} value={false} />} label={"Non"} />
                </Box>
              </RadioGroup>
              <FormHelperText color={"error"}>{errors["newsletters"] ?? " "}</FormHelperText>
            </FormControl>
          )}
        </CardContent>
      </Card>
    </form>
  )
}
