import { useKeycloak } from "@react-keycloak/web"
import { useDispatch, useSelector } from "react-redux"

import { useSnackbar } from "notistack"
import { Box, Button, DialogActions, Typography } from "@mui/material"
import { NotificationsRounded, PriorityHighRounded } from "@mui/icons-material"
import { isNumber, isObject } from "lodash"

import { useParams } from "react-router-dom"
import { fetchRegistrations, getRegistrationsState, removeRegFromSelections } from "../../../../redux/slices/registrationsSlice"
import { getEventDb } from "../../../../redux/slices/eventSlice"
import { STATUSES, STATUSES_LABEL } from "../../../../utils/constants/statuses"
import { putLocalRegistration } from "../../../../redux/slices/localRegistrationSlice"

export function ConfirmButtons({ numStatus, setConfirmDialogOpen, selectedWorkshopId, reason, disabled }) {
  const { keycloak } = useKeycloak()
  const { eventId, workshopId } = useParams()
  const { list, selectionsRegs } = useSelector(getRegistrationsState)
  const { selections } = useSelector(getEventDb)

  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  /*
   * Permet de récupérer le choix sélectionné

   *
   * 1. On cherche dans les choix de la ddi, l'ordre du choix de l'atelier qui match
   * Donc on cherche l'index du bon atelier via l'id de l'atelier
   * MAIS en fonction du contexte, le workshop d'un choice peut soit être l'id (string) soit un objet contenant l'id et le nom du workshop
   * Donc on vérifie si c'est un objet pour appliquer la bonne méthode pour trouver l'atelier
   * puis on récupère l'ordre dans selected_choice
   *
   * 2. SI l'atelier sélectionné dans le dialogue n'est pas dans la liste des choix (selected_choice n'est pas un nombre)
   * ALORS on crée un nouveau choix avec l'atelier sélectionné (en prenant soin de formatter tous les choix pour la bdd)
   * puis on donne l'ordre du choix à selected_choice
   *
   * 3. On renvoie selected_choice
   * 
   * if (!isNumber(selected_choice)) {
      registration["choices"] = [
        ...choices.map(({ workshop, order }) => ({ workshop: workshop._id, order })),
        { workshop: selectedChoice, order: choices.length + 1 },
      ]
      selected_choice = choices.length + 1
    }
   *
   * */
  const getSelectedChoice = (choices) => {
    let selected_choice = isObject(choices[0].workshop)
      ? choices[choices.map((choice) => choice.workshop._id).indexOf(selectedWorkshopId)]?.order
      : choices[choices.map((choice) => choice.workshop).indexOf(selectedWorkshopId)]?.order
    if (!isNumber(selected_choice)) {
      selected_choice = choices.length + 1
    }
    return selected_choice
  }

  const isOkToChangeStatus = (reg) => {
    if (reg.status !== numStatus) return true
    if ([1, 2].includes(reg.status) && getSelectedChoice(reg.choices) !== reg.selected_choice) return true
    return false
  }

  const handleClick = (confirm) => {
    setConfirmDialogOpen(false)
    if (confirm) {
      // Pour chaque ddi sélectionnée
      selectionsRegs.forEach((regId) => {
        const reg = list[list.map((reg) => reg._id).indexOf(regId)]

        // if (oldStatus !== numStatus) {
        if (isOkToChangeStatus(reg)) {
          const registration = {
            status: numStatus,
            selected_choice: [1, 2].includes(numStatus) ? getSelectedChoice(reg.choices) : null,
            reason: [1, 2].includes(numStatus) ? null : reason,
          }

          if ([1, 2].includes(numStatus)) {
            if (registration.selected_choice > selections)
              registration.choices = [
                ...reg.choices.map(({ workshop, order }) => ({ workshop: workshop._id, order })),
                { workshop: selectedWorkshopId, order: reg.choices.length + 1 },
              ]
          }

          dispatch(putLocalRegistration(regId, { registration }, keycloak.token)).then((result) => {
            if (result.text === "error") {
              return enqueueSnackbar(`Une erreur est survenue lors du changement de statut. (${reg.child.firstname} ${reg.child.lastname})`, {
                variant: "error",
              })
            } else if (result.text === "ok") {
              enqueueSnackbar("Statut(s) mis à jour", { variant: "success" })
              dispatch(fetchRegistrations(`/events/${eventId}${workshopId ? `/workshops/${workshopId}` : ""}/registrations`, keycloak.token))
            }
          })
        } else {
          enqueueSnackbar(`${reg.child.firstname} ${reg.child.lastname} est déjà ${STATUSES_LABEL[STATUSES[numStatus]]}`, {
            variant: "warning",
          })
        }
        dispatch(removeRegFromSelections(regId)) // on retire la ddi des ddi sélectionnées dans la liste
      })
    }
  }

  return (
    <DialogActions
      sx={{
        px: 2,
        justifyContent: numStatus >= 2 && numStatus <= 5 ? "space-between" : "flex-end",
        gap: 2,
      }}
    >
      {numStatus >= 2 && numStatus <= 5 && (
        <Box sx={{ display: "flex", alignItems: "center", gap: 0 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <NotificationsRounded color="warning" />
            <PriorityHighRounded sx={{ ml: -1.5 }} color="warning" />
          </Box>
          <Typography variant="sub6" color={"warning.main"}>
            {"Statut notifiant"}
          </Typography>
        </Box>
      )}
      <Box>
        <Button color="inherit" onClick={() => handleClick(false)}>
          Annuler
        </Button>
        <Button disabled={disabled} variant="text" onClick={() => handleClick(true)}>
          Confirmer
        </Button>
      </Box>
    </DialogActions>
  )
}
