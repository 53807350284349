import { Tooltip } from "@mui/material"
import {
  AccessTimeRounded,
  ArrowCircleDownRounded,
  CancelRounded,
  CheckCircleOutlineRounded,
  CheckCircleRounded,
  DescriptionRounded,
  FlagCircleRounded,
  HourglassBottomRounded,
  TaskRounded,
} from "@mui/icons-material"

export const STATUSES = ["pending", "admissible", "admitted", "waiting", "rejected", "abandoned"]

export const STATUSES_LABEL = {
  pending: "À traiter",
  admissible: "Admissible",
  admitted: "Admise",
  waiting: "En attente",
  rejected: "Non admise",
  abandoned: "Abandonée",
}

export const STATUSES_COLOR = {
  pending: "pending",
  admissible: "success",
  admitted: "success",
  waiting: "warning",
  rejected: "error",
  abandoned: "shade",
}

export const STATUSES_ICON = {
  pending: (
    <Tooltip title={STATUSES_LABEL.pending} sx={{ p: 0, m: 0 }}>
      <ArrowCircleDownRounded color={STATUSES_COLOR.pending} sx={{ verticalAlign: "middle" }} />
    </Tooltip>
  ),
  admissible: (
    <Tooltip title={STATUSES_LABEL.admissible}>
      <CheckCircleOutlineRounded color={STATUSES_COLOR.admissible} sx={{ verticalAlign: "middle" }} />
    </Tooltip>
  ),
  admitted: (
    <Tooltip title={STATUSES_LABEL.admitted}>
      <CheckCircleRounded color={STATUSES_COLOR.admitted} sx={{ verticalAlign: "middle" }} />
    </Tooltip>
  ),
  waiting: (
    <Tooltip title={STATUSES_LABEL.waiting}>
      <AccessTimeRounded color={STATUSES_COLOR.waiting} sx={{ verticalAlign: "middle" }} />
    </Tooltip>
  ),
  rejected: (
    <Tooltip title={STATUSES_LABEL.rejected}>
      <CancelRounded color={STATUSES_COLOR.rejected} sx={{ verticalAlign: "middle" }} />
    </Tooltip>
  ),
  abandoned: (
    <Tooltip title={STATUSES_LABEL.abandoned}>
      <FlagCircleRounded color={STATUSES_COLOR.abandoned} sx={{ verticalAlign: "middle" }} />
    </Tooltip>
  ),
}

export const ADMITTED_STATUSES = ["waiting", "halfway", "done"]

export const ADMITTED_STATUSES_LABEL = {
  waiting: "Données non saisies",
  halfway: "Fiche manquante",
  done: "Inscription complète",
}

export const ADMITTED_STATUSES_COLOR = {
  waiting: "error",
  halfway: "warning",
  done: "success",
}

export const ADMITTED_STATUSES_ICON = {
  waiting: (
    <Tooltip title={ADMITTED_STATUSES_LABEL.waiting}>
      <HourglassBottomRounded color={ADMITTED_STATUSES_COLOR.waiting} sx={{ verticalAlign: "middle" }} />
    </Tooltip>
  ),
  halfway: (
    <Tooltip title={ADMITTED_STATUSES_LABEL.halfway}>
      <DescriptionRounded color={ADMITTED_STATUSES_COLOR.halfway} sx={{ verticalAlign: "middle" }} />
    </Tooltip>
  ),
  done: (
    <Tooltip title={ADMITTED_STATUSES_LABEL.done}>
      <TaskRounded color={ADMITTED_STATUSES_COLOR.done} sx={{ verticalAlign: "middle" }} />
    </Tooltip>
  ),
}
