import Events from "../dashboard/events/Events"
import EventsList from "../dashboard/events/list/EventsList"
import NewEvent from "../dashboard/events/new/NewEvent"
import Event from "../dashboard/events/event/Event"
import Workshops from "../dashboard/events/event/workshops/Workshops"
import ChangeEvent from "../dashboard/events/event/edit/ChangeEvent"
import EventForms from "../dashboard/events/event/forms/EventForms"
import EventNotifications from "../dashboard/events/event/notifications/EventNotifications"
import Workshop from "../dashboard/events/event/workshops/workshop/Workshop"
import Activities from "../dashboard/activities/Activities"
import Export from "../dashboard/export/Export"
import NotFound from "../NotFound"
import Dashboard from "../dashboard/Dashboard"
import ActivitiesList from "../dashboard/activities/list/ActivitiesList"
import NewActivity from "../dashboard/activities/new/NewActivity"
import EventRegistrations from "../dashboard/events/event/registrations/EventRegistrations"
import Forms from "../forms/Forms"
import MainForm from "../forms/mainForm/MainForm"
import { Navigate } from "react-router-dom"
import UsersRole from "../dashboard/events/event/usersRole/UsersRole"
import WorkshopRegistrations from "../dashboard/events/event/workshops/workshop/registrations/WorkshopRegistrations"
import AdmissionForm from "../forms/admissionForm/AdmissionForm"
import { activitiesActions, eventActions, eventsActions } from "./pageActions"
import ConfirmMain from "../forms/confirmMain/ConfirmMain"

export const routes = (options = {}) => {
  const event = options.eventName ?? "Évènement"
  const workshop = options.workshopName ?? "Atelier"
  const eventId = options.eventId ?? null
  const workshopId = options.workshopId ?? null

  return [
    {
      path: "/",
      element: <Navigate to={"/dashboard"} replace />,
      app_role: -1,
    },
    {
      path: "/dashboard",
      element: <Dashboard />,
      app_role: -1,
      context: {
        title: "Index",
      },
      children: [
        {
          path: "events",
          element: <Events />,
          app_role: 2,
          context: {
            title: "Évènements",
            actions: eventsActions,
            breadcrumbs: [{ text: "Évènements" }],
          },
          children: [
            {
              path: "list",
              element: <EventsList />,
              app_role: 2,
              context: {
                title: "Liste des évènements",
                actions: eventsActions,
                breadcrumbs: [{ text: "Évènements", href: "/dashboard/events" }, { text: "Liste" }],
              },
            },
            {
              path: "archived",
              element: <EventsList archived={true} />,
              app_role: 2,
              context: {
                title: "Évènements archivés",
                actions: eventsActions,
                breadcrumbs: [{ text: "Évènements", href: "/dashboard/events" }, { text: "Évènements" }],
              },
            },
            {
              path: "new",
              element: <NewEvent />,
              app_role: 1,
              resource_role: "editor",
              context: {
                title: "Nouvel évènement",
                actions: eventsActions,
                breadcrumbs: [{ text: "Évènements", href: "/dashboard/events" }, { text: "Nouveau" }],
              },
            },
            {
              path: "404",
              element: <NotFound />,
              app_role: 2,
              context: {
                title: "Évènement non trouvé",
                actions: [],
                breadcrumbs: [{ text: "Évènements", href: "/dashboard/events" }, { text: "404" }],
              },
            },
            {
              path: ":eventId",
              element: <Event />,
              app_role: 2,
              context: {
                title: event,
                actions: eventActions(eventId),
                breadcrumbs: [{ text: "Évènements", href: "/dashboard/events" }, { text: event }],
              },
              children: [
                {
                  path: "workshops",
                  element: <Workshops />,
                  app_role: 2,
                  context: {
                    title: "Liste des ateliers",
                    actions: eventActions(eventId),
                    breadcrumbs: [
                      { text: "Évènements", href: "/dashboard/events" },
                      {
                        text: event,
                        href: `/dashboard/events/${eventId}`,
                      },
                      { text: "Ateliers" },
                    ],
                  },
                },
                {
                  path: "workshops/404",
                  element: <NotFound />,
                  app_role: -1,
                  context: {
                    title: "Atelier non trouvé",
                    actions: [],
                    breadcrumbs: [
                      { text: "Évènements", href: "/dashboard/events" },
                      { text: event, href: `/dashboard/events/${eventId}` },
                      {
                        text: "Ateliers",
                        href: `/dashboard/events/${eventId}/workshops`,
                      },
                      { text: "404" },
                    ],
                  },
                },
                {
                  path: "workshops/:workshopId",
                  element: <Workshop />,
                  app_role: 2,
                  context: {
                    title: workshop,
                    actions: [],
                    breadcrumbs: [
                      { text: "Évènements", href: "/dashboard/events" },
                      { text: event, href: `/dashboard/events/${eventId}` },
                      {
                        text: "Ateliers",
                        href: `/dashboard/events/${eventId}/workshops`,
                      },
                      { text: workshop },
                    ],
                  },
                  children: [
                    {
                      path: "registrations",
                      element: <WorkshopRegistrations />,
                      app_role: 2,
                      context: {
                        title: workshop,
                        actions: [],
                        breadcrumbs: [
                          { text: "Évènements", href: "/dashboard/events" },
                          { text: event, href: `/dashboard/events/${eventId}` },
                          {
                            text: "Ateliers",
                            href: `/dashboard/events/${eventId}/workshops`,
                          },
                          {
                            text: workshop,
                            href: `/dashboard/events/${eventId}/workshops/${workshopId}`,
                          },
                          {
                            text: "Demandes d'inscription",
                          },
                        ],
                      },
                    },
                    {
                      path: "404",
                      element: <NotFound />,
                      app_role: -1,
                      context: {
                        title: "Page non trouvée",
                        actions: [],
                        breadcrumbs: [
                          { text: "Évènements", href: "/dashboard/events" },
                          { text: event, href: `/dashboard/events/${eventId}` },
                          {
                            text: "Ateliers",
                            href: `/dashboard/events/${eventId}/workshops`,
                          },
                          {
                            text: workshop,
                            href: `/dashboard/events/${eventId}/workshops/${workshopId}`,
                          },
                          {
                            text: "404",
                          },
                        ],
                      },
                    },
                    {
                      path: "*",
                      element: <Navigate to={"./404"} replace />,
                      app_role: -1,
                      context: {
                        title: "Page non trouvée",
                        actions: [],
                      },
                    },
                  ],
                },
                {
                  path: "registrations",
                  element: <EventRegistrations />,
                  app_role: 2,
                  context: {
                    title: "Toutes les demandes d'inscription",
                    actions: eventActions(eventId),
                    breadcrumbs: [
                      { text: "Évènements", href: "/dashboard/events" },
                      {
                        text: event,
                        href: `/dashboard/events/${eventId}`,
                      },
                      { text: "Demandes d'inscription" },
                    ],
                  },
                },
                {
                  path: "edit",
                  element: <ChangeEvent />,
                  app_role: 1,
                  context: {
                    title: `Modifier ${event}`,
                    actions: eventActions(eventId),
                    breadcrumbs: [
                      { text: "Évènements", href: "/dashboard/events" },
                      {
                        text: event,
                        href: `/dashboard/events/${eventId}`,
                      },
                      { text: "Modifier" },
                    ],
                  },
                },
                {
                  path: "forms",
                  element: <EventForms />,
                  app_role: 1,
                  context: {
                    title: "Configuration des formulaires",
                    actions: eventActions(eventId),
                    breadcrumbs: [
                      { text: "Évènements", href: "/dashboard/events" },
                      {
                        text: event,
                        href: `/dashboard/events/${eventId}`,
                      },
                      { text: "Formulaires" },
                    ],
                  },
                },
                {
                  path: "notifications",
                  element: <EventNotifications />,
                  app_role: 1,
                  context: {
                    title: "Configuration des notifications",
                    actions: eventActions(eventId),
                    breadcrumbs: [
                      { text: "Évènements", href: "/dashboard/events" },
                      {
                        text: event,
                        href: `/dashboard/events/${eventId}`,
                      },
                      { text: "Notifications" },
                    ],
                  },
                },
                {
                  path: "users",
                  element: <UsersRole />,
                  app_role: 1,
                  context: {
                    title: "Gestion des utilisateurs",
                    actions: eventActions(eventId),
                    breadcrumbs: [
                      { text: "Évènements", href: "/dashboard/events" },
                      {
                        text: event,
                        href: `/dashboard/events/${eventId}`,
                      },
                      { text: "Utilisateurs" },
                    ],
                  },
                },
                {
                  path: "*",
                  element: <NotFound />,
                  app_role: -1,
                  context: {
                    title: "Page non trouvée",
                    actions: [],
                    breadcrumbs: [
                      { text: "Évènements", href: "/dashboard/events" },
                      {
                        text: event,
                        href: `/dashboard/events/${eventId}`,
                      },
                      { text: "404" },
                    ],
                  },
                },
              ],
            },
            {
              path: "*",
              element: <NotFound />,
              app_role: -1,
              context: {
                title: "Page non trouvée",
                actions: [],
                breadcrumbs: [{ text: "Évènements", href: "/dashboard/events" }, { text: "404" }],
              },
            },
          ],
        },
        {
          path: "activities",
          element: <Activities />,
          app_role: 1,
          context: {
            title: "Activités jeunes",
            actions: activitiesActions,
            breadcrumbs: [{ text: "Activités jeunes" }],
          },
          children: [
            {
              path: "list",
              element: <ActivitiesList />,
              app_role: 1,
              context: {
                title: "Liste des activités jeunes",
                actions: activitiesActions,
                breadcrumbs: [{ text: "Activités jeunes", href: "/dashboard/activities" }, { text: "Liste" }],
              },
            },
            {
              path: "new",
              element: <NewActivity />,
              app_role: 0,
              context: {
                title: "Nouvelle activité jeunes",
                actions: activitiesActions,
                breadcrumbs: [{ text: "Activités jeunes", href: "/dashboard/activities" }, { text: "Nouveau" }],
              },
            },
          ],
        },
        {
          path: "export",
          element: <Export />,
          app_role: 2,
          context: {
            actions: [],
            title: "Export",
          },
        },
        {
          path: "404",
          element: <NotFound />,
          app_role: -1,
          context: {
            title: "Page non trouvée",
            actions: [],
          },
        },
        {
          path: "*",
          element: <Navigate to={"./404"} replace />,
          app_role: -1,
          context: {
            title: "Page non trouvée",
            actions: [],
          },
        },
      ],
    },
    {
      path: "/forms",
      element: <Forms />,
      app_role: -1,
      children: [
        {
          path: "404",
          element: <NotFound />,
          app_role: -1,
          context: {
            title: "Formulaire non trouvé",
            actions: [],
          },
        },
        {
          path: ":formId",
          element: <MainForm />,
          app_role: -1,
        },
        {
          path: ":formId/confirmed",
          element: <ConfirmMain />,
          app_role: -1,
        },
        {
          path: ":formId/admissions/404",
          element: <NotFound />,
          app_role: -1,
          context: {
            title: "Formulaire non trouvé",
            actions: [],
          },
        },
        {
          path: ":formId/admissions/:secret",
          element: <AdmissionForm />,
          app_role: -1,
        },
      ],
    },
    {
      path: "/404",
      element: <NotFound />,
      app_role: -1,
      context: {
        title: "Page non trouvée",
        actions: [],
      },
    },
    {
      path: "*",
      element: <Navigate to={"/404"} replace />,
      app_role: -1,
      context: {
        title: "Page non trouvée",
        actions: [],
      },
    },
  ]
}
