import { ACTIONS_ICON } from "../../utils/constants/actions"
import { styled } from "@mui/material"

export const drawerWidth = 260 // Same in Topbar component

export const sidebarMenus = {
  // "[your Menu eventName]": { "[your page eventName]": { icon: <[your icon]/>, route: "[your route]" }},
  Pages: {
    Évènements: { icon: ACTIONS_ICON.event, route: "/dashboard/events/list", user_role: 2 },
    "Activités jeunes": {
      icon: ACTIONS_ICON.activity,
      route: "/dashboard/activities/list",
      user_role: 1,
    },
    // Newsletters: { icon: <NewspaperTwoTone />, route: "/newsletters" },
  },
  Outils: {
    "Export des données": {
      icon: ACTIONS_ICON.export,
      route: "/dashboard/export",
      user_role: 2,
    },
  },
}

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1), // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

export const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingX: theme.spacing(1),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
)
