import { TextFieldsRounded } from "@mui/icons-material"
import { Box, TextField } from "@mui/material"
import { getActivities, setLocalActivityAttribute } from "../../../../redux/slices/activitiesSlice"
import { useDispatch, useSelector } from "react-redux"

export default function AttributesInputs() {
  const { localActivity } = useSelector(getActivities)
  const dispatch = useDispatch()

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <TextFieldsRounded sx={{ color: "primary.main", mr: 1, my: 0.5 }} />
      <TextField
        label="Nom"
        value={localActivity?.name ?? "Loading..."}
        onChange={(event) =>
          dispatch(setLocalActivityAttribute({ attribute: "name", newValue: event.target.value }))
        }
        type="text"
        variant="standard"
        fullWidth
      />
    </Box>
  )
}
