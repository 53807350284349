import { Box, Typography } from "@mui/material"
import EmailInput from "./EmailInput"

export default function CustomEmail() {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Typography sx={{ fontWeight: "bold" }}>{"Adresse email personnalisée"}</Typography>
      </Box>
      <EmailInput input={"custom_email"} placeholder={"Adresse email personnalisée"} />
    </Box>
  )
}
