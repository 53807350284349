import { Box, CircularProgress, Container, Typography } from "@mui/material"
import { Navigate, useParams } from "react-router-dom"
import { fetchConfig, getMainForm } from "../../redux/slices/mainFormSlice"
import { useDispatch, useSelector } from "react-redux"
import * as React from "react"
import { useEffect, useState } from "react"
import { useSnackbar } from "notistack"
import FormContent from "./FormContent"
import { FormHeader } from "../FormHeader"
import ServerError from "../../components/ServerError"
import { isEmpty } from "lodash"
import StartCard from "./steps/StartCard"

export default function MainForm() {
  const { formId } = useParams()
  const mainForm = useSelector(getMainForm)
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [error, setError] = useState(null)
  const [started, setStarted] = useState(false)

  useEffect(async () => {
    setStarted(sessionStorage.getItem("mainFormStarted") === "true")
    dispatch(fetchConfig(formId)).then(({ status, error, details }) => {
      if (status === "error") {
        setError(error)
        enqueueSnackbar("Une erreur est survenue lors de l'affichage du formulaire", {
          variant: "error",
        })
        if (details) console.error(details)
      }
    })
  }, [formId])

  if (!error && isEmpty(mainForm.config))
    return (
      <Container maxWidth={"md"} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", py: 3 }}>
        <CircularProgress sx={{ m: "auto" }} color="heig" size={"3rem"} />
      </Container>
    )
  if (error && error.status >= 500) return <ServerError code={error.data.code} />
  if (error && error.status >= 400) return <Navigate to={"404"} />

  return (
    <Box id="MainFormContainer" sx={{ minHeight: "inherit", minWidth: "inherit", display: "flex", flexDirection: "column" }}>
      <FormHeader eventName={mainForm.config?.event?.name} logos={mainForm.config.logos} title={"Formulaire de demande d'inscription"} />
      {mainForm.config.available ? (
        mainForm.config.workshops.filter((ws) => ws.available).length > 0 ? (
          started ? (
            <FormContent />
          ) : (
            <StartCard eventName={mainForm.config.event.name} setStarted={setStarted} />
          )
        ) : (
          <Container maxWidth={"md"} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", py: 3 }}>
            <Typography sx={{ textAlign: "center" }} variant="h4">
              {"Aucun atelier disponible pour le moment"}
            </Typography>
          </Container>
        )
      ) : (
        <Container maxWidth={"md"} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", py: 3 }}>
          <Typography sx={{ textAlign: "center" }} variant="h4">
            {"Les inscriptions ne sont pas ouvertes pour le moment."}
          </Typography>
        </Container>
      )}
    </Box>
  )
}
