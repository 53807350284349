import { Box } from "@mui/material"
import { Outlet } from "react-router-dom"

export default function Forms() {
  return (
    <Box
      id="FormsContainer"
      sx={{ minHeight: "100vh", minWidth: "100vw", backgroundColor: "shade.bg" }}
    >
      <Outlet />
    </Box>
  )
}
