export const activitiesActions = [
  {
    tooltip: "Créer une nouvelle activité jeunes",
    icon: "add",
    href: "./new",
    user_role: 0,
    resource_role: 0,
  },
  {
    tooltip: "Liste des activités jeunes",
    icon: "view_list",
    href: "./list",
    user_role: 1,
    resource_role: 1,
  },
  {
    tooltip: "Activités jeunes archivées",
    icon: "inventory_2",
    href: "./list?archived=true",
    user_role: 1,
    resource_role: 1,
  },
]

export const eventsActions = [
  {
    tooltip: "Créer un nouvel évènement",
    icon: "add",
    href: "./new",
    user_role: 1,
    resource_role: 1,
  },
  {
    tooltip: "Liste des évènements",
    icon: "view_list",
    href: "./list",
    user_role: 2,
    resource_role: 0,
  },
  {
    tooltip: "Évènements archivés",
    icon: "inventory_2",
    href: "./archived",
    user_role: 2,
    resource_role: 0,
  },
]
export const eventActions = (eventId) => [
  {
    tooltip: "Ateliers",
    icon: "grid_view",
    href: `./${eventId}/workshops`,
    user_role: 2,
    resource_role: 0,
  },
  {
    tooltip: "Inscriptions",
    icon: "view_list",
    href: `./${eventId}/registrations`,
    user_role: 2,
    resource_role: 0,
  },
  {
    tooltip: "Formulaires",
    icon: "dynamic_form",
    href: `./${eventId}/forms`,
    user_role: 1,
    resource_role: 1,
  },
  {
    tooltip: "Notifications",
    icon: "notifications",
    href: `./${eventId}/notifications`,
    user_role: 1,
    resource_role: 1,
  },
  {
    tooltip: "Modifier",
    icon: "edit",
    href: `./${eventId}/edit`,
    user_role: 1,
    resource_role: 1,
  },
  {
    tooltip: "Utilisateurs",
    icon: "people_alt",
    href: `./${eventId}/users`,
    user_role: 1,
    resource_role: 1,
  },
]

// const workshopActions = (eventId, workshopId) => [
//   { tooltip: "Supprimer", icon: "delete", href: `./${eventId}/workshops/${workshopId}?aa=delete`, user_role: 2 },
// ]
