import { Box, Button, Card, CardContent } from "@mui/material"
import ResourceRoleSelect from "../userCard/ResourceRoleSelect"
import AddUserButton from "./AddUserButton"
import AutocompleteAdPeople from "../../../../../components/inputs/AutoCompleteAdPeople"
import WrapperApiAdPeopleToken from "../../../../../components/WrapperApiAdPeopleToken"
import { useState } from "react"
import { AddCircleRounded } from "@mui/icons-material"
import { useSnackbar } from "notistack"
import { isEmpty } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import { getUsers, postUserPermission } from "../../../../../redux/slices/usersSlice"
import { useKeycloak } from "@react-keycloak/web"

export default function AddUserCard() {
  const { enqueueSnackbar } = useSnackbar()

  const {
    activity: { managers },
    event: { users },
  } = useSelector(getUsers)

  const [addButtonClicked, setAddButtonClicked] = useState(false)
  const [newUser, setNewUser] = useState({})
  const [resourceRole, setResourceRole] = useState(0)
  const dispatch = useDispatch()
  const { keycloak } = useKeycloak()
  const [btnError, setBtnError] = useState(false)

  const onUserSelected = (user) => {
    setNewUser(user)
  }

  const handleError = () => {
    setBtnError(true)
    setTimeout(() => setBtnError(false), 1000)
  }

  const onClickAddUser = () => {
    if (isEmpty(newUser)) {
      handleError()
      return enqueueSnackbar("Vous devez d'abord sélectionner un utilisateur !", {
        variant: "warning",
      })
    }

    if (managers.length > 0)
      if (managers.map((man) => man.email).includes(newUser.email)) {
        handleError()
        return enqueueSnackbar("Cet utilisateur est déjà manager", { variant: "error" })
      }

    if (users.length > 0)
      if (users.map((user) => user.email).includes(newUser.email)) {
        handleError()
        return enqueueSnackbar("Cet utilisateur est déjà un utilisateur", { variant: "error" })
      }

    dispatch(postUserPermission({ user: newUser, resourceRole }, keycloak.token)).then((result) => {
      if (result === "error") {
        handleError()
        return enqueueSnackbar("Une erreur est survenue lors de l'ajout de l'utilisateur", {
          variant: "error",
        })
      } else {
        setAddButtonClicked(false)
        return enqueueSnackbar("Droits accordés à l'utilisateur", { variant: "success" })
      }
    })
  }

  return (
    <Card>
      <CardContent
        sx={{
          px: "16px!important",
          py: "8px!important",
          height: "65px",
          display: "flex",
          flexWrap: "none",
          justifyContent: "space-between",
        }}
      >
        {addButtonClicked ? (
          <>
            <Box sx={{ display: "flex", flex: 1 }}>
              <WrapperApiAdPeopleToken>
                <AutocompleteAdPeople
                  variant={"outlined"}
                  size={"small"}
                  fullWidth={false}
                  callback={onUserSelected}
                  textInputLabel={"Saisir un utilisateur"}
                />
              </WrapperApiAdPeopleToken>
            </Box>
            <Box sx={{ display: "flex", flex: 1 }} />
            <Box sx={{ display: "flex", flex: 1, justifyContent: "flex-end", gap: 2 }}>
              <ResourceRoleSelect value={resourceRole} setValue={setResourceRole} />
              <AddUserButton onClick={onClickAddUser} error={btnError} />
            </Box>
          </>
        ) : (
          <Button
            color="black"
            sx={{ flex: 1, "&:hover": { color: "primary.main" } }}
            size="small"
            onClick={() => setAddButtonClicked(true)}
          >
            <AddCircleRounded />
          </Button>
        )}
      </CardContent>
    </Card>
  )
}
