import { Box, Chip } from "@mui/material"
import { useSelector } from "react-redux"
import { UpdateRounded } from "@mui/icons-material"
import { formatDateCH } from "../../../../utils/tablesHelpers"
import { getNotificationsDb } from "../../../../redux/slices/notificationsSlice"

export default function Infos() {
  const notifications = useSelector(getNotificationsDb)

  // if (!form || isEmpty(form)) return <CircularProgress color="heig" />

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <Chip
        icon={<UpdateRounded />}
        label={`Dernière mise à jour : ${formatDateCH(notifications.updatedAt)}`}
        sx={{ px: 0.5, fontSize: "1rem" }}
      />
    </Box>
  )
}
