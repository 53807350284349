import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material"
import WorkshopCard from "./WorkshopCard"
import { useSelector } from "react-redux"
import { getMainForm } from "../../../../redux/slices/mainFormSlice"
import { cloneDeep } from "lodash"

export default function WorkshopsList({ index, error = " ", wsToSelect }) {
  const {
    config: { workshops },
  } = useSelector(getMainForm)

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("sm"))

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3, mt: 2 }}>
      <Box>
        {wsToSelect > 1 ? (
          <>
            <Typography sx={{ whiteSpace: "nowrap" }} variant={matches ? "h6" : "body1"} gutterBottom>{`Préférence d'atelier${
              wsToSelect > 1 ? "s" : ""
            } `}</Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              <Typography sx={{ whiteSpace: "nowrap" }} variant={matches ? "body1" : "body2"}>{`Sélectionnez `}</Typography>
              <Typography variant={matches ? "body1" : "body2"} color={"primary.main"} fontWeight={"bold"}>
                {`les ${wsToSelect} ateliers`}
              </Typography>
              <Typography sx={{ whiteSpace: "nowrap" }} variant={matches ? "body1" : "body2"}>{` qui vous intéressent le plus.`}</Typography>
            </Box>
          </>
        ) : (
          <Typography variant={matches ? "h6" : "body1"}>{"Sélectionnez l'atelier qui vous intéresse le plus."}</Typography>
        )}
      </Box>
      <Typography sx={{ mt: -2 }} color={"error"}>
        {error}
      </Typography>
      <Grid container rowSpacing={2} columnSpacing={4}>
        {workshops &&
          cloneDeep(workshops)
            .sort((a, b) => {
              return new Date(a.start) - new Date(b.start)
            })
            .map((workshop, wsIndex) => (
              <Grid key={wsIndex} item xs={12} md={6}>
                <WorkshopCard workshop={workshop} index={index} />
              </Grid>
            ))}
      </Grid>
    </Box>
  )
}
