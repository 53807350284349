import { Box, Divider, TablePagination, Toolbar } from "@mui/material"
import PropTypes from "prop-types"

export default function MagicFooter(props) {
  const {
    pagination = false,
    page = 1,
    rowsPerPage = 10,
    setPage = () => {},
    setRowsPerPage = () => {},
    count = 0,
    tools = [],
    small = false,
  } = props

  return (
    <div>
      <Divider />
      <Toolbar
        sx={{
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          p: "0!important",
          alignItems: "center",
        }}
        variant={small ? "dense" : "regular"}
      >
        {pagination && (
          <TablePagination
            component={"div"}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={setPage}
            onRowsPerPageChange={setRowsPerPage}
            size={small ? "small" : "medium"}
          />
        )}
        {tools.length > 0 && <Box sx={{ display: "flex", gap: 2 }}>{tools}</Box>}
      </Toolbar>
    </div>
  )
}

MagicFooter.propTypes = {
  pagination: PropTypes.bool,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  setPage: PropTypes.func,
  setRowsPerPage: PropTypes.func,
  count: PropTypes.number,
  tools: PropTypes.array,
}
