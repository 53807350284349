import { ArrowForwardRounded, DownloadRounded } from "@mui/icons-material"
import { Box, Button, Card, CardContent, CardHeader, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useSelector } from "react-redux"
import CreateConsentFile from "../../../components/inputs/createConsentFile/CreateConsentFile"
import { getAdForm } from "../../../redux/slices/adFormSlice"
import { slugify } from "../../../utils/utils"
import { SendModes } from "./sendModes/SendModes"

export default function ConsentStep() {
  const adForm = useSelector(getAdForm)
  const {
    config: {
      event,
      logos,
      inputs: { unil_survey_doc },
    },
    dbReg,
  } = adForm

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("sm"))

  return (
    <Card sx={{ p: 1 }}>
      <CardHeader
        title={
          <>
            <Typography variant="inherit" noWrap gutterBottom>
              {"Fiche de consentement"}
            </Typography>
            <Typography variant="sub6" noWrap>
              {"Vous devez la remplir, le signer et nous l'envoyer."}
            </Typography>
            <Typography noWrap sx={{ mt: 2, color: "primary.main" }} fontWeight={"bold"}>
              <ArrowForwardRounded fontSize="inherit" sx={{ verticalAlign: "text-top", mr: 1 }} />
              {`Formulaire destiné à ${dbReg?.child?.firstname} ${dbReg?.child?.lastname}.`}
            </Typography>
          </>
        }
      />
      <CardContent sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
        <Typography variant={matches ? "h6" : "body1"}>{"Télécharger le formulaire de consentement"}</Typography>
        <Box sx={{ mb: 1 }}>
          {event?.name ? (
            <Box>
              <CreateConsentFile
                event={event}
                reg={dbReg}
                logos={logos}
                unilDoc={unil_survey_doc}
                button={
                  <Button sx={{ ml: 1, textTransform: "lowercase" }} variant="contained" disableElevation endIcon={<DownloadRounded />}>
                    {`${slugify(`consent-${event.name}-${dbReg.child.firstname}-${dbReg.child.lastname}`)}.pdf`}
                  </Button>
                }
              />
            </Box>
          ) : (
            <Typography color="error">{"Erreur. Le formulaire ne peut pas être généré."}</Typography>
          )}
        </Box>
        <SendModes />
        <Typography sx={{ textAlign: "center", mb: -2 }}>
          {"Cette page reste accessible, vous pouvez revenir transférer le formulaire plus tard."}
        </Typography>
      </CardContent>
    </Card>
  )
}
