import { Box, Chip, IconButton } from "@mui/material"
import { EditRounded } from "@mui/icons-material"
import { GENDERS_ENUM, WS_FILTERS_ICON } from "../../../../utils/constants/workshopFilters"
import EditFiltersDialog from "./EditFiltersDialog"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { getWorkshopsLocal } from "../../../../redux/slices/workshopsSlice"

export default function FiltersField({ index, changeWSAttribute }) {
  const [openDialog, setOpenDialog] = useState(false)
  const ws = useSelector(getWorkshopsLocal)[index]
  const [filters, setFilters] = useState([null, null, null])

  const toggleOpenDialog = () => setOpenDialog(!openDialog)

  // TODO problème d'affichage des filtres : le filtre s'affiche à 0-0 alors qu'il devrait disparaître

  const ageFilter = () => {
    if ((ws["age_min"] && ws["age_max"]) || ws["age_max"] - ws["age_min"] > 0)
      return (
        <Chip
          key="ageFilter"
          label={`${ws["age_min"]} - ${ws["age_max"]}`}
          icon={WS_FILTERS_ICON["age"]}
        />
      )
    return null
  }

  const gradeFilter = () => {
    if ((ws["grade_min"] && ws["grade_max"]) || ws["grade_max"] - ws["grade_min"] > 0)
      return (
        <Chip
          key="gradeFilter"
          label={`${ws["grade_min"]} - ${ws["grade_max"]}`}
          icon={WS_FILTERS_ICON["grade"]}
        />
      )
    return null
  }

  const genderFilter = () => {
    if (ws["gender"] > 0)
      return (
        <Chip
          key="genderFilter"
          label={GENDERS_ENUM[ws["gender"]]}
          icon={WS_FILTERS_ICON["gender"]}
        />
      )
    return null
  }

  const computeFilters = () => [ageFilter(), genderFilter(), gradeFilter()]

  useEffect(() => {
    setFilters(computeFilters())
  }, [ws])

  return (
    <Box>
      <Box sx={{ display: "flex", gap: 2, justifyContent: "center", alignItems: "center", ml: 2 }}>
        <Box sx={{ display: "flex", gap: 1 }}>
          {filters.filter((fil) => !!fil).length > 0 ? filters : "Aucun filtre"}
        </Box>
        <IconButton size="small" onClick={toggleOpenDialog}>
          <EditRounded fontSize="small" />
        </IconButton>
      </Box>
      <EditFiltersDialog
        ws={ws}
        open={openDialog}
        toggleOpen={toggleOpenDialog}
        wsIndex={index}
        changeWSAttribute={changeWSAttribute}
      />
    </Box>
  )
}
