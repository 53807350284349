import { cols, colsOptions } from "../../../../utils/constants/workshops"
import { addInputError, getInputsError, removeInputError } from "../../../../redux/slices/appSlice"
import { getWorkshopsLocal, setWSAttribute } from "../../../../redux/slices/workshopsSlice"
import { useDispatch, useSelector } from "react-redux"
import { Box, Card, CardContent, CardHeader, Switch, TextField, Tooltip, Typography } from "@mui/material"
import MagicTable from "../../../../components/magicTable/MagicTable"
import FiltersField from "../../new/filtersField/FiltersField"
import { DynamicFormRounded, PriorityHighRounded } from "@mui/icons-material"
import DateRangeField from "../../../../components/dateRangeField/DateRangeField"

export function WorkshopsList() {
  const workshops = useSelector(getWorkshopsLocal)
  const inputsError = useSelector(getInputsError)
  const dispatch = useDispatch()
  const autoFormDisabled = workshops.filter((ws) => !!ws.available).length === 0

  const changeWSAttribute = (event) => {
    switch (event.target.name.split("-")[1]) {
      case "name":
        if (!event.target.value) dispatch(addInputError(event.target.name))
        else dispatch(removeInputError(event.target.name))
        break
      case "place":
        if (!event.target.value) dispatch(addInputError(event.target.name))
        else dispatch(removeInputError(event.target.name))
        break
      case "start":
        if (!event.target.value) dispatch(addInputError(event.target.name))
        else dispatch(removeInputError(event.target.name))
        break
      case "end":
        if (!event.target.value) dispatch(addInputError(event.target.name))
        else dispatch(removeInputError(event.target.name))
        break
      case "capacity":
        if (!event.target.value) dispatch(addInputError(event.target.name))
        else if (event.target.value < 1) dispatch(addInputError(event.target.name))
        else dispatch(removeInputError(event.target.name))
        break
      default:
        break
    }
    const index = parseInt(event.target.name.split("-")[0])
    const name = event.target.name.split("-")[1]
    dispatch(setWSAttribute({ index: index, key: name, value: event.target.value }))
  }

  const changeCheckedWSAttribute = (event) => {
    const index = parseInt(event.target.name.split("-")[0])
    const name = event.target.name.split("-")[1]
    dispatch(setWSAttribute({ index: index, key: name, value: event.target.checked }))
  }

  const workshopsToRows = () =>
    workshops.map((ws, index) => ({
      cells: [
        <TextField
          key="0"
          size="small"
          placeholder="Nom de l'atelier"
          name={`${index}-name`}
          value={ws.name}
          onChange={changeWSAttribute}
          type="text"
          InputProps={
            ws.deleted
              ? {
                  sx: {
                    color: "error.main",
                    textDecorationLine: "line-through",
                  },
                }
              : !ws._id
              ? { sx: { color: "success.main" } }
              : null
          }
          variant="standard"
          error={inputsError.includes(`${index}-name`)}
        />,
        <TextField
          key="1"
          size="small"
          placeholder="Lieu"
          name={`${index}-place`}
          value={ws.place}
          onChange={changeWSAttribute}
          type="text"
          variant="standard"
          error={inputsError.includes(`${index}-place`)}
        />,
        <DateRangeField key="2" index={index} start={ws.start} end={ws.end} changeWSAttribute={changeWSAttribute} />,
        <TextField
          key="3"
          size="small"
          name={`${index}-capacity`}
          value={ws.capacity}
          onChange={changeWSAttribute}
          inputProps={{ sx: { textAlign: "right" } }}
          sx={{ maxWidth: "6ch" }}
          type="number"
          variant="standard"
          error={inputsError.includes(`${index}-capacity`)}
        />,
        <FiltersField key="4" ws={ws} changeWSAttribute={changeWSAttribute} index={index} />,
        <Switch key="5" name={`${index}-available`} checked={ws.available} onChange={changeCheckedWSAttribute} />,
      ],
    }))

  return (
    <Card sx={{ p: 1, zIndex: 100 }}>
      <CardHeader
        title="Ateliers"
        action={
          autoFormDisabled && (
            <Box sx={{ display: "flex" }}>
              <Typography alignItems={"center"} fontWeight={"bold"} textAlign={"center"} color={"warning.main"}>
                {"Formulaire inaccessible"}
              </Typography>
              <Tooltip
                title={
                  <>
                    <Typography color={"white!important"}>{"Le formulaire est inaccessible quand aucun atelier n'est disponible."}</Typography>
                    <Typography color={"white!important"}>{"Cela ne touche pas à la disponibilité du formulaire."}</Typography>
                  </>
                }
              >
                <Box sx={{ display: "flex", ml: 2 }}>
                  <DynamicFormRounded color="warning" />
                  <PriorityHighRounded color="warning" sx={{ ml: -1 }} />
                </Box>
              </Tooltip>
            </Box>
          )
        }
      />
      <CardContent sx={{ py: "8px!important" }}>
        {workshops ? (
          <MagicTable rows={workshopsToRows()} cols={cols} colsOptions={colsOptions} />
        ) : (
          <Typography textAlign={"center"}>Loading</Typography>
        )}
      </CardContent>
    </Card>
  )
}
