import { Box, Toolbar, Typography } from "@mui/material"

export default function RTToolbar({ tools = [], small = false }) {
  return (
    <Toolbar
      id={"RTToolbar"}
      variant={small ? "dense" : "regular"}
      sx={{ justifyContent: "space-between", px: "0!important", alignItems: "center" }}
    >
      <Typography id={"RTT-TOPBAR_TITLE"} variant={small ? "body" : "h5"}>
        {"Demandes d'inscription"}
      </Typography>
      <Box
        id={"RTT-tools"}
        sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", flex: 1 }}
      >
        {tools}
      </Box>
    </Toolbar>
  )
}
