import { CssBaseline, ThemeProvider } from "@mui/material"
import { appTheme } from "./utils/theme/app"
import { BrowserRouter } from "react-router-dom"
import Router from "./Router"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { SnackbarProvider } from "notistack"

export default function App() {
  const queryClient = new QueryClient()

  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider
          sx={{ "& .SnackbarContent-root": { borderRadius: 1, fontWeight: "bold", p: "8px 16px" } }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </SnackbarProvider>
      </QueryClientProvider>
    </ThemeProvider>
  )
}
