import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Switch, Typography } from "@mui/material"
import FormInputs from "./FormInputs"
import { getFormLocal, setLocalAttribute } from "../../../../../redux/slices/formSlice"
import { useDispatch, useSelector } from "react-redux"
import { isEmpty } from "lodash"
import { AVAILABLE_COLOR, AVAILABLE_LABEL } from "../../../../../utils/constants/forms"
import HelpTooltip from "../HelpTooltip"

export default function MainForm({ expanded, handleExpanded }) {
  const form = useSelector(getFormLocal)
  const dispatch = useDispatch()
  // const workshops = useSelector(getWorkshopsDb)

  const onClickAvailable = (event) => {
    event.stopPropagation()
    dispatch(setLocalAttribute({ key: "available", value: event.target.checked }))
  }

  if (!form || isEmpty(form)) return <CircularProgress color="heig" />

  // check if at least one workshop is available
  // const isWorkshopsAvailable = workshops.some((workshop) => workshop.available)
  //         {!isWorkshopsAvailable && (
  //           <Typography color={"warning.main"} mr={2} sx={{ backgroundColor: "warning.bg", py: 0.5, px: 1, borderRadius: BR_MEDIUM }}>
  //             <WarningRounded sx={{ verticalAlign: "bottom", mr: 0.5 }} />
  //             Aucun atelier disponible
  //           </Typography>
  //         )}

  return (
    <Accordion expanded={expanded === "mainForm"} onChange={handleExpanded("mainForm")}>
      <AccordionSummary>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Typography variant="h5">{"Formulaire de demande d'inscription"}</Typography>
          <HelpTooltip />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Switch
            // sx={{ height: "24px!important" }}
            checked={form.available}
            onClick={onClickAvailable}
          />
          <Typography color={AVAILABLE_COLOR(form.available)}>{AVAILABLE_LABEL(form.available)}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <FormInputs />
      </AccordionDetails>
    </Accordion>
  )
}
