import { AddRounded } from "@mui/icons-material"
import { Box, IconButton, TableCell, TableHead, TableRow, TableSortLabel, Typography } from "@mui/material"
import { visuallyHidden } from "@mui/utils"
import PropTypes from "prop-types"
import { BR_LARGE as ABR } from "../../utils/theme/app"

export default function MagicHead({
  cols = null,
  colsOptions = null,
  rowActionsCol = false,
  addRow = null,
  isSubRows = false,
  orderBy = null,
  sortBy = null,
  colsSorting = null,
}) {
  const handleColsSorting = (property) => (event) => {
    colsSorting(event, property)
  }

  const colsElems = cols.map((col, index) => {
    const colOptions = colsOptions ? colsOptions[index] : null
    return (
      <TableCell
        key={`MHead-th-${index}`}
        align={colOptions ? colOptions.align : "left"}
        sx={{
          // width: a[index],
          whiteSpace: "nowrap",
          alignItems: "center",
        }}
        sortDirection={sortBy === index ? orderBy : false}
      >
        {!!orderBy && !!colsSorting ? (
          <TableSortLabel active={sortBy === index} direction={sortBy === index ? orderBy : "asc"} onClick={handleColsSorting(index)}>
            <Typography fontWeight="bold" noWrap>
              {col}
            </Typography>
            {sortBy === index ? (
              <Box component="span" sx={visuallyHidden}>
                {orderBy === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        ) : (
          { col }
        )}
      </TableCell>
    )
  })

  const subRowsColElem = (
    <TableCell align="left" sx={{ width: "56px" }} style={{ borderTopLeftRadius: ABR, borderBottomLeftRadius: ABR }}>
      {" "}
    </TableCell>
  )

  const addRowButton = (
    <IconButton onClick={() => addRow()} size={"small"} sx={{ p: 0, color: "primary.main", fontSize: "1rem" }}>
      <AddRounded />
    </IconButton>
  )

  const actionsColElems = (
    <TableCell style={{ borderTopRightRadius: ABR, borderBottomRightRadius: ABR }} align={"right"} sx={{ width: "56px" }}>
      {addRow ? addRowButton : " "}
    </TableCell>
  )

  return (
    <TableHead id={"MHead-container"}>
      <TableRow
        id={"MHead-tr"}
        sx={{
          "& th": { backgroundColor: "whitesmoke", cursor: "pointer" },
          "&> th:first-of-type": { borderRadius: `${ABR} 0 0 ${ABR}` },
          "&> th:last-child": { borderRadius: `0 ${ABR} ${ABR} 0` },
        }}
      >
        {/* Add a col to left if table display sub rows*/}
        {isSubRows && subRowsColElem}
        {/*  Cols mapping*/}
        {cols ? colsElems : <TableCell>props cols should not be null</TableCell>}
        {rowActionsCol && actionsColElems}
      </TableRow>
    </TableHead>
  )
}

MagicHead.propTypes = {
  cols: PropTypes.array.isRequired,
  rowActionsCol: PropTypes.bool,
  addRow: PropTypes.func || null,
  isSubRows: PropTypes.bool,
  orderBy: PropTypes.oneOf(["asc", "desc"]) || null,
  sortBy: PropTypes.number || null,
  colsSorting: PropTypes.func || null,
}
