import { Box, Container } from "@mui/material"
import PageHeader from "../../components/pageHeader/PageHeader"
import ExportTable from "./ExportTable"

export default function Export() {
  return (
    <>
      <Container>
        <PageHeader title={"Export des données"} />
      </Container>
      <Box sx={{ display: "flex", gap: 4, flexDirection: "column", alignItems: "center", px: 4, pb: 4 }}>
        <ExportTable />
      </Box>
    </>
  )
}
