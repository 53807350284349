import { useDispatch, useSelector } from "react-redux"
import { getLocalActivity, setLocalActivityAttribute } from "../../../redux/slices/activitiesSlice"
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { TextFieldsRounded } from "@mui/icons-material"

export function AttributesInputs() {
  const dispatch = useDispatch()
  const localActivity = useSelector(getLocalActivity)
  const isMobile = useMediaQuery(useTheme().breakpoints.down("md"))

  return (
    <Card sx={{ p: 1 }}>
      <CardHeader title={"Attributs"} />
      <CardContent
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          flexWrap: "wrap",
          gap: 4,
        }}
      >
        <Box sx={{ flex: 1, display: "flex", alignItems: "flex-end" }}>
          <TextFieldsRounded sx={{ color: "primary.main", mr: 1, my: 0.5 }} />
          <TextField
            label="Nom de l'activité"
            name="name"
            value={localActivity?.name ?? ""}
            onChange={(event) =>
              dispatch(
                setLocalActivityAttribute({ attribute: "name", newValue: event.target.value })
              )
            }
            type="text"
            variant="standard"
            fullWidth
          />
        </Box>
        <Box sx={{ flex: 3 }} />
      </CardContent>
    </Card>
  )
}
