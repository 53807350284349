import { CircularProgress, Grid, Typography } from "@mui/material"
import FormInputToggleButtons from "../../../../../components/inputs/FormInputToggleButtons"
import { useDispatch, useSelector } from "react-redux"
import { getFormLocal, setLocalInput } from "../../../../../redux/slices/formSlice"
import { isEmpty, isNull } from "lodash"
import { translateRegLabel } from "../../../../../utils/constants/registrations"

export default function FormInputs() {
  const { inputs } = useSelector(getFormLocal)
  const dispatch = useDispatch()

  if (!inputs || isEmpty(inputs)) return <CircularProgress color="heig" />

  const handleChangeInput = (key, value) => {
    if (!isNull(value)) dispatch(setLocalInput({ key, value }))
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant={"sub5"} color={"primary"} fontWeight="bold">
          Enfant
        </Typography>
        <Grid container columnSpacing={8}>
          <Grid item xs={4}>
            <FormInputToggleButtons
              keyValue={"child_name"}
              value={inputs["child_name"]}
              // setValue={handleChangeInput}
              optional
              label={"Nom / Prénom"}
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <FormInputToggleButtons
              keyValue={"child_birthdate"}
              value={inputs["child_birthdate"]}
              setValue={handleChangeInput}
              optional
              label={"Date de naissance"}
            />
          </Grid>
          <Grid item xs={4}>
            <FormInputToggleButtons keyValue={"child_email"} value={inputs["child_email"]} setValue={handleChangeInput} optional label={"Email"} />
          </Grid>
          <Grid item xs={4}>
            <FormInputToggleButtons
              keyValue={"child_phone"}
              value={inputs["child_phone"]}
              setValue={handleChangeInput}
              optional
              label={"Téléphone"}
            />
          </Grid>
          <Grid item xs={4}>
            <FormInputToggleButtons keyValue={"child_gender"} value={inputs["child_gender"]} setValue={handleChangeInput} optional label={"Genre"} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"sub5"} color={"primary"} fontWeight="bold">
          Adresse
        </Typography>
        <Grid container columnSpacing={8}>
          <Grid item xs={4}>
            <FormInputToggleButtons
              keyValue={"address_street"}
              value={inputs["address_street"]}
              setValue={handleChangeInput}
              optional
              label={"Rue"}
            />
          </Grid>
          <Grid item xs={4}>
            <FormInputToggleButtons keyValue={"address_zip"} value={inputs["address_zip"]} setValue={handleChangeInput} optional label={"NPA"} />
          </Grid>
          <Grid item xs={4}>
            <FormInputToggleButtons keyValue={"address_city"} value={inputs["address_city"]} setValue={handleChangeInput} optional label={"Ville"} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"sub5"} color={"primary"} fontWeight="bold">
          École
        </Typography>
        <Grid container columnSpacing={8}>
          <Grid item xs={4}>
            <FormInputToggleButtons keyValue={"school_grade"} value={inputs["school_grade"]} setValue={handleChangeInput} optional label={"Classe"} />
          </Grid>
          <Grid item xs={4}>
            <FormInputToggleButtons keyValue={"school_name"} value={inputs["school_name"]} setValue={handleChangeInput} optional label={"Nom"} />
          </Grid>
          <Grid item xs={4}>
            <FormInputToggleButtons
              keyValue={"school_zip"}
              value={inputs["school_zip"]}
              setValue={handleChangeInput}
              optional
              label={translateRegLabel["zip"]}
            />
          </Grid>
          <Grid item xs={4}>
            <FormInputToggleButtons keyValue={"school_city"} value={inputs["school_city"]} setValue={handleChangeInput} optional label={"Ville"} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"sub5"} color={"primary"} fontWeight="bold">
          Parent
        </Typography>
        <Grid container columnSpacing={8}>
          <Grid item xs={4}>
            <FormInputToggleButtons
              keyValue={"parent_name"}
              value={inputs["parent_name"]}
              setValue={handleChangeInput}
              optional
              label={"Nom / Prénom"}
            />
          </Grid>
          <Grid item xs={4}>
            <FormInputToggleButtons
              disabled
              keyValue={"parent_email"}
              value={inputs["parent_email"]}
              setValue={handleChangeInput}
              optional
              label={"Email"}
            />
          </Grid>
          <Grid item xs={4}>
            <FormInputToggleButtons
              keyValue={"parent_phone"}
              value={inputs["parent_phone"]}
              setValue={handleChangeInput}
              optional
              label={"Téléphone"}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"sub5"} color={"primary"} fontWeight="bold">
          Autres
        </Typography>
        <Grid container spacing={8}>
          <Grid item xs={4}>
            <FormInputToggleButtons keyValue={"newsletters"} value={inputs["newsletters"]} setValue={handleChangeInput} label={"Newsletters"} />
          </Grid>
          <Grid item xs={4}>
            <FormInputToggleButtons keyValue={"comments"} value={inputs["comments"]} setValue={handleChangeInput} label={"Commentaires"} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
