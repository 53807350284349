import { Box, Tooltip, Typography } from "@mui/material"
import { ClearRounded, HelpRounded, PriorityHighRounded, QuestionMarkRounded } from "@mui/icons-material"

export default function HelpTooltip() {
  return (
    <Tooltip
      key={"HelpTooltip"}
      arrow
      PopperProps={{
        sx: {
          color: "#333333",
          maxWidth: "400px",
          "& .MuiTooltip-arrow	": {
            color: "white!important",
            ml: -0.5,
          },
          "& .MuiTooltip-tooltip": {
            mr: -1,
            background: "white",
            color: "primary.main",
            fontWeight: "bold",
            fontSize: "1rem",
            maxWidth: "none",
            p: 2,
            verticalAlign: "bottom",
            filter: "drop-shadow(0 0rem 1mm lightgray)",
          },
        },
      }}
      placement="bottom-end"
      title={
        <Box sx={{ p: 1 }}>
          <Typography sx={{ lineHeight: 1, textAlign: "justify" }} gutterBottom>
            {"Le menu de contrôle des champs permet de contrôler les champs qui apparaîtront dans le formulaire d'inscription pour l'évènement"}
          </Typography>
          <Box mt={1} />
          <Typography sx={{ lineHeight: 1, textAlign: "justify" }} gutterBottom>
            - <ClearRounded fontSize={"small"} /> {": Le champ n'apparaît pas de le formulaire"}
          </Typography>
          <Box />
          <Typography sx={{ lineHeight: 1, textAlign: "justify" }} gutterBottom>
            - <QuestionMarkRounded fontSize={"small"} /> : Le champ apparaît dans le formulaire mais peut être laissé vide
          </Typography>
          <Box />
          <Typography sx={{ lineHeight: 1, textAlign: "justify" }} gutterBottom>
            - <PriorityHighRounded fontSize={"small"} /> : Le champ apparaît dans le formulaire et ne peut pas rester vide
          </Typography>
        </Box>
      }
    >
      <HelpRounded sx={{ verticalAlign: "middle", mr: 2, color: "darkgray" }} />
    </Tooltip>
  )
}
