import { createSlice } from "@reduxjs/toolkit"

import apiClient from "../../configs/axiosCfg"
import { cloneDeep, findIndex } from "lodash"

const initialState = {
  list: [],
  dbActivity: null,
  localActivity: null,
  archived: false,
}

export const activitiesSlice = createSlice({
  name: "activities",
  initialState,
  reducers: {
    setList: (state, action) => {
      state.list = action.payload
    },
    setArchived: (state, action) => {
      state.archived = action.payload
    },
    setDbActivity: (state, action) => {
      state.dbActivity = action.payload
    },
    setLocalActivity: (state, action) => {
      state.localActivity = action.payload
    },
    setLocalActivityAttribute: (state, action) => {
      state.localActivity[action.payload.attribute] = action.payload.newValue
    },
    addManager: (state, action) => {
      state.localActivity.managers = [...state.localActivity.managers, action.payload]
    },
    deleteManager: (state, action) => {
      const newManagers = cloneDeep(state.localActivity.managers)
      newManagers.splice(
        findIndex(newManagers, (man) => man.email === action.payload),
        1
      )
      state.localActivity.managers = newManagers
    },
  },
})

export const { setList, setArchived, setDbActivity, setLocalActivity, setLocalActivityAttribute, addManager, deleteManager } = activitiesSlice.actions

export const activities = (state) => state.activities.list
export const getActivityContext = (state) => state.activities.activityContext
export const getLocalActivity = (state) => state.activities.localActivity
export const getActivities = (state) => state.activities

export const fetchActivities =
  (kToken, archived = false) =>
  async (dispatch) =>
    apiClient
      .get(`/activities?archived=${archived}`, {
        headers: { Authorization: "Bearer " + kToken },
      })
      .then((response) => {
        if (response.status === 204) return { status: response.status, text: "ok" }
        dispatch(setList(response.data))
        return { status: response.status, text: "ok" }
      })
      .catch((error) => {
        console.error(error.response.data)
        return { status: error.toJSON().status, text: "error" }
      })

export const postActivity = (body, kToken) => async (dispatch, getState) =>
  apiClient
    .post(`/activities`, body, {
      headers: { Authorization: "Bearer " + kToken },
    })
    .then((response) => {
      dispatch(fetchActivities(kToken, getState().archived))
      return { status: response.status, text: "ok" }
    })
    .catch((error) => {
      console.error(error.response.data)
      return { status: error.toJSON().status, text: "error" }
    })

export const putActivity = (actId, body, kToken) => async (dispatch, getState) => {
  return apiClient
    .put(`/activities/${actId}`, body, {
      headers: { Authorization: "Bearer " + kToken },
    })
    .then((response) => {
      dispatch(fetchActivities(kToken, getState().archived))
      return { status: response.status, text: "ok" }
    })
    .catch((error) => {
      console.error(error.response.data)
      return { status: error.toJSON().status, text: "error" }
    })
}

export const deleteActivity = (actId, kToken) => async (dispatch, getState) =>
  apiClient
    .delete(`/activities/${actId}`, {
      headers: { Authorization: "Bearer " + kToken },
    })
    .then((response) => {
      dispatch(fetchActivities(kToken, getState().archived))
      return { status: response.status, text: "ok" }
    })
    .catch((error) => {
      console.error(error.response.data)
      return { status: error.toJSON().status, text: "error" }
    })

export const deletePermissionActivity = (actId, permId, kToken) => async () =>
  apiClient
    .delete(`/activities/${actId}/permissions/${permId}`, {
      headers: { Authorization: "Bearer " + kToken },
    })
    .then((response) => {
      return { status: response.status, text: "ok" }
    })
    .catch((error) => {
      console.error(error.response.data)
      return { status: error.toJSON().status, text: "error" }
    })

export const postPermissionActivity = (actId, body, kToken) => async () =>
  apiClient
    .post(`/activities/${actId}/permissions`, body, {
      headers: { Authorization: "Bearer " + kToken },
    })
    .then((response) => {
      return { status: response.status, text: "ok" }
    })
    .catch((error) => {
      console.error(error.response.data)
      return { status: error.toJSON().status, text: "error" }
    })

export default activitiesSlice.reducer
