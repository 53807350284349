import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material"
import { capitalize, isEmpty } from "lodash"
import { getRegistration } from "../../../../redux/slices/registrationSlice"
import { useSelector } from "react-redux"
import { formatDateCH } from "../../../../utils/tablesHelpers"
import { STATUSES, STATUSES_ICON } from "../../../../utils/constants/statuses"
import TableReg from "../../components/TableReg"
import ShowDetails from "../../../dialogs/ShowDetails"

const header = [
  {
    label: "Enfant",
    align: "left",
  },
  {
    label: "Évènement",
    align: "left",
  },
  {
    label: "Statut",
    align: "center",
  },
  {
    label: "Atelier / Raison",
    align: "left",
  },
  {
    label: "Créée le",
    align: "right",
  },
]

export default function OthersRegs() {
  const { othersRegs } = useSelector(getRegistration)
  const computeDetails = ({ status, reason, selected_choice, choices }) => {
    if (!status) return "-"
    const content = [1, 2].includes(status) ? choices[choices.map((choice) => choice.order).indexOf(selected_choice)].workshop.name : reason
    const isTooManyChars = typeof content === "string" && content.length > 35
    return (
      <Box key={selected_choice + status} sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Typography variant="inherit" sx={isTooManyChars ? { width: "24ch" } : {}} noWrap>
          {content}
        </Typography>
        {isTooManyChars && <ShowDetails title={"Détails"} content={content} />}
      </Box>
    )
  }

  const mapRegs = () =>
    othersRegs.map((reg) => ({
      regId: reg._id,
      cells: [
        capitalize(reg?.child?.lastname) + " " + capitalize(reg?.child?.firstname) ?? "-",
        reg.eventId.name,
        STATUSES_ICON[STATUSES[reg?.status]] ?? "-",
        computeDetails(reg),
        formatDateCH(reg?.createdAt),
      ],
    }))

  return (
    !isEmpty(othersRegs) && (
      <Card>
        <CardHeader
          title={
            <Box>
              <Typography variant="inherit">{"Demandes d'inscription aux autres évènements"}</Typography>
              <Typography variant="sub6">{"Recherche basée sur le nom, prénom et date de naissance de l'enfant"}</Typography>
            </Box>
          }
        />
        <CardContent sx={{ pt: 0, pb: "8px!important", display: "flex", gap: 2, flexWrap: "wrap" }}>
          <TableReg header={header} rows={mapRegs()} />
        </CardContent>
      </Card>
    )
  )
}
