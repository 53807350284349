import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { useEffect } from "react"
import Topbar from "./topbar/Topbar"
import InitContext from "./InitContext"
import Sidebar from "./sidebar/Sidebar"
import InitKeycloak from "./InitKeycloak"

export default function Dashboard() {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname === "/dashboard") navigate("/dashboard/events/list")
  }, [])

  return (
    <InitKeycloak>
      <InitContext>
        <Topbar />
        <Sidebar>
          <Outlet />
        </Sidebar>
      </InitContext>
    </InitKeycloak>
  )
}
