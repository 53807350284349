import { DownloadOutlined } from "@mui/icons-material"
import { Button } from "@mui/material"
import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
import { cloneElement } from "react"
import { getConsentPDF, regNeededFields } from "../../../utils/forms"

pdfMake.vfs = pdfFonts.pdfMake.vfs

export default function CreateConsentFile({ button = null, reg, event = null, stopPropagation = false, logos = [], unilDoc }) {
  const onClick = (e) => {
    if (stopPropagation) {
      e.stopPropagation()
    }
    const neededFields = regNeededFields(reg)
    getConsentPDF(event, reg.child, neededFields, logos, unilDoc)
  }

  return cloneElement(
    button ?? (
      <Button sx={{ p: 0 }} startIcon={<DownloadOutlined sx={{ ml: "4px", mr: "-6px" }} />}>
        Vierge
      </Button>
    ),
    { onClick: onClick }
  )
}
