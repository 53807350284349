import { Box, Typography } from "@mui/material"
import { UsersEmail } from "./users/UsersEmail"
import CustomEmail from "./CustomEmail"

export default function Feedback() {
  return (
    <Box sx={{ ml: 2, display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ ml: -1, display: "flex", gap: 1, flexDirection: "column" }}>
        <Typography variant="h6">{"Feedback utilisateurs"}</Typography>
        <Typography sx={{ ml: 1 }} variant="sub6">
          {"Recevoir une notification pour chaque demande d'inscription enregistrée."}
        </Typography>
      </Box>
      <UsersEmail />
      <CustomEmail />
    </Box>
  )
}
