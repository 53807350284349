import { Box, Typography } from "@mui/material"
import { cloneElement, isValidElement, useState } from "react"

export default function TypoField({
  title = "",
  content = "",
  bgColor = "shade.bg",
  borderColor = "#ffffff",
  titleWidth = "10ch",
  editMode = false,
}) {
  const [color, setColor] = useState("shade.main")

  return (
    <Box
      onMouseEnter={() => editMode && setColor("primary.main")}
      onMouseLeave={() => editMode && setColor("shade.main")}
      sx={{
        display: "flex",
        alignItems: "start",
        backgroundColor: bgColor,
        borderRight: `1px solid ${borderColor}`,
        borderLeft: `1px solid ${borderColor}`,
        minHeight: "40px",
        pt: "8px",
      }}
    >
      <Box sx={{ display: "flex", color: "inherit" }}>
        <Typography
          sx={{
            width: titleWidth,
            fontWeight: "bold",
            mx: 1,
            textTransform: "capitalize",
            color: color,
          }}
        >
          {title}
        </Typography>
      </Box>

      <Box sx={{ flex: 1, mx: 1, textAlign: "justify", color: title ? "#333333" : "#ffffff" }}>
        {isValidElement(content) ? cloneElement(content, { color: color }) : content}
      </Box>
    </Box>
  )
}
