import { useDispatch, useSelector } from "react-redux"
import { getMainForm, setActiveStep } from "../../../../redux/slices/mainFormSlice"
import { Button, CardHeader, Typography, useMediaQuery, useTheme } from "@mui/material"

export function CheckCardHeader() {
  const { regs } = useSelector(getMainForm)
  const dispatch = useDispatch()

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("sm"))

  return (
    <CardHeader
      title={
        <>
          <Typography variant={matches ? "inherit" : "h6"} gutterBottom>
            {`Récapitulatif ${regs.length > 1 ? "des inscriptions" : "de l'inscription"}`}
          </Typography>
          <Typography variant="sub6">{"Veuillez vérifier si tous les informations sont correctes."}</Typography>
        </>
      }
      action={
        <Button color="warning" variant="outlined" onClick={() => dispatch(setActiveStep(0))}>
          Corriger
        </Button>
      }
    />
  )
}
