import { Checkbox, TableCell, TableHead, TableRow } from "@mui/material"
import RTHeadCell from "./RTHeadCell"
import { getRegistrationsState, setAttribute } from "../../redux/slices/registrationsSlice"
import { useDispatch, useSelector } from "react-redux"
import { getColsAndOptions } from "./utils"
import { useEffect, useState } from "react"
import { BR_LARGE as ABR } from "../../utils/theme/app"

export default function RTHead() {
  const dispatch = useDispatch()
  const { selectedStatus, selectionsRegs, list } = useSelector(getRegistrationsState)
  const [{ cols, colsOptions }, setColsAndOptions] = useState(getColsAndOptions(selectedStatus))
  const [selectionsStatus, setSelectionsStatus] = useState(0)

  useEffect(() => {
    setSelectionsStatus(selectionsRegs.length === 0 ? 0 : selectionsRegs.length < list.length ? 1 : selectionsRegs.length === list.length ? 2 : -1)
  }, [selectionsRegs, list])

  useEffect(() => {
    setColsAndOptions(getColsAndOptions(selectedStatus))
  }, [selectedStatus])

  useEffect(() => {
    dispatch(setAttribute({ key: "sortBy", value: cols.length - 1 }))
  }, [cols])

  const selectAllOnClick = () => {
    if (selectionsStatus === 0 || selectionsStatus === 1) dispatch(setAttribute({ key: "selectionsRegs", value: list.map((reg) => reg._id) }))
    else dispatch(setAttribute({ key: "selectionsRegs", value: [] }))
  }

  return (
    <TableHead>
      <TableRow
        sx={{
          "&> th": { backgroundColor: "shade.bg" },
          "&> th:first-of-type": { borderRadius: `${ABR} 0 0 ${ABR}` },
          "&> th:last-child": { borderRadius: `0 ${ABR} ${ABR} 0` },
        }}
      >
        <TableCell align="center" padding="checkbox">
          <Checkbox checked={!!selectionsStatus} indeterminate={selectionsStatus === 1} onClick={selectAllOnClick} />
        </TableCell>
        {cols.map((col, index) => (
          <RTHeadCell key={index} index={index} align={colsOptions[index]?.align} cellData={col} />
        ))}
        <TableCell align="center" padding="checkbox">
          {""}
        </TableCell>
      </TableRow>
    </TableHead>
  )
}
