export const initWorkshop = {
  name: "",
  place: "",
  age_min: 0,
  age_max: 0,
  gender: 0,
  grade_min: 0,
  grade_max: 0,
  start: "",
  end: "",
  capacity: 16,
  available: true,
  deleted: false,
}

export const initEvent = {
  name: "",
  selections: 3,
  validations: 1,
  archived: false,
  deleted: false,
  activityId: 0,
}

export const notificationsInitState = {
  users_email: [],
  contact_email: "",
  custom_email: "",
  send_to_contact: false,
  notify_enrollment: false,
}
