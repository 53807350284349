import { deburr, join, toLower, toString, trim } from "lodash"
import { GENDER } from "./constants/registrations"
import { ADMITTED_STATUSES, ADMITTED_STATUSES_LABEL, STATUSES, STATUSES_LABEL } from "./constants/statuses"

const descendingComparator = (a, b, sortBy) => {
  if (deburr(b["cells"][sortBy]).toLowerCase() < deburr(a["cells"][sortBy]).toLowerCase()) {
    return -1
  }
  if (deburr(b["cells"][sortBy]).toLowerCase() > deburr(a["cells"][sortBy]).toLowerCase()) {
    return 1
  }
  return 0
}

export const getComparator = (orderBy, sortBy) => {
  return orderBy === "desc" ? (a, b) => descendingComparator(a, b, sortBy) : (a, b) => -descendingComparator(a, b, sortBy)
}

const descendingObjectComparator = (a, b, sortBy) => {
  if (b["cells"][sortBy].key < a["cells"][sortBy].key) {
    return -1
  }
  if (b["cells"][sortBy].key > a["cells"][sortBy].key) {
    return 1
  }
  return 0
}

export const getObjectComparator = (orderBy, sortBy) => {
  return orderBy === "desc" ? (a, b) => descendingObjectComparator(a, b, sortBy) : (a, b) => -descendingObjectComparator(a, b, sortBy)
}

export const getAge = (birthDate = null, targetDate = new Date().toISOString()) => {
  if (!birthDate) return ""
  return Math.floor((new Date(targetDate) - new Date(birthDate).getTime()) / 3.15576e10)
}

export const formatDateCH = (isoDateString) =>
  new Date(isoDateString).toLocaleString("fr-CH", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    formatMatcher: "best fit",
  })

const formatDate = (date) =>
  date.toLocaleString("fr-CH", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  })
const formatHours = (date) =>
  date.toLocaleString("fr-CH", {
    hour: "2-digit",
    minute: "2-digit",
  })

const _MS_PER_DAY = 1000 * 60 * 60 * 24

export function dateDisplay(start, end) {
  const a = new Date(start)
  const b = new Date(end)

  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())

  const dateDiff = Math.floor((utc2 - utc1) / _MS_PER_DAY)

  return dateDiff === 0 ? `${formatDate(a)}, ${formatHours(a)} - ${formatHours(b)}` : `${a.toLocaleString("fr-CH")} - ${b.toLocaleString("fr-CH")}`
}

export const formatString = (str) => toLower(deburr(trim(toString(str))))

export const regArrayForSearch = (reg, start, startWS) => [
  ["enfant", `${formatString(reg?.child?.firstname)} ${formatString(reg?.child?.lastname)}`],
  ["genre", formatString(GENDER[reg?.child?.gender])],
  ["naissance", formatString(formatDateCH(reg?.child?.birthdate))],
  ["age", formatString(getAge(reg?.child?.birthdate, startWS || start))],
  ["email", `${formatString(reg?.parent?.email)} ${formatString(reg?.child?.email)}`],
  ["telephone", `${formatString(reg?.child?.phone)} ${formatString(reg?.parent?.phone)}`],
  ["parent", `${formatString(reg?.parent?.firstname)} ${formatString(reg?.parent?.lastname)}`],
  ["rue", formatString(reg?.address?.line1)],
  ["npa", formatString(reg?.address?.zip)],
  ["ville", formatString(reg?.address?.city)],
  ["degre", formatString(reg?.school?.grade)],
  ["voie", formatString(reg?.school?.path)],
  ["classe", formatString(reg?.school?.class)],
  ["ecole", formatString(reg?.school?.name)],
  ["ecole-npa", formatString(reg?.school?.zip)],
  ["ecole-ville", formatString(reg?.school?.city)],
  ["statut", formatString(STATUSES_LABEL[STATUSES[reg?.status]])],
  ["statut-admission", formatString(ADMITTED_STATUSES_LABEL[ADMITTED_STATUSES[reg?.admitted_status]])],
  [
    "ateliers",
    join(
      reg?.choices.map((choice) => formatString(choice?.workshop?.name)),
      " "
    ),
  ],
  ["t-shirt", formatString(reg?.tshirt_size)],
  ["regime", formatString(reg?.diet)],
  ["commentaires", formatString(reg?.comments)],
  ["transport", reg?.transportation ? "oui" : "non"],
  ["newsletters", reg?.newsletters ? "oui" : "non"],
  ["medias", reg?.media_consent ? "oui" : "non"],
  ["risques", reg?.risks_consent ? "oui" : "non"],
  ["unil", reg?.unil_survey ? "oui" : "non"],
]
