import { Divider, TablePagination, Toolbar } from "@mui/material"
import { getRegistrationsState, setAttribute } from "../../redux/slices/registrationsSlice"
import { useDispatch, useSelector } from "react-redux"

export default function RTFooter({ small = false }) {
  const { page, rowsPerPage, count } = useSelector(getRegistrationsState)
  const dispatch = useDispatch()

  const setPage = (event, newPage) => dispatch(setAttribute({ key: "page", value: newPage }))

  const setRowsPerPage = (event) => {
    dispatch(setAttribute({ key: "rowsPerPage", value: parseInt(event.target.value, 10) }))
    setPage(null, 0)
  }

  return (
    <div>
      <Divider />
      <Toolbar
        sx={{
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          p: "0!important",
          alignItems: "center",
        }}
        variant={small ? "dense" : "regular"}
      >
        <TablePagination
          component={"div"}
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={setPage}
          onRowsPerPageChange={setRowsPerPage}
          size={small ? "small" : "medium"}
        />
      </Toolbar>
    </div>
  )
}
