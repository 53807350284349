import { DownloadRounded } from "@mui/icons-material"
import { useSelector } from "react-redux"
import { getWorkshop } from "../../../redux/slices/workshopSlice"
import { LoadingButton } from "@mui/lab"
import { useState } from "react"
import { useKeycloak } from "@react-keycloak/web"
import { useParams } from "react-router-dom"
import apiClient from "../../../configs/axiosCfg"
import { useSnackbar } from "notistack"

export default function WorkshopRegistrationsFile() {
  const { keycloak } = useKeycloak()
  const { eventId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const workshop = useSelector(getWorkshop)
  const [loading, setLoading] = useState(false)

  const onClick = async () => {
    setLoading(true)
    apiClient
      .get(`/events/${eventId}/workshops/${workshop._id}/registrations/list`, {
        headers: { Authorization: "Bearer " + keycloak.token },
        responseType: "arraybuffer",
      })
      .then(async (response) => {
        if (response.status === 204) return enqueueSnackbar("Aucune inscription pour cet atelier", { variant: "warning" })
        const blob = new Blob([response.data], { type: "application/pdf" })
        // download file
        const link = document.createElement("a")
        link.href = window.URL.createObjectURL(blob)
        // slugify(workshop.name)
        link.download = `inscriptions-${workshop.name.replace(/ /g, "-")}.pdf`
        link.click()
        setLoading(false)
      })
      .catch((error) => {
        console.error(error.response.data)
        enqueueSnackbar("Une erreur est survenue lors de la récupération du fichier", { variant: "error" })
        return setLoading(false)
      })
  }

  return (
    <LoadingButton
      disabled={!workshop?._id || !eventId}
      variant="contained"
      loading={loading}
      loadingPosition="end"
      endIcon={<DownloadRounded />}
      onClick={onClick}
    >
      Résumé des admissions
    </LoadingButton>
  )
}
