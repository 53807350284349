import { Box, Typography } from "@mui/material"

export function SendMode({ title, content }) {
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "shade.bg",
        borderRadius: 1,
        overflow: "clip",
      }}
    >
      <Typography
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          backgroundColor: "shade.main",
          color: "shade.bg",
          py: 0.5,
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          py: 0.5,
          minHeight: "64px",
        }}
      >
        {content ?? ""}
      </Box>
    </Box>
  )
}
