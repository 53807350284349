import { Box, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material"
import { AddRounded, RemoveRounded } from "@mui/icons-material"
import { useDispatch, useSelector } from "react-redux"
import { decrementNumValidWanted, getMainForm, incrementNumValidWanted, setNumValidWanted } from "../../../../redux/slices/mainFormSlice"
import { useEffect } from "react"

export default function NumValidWanted({ index }) {
  const dispatch = useDispatch()
  const {
    regs,
    config: {
      event: { validations },
    },
  } = useSelector(getMainForm)

  const numValidWanted = regs[index]?.numValidWanted

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("sm"))

  const changeNumValidWanted = (increment) => {
    if (increment) {
      if (validations > regs[index]?.numValidWanted) dispatch(incrementNumValidWanted(index))
    } else if (regs[index]?.numValidWanted > 0) dispatch(decrementNumValidWanted(index))
  }

  useEffect(() => {
    dispatch(setNumValidWanted({ index, value: validations }))
  }, [])

  return validations > 1 ? (
    <>
      <Typography variant={matches ? "h6" : "body1"}>{`À combien d'ateliers souhaitez-vous participer ?`}</Typography>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 4 }}>
        <Box
          display="flex"
          alignItems="center"
          gap={2}
          sx={{
            backgroundColor: "white!important",
            borderRadius: 1,
            filter: "drop-shadow(0 0rem 1mm lightgray)",
          }}
        >
          <IconButton disabled={numValidWanted === 1} size="small" color="error" onClick={() => changeNumValidWanted(false)}>
            <RemoveRounded />
          </IconButton>
          <Typography width={"3ch"} textAlign={"center"}>
            {numValidWanted}
          </Typography>
          <IconButton disabled={numValidWanted === validations} size="small" color="success" onClick={() => changeNumValidWanted(true)}>
            <AddRounded />
          </IconButton>
        </Box>
      </Box>
    </>
  ) : (
    <>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Typography variant={matches ? "h6" : "body1"}>{`Vous pouvez participer à`}</Typography>
        <Typography variant={matches ? "h6" : "body1"} color={"primary.main"} fontWeight={"bold"}>
          {1}
        </Typography>
        <Typography variant={matches ? "h6" : "body1"}>{`atelier.`}</Typography>
      </Box>
    </>
  )
}
