import { FormControl, Grid, IconButton, MenuItem, Select, Typography } from "@mui/material"
import { useKeycloak } from "@react-keycloak/web"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { useEffect, useState } from "react"
import { isEmpty } from "lodash"
import { LockOpenRounded, LockRounded } from "@mui/icons-material"
import BasicConfirm from "../../../dialogs/BasicConfirm"
import { fetchWorkshops, getWorkshopsDb } from "../../../../redux/slices/workshopsSlice"

export function WorkshopSelect({ value, setValue }) {
  const { keycloak } = useKeycloak()
  const { eventId } = useParams()
  const workshops = useSelector(getWorkshopsDb)
  const dispatch = useDispatch()
  const [selectDisabled, setSelectDisabled] = useState(true)
  const [lockHovered, setLockHovered] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {
    if (!workshops || isEmpty(workshops)) dispatch(fetchWorkshops(eventId, keycloak.token))
  }, [])

  const confirmCallback = (confirm) => {
    setOpenDialog(false)
    if (confirm) {
      setSelectDisabled(false)
    }
  }

  const onClickLock = () => {
    if (selectDisabled) setOpenDialog(true)
    else setSelectDisabled(true)
  }

  return (
    <Grid container spacing={1} columnSpacing={2}>
      <Grid item xs={12}>
        <Typography
          fontWeight={"bold"}
          noWrap
          overflow={"auto"}
          textOverflow={"ellipsis"}
          gutterBottom
        >
          {"Atelier attribué :"}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <IconButton
          size="small"
          color={
            (!lockHovered && selectDisabled) || (lockHovered && !selectDisabled)
              ? "shade"
              : "warning"
          }
          onClick={onClickLock}
          onMouseEnter={() => setLockHovered(true)}
          onMouseLeave={() => setLockHovered(false)}
        >
          {(!lockHovered && selectDisabled) || (lockHovered && !selectDisabled) ? (
            <LockRounded />
          ) : (
            <LockOpenRounded />
          )}
        </IconButton>
        {selectDisabled ? (
          <Typography noWrap>
            {workshops[workshops.map((ws) => ws._id).indexOf(value)]?.name}
          </Typography>
        ) : (
          <FormControl fullWidth sx={{ maxWidth: "25ch" }} size="small" disabled={selectDisabled}>
            <Select value={value} onChange={(event) => setValue(event.target.value)}>
              {workshops.map((ws, index) => (
                <MenuItem key={index} value={ws._id}>
                  {ws.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Grid>
      <BasicConfirm
        open={openDialog}
        content={"Êtes-vous sûr de vouloir modifier l'atelier attribué ?"}
        title={"Sélection Manuelle de l'atelier"}
        onClose={confirmCallback}
      />
    </Grid>
  )
}
