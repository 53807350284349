import { Box, Button, Grid, Typography } from "@mui/material"

import {
  ADMITTED_STATUSES,
  ADMITTED_STATUSES_COLOR,
  ADMITTED_STATUSES_ICON,
  ADMITTED_STATUSES_LABEL,
  STATUSES,
  STATUSES_COLOR,
  STATUSES_ICON,
  STATUSES_LABEL,
} from "../../../../utils/constants/statuses"
import { useEffect, useState } from "react"
import ShowDetails from "../../../dialogs/ShowDetails"
import { CallMadeRounded } from "@mui/icons-material"
import { setAttribute } from "../../../../redux/slices/registrationsSlice"
import { useDispatch, useSelector } from "react-redux"
import { getFormDb } from "../../../../redux/slices/formSlice"
import { eventNeedFile } from "../../../../utils/forms"
import ConsentFileDownload from "../../../inputs/ConsentFileDownload"
import ConsentFileUpload from "../../../inputs/ConsentFileUpload"
import CreateFile from "./consentFile/CreateFile"
import RegAdStatusSelect from "../../../inputs/RegAdStatusSelect"

export default function StatusLine({ registration = null, otherReg = false, editMode = false }) {
  const form = useSelector(getFormDb)
  const dispatch = useDispatch()
  const [wsOrReason, setWsOrReason] = useState({ label: "workshop", value: "-" })
  const [hovered, setHovered] = useState(false)

  const onSeeClick = () => {
    dispatch(setAttribute({ key: "selectedReg", value: registration._id }))
  }

  useEffect(() => {
    let data = {
      label: "workshop",
      value: "-",
    }
    if ([0, 1, 2].includes(registration.status) && !!registration.selected_choice) {
      data.value = registration.choices[registration.choices.map((choice) => choice.order).indexOf(registration.selected_choice)].workshop.name
    } else {
      data = {
        label: "reason",
        value: registration.reason ?? "-",
      }
    }
    setWsOrReason(data)
  }, [registration])

  const displayFileButtons = () => {
    let elems = null
    if (!otherReg)
      if (!form.event)
        elems = (
          <Grid item xs={6} lg={4} sx={{ display: "flex", flexDirection: "column", gap: 1, opacity: [1, 2].includes(registration.status) ? 1 : 0.5 }}>
            <Typography fontWeight={"bold"}>{"Fiche de consentement"}</Typography>
            <Typography color={"error.main"}>{"Erreur"}</Typography>
          </Grid>
        )
      else if (eventNeedFile(form.inputs))
        elems = (
          <Grid item xs={6} lg={4} sx={{ display: "flex", flexDirection: "column", gap: 1, opacity: [1, 2].includes(registration.status) ? 1 : 0.5 }}>
            <Typography fontWeight={"bold"}>{"Fiche de consentement"}</Typography>
            <Box sx={{ display: "flex", flexWrap: "flex", gap: 2, alignItems: "center" }}>
              <ConsentFileDownload />
              <ConsentFileUpload />
              <CreateFile />
            </Box>
          </Grid>
        )
    return elems
  }

  const displayWsOrReason = () => {
    const isTooManyChars = typeof wsOrReason.value === "string" && wsOrReason.value.length > 35
    return (
      <Box sx={{ flex: 1, display: "flex", alignItems: "center" }}>
        <Typography sx={isTooManyChars ? { width: "24ch" } : {}} noWrap>
          {wsOrReason.value}
        </Typography>
        {isTooManyChars && <ShowDetails title={"Raison"} content={wsOrReason.value} />}
      </Box>
    )
  }

  return (
    <Box onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      <Grid container rowGap={2} columnSpacing={4}>
        <Grid item xs={6} lg={2} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography sx={{ display: otherReg ? "none" : "block" }} fontWeight={"bold"}>
            {"Statut"}
          </Typography>
          <Box sx={{ flex: 1, display: "flex", alignItems: "center", gap: 1 }}>
            {STATUSES_ICON[STATUSES[registration.status]]}
            <Typography fontWeight={"bold"} color={`${STATUSES_COLOR[STATUSES[registration.status]]}.main`} noWrap>
              {STATUSES_LABEL[STATUSES[registration.status]]}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} lg={3} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography sx={{ display: otherReg ? "none" : "block" }} fontWeight={"bold"}>
            {"Atelier / Raison"}
          </Typography>
          {displayWsOrReason()}
        </Grid>
        {!otherReg && editMode && (
          <Grid item xs={6} lg={3} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography fontWeight={"bold"}>{"Informations complémentaires"}</Typography>
            <Box sx={{ display: "flex", alignItems: "center", height: "30.75px" }}>
              <RegAdStatusSelect />
            </Box>
          </Grid>
        )}
        {!otherReg && !editMode && (
          <Grid item xs={6} lg={3} sx={{ display: "flex", flexDirection: "column", gap: 1, opacity: [1, 2].includes(registration.status) ? 1 : 0.5 }}>
            <Typography fontWeight={"bold"}>{"Informations complémentaires"}</Typography>
            <Box sx={{ flex: 1, display: "flex", alignItems: "center", gap: 1 }}>
              {ADMITTED_STATUSES_ICON[ADMITTED_STATUSES[registration.admitted_status]]}
              <Typography fontWeight={"bold"} color={`${ADMITTED_STATUSES_COLOR[ADMITTED_STATUSES[registration.admitted_status]]}.main`} noWrap>
                {ADMITTED_STATUSES_LABEL[ADMITTED_STATUSES[registration.admitted_status]]}
              </Typography>
            </Box>
          </Grid>
        )}
        {!editMode && displayFileButtons()}
        {otherReg && (
          <Grid key={"showReg"} item xs={6} lg={3} sx={{ display: "flex", opacity: hovered ? 1 : 0, flexDirection: "column", gap: 1 }}>
            <Box>
              <Button variant="outlined" size="small" endIcon={<CallMadeRounded />} onClick={onSeeClick}>
                Voir
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}
