import { Box, Button } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchNotifications,
  getNotifications,
  initNotifications,
  putNotifications,
  resetLocalNotifcations,
} from "../../../../redux/slices/notificationsSlice"
import { useKeycloak } from "@react-keycloak/web"
import { useEffect, useState } from "react"
import { notificationsInitState } from "../../../../utils/constants/redux"
import { useSnackbar } from "notistack"
import { fetchUsers } from "../../../../redux/slices/usersSlice"
import Infos from "./Infos"
import { isEqual } from "lodash"
import { RestartAltRounded } from "@mui/icons-material"
import ConfirmButton from "../../../../components/dialogs/ConfirmButton"
import { useParams } from "react-router-dom"
import Registrations from "./registrations/Registrations"

export default function EventNotifications() {
  const { eventId } = useParams()
  const { db, local, errors } = useSelector(getNotifications)
  const { keycloak } = useKeycloak()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [dbLocalEqual, setDbLocalEqual] = useState(isEqual(db, local))

  useEffect(() => {
    dispatch(fetchNotifications(eventId, keycloak.token)).then(({ text }) => {
      if (text === "error") enqueueSnackbar("Une erreur s'est produite (fetchNotifications)", { variant: "error" })
    })
    dispatch(fetchUsers(eventId, keycloak.token)).then((result) => {
      if (result === "error") enqueueSnackbar("Une erreur s'est produite (fetchUsers)", { variant: "error" })
    })
    return () => {
      dispatch(initNotifications(notificationsInitState))
    }
  }, [eventId])

  useEffect(() => {
    setDbLocalEqual(isEqual(db, local))
  }, [db, local])

  const handleConfirm = (confirm) => {
    if (confirm) {
      if (errors.length > 0) {
        return enqueueSnackbar("Veuillez corriger les erreurs avant de sauvegarder", { variant: "error" })
      }
      if (!dbLocalEqual) {
        return dispatch(putNotifications(eventId, keycloak.token)).then((result) => {
          if (result.text === "error") {
            return enqueueSnackbar("Une erreur est survenue (putNotifications)", {
              variant: "error",
            })
          }
          return enqueueSnackbar("Modifications sauvegardées", { variant: "success" })
        })
      }
      enqueueSnackbar("Aucune modification à sauvegarder", { variant: "info" })
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        mb: 4,
      }}
    >
      <Infos />
      <Registrations />
      {/*<Application />*/}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 2,
          zIndex: 0,
        }}
      >
        <Button
          sx={{ top: dbLocalEqual ? -74 : 0, transition: "top 0.3s" }}
          variant="outlined"
          color="warning"
          startIcon={<RestartAltRounded />}
          onClick={() => dispatch(resetLocalNotifcations())}
        >
          Reset
        </Button>
        <ConfirmButton
          disableElevation={!!dbLocalEqual}
          sx={{ top: dbLocalEqual ? -74 : 0, transition: "all 0.4s" }}
          title="Valider les modifications"
          content="Êtes-vous sûr de vouloir sauvegarder les modifications ?"
          callback={handleConfirm}
        />
      </Box>
    </Box>
  )
}
