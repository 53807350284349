import { FormControl, MenuItem, Select } from "@mui/material"
import { GENDER } from "../../../../utils/constants/registrations"

export const GenderInput = ({ value, onChange, color }) => {
  return (
    <FormControl
      sx={{ pr: "1px", "& .MuiSvgIcon-root": { color: color } }}
      fullWidth
      size="small"
      variant={"standard"}
    >
      <Select
        style={{ marginBottom: "-5px" }}
        disableUnderline
        value={value ?? ""}
        onChange={(event) => onChange(event.target.value)}
      >
        {[1, 2, 3].map((num, index) => (
          <MenuItem value={num} key={index}>
            {GENDER[num]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
