import { Box, Typography } from "@mui/material"
import { BR_LARGE } from "../../../../../utils/theme/app"
import { PeopleRounded } from "@mui/icons-material"
import { useEffect, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import apiClient from "../../../../../configs/axiosCfg"
import { useKeycloak } from "@react-keycloak/web"
import _ from "lodash"
import StatsTable from "./statsTable/StatsTable"
import { useParams } from "react-router-dom"

export function StatsBox({ workshop }) {
  const { eventId } = useParams()
  const { keycloak } = useKeycloak()
  const [stats, setStats] = useState({})

  const { refetch } = useQuery(
    [`getStats-${workshop.name}`],
    () =>
      apiClient.get(`/events/${eventId}/workshops/${workshop._id}/stats`, {
        headers: { Authorization: "Bearer " + keycloak.token },
      }),
    {
      onSuccess: (results) => {
        setStats(results.data.regsByStatus)
      },
      onError: (error) => {
        if (error.toJSON().status !== 404) console.error(error)
        setStats({})
      },
      enabled: false,
      retry: false,
    }
  )

  useEffect(() => {
    refetch()
    return () => {
      setStats({})
    }
  }, [])

  return (
    <Box
      id={`RegistrationsCard-${workshop.name}`}
      sx={{
        px: 2,
        py: 1,
        backgroundColor: "shade.bg",
        borderRadius: BR_LARGE,
      }}
    >
      {/* CARD HEADER - TITLE AND BUTTON  */}
      <Box sx={{ display: "flex", justifyContent: "space-between", p: 0, mb: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
          <Typography color="primary" sx={{ textTransform: "uppercase" }}>
            Inscriptions
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <PeopleRounded color={"primary"} />
          <Typography color={"primary"}>{`${!_.isEmpty(stats) ? Object.values(stats[2]).reduce((a, b) => a + b) : "-"} /${
            workshop.capacity
          }`}</Typography>
        </Box>
      </Box>
      {/* REGS TABLE */}
      <StatsTable stats={stats} />
    </Box>
  )
}
