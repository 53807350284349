import MultiSelect from "../../components/inputs/MultiSelect"
import { useSnackbar } from "notistack"
import { useKeycloak } from "@react-keycloak/web"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { fetchActivities, getActivities, setSelected } from "../../redux/slices/exportSlice"

export default function ActivitiesSelect() {
  const { keycloak } = useKeycloak()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const { items, selected } = useSelector(getActivities)

  useEffect(() => {
    dispatch(fetchActivities(keycloak.token)).then((response) => {
      if (response.status === 204) {
        enqueueSnackbar("Aucune activité n'est disponible", { variant: "info" })
      }
      if (response.text === "error") {
        enqueueSnackbar("Une erreur est survenue", { variant: "error" })
      }
    })
  }, [])

  return (
    <MultiSelect
      label="Activités"
      items={items}
      value={selected}
      setValue={(newSelected) => dispatch(setSelected({ type: "activities", selected: newSelected }))}
    />
  )
}
