import { Box, Button, Card, CardContent, CardHeader, Typography } from "@mui/material"
import { getMainForm, postReg, setField } from "../../../../redux/slices/mainFormSlice"
import { useDispatch, useSelector } from "react-redux"
import Stepper from "../Stepper"
import { useState } from "react"
import { flatten, isNull } from "lodash"
import { ObjectID } from "bson"
import { useSnackbar } from "notistack"

import { cleanProperties } from "../../../../utils/forms"

// const siteKey = "6LekpogiAAAAAAUktj7QBXfbIqL1YvgGqgPAhczF"

export default function ConfirmStep() {
  const {
    childCount,
    regs,
    parent,
    address,
    newsletters,
    comments,
    config: {
      event: { _id: eventId },
      inputs,
    },
  } = useSelector(getMainForm)
  const dispatch = useDispatch()
  const [status, setStatus] = useState("idle")
  const [message, setMessage] = useState("Enregistrer")
  const { enqueueSnackbar } = useSnackbar()

  const isAdFormNeeded = () => {
    return (
      Object.keys(inputs).filter(
        (key) => ["media_consent", "transportation", "risks_consent", "diet", "tshirt_size", "unil_survey"].includes(key) && inputs[key] === 1
      ).length > 0
    )
  }

  // Create a reg object (future document in db)
  const makeReg = ({ child, choices, school }, linkId = null, multi_registration = null, multi_order = null) => {
    let registration = {
      status: 0,
      eventId,
      parent: cleanProperties(parent),
      address: cleanProperties(address),
      comments: comments.trim(),
      newsletters: !!newsletters,
      child: cleanProperties(child),
      school: cleanProperties(school),
      choices: choices.map((workshop, cIndex) => ({ workshop, order: cIndex + 1 })),
      admission_secret: new ObjectID().toString(),
      admitted_status: isAdFormNeeded() ? 0 : 2,
    }

    if (!isNull(linkId)) registration["linkId"] = linkId
    if (!isNull(multi_registration)) registration["multi_registration"] = multi_registration
    if (!isNull(multi_order)) registration["multi_order"] = multi_order

    if (registration.address?.zip === "") registration.address.zip = undefined
    if (registration.school?.zip === "") registration.school.zip = undefined
    if (registration.child?.gender === "") registration.child.gender = undefined
    if (registration.school?.grade === "") registration.school.grade = undefined

    return registration
  }

  // Handle different registrations scenarios
  const saveRegs = async () => {
    let linkId = null
    if (childCount > 1) linkId = new ObjectID().toString() // If multiple child add linkId property
    const body = {
      registration: flatten(
        // for each child
        regs.map(({ numValidWanted, ...reg }) => {
          // Check if child wants multiple validations or only one
          if (numValidWanted === 1) {
            // if one
            return makeReg(reg, linkId)
          } else if (numValidWanted > 1) {
            // if multiple
            const multi_registration = new ObjectID().toString() // add multi properties
            const a = [] // Create the number of regs needed
            for (let i = 1; i <= numValidWanted; i++) {
              a.push(makeReg(reg, linkId, multi_registration, i))
            }
            return a
          }
        })
      ),
    }

    // Then sends regs to api
    return dispatch(postReg(body)).then((result) => {
      if (result === "error") {
        enqueueSnackbar("Une erreur est survenue lors de l'enregistrement de l'inscription", {
          variant: "error",
        })
        return false
      }
      if (result === "ok") return true
    })
  }

  const onConfirm = () => {
    dispatch(setField({ field: "processStatus", newValue: 2 }))
    setStatus("loading")
    setMessage("Enregistrement...")
    saveRegs().then((isOk) => {
      if (isOk) {
        setStatus("saved")
        setMessage("Enregistré.")
        sessionStorage.clear()
      } else {
        setStatus("error")
        setMessage("Erreur")
      }
    })
  }

  return (
    <div>
      <Stepper />
      <Box sx={{ my: 2 }} />
      <Card sx={{ p: 1 }}>
        <CardHeader
          title={
            <>
              <Typography variant="inherit" gutterBottom>
                {`Enregistrement ${childCount > 1 ? "des demandes d'inscription" : "de la demande d'inscription"}`}
              </Typography>
              <Typography variant="sub6">{`Cliquer sur le bouton ci-dessous pour enregistrer ${
                childCount > 1 ? "vos demandes d'inscription." : "votre demande d'inscription."
              }`}</Typography>
            </>
          }
        />
        <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2, alignItems: "center" }}>
          <Button
            sx={{
              "&:disabled": {
                backgroundColor: status === "loading" ? "primary.bg" : status === "error" ? "error.bg" : "success.main",
                color: status === "loading" ? "primary.main" : status === "error" ? "error.main" : "success.contrastText",
              },
            }}
            disabled={status !== "idle"}
            color="primary"
            variant="contained"
            size="large"
            onClick={onConfirm}
          >
            {message}
          </Button>
          {status === "saved" && (
            <Box sx={{ mt: 2 }}>
              <Typography textAlign={"center"} fontWeight="bold" color="primary">{`Vous allez recevoir un email de d'enregistrement ${
                childCount > 1 ? "de vos demandes d'inscription (1 par enfant). Veuillez les" : "de votre demande d'inscription. Veuillez la"
              } confirmer en cliquant sur le bouton qui se trouve dans le mail.`}</Typography>
              <Typography textAlign={"center"} sx={{ mt: 2 }} fontWeight="bold" color="primary">{``}</Typography>
            </Box>
          )}
          {status === "error" && (
            <Box>
              <Typography textAlign={"center"} color={"error"}>{`Une erreur s'est produite pendant l'enregistrement ${
                childCount > 1 ? "des inscriptions" : "de l'inscription"
              }, veuillez réessayez plus tard.`}</Typography>
              <Typography textAlign={"center"} color={"error"}>{`Si le problème persiste, contactez un responsable des inscriptions.`}</Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    </div>
  )
}
