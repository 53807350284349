import { Box, Card, CardContent, CardHeader, CircularProgress, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { getMainForm, jumpState, setChoices } from "../../../../redux/slices/mainFormSlice"
import WorkshopsList from "./WorkshopsList"
import NumValidWanted from "./NumValidWanted"
import Stepper from "../Stepper"
import * as React from "react"
import { useEffect, useState } from "react"
import { isUndefined } from "lodash"

export default function ChoicesStep({ index }) {
  const {
    childCount,
    regs,
    config: {
      event: { selections },
      workshops,
    },
  } = useSelector(getMainForm)
  const [error, setError] = useState(" ")
  const dispatch = useDispatch()

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("sm"))

  useEffect(() => {
    const choices = JSON.parse(sessionStorage.getItem(`choicesStep${index}`))
    // if choices exist and is an array, set choices in redux
    if (choices && Array.isArray(choices)) dispatch(setChoices({ index: index, choices: choices }))
  }, [])

  if (isUndefined(selections) || isUndefined(workshops)) return <CircularProgress sx={{ justifySelf: "center" }} color="heig" />

  const availableWs = workshops.filter((ws) => ws.available).length
  const wsToSelect = selections > availableWs ? availableWs : selections

  const nextStep = (event) => {
    event.preventDefault()
    const { choices } = regs[index]
    sessionStorage.setItem(`choicesStep${index}`, JSON.stringify(choices))
    if (choices.length < wsToSelect) {
      setError(`Vous devez sélectionner ${wsToSelect} atelier${wsToSelect > 1 ? "s" : ""}.`)
    } else {
      dispatch(jumpState(1))
    }
  }

  return (
    selections &&
    workshops && (
      <form noValidate autoComplete="off" onSubmit={nextStep}>
        <Stepper />
        <Box sx={{ my: 2 }} />
        <Card sx={{ p: 1 }}>
          <CardHeader
            title={
              <>
                <Typography variant={matches ? "inherit" : "h6"} gutterBottom>
                  {`Choix des ateliers ${childCount > 1 ? index + 1 : ""}`}
                </Typography>
                <Typography variant="sub6">{`Veuillez saisir les informations pour l'enfant ${childCount > 1 ? index + 1 : ""}`}</Typography>
              </>
            }
          />
          <CardContent sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
            {/* NUM VALID WANTED -> Choose number of regs to save*/}
            <NumValidWanted index={index} wsToSelect={wsToSelect} />
            {/* WORKSHOPS SELECTIONS */}
            <WorkshopsList index={index} error={error} wsToSelect={wsToSelect} />
          </CardContent>
        </Card>
        {!matches && <Box sx={{ mt: 8 }} />}
      </form>
    )
  )
}
