import { Lang, useFormInputValidation } from "react-form-input-validation"
import { Box, Card, CardContent, CardHeader, TextField, Typography, useMediaQuery, useTheme } from "@mui/material"
import { getMainForm, jumpState, setField } from "../../../redux/slices/mainFormSlice"
import { useDispatch, useSelector } from "react-redux"

import "../../../utils/theme/phoneInput.css"
import Stepper from "./Stepper"
import { translateRegLabel } from "../../../utils/constants/registrations"
import { MuiPhoneInput } from "../../../components/inputs/MuiPhoneInput"
import * as React from "react"

export default function GlobalStep() {
  const { config, ...mainForm } = useSelector(getMainForm)
  const dispatch = useDispatch()

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("sm"))

  // function that check if mainForm[v.split("_")[0]][v.split("_")[1]] contains a value
  // if it does, it returns the value, else it check if session storage globalStep[v] contains a value
  // if it does, it returns the value, else it returns ""
  const getVal = (v) => {
    const reduxValue = mainForm[v.split("_")[0]][v.split("_")[1]]
    if (reduxValue) return reduxValue
    const sessionValue = JSON.parse(sessionStorage.getItem("globalStep"))?.[v]
    if (sessionValue) return sessionValue
    return ""
  }
  // mainForm[v.split("_")[0]][v.split("_")[1]] ?? ""

  const partValueReducer = (part) => Object.keys(config.mainForm.global[part]).reduce((a, v) => ({ ...a, [v]: getVal(v) }), {})

  const partConfigReducer = (part) =>
    Object.keys(config.mainForm.global[part]).reduce((a, v) => ({ ...a, [v]: config.mainForm.global[part][v].config }), {})

  // eslint-disable-next-line no-unused-vars
  const [fields, errors, form] = useFormInputValidation(
    Object.keys(config.mainForm.global).reduce((a, v) => ({ ...a, ...partValueReducer(v) }), {}), // manipulate the config object to create the initial values object
    Object.keys(config.mainForm.global).reduce((a, v) => ({ ...a, ...partConfigReducer(v) }), {}) // manipulate the config object to create the validation rules object
  )

  form.useLang("fr")
  form.setMessages(Lang.fr, { ...form.getMessages(Lang.fr), required: "Ce champ est requis" })

  const onChange = (event) => {
    sessionStorage.setItem("globalStep", JSON.stringify(fields))
    form.validate(event)
  }

  const nextStep = (event) => {
    form.validate(event).then((isValid) => {
      if (isValid) {
        Object.keys(fields).forEach((field) => {
          dispatch(
            setField({
              superField: field.split("_")[0],
              field: field.split("_")[1],
              newValue: fields[field],
            })
          )
        })
        dispatch(jumpState(1))
      }
    })
  }

  return (
    <form onChange={onChange} noValidate autoComplete="off" onSubmit={nextStep}>
      <Stepper />
      <Box sx={{ my: 2 }} />
      <Card sx={{ p: 1 }}>
        <CardHeader
          title={
            <>
              <Typography variant={matches ? "inherit" : "h6"} gutterBottom>
                {"Informations sur le parent et l'adresse"}
              </Typography>
              <Typography variant="sub6">{"Ces informations seront liées à l'enfant/aux enfants que vous souhaitez inscrire."}</Typography>
            </>
          }
        />
        <CardContent sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography sx={{ mb: 2 }} color={"info.main"}>
            {
              "Si vous avez déjà fait une demande pour un autre enfant, utilisez idéalement la même adresse email parent. Cela nous permettra de mieux traiter vos demandes d'inscription."
            }
          </Typography>
          {Object.keys(config.mainForm.global).map((superField, index) => (
            <Box key={index} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Typography variant="h6" sx={{ mb: 2, textTransform: "capitalize" }}>
                {translateRegLabel[superField]}
              </Typography>
              <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", justifyContent: "space-between" }}>
                {Object.keys(config.mainForm.global[superField]).map((field, index) => {
                  const input = config.mainForm.global[superField][field]
                  return input?.special === "phone" ? (
                    <MuiPhoneInput
                      key={index}
                      error={errors[field]}
                      field={field}
                      fields={fields}
                      required={input?.config?.includes("required")}
                      form={form}
                      sx={{ width: matches ? "48%" : "100%", textTransform: "capitalize" }}
                    />
                  ) : (
                    <TextField
                      key={index}
                      sx={{ width: matches ? "48%" : "100%" }}
                      name={field}
                      type={input.type}
                      label={translateRegLabel[field.split("_")[1]]}
                      InputLabelProps={{
                        sx: { textTransform: "capitalize" },
                      }}
                      value={fields[field] ?? ""}
                      onBlur={form.handleBlurEvent}
                      onChange={form.handleChangeEvent}
                      error={!!errors[field]}
                      helperText={errors[field] ?? " "}
                      required={input.config.includes("required")}
                    />
                  )
                })}
              </Box>
            </Box>
          ))}
        </CardContent>
      </Card>
      {!matches && <Box sx={{ mt: 8 }} />}
    </form>
  )
}
