import _ from "lodash"
import { Table, TableBody, TableContainer } from "@mui/material"
import StatsHeader from "./StatsHeader"
import StatsRow from "./StatsRow"

export default function StatsTable({ stats }) {
  if (_.isEmpty(stats)) {
    return "Pas de données"
  } else {
    return (
      <TableContainer>
        <Table size="small" sx={{ borderCollapse: "separate", borderSpacing: "0px 8px" }}>
          <TableBody>
            {/* BODY HEADER */}
            <StatsHeader stats={stats} />
            {/* BODY ROWS */}
            <StatsRow stats={stats} statusName={"pending"} statusNum={0} />
            <StatsRow stats={stats} statusName={"admissible"} statusNum={1} />
            <StatsRow stats={stats} statusName={"admitted"} statusNum={2} />
            <StatsRow stats={stats} statusName={"waiting"} statusNum={3} />
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}
