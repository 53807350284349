import { Box, Card, CardContent, Chip, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Typography } from "@mui/material"
import { cloneElement, useState } from "react"
import { ArrowForwardIosRounded } from "@mui/icons-material"
import PropTypes from "prop-types"
import { BR_LARGE } from "../../../../utils/theme/app"
import { STATUSES_COLOR, STATUSES_ICON } from "../../../../utils/constants/statuses"
import { useNavigate } from "react-router-dom"

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export default function EventsTable(props) {
  // const { headCells, rows } = props
  const { rows } = props
  const [order] = useState("desc")
  const [orderBy] = useState("start")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const navigate = useNavigate()

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === "asc"
  //   setOrder(isAsc ? "desc" : "asc")
  //   setOrderBy(property)
  // }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleClick = (eventId) => {
    navigate(`../${eventId}/workshops`)
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  return (
    <TableContainer>
      <Table sx={{ borderCollapse: "separate", minWidth: "400px" }}>
        {/*<ETHead headCells={headCells} onRequestSort={handleRequestSort} order={order} orderBy={orderBy} />*/}
        <TableBody>
          {stableSort(rows, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => (
              <TableRow onClick={() => handleClick(row._id)} tabIndex={-1} key={`row-${index}`}>
                <TableCell sx={{ pt: 1 }}>
                  <Card sx={{ p: 1, background: "white", "&:hover": { backgroundColor: "whitesmoke", cursor: "pointer" } }}>
                    <CardContent sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: "8px!important" }}>
                      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                        <Typography variant="h6">{row.name}</Typography>
                        <Chip label={row.activityName} />
                      </Box>
                      <Typography>{row.dates}</Typography>
                      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 0.5,
                            px: 1,
                            py: 0.5,
                            backgroundColor: `${STATUSES_COLOR["pending"]}.bg`,
                            borderRadius: BR_LARGE,
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {cloneElement(STATUSES_ICON["pending"])}
                          <Typography
                            width="3ch"
                            display="flex"
                            justifyContent="center"
                            fontWeight="bold"
                            color={`${STATUSES_COLOR["pending"]}.main`}
                          >
                            {row.pending_regs}
                          </Typography>
                        </Box>
                        <ArrowForwardIosRounded fontSize="small" sx={{ verticalAlign: "middle" }} />
                      </Box>
                    </CardContent>
                  </Card>
                </TableCell>
              </TableRow>
            ))}
          {emptyRows > 0 && (
            <TableRow
              style={{
                height: 96 * emptyRows,
              }}
            >
              <TableCell colSpan={1} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <TablePagination
          sx={{ mr: 2, mb: 2, mt: 1 }}
          rowsPerPageOptions={[5, 10, 25]}
          component={Card}
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </TableContainer>
  )
}

EventsTable.propTypes = {
  headCells: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
}
