import { useDispatch, useSelector } from "react-redux"
import { getFormLocal, setLocalAttribute } from "../../../../../redux/slices/formSlice"
import { cloneDeep, isEmpty } from "lodash"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
} from "@mui/material"

export default function FormLogos({ expanded, handleExpanded }) {
  const form = useSelector(getFormLocal)
  const dispatch = useDispatch()

  const setLogos = (logo) => {
    let newLogos = cloneDeep(form.logos)
    if (newLogos.includes(logo)) {
      if (newLogos.length > 1) newLogos.splice(newLogos.indexOf(logo), 1)
    } else newLogos.push(logo)
    dispatch(setLocalAttribute({ key: "logos", value: newLogos }))
  }

  if (!form || isEmpty(form)) return <CircularProgress color="heig" />

  return (
    <Accordion expanded={expanded === "formLogos"} onChange={handleExpanded("formLogos")}>
      <AccordionSummary>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Typography variant="h5">{"Logos"}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Typography sx={{ mb: 4 }}>{"Choisir le logo qui apparaîtra sur les formulaires (vous pouvez sélectionner les deux)"}</Typography>
        <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
          <Card sx={{ width: "250px" }}>
            <CardActionArea sx={{ p: 2, display: "flex" }} onClick={() => setLogos("heig")}>
              <CardContent>
                <FormControlLabel label={<div />} control={<Checkbox disableRipple checked={form.logos.includes("heig")} />} />
              </CardContent>
              <CardMedia
                sx={{ objectFit: "contain" }}
                component={"img"}
                src={"https://storage.googleapis.com/visual-identity/logo/2020-slim.svg"}
                height={"48px"}
                alt="Logo HEIG-VD"
              />
            </CardActionArea>
          </Card>
          <Card sx={{ width: "250px" }}>
            <CardActionArea sx={{ p: 2, display: "flex" }} onClick={() => setLogos("cpnv")}>
              <CardContent>
                <FormControlLabel label={<div />} control={<Checkbox disableRipple checked={form.logos.includes("cpnv")} />} />
              </CardContent>
              <CardMedia
                sx={{ objectFit: "contain", imageRendering: "high-quality" }}
                component={"img"}
                src={"https://storage.googleapis.com/visual-identity/logo/divers/cpnv.png"}
                height={"48px"}
                alt="Logo CPNV"
              />
            </CardActionArea>
          </Card>
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}
