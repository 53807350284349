import { Box, Collapse, Table, TableContainer, ThemeProvider } from "@mui/material"
import RTBody from "./RTBody"
import RTHead from "./RTHead"
import RTToolbar from "./RTToolbar"
import RTFooter from "./RTFooter"
import { MTTheme } from "../../utils/theme/magicTable"
import { getRegistrationsState } from "../../redux/slices/registrationsSlice"
import { useSelector } from "react-redux"
import RTStatusToolbar from "./statusToolbar/RTStatusToolbar"
import { useParams } from "react-router-dom"
import { getUserContext } from "../../redux/slices/appSlice"
import { useEffect, useState } from "react"

export default function RegsTable({ tools = [], small = false }) {
  const params = useParams()
  const { selectionsRegs } = useSelector(getRegistrationsState)
  const { permissions: userPerms, role } = useSelector(getUserContext)
  const [userCanEdit, setUserCanEdit] = useState(false)

  useEffect(() => {
    setUserCanEdit(role < 2 || userPerms.filter(({ resource, role }) => resource === params.eventId && role === 1).length > 0)
  }, [params])

  return (
    <Box id="RegsTable-Container">
      <ThemeProvider theme={MTTheme}>
        <RTToolbar tools={tools} small={small} />
        {userCanEdit && (
          <Collapse in={selectionsRegs.length > 0} unmountOnExit mountOnEnter>
            <RTStatusToolbar small />
          </Collapse>
        )}
        <TableContainer>
          <Table sx={{ borderCollapse: "separate", borderSpacing: "0px 4px", minWidth: "400px" }} size={small ? "small" : "medium"}>
            <RTHead />
            <RTBody />
          </Table>

          <RTFooter small={small} />
        </TableContainer>
      </ThemeProvider>
    </Box>
  )
}
