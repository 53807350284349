import MultiSelect from "../../components/inputs/MultiSelect"
import { useSnackbar } from "notistack"
import { useKeycloak } from "@react-keycloak/web"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { fetchEvents, getActivities, getEvents, setEventSelected } from "../../redux/slices/exportSlice"

export default function EventsSelect() {
  const { keycloak } = useKeycloak()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const events = useSelector(getEvents)
  const activities = useSelector(getActivities)
  const [items, setItems] = useState([])

  useEffect(() => {
    setItems(events.items)
    return () => {
      setItems([])
    }
  }, [events.items])

  useEffect(() => {
    dispatch(fetchEvents(keycloak.token)).then((response) => {
      if (response.status === 204) {
        enqueueSnackbar("Aucun évènement n'est disponible", { variant: "info" })
      }
      if (response.text === "error") {
        enqueueSnackbar("Une erreur est survenue", { variant: "error" })
      }
    })
  }, [activities])

  return (
    <MultiSelect
      label="Événements"
      items={items}
      value={events.selected.map((event) => event._id)}
      setValue={(newSelected) => dispatch(setEventSelected({ type: "events", selected: newSelected }))}
    />
  )
}
