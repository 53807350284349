import { Box, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import EventInfos from "./EventInfos"
import { useSnackbar } from "notistack"
import { useKeycloak } from "@react-keycloak/web"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { fetchWorkshops, getWorkshopsDb, initWorkshops } from "../../../../redux/slices/workshopsSlice"
import { WorkshopCard } from "./cards/WorkshopCard"
import { isEmpty } from "lodash"
import EventStats from "../registrations/EventStats"

export default function Workshops() {
  const { eventId } = useParams()
  const workshops = useSelector(getWorkshopsDb)
  const dispatch = useDispatch()
  const { keycloak } = useKeycloak()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    dispatch(fetchWorkshops(eventId, keycloak.token)).then((result) => {
      if (result === "error")
        enqueueSnackbar("Une erreur est survenue lors de la récupération des ateliers", {
          variant: "error",
        })
    })
    return () => {
      dispatch(initWorkshops([]))
    }
  }, [])

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 4, mb: 4 }}>
      <EventInfos />
      <EventStats inWorkshops />
      <Box id={`EventWorkshops`} display={"flex"} flexWrap={"wrap"} justifyContent={"space-between"} gap={3}>
        {isEmpty(workshops) ? (
          <Typography>{"Pas d'atelier"}</Typography>
        ) : (
          [...workshops]
            .sort((a, b) => (a.start > b.start ? 1 : -1))
            .map((ws, index) => {
              return <WorkshopCard key={index} workshop={ws} />
            })
        )}
      </Box>
    </Box>
  )
}
