import { ExpandMoreRounded } from "@mui/icons-material"
import { Collapse, TableCell, TableRow } from "@mui/material"
import { BR_LARGE as ABR } from "../../utils/theme/app"
import MagicCell from "./MagicCell"
import MagicRowMenu from "./MagicRowMenu"

export default function MagicRow({ row = null, colsOptions = null, actionsRow = null, openSR = null, setOpenSR = null, cellFullLength = false }) {
  const isSubRow = !!row.subRow

  const subRowArrowCell = () => (
    <MagicCell
      data={
        <ExpandMoreRounded
          sx={{
            verticalAlign: "middle",
            transition: "transform 250ms",
            transform: openSR ? "rotateX(-180deg)" : "rotateX(0)",
          }}
        />
      }
      colOptions={{ align: "left", maxWidth: "32px" }}
      onClickCell={() => setOpenSR()}
      style={{
        textAlign: "center",
      }}
    />
  )

  const magicCells = () =>
    !!row.cells &&
    row.cells.map((cell, index) => {
      return <MagicCell key={index} data={cell} colOptions={colsOptions ? colsOptions[index] : null} fullLength={cellFullLength} />
    })

  const actionsRowCell = () =>
    !!actionsRow && <MagicCell data={<MagicRowMenu items={actionsRow} />} colOptions={{ align: "right", maxWidth: "32px" }} />

  return isSubRow ? (
    [
      <TableRow
        key={`mainRow`}
        sx={{
          "&:hover > td": { backgroundColor: "primary.bg" },
          "&> td:first-of-type": { borderRadius: `${ABR} 0 0 ${ABR}` },
          "&> td:last-child": { borderRadius: `0 ${ABR} ${ABR} 0` },
        }}
      >
        {subRowArrowCell()}
        {magicCells()}
        {actionsRowCell()}
      </TableRow>,
      <TableRow key={`subRow`}>
        <TableCell colSpan={row.cells.length + 1 + (actionsRow ? 1 : 0)} style={{ padding: 0 }}>
          <Collapse in={openSR} timeout={100} unmountOnExit>
            {row.subRow}
          </Collapse>
        </TableCell>
      </TableRow>,
    ]
  ) : (
    <TableRow
      sx={{
        "&:hover > td": { backgroundColor: "whitesmoke" },
        "&> td:first-of-type": { borderRadius: `${ABR} 0 0 ${ABR}` },
        "&> td:last-child": { borderRadius: `0 ${ABR} ${ABR} 0` },
      }}
    >
      {magicCells()}
      {actionsRowCell()}
    </TableRow>
  )
}

// rowExample = {
//   id: reg["id"],
//   cells: [
//     fullName(reg["child_firstname"], reg["child_lastname"]),
//     getAge(reg["child_birthdate"]),
//     `${reg["address_zip"]} ${reg["address_city"]}`,
//     reg["school_grade"],
//     reg["school_name"],
//     <AJSelect items={getChoices(reg["choices"])} keyStart={"Simon Boucher-choices"} />,
//     formatDateCH(reg["createdAt"]),
//   ],
//   subRow: <Typography>{reg["grade"]}</Typography>,
//   srOpen: openCollapse.includes(reg["id"]),
//   setSrOpen: () => handleSetOpenCollapse(reg["id"]),
// }

// actionsRow = [<Action action={"example"} />],
