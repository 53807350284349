import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material"
import { filterExplanations, GENDERS_ENUM, WS_FILTERS_LABEL as F_LABEL } from "../../../../utils/constants/workshopFilters"
import { InfoOutlined } from "@mui/icons-material"
import RangeSlider from "../../../../components/inputs/RangeSlider"

export default function EditFiltersDialog({ open, toggleOpen, wsIndex, ws, changeWSAttribute }) {
  const handleGenderInput = (event) => {
    changeWSAttribute(event)
  }

  const handleAgeSlider = (event, value) => {
    changeWSAttribute({ target: { name: `${wsIndex}-age_min`, value: value[0] } })
    changeWSAttribute({ target: { name: `${wsIndex}-age_max`, value: value[1] } })
  }

  const handleGradeSlider = (event, value) => {
    changeWSAttribute({ target: { name: `${wsIndex}-grade_min`, value: value[0] } })
    changeWSAttribute({ target: { name: `${wsIndex}-grade_max`, value: value[1] } })
  }

  return (
    <Dialog onClose={toggleOpen} open={open}>
      <DialogTitle sx={{ py: 3 }}>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          {"Filtres de l'atelier"}
          <Tooltip title={filterExplanations} placement="right">
            <InfoOutlined fontSize="small" color="shade" />
          </Tooltip>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        <Typography fontWeight="bold">{F_LABEL["age"]}</Typography>
        <RangeSlider value={[ws["age_min"] ?? 0, ws["age_max"] ?? 0]} onChange={handleAgeSlider} />
        <Typography fontWeight="bold">{F_LABEL["grade"]}</Typography>
        <RangeSlider value={[ws["grade_min"] ?? 0, ws["grade_max"] ?? 0]} onChange={handleGradeSlider} />
        <Typography fontWeight="bold">{F_LABEL["gender"]}</Typography>
        <FormControl variant="standard" fullWidth sx={{ mt: -1 }}>
          <InputLabel id="gender-input">{F_LABEL["gender"]}</InputLabel>
          <Select labelId="gender-input" name={`${wsIndex}-gender`} value={ws["gender"] ?? 0} label={F_LABEL["gender"]} onChange={handleGenderInput}>
            {Object.keys(GENDERS_ENUM).map((itemKey, index) => (
              <MenuItem key={index} value={parseInt(itemKey)}>
                {GENDERS_ENUM[itemKey]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleOpen}>Fermer</Button>
      </DialogActions>
    </Dialog>
  )
}
