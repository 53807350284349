import { Container } from "@mui/material"
import { getMainForm } from "../../redux/slices/mainFormSlice"
import { useSelector } from "react-redux"
import ChildStep from "./steps/ChildStep"
import ChoicesStep from "./steps/choicesStep/ChoicesStep"
import SetChildCountStep from "./steps/SetChildCountStep"
import OthersStep from "./steps/OthersStep"
import CheckStep from "./steps/checkStep/CheckStep"
import ConfirmStep from "./steps/confirmStep/ConfirmStep"
import GlobalStep from "./steps/GlobalStep"

export default function FormContent() {
  const {
    activeStep,
    childCount,
    config: { mainForm },
  } = useSelector(getMainForm)

  const childStepsNum = Array.from(Array(childCount), (a, index) => 2 + index * 2)

  const choicesStepsNum = childStepsNum.map((num) => num + 1)

  return (
    <Container maxWidth={"md"} sx={{ p: 3, display: "flex", flexDirection: "column", gap: 2 }}>
      {activeStep === 0 && <GlobalStep />}
      {activeStep === 1 && <SetChildCountStep />}
      {childStepsNum.includes(activeStep) && <ChildStep index={childStepsNum.indexOf(activeStep)} />}
      {choicesStepsNum.includes(activeStep) && <ChoicesStep index={choicesStepsNum.indexOf(activeStep)} />}
      {activeStep === 2 + childCount * 2 && mainForm?.others && <OthersStep />}
      {activeStep === 2 + childCount * 2 + (mainForm?.others ? 1 : 0) && <CheckStep />}
      {activeStep === 3 + childCount * 2 + (mainForm?.others ? 1 : 0) && <ConfirmStep />}
    </Container>
  )
}
