import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material"

// eslint-disable-next-line no-unused-vars
export default function MultiSelect({ label = "Label", value = [], items, setValue = () => {} }) {
  const onChange = (event) => {
    setValue(event.target.value)
  }

  return (
    <FormControl sx={{ ml: 1, width: "10rem" }} size={"small"} onClick={(event) => event.stopPropagation()}>
      <InputLabel id={"label" + label}>{label}</InputLabel>
      <Select
        labelId={"label" + label}
        multiple
        autoWidth
        value={value}
        input={<OutlinedInput label={label} />}
        onChange={onChange}
        sx={{ select: { fontSize: "0.9rem" } }}
      >
        {items.map((item, index) => (
          <MenuItem key={index} value={item._id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
