import { Box } from "@mui/material"
import StatusCard from "./statusCard/StatusCard"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { useKeycloak } from "@react-keycloak/web"
import { useSnackbar } from "notistack"
import { useEffect } from "react"
import {
  fetchStats,
  getRegistrationsState,
} from "../../../../../../../redux/slices/registrationsSlice"

export default function StatusCards({ setSelectedStatus }) {
  const { regsByStatus, list } = useSelector(getRegistrationsState)
  const { eventId, workshopId } = useParams()
  const dispatch = useDispatch()
  const { keycloak } = useKeycloak()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    dispatch(fetchStats(eventId, workshopId, keycloak.token)).then((result) => {
      if (result.text === "error" && result.status !== 404)
        return enqueueSnackbar(
          "Une erreur est survenue lors de la récupération des stats de l'atelier",
          {
            variant: "error",
          }
        )
    })
  }, [list])

  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        justifyContent: "space-around",
      }}
    >
      {Object.keys(regsByStatus).map((numStatus) => (
        <StatusCard key={numStatus} numStatus={numStatus} setSelectedStatus={setSelectedStatus} />
      ))}
    </Box>
  )
}
