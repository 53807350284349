import { Box, Divider, IconButton, Typography } from "@mui/material"
import { datesOptionalDisplay } from "../../utils/dates"
import { CalendarMonthRounded } from "@mui/icons-material"
import SelectDateRangeDialog from "./SelectDateRangeDialog"
import { useState } from "react"
import { useSelector } from "react-redux"
import { getInputsError } from "../../redux/slices/appSlice"

export default function DateRangeField({ index, start, end, changeWSAttribute }) {
  const [openDialog, setOpenDialog] = useState(false)
  const inputsError = useSelector(getInputsError)
  const toggleOpenDialog = () => setOpenDialog(!openDialog)

  const checkError = () => {
    if (inputsError.includes(`${index}-start`)) return true
    if (inputsError.includes(`${index}-end`)) return true
    return false
  }

  return (
    <Box>
      <Box sx={{ display: "flex", gap: 2, justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="body2">{datesOptionalDisplay(start, end)}</Typography>
        <IconButton size="small" onClick={toggleOpenDialog}>
          <CalendarMonthRounded color={checkError() ? "error" : "inherit"} />
        </IconButton>
      </Box>
      <Divider color={checkError() ? "error" : "#aaaaaa"} sx={{ mb: 1 }} />
      <SelectDateRangeDialog
        open={openDialog}
        toggleOpen={toggleOpenDialog}
        start={start}
        end={end}
        changeWSAttribute={changeWSAttribute}
        index={index}
      />
    </Box>
  )
}
