import { ListItemIcon, ListItemText, MenuItem, Typography } from "@mui/material"
import PropTypes from "prop-types"
import { cloneElement } from "react"
import { ACTIONS_COLOR, ACTIONS_ICON, ACTIONS_LABEL } from "../../utils/constants/actions"

export default function ActionMenuItem(props) {
  const { action = "example", onClickItem = () => {}, handleCloseMenu = () => {} } = props

  const handleClick = () => {
    handleCloseMenu()
    onClickItem()
  }

  return (
    <MenuItem color={"error.main"} onClick={handleClick} sx={{ "&:hover": { backgroundColor: `${ACTIONS_COLOR[action]}.bg` } }}>
      <ListItemIcon>{cloneElement(ACTIONS_ICON[action], { fontSize: "small", color: ACTIONS_COLOR[action] })}</ListItemIcon>
      <ListItemText disableTypography>
        <Typography color={`${ACTIONS_COLOR[action]}.main`}>{ACTIONS_LABEL[action]}</Typography>
      </ListItemText>
    </MenuItem>
  )
}

ActionMenuItem.propTypes = {
  action: PropTypes.string,
  onClickItem: PropTypes.func,
}
