import { Box, Breadcrumbs, Link, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material"
import { NavigateNextRounded } from "@mui/icons-material"
import HeaderActions from "./HeaderActions"
import { useNavigate } from "react-router-dom"

export default function PageHeader({ title = null, nav = null, actions }) {
  const navigate = useNavigate()
  const isMobile = useMediaQuery(useTheme().breakpoints.down("xl"))

  const onClickLink = (href = null) => {
    if (href) navigate(href)
  }

  return (
    <Box
      id="PageHeader"
      sx={{
        display: "flex",
        alignItems: "start",
        justifyContent: "space-between",
        my: isMobile ? 2 : 5,
      }}
    >
      <Box id="PH-title-nav" sx={{ flex: "auto" }}>
        {title ? (
          <Typography sx={{ height: "2rem" }} variant="h4" gutterBottom noWrap>
            {title}
          </Typography>
        ) : (
          <Skeleton variant="rectangular" sx={{ width: "150px", height: "28px", mb: "9.8px" }} />
        )}

        {!!nav && (
          <Box id="PH-nav">
            <Breadcrumbs separator={<NavigateNextRounded fontSize="small" sx={{ opacity: 0.7, mx: -0.5 }} />}>
              {nav.map((elem, index) => (
                <Link key={index} underline="hover" color="inherit" sx={{ cursor: "pointer" }} onClick={() => onClickLink(elem.href)}>
                  <Typography variant="body2" sx={{ opacity: 0.6 }} noWrap>
                    {elem.text}
                  </Typography>
                </Link>
              ))}
            </Breadcrumbs>
          </Box>
        )}
      </Box>
      <HeaderActions actions={actions} />
    </Box>
  )
}
