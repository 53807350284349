import { Box, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { getAllUsersArray } from "../../../../../../redux/slices/usersSlice"
import { getNotificationsLocal, handleClickUserCheckbox, setNotificationsAttribute } from "../../../../../../redux/slices/notificationsSlice"
import { BasicAllCheck } from "../../../../../../components/inputs/BasicAllCheck"
import { BasicCheck } from "../../../../../../components/inputs/BasicCheck"

export const UsersEmail = () => {
  const dispatch = useDispatch()
  const { users_email } = useSelector(getNotificationsLocal)
  const users = useSelector(getAllUsersArray)

  const handleClick = (userEmail) => dispatch(handleClickUserCheckbox(userEmail))

  const allChecked = users_email.length === 0 ? -1 : users_email.length < users.length ? 0 : 1

  const handleAllClick = () => {
    if (allChecked === 1) dispatch(setNotificationsAttribute({ key: "users_email", value: [] }))
    if (allChecked === -1 || allChecked === 0) dispatch(setNotificationsAttribute({ key: "users_email", value: users.map((user) => user.email) }))
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Typography sx={{ fontWeight: "bold" }}>{"Utilisateurs de l'évènement"}</Typography>
        <Typography>{"(adresse email HEIG-VD)"}</Typography>
      </Box>
      <Box sx={{ ml: 2, display: "flex", flexWrap: "wrap", gap: 2 }}>
        <BasicAllCheck label={"Tous"} checked={allChecked} onClick={handleAllClick} />
        {users.map((user, index) => (
          <BasicCheck label={user.name} value={user.email} checked={users_email.includes(user.email)} key={index} onClick={handleClick} />
        ))}
      </Box>
    </Box>
  )
}
