import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { DesktopDateTimePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import CustomDTPInput from "./CustomDTPInput"

export default function SelectDateRangeDialog(props) {
  const { open, toggleOpen, index, start, end, changeWSAttribute } = props

  return (
    <Dialog onClose={toggleOpen} open={open}>
      <DialogTitle sx={{ py: 3 }}>{"Dates de l'atelier"}</DialogTitle>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <DesktopDateTimePicker
            label="Date de début"
            onChange={(date) =>
              changeWSAttribute({ target: { name: `${index}-start`, value: date.toISOString() } })
            }
            value={start}
            renderInput={(props) => <CustomDTPInput {...props} />}
            ampm={false}
            maxDateTime={end ? new Date(end) : null}
            minutesStep={5}
            // disablePast
            inputFormat="dd.MM.yyyy HH:mm"
            handleCloseMenu={() => {}}
          />
          <DesktopDateTimePicker
            label="Date de fin"
            onChange={(date) =>
              changeWSAttribute({ target: { name: `${index}-end`, value: date.toISOString() } })
            }
            value={end}
            renderInput={(props) => <CustomDTPInput {...props} />}
            ampm={false}
            minutesStep={5}
            minDateTime={start ? new Date(start) : null}
            // disablePast
            inputFormat="dd.MM.yyyy HH:mm"
            handleCloseMenu={() => {}}
          />
        </DialogContent>
      </LocalizationProvider>
      <DialogActions>
        <Button onClick={toggleOpen}>Fermer</Button>
      </DialogActions>
    </Dialog>
  )
}
