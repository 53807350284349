import { CheckRounded, ClearRounded, PriorityHighRounded, QuestionMarkRounded } from "@mui/icons-material"
import { ToggleButton, ToggleButtonGroup } from "@mui/material"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import * as React from "react"

export default function FormInputToggleButtons({ label, optional, keyValue, value, setValue, disabled = false }) {
  const handleSetValue = (event, newValue) => {
    setValue(keyValue, newValue)
  }

  const marks3 = [
    { icon: <ClearRounded key={0} fontSize={"small"} />, color: "error" },
    { icon: <QuestionMarkRounded key={1} fontSize={"small"} />, color: "warning" },
    { icon: <PriorityHighRounded key={2} fontSize={"small"} />, color: "success" },
  ]

  const marks2 = [
    { icon: <ClearRounded key={0} fontSize={"small"} />, color: "error" },
    { icon: <CheckRounded key={1} fontSize={"small"} />, color: "success" },
  ]

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 4, mt: 1 }}>
      <Typography fontWeight={"bold"} noWrap>
        {label ? label : "label"}
      </Typography>
      <ToggleButtonGroup disabled={disabled} value={value} exclusive onChange={handleSetValue} size={"small"}>
        {(optional ? marks3 : marks2).map((mi, index) => (
          <ToggleButton color={mi.color} key={index} value={index}>
            {mi.icon}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  )
}
