import { Grid, Typography } from "@mui/material"
import { PlaceRounded } from "@mui/icons-material"

export default function WorkshopPlace({ place }) {
  return (
    <Grid container lineHeight={1} wrap="nowrap">
      <Grid item>
        <PlaceRounded sx={{ width: "16px", height: "16px", ml: "-2px", mr: 0.5 }} color="shade" />
      </Grid>
      <Grid item>
        <Typography variant="caption" color={"shade.main"} sx={{ ":first-letter": { textTransform: "capitalize" } }}>
          {place}
        </Typography>
      </Grid>
    </Grid>
  )
}
