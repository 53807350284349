import { Box } from "@mui/material"
import PropTypes from "prop-types"
import { WS_FILTERS_CHIPS } from "../../../../../utils/constants/workshopFilters"

export default function Filters({ workshop }) {
  const filtersChips = WS_FILTERS_CHIPS(workshop)

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Box sx={{ display: "flex", gap: 1 }}>{[filtersChips[0], filtersChips[1]]}</Box>
      <Box sx={{ display: "flex", gap: 1 }}>{filtersChips[2]}</Box>
    </Box>
  )
}

Filters.propTypes = {
  workshop: PropTypes.object.isRequired,
}
