import { Box, Checkbox, Typography } from "@mui/material"

export const BasicAllCheck = ({ label, checked = -1, onClick = () => {} }) => {
  return (
    <Box
      sx={{
        display: "flex",
        borderRadius: 1,
        backgroundColor: checked === 1 ? "primary.bgLight" : "#ffffff",
        filter: `drop-shadow(0 0rem 1mm lightgray)`,
        alignItems: "center",
        "& :hover": {
          cursor: "pointer",
        },
      }}
      onClick={onClick}
    >
      <Checkbox checked={checked === 1} indeterminate={checked === 0} size="small" />
      <Typography sx={{ mr: 1.2, fontWeight: "normal" }} color={checked === 1 ? "primary" : ""}>
        {label}
      </Typography>
    </Box>
  )
}
