import { createSlice } from "@reduxjs/toolkit"
import apiClient from "../../configs/axiosCfg"

const initialState = {
  drawerOpened: false,
  pageActions: [],
  userContext: {
    status: 0,
  },
  inputsError: [],
}

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    openDrawer: (state, action) => {
      state.drawerOpened = action.payload
    },
    setUserContext: (state, action) => {
      state.userContext = action.payload
    },
    setInputsError: (state, action) => {
      state.inputsError = action.payload
    },
    addInputError: (state, action) => {
      if (!state.inputsError.includes(action.payload)) state.inputsError = [...state.inputsError, action.payload]
    },
    removeInputError: (state, action) => {
      state.inputsError = state.inputsError.filter((item) => item !== action.payload)
    },
  },
})

export const { openDrawer, setUserContext, setInputsError, addInputError, removeInputError } = appSlice.actions

export const isDrawerOpened = (state) => state.app.drawerOpened
export const getUserContext = (state) => state.app.userContext
export const getInputsError = (state) => state.app.inputsError
export const getState = (state) => state

export const fetchUserContext = (kToken) => async (dispatch) => {
  apiClient
    .get("/users/userContext", {
      headers: { Authorization: "Bearer " + kToken },
    })
    .then((response) => {
      dispatch(setUserContext({ ...response.data, status: 1 }))
      return "ok"
    })
    .catch((error) => {
      dispatch(setUserContext({ status: error.toJSON().status < 500 ? -1 : -2 }))
      console.error(error)
      return "error"
    })
}

export default appSlice.reducer
