import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"

export default function BasicConfirm({ title = null, content = null, open, onClose }) {
  return (
    <Dialog open={open} keepMounted={false}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText sx={{ textAlign: "justify" }}>
          {content ?? "Êtes-vous sûr ?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={() => onClose(false)}>
          Annuler
        </Button>
        <Button variant="text" color="primary" onClick={() => onClose(true)}>
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  )
}
