import { Box } from "@mui/material"
import { AVAILABLE_COLOR, AVAILABLE_ICON, AVAILABLE_LABEL } from "../../../../../../utils/constants/workshop"
import { EventRounded, PeopleRounded, PlaceRounded } from "@mui/icons-material"
import { useSelector } from "react-redux"
import { getWorkshop } from "../../../../../../redux/slices/workshopSlice"
import { workshopDateDisplay } from "../../../../../../utils/dates"
import InfoChip from "../../../../../../components/data/InfoChip"

export default function WorkshopInfos() {
  const workshop = useSelector(getWorkshop)
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <InfoChip icon={<EventRounded />} label={workshopDateDisplay(workshop.start, workshop.end)} sx={{ textTransform: "capitalize" }} />
      {workshop.place && <InfoChip icon={<PlaceRounded />} label={workshop.place} />}
      <InfoChip icon={<PeopleRounded />} label={`${workshop.capacity} places`} />
      <InfoChip
        color={AVAILABLE_COLOR(workshop?.available)}
        icon={AVAILABLE_ICON(workshop?.available)}
        label={AVAILABLE_LABEL(workshop?.available)}
        chipColor
      />
    </Box>
  )
}
