import { createSlice } from "@reduxjs/toolkit"
import apiClient from "../../configs/axiosCfg"
import { initWorkshop } from "../../utils/constants/redux"

const initialState = {}

export const localRegistrationSlice = createSlice({
  name: "localRegistration",
  initialState,
  reducers: {
    setLocalRegistration: (state, action) => {
      return action.payload
    },
    setAttribute: (state, action) => {
      return { ...state, [action.payload.key]: action.payload.value }
    },
    setContextAttribute: (state, action) => {
      return {
        ...state,
        [action.payload.context]: {
          ...state[action.payload.context],
          [action.payload.key]: action.payload.value,
        },
      }
    },
    setChoice: (state, action) => {
      return {
        ...state,
        choices: state.choices.map((choice) => (choice.order === action.payload.order ? { ...choice, workshop: action.payload.workshop } : choice)),
      }
    },
    newLocalRegistration: (state) => {
      return { ...state, workshops: [...state.workshops, initWorkshop] }
    },
  },
})

export const { setLocalRegistration, setAttribute, setContextAttribute, setChoice, newLocalRegistration } = localRegistrationSlice.actions

export const getLocalRegistration = (state) => state.localRegistration

export const putLocalRegistration = (regId, body, kToken) => async () =>
  apiClient
    .put(`/registrations/${regId}`, body, {
      headers: { Authorization: "Bearer " + kToken },
    })
    .then((response) => {
      // return response
      return { status: response.status, text: "ok" }
    })
    .catch((error) => {
      console.error(error.response.data)
      return { status: error.toJSON().status, text: "error" }
    })

// export const fetchLocalRegistration = (regId, kToken) => async (dispatch) => {
//   const response = await apiClient.get(`/localRegistrations/${regId}`, {
//     headers: { Authorization: "Bearer " + kToken },
//   })
//   dispatch(setLocalRegistration(response.data))
// }

export default localRegistrationSlice.reducer
