import { Box, Button, CircularProgress, Collapse } from "@mui/material"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useKeycloak } from "@react-keycloak/web"
import EventFormInfos from "./EventFormInfos"
import { fetchForm, getFormDb, getFormLocal, initForm, resetLocal, saveFormLocal } from "../../../../redux/slices/formSlice"
import MainForm from "./mainForm/MainForm"
import MoreInfosForm from "./moreInfosForm/MoreInfosForm"
import ConfirmButton from "../../../../components/dialogs/ConfirmButton"
import { RestartAltRounded } from "@mui/icons-material"
import { isEmpty, isEqual } from "lodash"
import { getEditedFormProperties } from "../../../../utils/forms"
import { useSnackbar } from "notistack"
import FormLogos from "./formLogos/FormLogos"

export default function EventForms() {
  const { eventId } = useParams()
  const { keycloak } = useKeycloak()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const formDb = useSelector(getFormDb)
  const formLocal = useSelector(getFormLocal)
  const [collapseIn, setCollapseIn] = useState(!isEqual(formDb, formLocal))
  const [expanded, setExpanded] = useState("mainForm")

  const handleExpanded = (panel) => (event, isExpanded) => setExpanded(isExpanded ? panel : false)

  useEffect(() => {
    dispatch(fetchForm(eventId, keycloak.token)).then((result) => {
      if (result === "error") enqueueSnackbar("Une erreur est survenue (fetchForm)", { variant: "error" })
    })

    return () => {
      dispatch(initForm({}))
    }
  }, [])

  useEffect(() => {
    setCollapseIn(!isEqual(formDb, formLocal))
  }, [formDb, formLocal])

  const handleConfirm = (confirm) => {
    if (confirm) {
      if (!isEqual(formDb, formLocal)) {
        return dispatch(saveFormLocal(formLocal._id, getEditedFormProperties(formDb, formLocal), keycloak.token)).then((result) => {
          if (result === "error") {
            return enqueueSnackbar("Une erreur est survenue lors de l'enregistrement des modifications", {
              variant: "error",
            })
          }
          return enqueueSnackbar("Modifications sauvegardées", { variant: "success" })
        })
      }
      enqueueSnackbar("Aucune modification à sauvegarder")
    }
  }

  if (!formLocal || isEmpty(formLocal) || !formDb || isEmpty(formDb)) return <CircularProgress color="heig" />

  return (
    <Box sx={{ display: "flex", flexDirection: "column", mb: 4, gap: 2 }}>
      <EventFormInfos />
      <Box sx={{ mt: 1 }} />
      <MainForm expanded={expanded} handleExpanded={handleExpanded} />
      <MoreInfosForm expanded={expanded} handleExpanded={handleExpanded} />
      <FormLogos expanded={expanded} handleExpanded={handleExpanded} />
      <Collapse in={collapseIn}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 2 }}>
          <Button variant="outlined" color="warning" startIcon={<RestartAltRounded />} onClick={() => dispatch(resetLocal())}>
            Reset
          </Button>
          <ConfirmButton
            title="Valider les modifications"
            content="Êtes-vous sûr de vouloir sauvegarder les modifications ?"
            callback={handleConfirm}
          />
        </Box>
      </Collapse>
    </Box>
  )
}
