import { Box, Typography } from "@mui/material"
import { WarningRounded } from "@mui/icons-material"
import { useSelector } from "react-redux"
import { getRegistration } from "../../../redux/slices/registrationSlice"
import { isEmpty, join } from "lodash"
import { GENDER, translateRegLabel } from "../../../utils/constants/registrations"

export default function WarningFilters() {
  const { warnings } = useSelector(getRegistration)

  return !isEmpty(warnings) ? (
    <Box sx={{ py: 1, px: 2, mx: -2, backgroundColor: "warning.bg", display: "flex", flexDirection: "column", gap: 1 }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <WarningRounded color="warning" />
        <Typography color={"warning.main"} fontWeight={"bold"}>
          {`Certaines informations sont incompatibles avec les ateliers choisis`}
        </Typography>
      </Box>
      <Box sx={{ ml: 2 }}>
        {Object.keys(warnings).map((key, index) => (
          <Box key={index} sx={{ display: "flex", gap: 2 }}>
            <Typography sx={{ textTransform: "capitalize", fontWeight: "bold" }}>{`${translateRegLabel[key]} :`}</Typography>
            <Typography>
              {join(
                Object.keys(warnings[key]).map((ws) => `${ws} (${key === "gender" ? GENDER[warnings[key][ws]] : warnings[key][ws]})`),
                ", "
              )}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  ) : (
    <></>
  )
}
