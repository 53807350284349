import { Collapse, Icon, IconButton, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { BR_MEDIUM } from "../../utils/theme/app"

export default function ActionIconButton({ action }) {
  const navigate = useNavigate()
  const [hovered, setHovered] = useState(false)

  return (
    <IconButton
      size="medium"
      disableRipple
      disableFocusRipple
      sx={{
        borderRadius: BR_MEDIUM,
        "&:hover": {
          backgroundColor: "heig.bg",
          color: "heig.main",
          transition: `background-color 0.2s 0.1s linear, color 0.1s linear, gap 0.1s linear`,
        },
        transition: `background-color 0.1s 0.25s linear, color 0.1s 0.3s linear, gap 0.1s linear`,
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => navigate(action.href)}
    >
      <Collapse
        in={hovered}
        unmountOnExit
        mountOnEnter
        orientation="horizontal"
        sx={{
          ".MuiCollapse-wrapperInner": {
            display: "flex",
            alignItems: "center",
          },
        }}
      >
        <Typography variant="button" color={hovered ? "heig.main" : "inherit"} noWrap>
          {action.tooltip}
        </Typography>
      </Collapse>
      <Icon sx={{ ml: hovered ? 1 : 0, transition: "margin-left 0.1s linear" }} baseClassName="material-icons-round">
        {action.icon}
      </Icon>
    </IconButton>
  )
}
