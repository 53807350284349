import { FormControl, MenuItem, Select as MUISelect } from "@mui/material"

const items = [
  {
    value: "default",
    title: "par défaut",
  },
  {
    value: "mint",
    title: "MINT",
  },
]

export default function SelectUnilFile({ value, ml, setValue, disabled = false }) {
  return (
    <FormControl disabled={disabled} size={"small"} onClick={(event) => event.stopPropagation()} sx={{ ml: ml }}>
      <MUISelect value={value ? value : 0} sx={{ select: { fontSize: "0.9rem" } }} onChange={(event) => setValue(event.target.value)}>
        {items.map((item, index) => (
          <MenuItem sx={{ justifyContent: "center" }} key={index} value={item.value}>
            {item.title}
          </MenuItem>
        ))}
      </MUISelect>
    </FormControl>
  )
}
