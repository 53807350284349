// Modules imports
import { Box, Drawer, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material"
import * as React from "react"
import { useEffect } from "react"
import pkg from "../../../package.json"

// Request imports
// import { useKeycloak } from "@react-keycloak/web";
// App imports
import SidebarList from "./SidebarList"
import { useDispatch, useSelector } from "react-redux"
import { isDrawerOpened, openDrawer } from "../../redux/slices/appSlice"
import { DrawerHeader, drawerWidth, Main, sidebarMenus } from "./utils"

/**
 * Component Sidebar
 *
 * higher-order component that displays sidebar and child passed in props
 *
 * @param {*} props
 * -
 * - setOpen: bool - sidebar display
 * - children: child to display
 *
 * @returns JSX.Element
 */
export default function Sidebar({ children }) {
  const drawerOpened = useSelector(isDrawerOpened)

  const dispatch = useDispatch()
  const isMobile = useMediaQuery(useTheme().breakpoints.down("xl"))

  useEffect(() => {
    dispatch(openDrawer(!isMobile))
  }, [isMobile])

  return (
    <>
      <Drawer
        data-cy="sidebar"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            border: " 2px whitesmoke dashed",
          },
          border: "none",
        }}
        PaperProps={{ sx: { filter: "none" } }}
        variant="persistent"
        open={!!drawerOpened}
      >
        <Toolbar />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            overflow: "auto",
          }}
        >
          <div>
            {/* public list */}

            <SidebarList menus={sidebarMenus} />
            {/* authenticated users list */}
            {/*{keycloak.authenticated && (*/}
            {/*	<SidebarList menus={sidebarMenusAuth} selectedItem={selectedItem} handleClick={handleClickItem} />*/}
            {/*)}*/}
          </div>

          <Box sx={{ my: 2, textAlign: "center", color: "gray" }}>
            <Typography
              variant="button"
              sx={{ pb: 2, fontWeight: "bold", color: "gray" }}
            >{`version : ${pkg.version}`}</Typography>
          </Box>
        </Box>
      </Drawer>
      <Main open={!drawerOpened}>
        <DrawerHeader />
        {children}
      </Main>
    </>
  )
}
