import { colors, createTheme } from "@mui/material"
import { ExpandMoreRounded } from "@mui/icons-material"

export const BR_LARGE = "12px"
export const BR_MEDIUM = "8px"

export const ABR2 = "24px"

export const appTheme = createTheme({
  shape: {
    borderRadius: 8,
  },
  shadows: {
    ...createTheme({}).shadows,
    101: "0 0rem 2mm lightgray",
    104: "0 0rem 4mm lightgray",
  },
  typography: {
    fontFamily: ["Arial"],
    allVariants: {
      color: "#333333",
    },
    h1: { fontSize: "3rem", lineHeight: 1 },
    sub1: { fontSize: "2.5rem", color: "dimgray", lineHeight: 1 },
    h2: { fontSize: "2.5rem", lineHeight: 1 },
    sub2: { fontSize: "2rem", color: "dimgray", lineHeight: 1 },
    h3: { fontSize: "2rem", lineHeight: 1 },
    sub3: { fontSize: "1.75rem", color: "dimgray", lineHeight: 1 },
    h4: { fontSize: "1.75rem", lineHeight: 1 },
    sub4: { fontSize: "1.5rem", color: "dimgray", lineHeight: 1 },
    h5: { fontSize: "1.50rem", lineHeight: 1 },
    sub5: { fontSize: "1.25rem", color: "dimgray", lineHeight: 1 },
    h6: { fontSize: "1.25rem", lineHeight: 1 },
    sub6: { fontSize: "1rem", color: "dimgray", lineHeight: 1 },
    body1: { fontSize: "1rem", color: "#555555" },
    body2: { fontSize: "0.90rem", lineHeight: 1 },
    subtitle1: undefined,
    subtitle2: undefined,
    button: { fontSize: "0.90rem" },
    overline: { fontSize: "0.90rem", lineHeight: 1 },
    caption: { fontSize: "0.80rem", lineHeight: 1 },
    topbarTitle: {
      fontSize: "1rem",
      color: "#333333",
      lineHeight: 1.2,
      textTransform: "uppercase",
    },
    topbarSubtitle: { fontSize: "0.90rem", color: "dimgray" },
  },
  palette: {
    warning: {
      main: colors.orange[700],
      bg: colors.orange[100],
      skeleton: colors.orange[300],
      contrastText: "#fff",
    },
    white: {
      main: "#ffffff",
    },
    black: {
      main: "#AAAAAA",
      contrastText: "#ffffff",
      bg: "#ffffff",
    },
    shade: {
      main: "#808080",
      contrastText: "#555555",
      bg: "#f5f5f5",
    },
    pending: {
      main: "gray",
      contrastText: "#555555",
      skeleton: "silver",
      bg: "lightgray",
    },
    primary: {
      main: colors.blue[500],
      light: colors.blue[400],
      bg: colors.blue[50],
      bgLight: "aliceblue",
    },
    error: {
      main: "#D32F2F",
      light: "#EF5350",
      dark: "#C62828",
      bg: "#ffcdd2",
      bgLight: "mistyrose",
    },
    heig: {
      main: "#e1251b",
      bg: "#FCE7E6",
      hover: "#FEF3F3",
      contrastText: "#fff",
    },
    success: {
      main: "#2e7d32",
      light: "#4caf50",
      dark: "#1b5e20",
      bg: colors.green[100],
      skeleton: colors.green[300],
      contrastText: "#fff",
    },
    app: {
      bgGray: "whitesmoke",
    },
  },
  components: {
    MuiBreadcrumbs: {
      styleOverrides: {
        ol: {
          flexWrap: "nowrap",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          padding: BR_LARGE,
          borderRadius: 12,
          "&:before": { height: 0 },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          gap: ABR2,
          height: "48px",
        },
        content: {
          justifyContent: "space-between",
          gap: ABR2,
        },
      },
      defaultProps: {
        expandIcon: <ExpandMoreRounded fontSize="large" />,
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          marginTop: 8,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: colors.blue[50],
            color: colors.blue[500],
            "& .MuiListItemText-primary": { color: colors.blue[500] },
            "& .MuiListItemIcon-root": { color: colors.blue[500] },
          },
          "&:hover": {
            color: colors.blue[500],
            backgroundColor: colors.blue[50],
            "& .MuiListItemText-primary": { color: colors.blue[500] },
            "& .MuiListItemIcon-root": { color: colors.blue[500] },
          },
          borderRadius: 8,
          margin: 4,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#FCE7E6",
            color: "#e1251b",
            "& .MuiListItemText-primary": { color: "#e1251b" },
            "& .MuiListItemIcon-root": { color: "#e1251b" },
            "&:hover": {
              backgroundColor: "#FEF3F3",
            },
          },
          "&:hover": {
            color: "#e1251b",
            backgroundColor: "#FEF3F3",
            "& .MuiListItemText-primary": { color: "#e1251b" },
            "& .MuiListItemIcon-root": { color: "#e1251b" },
          },
          borderRadius: 8,
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          borderRadius: BR_LARGE,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 101,
      },
      styleOverrides: {
        root: {
          borderRadius: BR_LARGE,
          mt: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.blue[500],
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "lightgray",
            borderWidth: 1,
          },
          "&:hover .MuiInputAdornment-root .MuiSvgIcon-root": {
            color: colors.blue[500],
          },
          "&.Mui-focused .MuiInputAdornment-root .MuiSvgIcon-root": {
            color: colors.blue[500],
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "none",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        action: {
          margin: 0,
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          // color: colors.blue[500],
          ".MuiTableSortLabel-icon": {
            opacity: 0,
            color: colors.blue[500],
          },
          "&:hover": {
            // color: colors.blue[500],
            "&.MuiTableSortLabel-icon": {
              opacity: 1,
              color: colors.blue[500],
            },
          },
          "&.Mui-active": {
            // color: colors.blue[500],
            ".MuiTableSortLabel-icon": {
              opacity: 1,
              color: colors.blue[500],
            },
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // Controls default (unchecked) color for the thumb
          color: colors.red[700],
        },
        track: {
          // Controls default (unchecked) color for the track
          opacity: 0.2,
          backgroundColor: colors.red[400],
        },
      },
    },
    MuiCollapse: {
      styleOverrides: {
        wrapper: {
          flex: 1,
        },
        wrapperInner: {
          // display: "flex",
          flex: 1,
        },
      },
    },
  },
})
