import { createSlice } from "@reduxjs/toolkit"
import apiClient from "../../configs/axiosCfg"
import { cloneDeep, pull } from "lodash"

const initialReg = {
  child: {},
  school: {},
  choices: [],
  numValidWanted: 0,
}

const initialState = {
  config: {},
  parent: {},
  address: {},
  comments: "",
  newsletters: "",
  regs: [initialReg],
  activeStep: 0,
  childCount: 1,
  processStatus: 0,
}

export const mainFormSlice = createSlice({
  name: "mainForm",
  initialState,
  reducers: {
    initState: () => {
      return initialState
    },
    setAll: (state, action) => {
      return { ...state, ...action.payload }
    },
    setConfig: (state, action) => {
      return { ...state, config: action.payload }
    },
    jumpState: (state, action) => {
      return { ...state, activeStep: state.activeStep + action.payload }
    },
    setActiveStep: (state, action) => {
      return { ...state, activeStep: action.payload }
    },
    setField: (state, { payload: { superField, field, newValue } }) => {
      const newState = cloneDeep(state)
      if (!superField) newState[field] = newValue
      else newState[superField][field] = newValue
      return newState
    },
    setRegField: (state, { payload: { index, field, subField, value } }) => {
      let newRegs = cloneDeep(state.regs)
      newRegs[index][field][subField] = value

      return { ...state, regs: newRegs }
    },
    addChoice: (state, { payload: { index, workshop } }) => {
      let newRegs = cloneDeep(state.regs)
      newRegs[index].choices.push(workshop)
      return { ...state, regs: newRegs }
    },
    removeChoice: (state, { payload: { index, workshop } }) => {
      let newRegs = cloneDeep(state.regs)
      pull(newRegs[index].choices, workshop)
      return { ...state, regs: newRegs }
    },
    setChoices: (state, { payload: { index, choices } }) => {
      let newRegs = cloneDeep(state.regs)
      newRegs[index].choices = choices
      return { ...state, regs: newRegs }
    },
    incrementChildCount: (state) => {
      return { ...state, childCount: state.childCount + 1, regs: [...state.regs, initialReg] }
    },
    decrementChildCount: (state) => {
      return state.childCount > 1
        ? {
            ...state,
            childCount: state.childCount - 1,
            regs: state.regs.filter((r, index) => index !== state.regs.length - 1),
          }
        : state
    },
    setNumValidWanted: (state, { payload: { index, value } }) => {
      let newRegs = cloneDeep(state.regs)
      newRegs[index]["numValidWanted"] = value
      return { ...state, regs: newRegs }
    },
    incrementNumValidWanted: (state, { payload }) => {
      let newRegs = cloneDeep(state.regs)

      newRegs[payload]["numValidWanted"]++
      return { ...state, regs: newRegs }
    },
    decrementNumValidWanted: (state, { payload }) => {
      let newRegs = cloneDeep(state.regs)
      newRegs[payload]["numValidWanted"]--
      return { ...state, regs: newRegs }
    },
  },
})

export const {
  initState,
  setConfig,
  jumpState,
  setActiveStep,
  setField,
  addChoice,
  removeChoice,
  incrementChildCount,
  decrementChildCount,
  setRegField,
  incrementNumValidWanted,
  decrementNumValidWanted,
  setNumValidWanted,
  setAll,
  setChoices,
} = mainFormSlice.actions

export const getMainForm = (state) => {
  return state.mainForm
}

export const fetchConfig = (formId) => async (dispatch) =>
  apiClient
    .get(`/forms/${formId}`, {
      headers: {
        "x-api-secret": "HZkByMDYwbZiBM3hYbNjNGT2ex3kW2DZf7J3EMFB4Ti4X9VmaARKwvtdPKKyEALf33nkT7A9YENcmvmhUu2gJX3BEupdrRzqZtLf",
      },
    })
    .then((response) => {
      if (response.status === 204) {
        return { status: "noContent", details: response.data }
      }
      dispatch(setConfig(response.data))
      return response
    })
    .catch((error) => {
      console.error(error.response)
      return { status: "error", error: error.response }
    })

export const postReg = (body) => async () =>
  apiClient
    .post(`/registrationsToValid`, body, {
      headers: {
        "x-api-secret": "HZkByMDYwbZiBM3hYbNjNGT2ex3kW2DZf7J3EMFB4Ti4X9VmaARKwvtdPKKyEALf33nkT7A9YENcmvmhUu2gJX3BEupdrRzqZtLf",
      },
    })
    .then((response) => {
      if (response.status === 201) return "ok"
      else return "error"
    })
    .catch((error) => {
      console.error(error.response.data)
      return "error"
    })

export default mainFormSlice.reducer
