import { configureStore } from "@reduxjs/toolkit"
import appReducer from "./slices/appSlice"
import eventReducer from "./slices/eventSlice"
import workshopReducer from "./slices/workshopSlice"
import activitiesReducer from "./slices/activitiesSlice"
import registrationReducer from "./slices/registrationSlice"
import localRegistrationReducer from "./slices/localRegistrationSlice"
import formReducer from "./slices/formSlice"
import workshopsReducer from "./slices/workshopsSlice"
import usersReducer from "./slices/usersSlice"
import registrationsReducer from "./slices/registrationsSlice"
import mainFormReducer from "./slices/mainFormSlice"
import adFormReducer from "./slices/adFormSlice"
import notificationsReducer from "./slices/notificationsSlice"
import exportReducer from "./slices/exportSlice"

export const store = configureStore({
  reducer: {
    app: appReducer,
    event: eventReducer,
    workshop: workshopReducer,
    activities: activitiesReducer,
    registration: registrationReducer,
    localRegistration: localRegistrationReducer,
    form: formReducer,
    workshops: workshopsReducer,
    users: usersReducer,
    registrations: registrationsReducer,
    mainForm: mainFormReducer,
    adForm: adFormReducer,
    notifications: notificationsReducer,
    export: exportReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
})
