// Utils functions to display date range
// if start date === end date => "12.06.2022, 11:00 - 19:00"
// else 12.06.2022, 11:00:00 - 13.06.2022, 19:00:00

const isInvalidDate = (date) => new Date(date).toString() === "Invalid Date"

export const formatDate = (date) =>
  date.toLocaleString("fr-CH", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  })

const formatHours = (date) =>
  date.toLocaleString("fr-CH", {
    hour: "2-digit",
    minute: "2-digit",
  })

const _MS_PER_DAY = 1000 * 60 * 60 * 24

export const formatDateHours = (date) => `${formatDate(date)}, ${formatHours(date)}`

export function dateDisplay(start, end) {
  if (isInvalidDate(start) || isInvalidDate(end)) return "- - -"

  const a = new Date(start)
  const b = new Date(end)

  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())

  const dateDiff = Math.floor((utc2 - utc1) / _MS_PER_DAY)

  return dateDiff === 0
    ? `${formatDate(a)}, ${formatHours(a)} - ${formatHours(b)}`
    : `${formatDate(a)}, ${formatHours(a)} - ${formatDate(b)}, ${formatHours(b)}`
}

//////// EVENT  ////////
const formatLongDate = (date) => date.toLocaleString("fr-CH", { weekday: "long", day: "numeric", month: "long", year: "numeric" }).replace(",", "")

const formatFormDate = (date) => date.toLocaleString("fr-CH", { weekday: "short", day: "numeric", month: "short", year: "numeric" }).replace(",", "")

export function datesOptionalDisplay(start, end) {
  if (!isInvalidDate(start) && !isInvalidDate(end)) return dateDisplay(start, end)

  return `${!isInvalidDate(start) ? `${formatDate(new Date(start))}, ${formatHours(new Date(start))}` : "-"} - ${
    !isInvalidDate(end) ? `${formatDate(new Date(end))}, ${formatHours(new Date(end))}` : "-"
  }`
}

export function longDateDisplay(start, end) {
  if (isInvalidDate(start) || isInvalidDate(end)) return "- - -"

  const a = new Date(start)
  const b = new Date(end)

  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())

  const dateDiff = Math.floor((utc2 - utc1) / _MS_PER_DAY)

  return dateDiff === 0 ? `Le ${formatLongDate(a)}` : `${formatLongDate(a)} ➜ ${formatLongDate(b)}`
}

export const getEventDate = (workshops, variant = "long") =>
  variant === "long"
    ? longDateDisplay(
        workshops.map((ws) => new Date(ws.start)).sort((a, b) => a - b)[0],
        workshops.map((ws) => new Date(ws.end)).sort((a, b) => b - a)[0]
      )
    : dateDisplay(
        workshops.map((ws) => new Date(ws.start)).sort((a, b) => a - b)[0],
        workshops.map((ws) => new Date(ws.end)).sort((a, b) => b - a)[0]
      )

//////// WORKSHOP ////////

export function workshopDateDisplay(start, end) {
  if (isInvalidDate(start) || isInvalidDate(end)) return "- - -"

  const a = new Date(start)
  const b = new Date(end)

  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())

  const dateDiff = Math.floor((utc2 - utc1) / _MS_PER_DAY)

  return dateDiff === 0
    ? `${formatLongDate(a)}, ${formatHours(a)} - ${formatHours(b)}`
    : `${formatLongDate(a)}, ${formatHours(a)} ➜ ${formatLongDate(b)}, ${formatHours(b)}`
}

export function workshopDateFormDisplay(start, end) {
  if (isInvalidDate(start) || isInvalidDate(end)) return "- - -"

  const a = new Date(start)
  const b = new Date(end)

  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())

  const dateDiff = Math.floor((utc2 - utc1) / _MS_PER_DAY)

  return dateDiff === 0
    ? [`${formatFormDate(a)}, ${formatHours(a)} - ${formatHours(b)}`]
    : [`Du ${formatFormDate(a)}, ${formatHours(a)}`, `au ${formatFormDate(b)}, ${formatHours(b)}`]
}

export function legalDateDisplay(start, end) {
  if (isInvalidDate(start) || isInvalidDate(end)) return "- - -"

  const a = new Date(start)
  const b = new Date(end)

  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())

  const dateDiff = Math.floor((utc2 - utc1) / _MS_PER_DAY)

  return dateDiff === 0 ? [`Le ${formatLongDate(a)}`] : [`Du ${formatLongDate(a)}`, `au ${formatLongDate(b)}`]
}
