import { useSelector } from "react-redux"
import { getEventDb } from "../../../../../redux/slices/eventSlice"
import { useNavigate } from "react-router-dom"
import { dateDisplay } from "../../../../../utils/tablesHelpers"
import { Box, Card, CardActionArea, CardContent, Chip, Typography } from "@mui/material"
import { BR_LARGE } from "../../../../../utils/theme/app"
import { AVAILABLE_COLOR, AVAILABLE_ICON, AVAILABLE_LABEL } from "../../../../../utils/constants/workshop"
import Filters from "./Filters"
import { StatsBox } from "./StatsBox"
import { PlaceRounded } from "@mui/icons-material"

export function WorkshopCard({ workshop }) {
  const event = useSelector(getEventDb)
  const navigate = useNavigate()

  const navigateToWorkshop = () => navigate(`../workshops/${workshop._id}`)

  const handleDateDisplay = (start, end) => {
    const dd = dateDisplay(start, end)
    return dd.includes("-") && dd.includes(".", dd.indexOf("-")) ? (
      <Box>
        <Typography color={"shade.main"} variant="body2">
          {`du ${dd.split("-")[0]}`}
        </Typography>
        <Typography color={"shade.main"} variant="body2">
          {`au ${dd.split("-")[1]}`}
        </Typography>
      </Box>
    ) : (
      <Typography color={"shade.main"} variant="body2">
        {dd}
      </Typography>
    )
  }

  return (
    <Card sx={{ width: "calc(50% - 24px)", borderRadius: BR_LARGE }} id={`workshopCard-${workshop.name}`}>
      <CardActionArea onClick={navigateToWorkshop} disableRipple>
        {/*<CardHeader title={<EventDashboardToolbar workshop={ws} />} />*/}
        <CardContent sx={{ display: "flex" }}>
          {/* LEFT SIDE OF CARD */}

          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", gap: 2, width: "40%" }}>
            {/* TITLE AND DATE*/}
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Box>
                <Typography variant="h6" gutterBottom>
                  {workshop.name}
                </Typography>
                {handleDateDisplay(workshop.start, workshop.end)}
              </Box>
              {/* FILTERS */}
              <Box sx={{ display: "flex" }}>
                <Chip
                  color={AVAILABLE_COLOR(workshop.available)}
                  icon={AVAILABLE_ICON(workshop.available)}
                  label={AVAILABLE_LABEL(workshop.available)}
                  sx={{
                    backgroundColor: `${AVAILABLE_COLOR(workshop.available)}.bg`,
                    color: `${AVAILABLE_COLOR(workshop.available)}.main`,
                  }}
                />
              </Box>
              <Filters workshop={workshop} />
            </Box>
            {workshop.place && (
              <Box sx={{ display: "inline-flex", alignItems: "center", gap: 0.5, ml: "-4px" }}>
                <PlaceRounded sx={{ width: "16px" }} color="shade" />
                <Typography variant="caption" color={"shade.main"}>
                  {workshop.place}
                </Typography>
              </Box>
            )}
          </Box>
          <Box sx={{ width: "5%" }} />
          {/* RIGHT SIDE OF CARD */}
          <Box sx={{ width: "55%" }}>{<StatsBox workshop={workshop} eventId={event._id} />}</Box>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
