import { createSlice } from "@reduxjs/toolkit"
import apiClient from "../../configs/axiosCfg"

const initialState = {
  selectedStatus: -1,
  sortBy: 6,
  orderBy: "desc",
  page: 0,
  rowsPerPage: 10,
  list: [],
  regsByStatus: {},
  searchText: "",
  count: 0,
  selectedReg: "",
  selectionsRegs: [],
}

export const registrationsSlice = createSlice({
  name: "registrations",
  initialState,
  reducers: {
    setAttribute: (state, action) => {
      return { ...state, [action.payload.key]: action.payload.value }
    },
    resetRegistrations: () => {
      return initialState
    },
    addRegToSelections: (state, action) => {
      return { ...state, selectionsRegs: [...state.selectionsRegs, action.payload] }
    },
    removeRegFromSelections: (state, action) => {
      return {
        ...state,
        selectionsRegs: state.selectionsRegs.filter((reg) => reg !== action.payload),
      }
    },
    updateOneRegistration: (state, action) => {
      const regs = [...state.list]
      const index = regs.map((reg) => reg._id).indexOf(action.payload._id)
      regs[index] = action.payload
      return { ...state, list: regs }
    },
    removeOneRegistration: (state, action) => {
      return { ...state, list: state.list.filter((reg) => reg._id !== action.payload) }
    },
    pushOne: (state, action) => {
      return { ...state, allRegs: [...state.allRegs, action.payload] }
    },
  },
})

export const { setAttribute, resetRegistrations, addRegToSelections, removeRegFromSelections } = registrationsSlice.actions

export const getRegistrationsState = (state) => state.registrations
export const getRegsByStatus = (state) => state.registrations.regsByStatus
export const getSearchText = (state) => state.registrations.searchText

export const fetchRegistrations = (url, kToken) => async (dispatch) =>
  apiClient
    .get(url, {
      headers: { Authorization: "Bearer " + kToken },
    })
    .then((response) => {
      if (response.status === 204) return "empty"
      dispatch(setAttribute({ key: "list", value: response.data }))
      return "ok"
    })
    .catch((error) => {
      console.error(error.response.data)
      return "error"
    })

export const fetchStats = (eventId, workshopId, kToken) => async (dispatch) =>
  apiClient
    .get(`/events/${eventId}/workshops/${workshopId}/stats`, {
      headers: { Authorization: "Bearer " + kToken },
    })
    .then((response) => {
      if (response.status === 204) return { status: 204, text: "empty" }
      dispatch(setAttribute({ key: "regsByStatus", value: response.data.regsByStatus }))
      return { status: response.status, text: "ok" }
    })
    .catch((error) => {
      console.error(error.response.data)
      return { status: error.toJSON().status, text: "error" }
    })

export default registrationsSlice.reducer
