import { createSlice } from "@reduxjs/toolkit"

import apiClient from "../../configs/axiosCfg"

const initialState = {
  activity: {
    name: "",
    managers: [],
  },
  event: {
    name: "",
    users: [],
  },
}

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetUsers: () => {
      return initialState
    },
    setUsers: (state, action) => {
      return action.payload
    },
    setEventUser: (state, action) => {
      return {
        ...state,
        event: {
          ...state.event,
          users: state.event.users.map((user, index) => (index === action.payload.index ? action.payload.user : user)),
        },
      }
    },
    newEventUser: (state, action) => {
      return {
        ...state,
        event: {
          ...state.event,
          users: [...state.event.users, action.payload],
        },
      }
    },
    deleteEventUser: (state, action) => {
      return {
        ...state,
        event: {
          ...state.event,
          users: state.event.users.filter((user, index) => index !== action.payload),
        },
      }
    },
    setUserResourceRole: (state, action) => {
      return {
        ...state,
        event: {
          ...state.event,
          users: state.event.users.map((user, index) =>
            index === action.payload.index ? { ...user, permission: { ...user.permission, role: action.payload.value } } : user
          ),
        },
      }
    },
  },
})

export const { resetUsers, setUsers, newEventUser, deleteEventUser, setUserResourceRole, setEventUser } = usersSlice.actions

export const getActivityUsers = (state) => state.users.activity
export const getEventUsers = (state) => state.users.event
export const getUser = (index, isManager) => (state) => isManager ? state.users.activity.managers[index] : state.users.event.users[index]
export const getUsers = (state) => state.users
export const getAllUsersArray = (state) => [...state.users.activity.managers, ...state.users.event.users]

export const fetchUsers = (eventId, kToken) => async (dispatch) =>
  apiClient
    .get(`/events/${eventId}/permissions`, {
      headers: { Authorization: "Bearer " + kToken },
    })
    .then((response) => {
      dispatch(setUsers(response.data))
      return "ok"
    })
    .catch((error) => {
      console.error(error.response.data)
      return "error"
    })

export const postUserPermission = (body, kToken) => async (dispatch, getState) => {
  const eventId = getState().event.db._id
  return await apiClient
    .post(`/events/${eventId}/permissions/`, body, {
      headers: { Authorization: "Bearer " + kToken },
    })
    .then(async (response) => {
      dispatch(newEventUser(response.data))
      return "ok"
    })
    .catch((error) => {
      console.error(error.response.data)
      return "error"
    })
}

export const updateUserPermission = (permission, userIndex, kToken) => async (dispatch, getState) => {
  const eventId = getState().event.db._id
  return await apiClient
    .put(
      `/events/${eventId}/permissions/${permission._id}`,
      { permission },
      {
        headers: { Authorization: "Bearer " + kToken },
      }
    )
    .then((response) => {
      dispatch(setEventUser({ index: userIndex, user: response.data }))
      return "ok"
    })
    .catch((error) => {
      console.error(error.response.data)
      return "error"
    })
}

export const deleteUserPermission = (permissionId, userIndex, kToken) => async (dispatch, getState) => {
  const eventId = getState().event.db._id
  return await apiClient
    .delete(`/events/${eventId}/permissions/${permissionId}`, {
      headers: { Authorization: "Bearer " + kToken },
    })
    .then(() => {
      dispatch(deleteEventUser(userIndex))
      return "ok"
    })
    .catch((error) => {
      console.error(error.response.data)
      return "error"
    })
}

export default usersSlice.reducer
