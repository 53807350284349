import { useDispatch, useSelector } from "react-redux"
import { getFormLocal, setLocalAttribute, setLocalInput } from "../../../../../redux/slices/formSlice"
import { isEmpty, isNull } from "lodash"
import { CircularProgress, Grid, TextField, Typography } from "@mui/material"
import FormInputToggleButtons from "../../../../../components/inputs/FormInputToggleButtons"
import { translateRegLabel } from "../../../../../utils/constants/registrations"
import SelectUnilFile from "../../../../../components/inputs/SelectUnilFile"
import Box from "@mui/material/Box"
import * as React from "react"

export default function FormInputs() {
  const { inputs, email, mail } = useSelector(getFormLocal)
  const dispatch = useDispatch()

  const handleChangeInput = (key, value) => {
    if (!isNull(value)) dispatch(setLocalInput({ key, value }))
  }

  const handleChangeAttribute = (key, value) => {
    dispatch(setLocalAttribute({ key, value: value }))
  }

  const handleChangeMail = (key, value) => {
    let newValue = value
    if (key === "zip") newValue = Math.abs(Math.round(value))

    handleChangeAttribute("mail", { ...mail, [key]: newValue })
  }

  if (!inputs || isEmpty(inputs) || !mail || isEmpty(mail)) return <CircularProgress color="heig" />

  console.log(inputs)

  return (
    <Grid container columnSpacing={8} rowSpacing={1}>
      <Grid item xs={4}>
        <FormInputToggleButtons keyValue={"tshirt_size"} value={inputs["tshirt_size"]} setValue={handleChangeInput} label={"Taille t-shirt"} />
      </Grid>
      <Grid item xs={4}>
        <FormInputToggleButtons keyValue={"transportation"} value={inputs["transportation"]} setValue={handleChangeInput} label={"Transport Bus"} />
      </Grid>
      <Grid item xs={4}>
        <FormInputToggleButtons keyValue={"diet"} value={inputs["diet"]} setValue={handleChangeInput} label={"Allergies/régime alimentaire"} />
      </Grid>

      <Grid item xs={4}>
        <FormInputToggleButtons
          keyValue={"media_consent"}
          value={inputs["media_consent"]}
          setValue={handleChangeInput}
          label={"Consentement médias"}
        />
      </Grid>
      <Grid item xs={4}>
        <FormInputToggleButtons
          keyValue={"risks_consent"}
          value={inputs["risks_consent"]}
          setValue={handleChangeInput}
          label={"Acceptation des risques"}
        />
      </Grid>
      <Grid item xs={4}>
        <FormInputToggleButtons keyValue={"unil_survey"} value={inputs["unil_survey"]} setValue={handleChangeInput} label={"Étude UNIL"} />
      </Grid>

      {inputs["unil_survey"] ? (
        <>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 4, mt: 1 }}>
              <Typography fontWeight={"bold"} noWrap>
                {"Formulaire étude UNIL"}
              </Typography>
              <SelectUnilFile value={inputs["unil_survey_doc"]} setValue={(value) => handleChangeInput("unil_survey_doc", value)} />
            </Box>
          </Grid>
        </>
      ) : null}
      <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography fontWeight={"bold"} sx={{ mt: 1 }} noWrap>
          {"Adresse email d'envoi"}
        </Typography>
        <TextField fullWidth size="small" value={email} onChange={(event) => handleChangeAttribute("email", event.target.value)} />
      </Grid>

      <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography fontWeight={"bold"} sx={{ mt: 1 }} noWrap>
          {"Adresse postale d'envoi"}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          size="small"
          label={translateRegLabel["line1"]}
          InputLabelProps={{ sx: { textTransform: "capitalize" } }}
          value={mail["line1"]}
          onChange={(event) => handleChangeMail("line1", event.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          size="small"
          label={translateRegLabel["line2"]}
          InputLabelProps={{ sx: { textTransform: "capitalize" } }}
          value={mail["line2"]}
          onChange={(event) => handleChangeMail("line2", event.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          size="small"
          label={translateRegLabel["zip"]}
          InputLabelProps={{ sx: { textTransform: "capitalize" } }}
          type="number"
          value={mail["zip"]}
          onChange={(event) => handleChangeMail("zip", event.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          size="small"
          label={translateRegLabel["city"]}
          InputLabelProps={{ sx: { textTransform: "capitalize" } }}
          value={mail["city"]}
          onChange={(event) => handleChangeMail("city", event.target.value)}
        />
      </Grid>
    </Grid>
  )
}
