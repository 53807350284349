import StatusCards from "./statusCards/StatusCards"
import WorkshopInfos from "./WorkshopInfos"
import { Box, Card, CardContent } from "@mui/material"
import { useParams } from "react-router-dom"
import { useKeycloak } from "@react-keycloak/web"
import { useSnackbar } from "notistack"
import { useDispatch, useSelector } from "react-redux"
import { fetchRegistrations, getRegistrationsState, resetRegistrations } from "../../../../../../redux/slices/registrationsSlice"
import { useEffect } from "react"
import RegsTable from "../../../../../../components/registrationsTable/RegsTable"
import RegsStatusSelect from "../../../../../../components/inputs/RegsStatusSelect"
import SearchInput from "../../../../../../components/inputs/SearchInput"
import RegistrationDetails from "../../../../../../components/registrationDetailsDialog/RegistrationDetails"
import WorkshopRegistrationsFile from "../../../../../../components/inputs/workshopRegistrationsFile/WorkshopRegistrationsFile"

export default function WorkshopRegistrations() {
  const { eventId, workshopId } = useParams()
  const { keycloak } = useKeycloak()
  const { selectedReg } = useSelector(getRegistrationsState)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchRegistrations(`/events/${eventId}/workshops/${workshopId}/registrations`, keycloak.token)).then((result) => {
      if (result === "error")
        return enqueueSnackbar("Une erreur est survenue lors de la récupération des inscriptions", {
          variant: "error",
        })
    })

    return () => {
      dispatch(resetRegistrations())
    }
  }, [])

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 4, mb: 4 }}>
      <WorkshopInfos />
      <StatusCards />
      <Card>
        <CardContent sx={{ pt: 1, pb: "0px!important" }}>
          <RegsTable tools={[<RegsStatusSelect key={0} forWorkshops />, <SearchInput key={1} />]} />
        </CardContent>
      </Card>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <WorkshopRegistrationsFile />
      </Box>
      {selectedReg && <RegistrationDetails />}
    </Box>
  )
}
