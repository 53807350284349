// API requests configuration file
// baseURL => of the bureau-projet.api
import axios from "axios"

// BEFORE COMMIT => Change API URL

export default axios.create({
  // baseURL: "http://localhost:3001/",
  baseURL: "https://api2.blacktree.io/ajp/",
  headers: {
    "Content-type": "application/json",
  },
})
