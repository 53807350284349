import { Button } from "@mui/material"
import { getEvents } from "../../redux/slices/exportSlice"
import { useSelector } from "react-redux"
import { useKeycloak } from "@react-keycloak/web"
import JsFileDownloader from "js-file-downloader"
import apiClient from "../../configs/axiosCfg"
import { useSnackbar } from "notistack"

export default function ExportButton() {
  const { keycloak } = useKeycloak()
  const events = useSelector(getEvents)
  const { enqueueSnackbar } = useSnackbar()

  const genBody = () => {
    const body = {
      events: events.selected.length > 0 ? events.selected : events.items,
    }
    return JSON.stringify(body)
  }

  const downloadFile = new JsFileDownloader({
    url: `${apiClient.defaults.baseURL}registrations/export/xlsx`,
    method: "POST",
    contentTypeDetermination: "signature",
    headers: [
      {
        name: "Authorization",
        value: `Bearer ${keycloak.token}`,
      },
    ],
    body: genBody(),
    contentType: "application/json",
    filename: "export.xlsx",
    autoStart: false,
  })

  const onClickExport = () => {
    downloadFile.start().catch((err) => {
      enqueueSnackbar("Une erreur est survenue lors du téléchargement du fichier", {
        variant: "error",
      })
      console.error(err)
    })
  }

  return (
    <Button variant="contained" sx={{ ml: 2 }} onClick={onClickExport}>
      Exporter
    </Button>
  )
}
