import { createSlice } from "@reduxjs/toolkit"
import apiClient from "../../configs/axiosCfg"

const initialState = {
  _id: "-",
  name: "Workshop",
  age_min: 0,
  age_max: 0,
  gender: 0,
  grade_min: 0,
  grade_max: 0,
  // start: "1970-01-01T00:00:00Z",
  // end: "2099-12-31T23:59:59Z",
  start: "",
  end: "",
  capacity: 0,
  available: false,
  deleted: false,
  event: "-",
}

export const workshopSlice = createSlice({
  name: "workshop",
  initialState,
  reducers: {
    setWorkshop: (state, action) => {
      return { ...state, ...action.payload }
    },
  },
})

export const { setWorkshop } = workshopSlice.actions

export const getWorkshop = (state) => state.workshop

export const fetchWorkshop = (eventId, workshopId, kToken) => async (dispatch) =>
  apiClient
    .get(`/events/${eventId}/workshops/${workshopId}`, {
      headers: { Authorization: "Bearer " + kToken },
    })
    .then((response) => {
      if (response.status === 204) return { status: 204, text: "empty" }
      dispatch(setWorkshop(response.data))
      return { status: response.status, text: "ok" }
    })
    .catch((error) => {
      console.error(error.response.data)
      return { status: error.toJSON().status, text: "error" }
    })

export default workshopSlice.reducer
