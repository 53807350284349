import { Box, Card, CardContent, Checkbox, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { addChoice, getMainForm, removeChoice } from "../../../../redux/slices/mainFormSlice"
import WorkshopDateRange from "../../../../components/data/WorkshopDateRange"
import WorkshopPlace from "../../../../components/data/WorkshopPlace"

export default function WorkshopCard({ workshop, index }) {
  const dispatch = useDispatch()
  const {
    regs,
    config: {
      event: { selections },
    },
  } = useSelector(getMainForm)
  const { _id, name, available, start, end, place } = workshop

  const isSelected = regs[index]?.choices?.includes(_id)

  const onClickCard = () => {
    if (regs[index]?.choices?.includes(_id)) dispatch(removeChoice({ index, workshop: _id }))
    else if (regs[index]?.choices.length < selections) dispatch(addChoice({ index, workshop: _id }))
  }

  return (
    <Card
      sx={{
        backgroundColor: isSelected ? "primary.bgLight" : "",
      }}
    >
      <CardContent
        sx={{
          opacity: !available || (regs[index]?.choices.length >= selections && !isSelected) ? 0.5 : 1,
          display: "flex",
          gap: 2,
          alignItems: "center",
          justifyContent: "space-between",
          py: "8px!important",
          px: "16px!important",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "baseline" }}>
          <Typography fontWeight={"bold"} color={isSelected ? "primary.main" : "inherit"}>
            {name}
          </Typography>
          <WorkshopDateRange start={start} end={end} />
          <Box sx={{ mb: 1 }} />
          {place && <WorkshopPlace place={place} />}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2 }}>
          {available ? (
            <>
              {isSelected && (
                <Typography variant="h6" fontWeight={"bold"} color={"primary.main"}>
                  {regs[index]?.choices.indexOf(_id) + 1}
                </Typography>
              )}
              <Checkbox
                sx={{ padding: 0 }}
                checked={isSelected}
                disabled={!available || (regs[index]?.choices.length >= selections && !isSelected)}
                onClick={onClickCard}
              />
            </>
          ) : (
            <Typography variant="body2">{"indisponible"}</Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  )
}
