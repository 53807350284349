import { IconButton, keyframes, Tooltip } from "@mui/material"
import { useState } from "react"
import BasicConfirm from "../../../../../components/dialogs/BasicConfirm"
import { AddCircleOutlineRounded } from "@mui/icons-material"

const slidein = keyframes`
  0% { transform: rotate(0deg); }
  15% { transform: translate(0, 0) rotate(45deg); }
  45% { transform: translate(-5px, 0) rotate(45deg); }
  30% { transform: translate(5px, 0) rotate(45deg); }
  75% { transform: translate(-5px, 0) rotate(45deg); }
  60% { transform: translate(5px, 0) rotate(45deg); }
  90% { transform: translate(0, 0) rotate(45deg); }
  100% { transform: rotate(0deg); }
`

export default function AddUserButton({ onClick = () => {}, error = false }) {
  const [openConfirmAddUser, setOpenConfirmAddUser] = useState(false)

  const confirmAddUser = (confirm) => {
    setOpenConfirmAddUser(false)
    if (confirm) {
      onClick()
    }
  }

  return (
    <>
      <Tooltip title={"Ajouter l'utilisateur"}>
        <IconButton
          color={error ? "error" : "black"}
          sx={
            error
              ? { color: "error", animation: `0.5s 1 ${slidein}` }
              : { "&:hover": { color: "primary.main" } }
          }
          onClick={() => setOpenConfirmAddUser(true)}
        >
          <AddCircleOutlineRounded />
        </IconButton>
      </Tooltip>
      <BasicConfirm
        title={"Ajouter un utilisateur"}
        content={"Êtes-vous sûr de vouloir ajouter cet utilisateur à l'évènement ?"}
        open={openConfirmAddUser}
        onClose={confirmAddUser}
      />
    </>
  )
}
