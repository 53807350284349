import { ListItemIcon, ListItemText, MenuItem } from "@mui/material"
import PropTypes from "prop-types"

export default function MagicMenuItem(props) {
  const { icon = null, label = null, action = () => {}, handleCloseMenu } = props

  const handleClick = () => {
    handleCloseMenu()
    action()
  }

  return (
    <MenuItem onClick={handleClick}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      {label && <ListItemText>{label}</ListItemText>}
    </MenuItem>
  )
}

MagicMenuItem.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string,
  action: PropTypes.func,
}
