import { Box, FormControl, MenuItem, Select } from "@mui/material"
import { ADMITTED_STATUSES, ADMITTED_STATUSES_COLOR, ADMITTED_STATUSES_ICON, ADMITTED_STATUSES_LABEL } from "../../utils/constants/statuses"
import { useDispatch, useSelector } from "react-redux"
import { getLocalRegistration, setAttribute as setAtt } from "../../redux/slices/localRegistrationSlice"

export default function RegAdStatusSelect() {
  const { admitted_status } = useSelector(getLocalRegistration)
  const dispatch = useDispatch()
  const onChange = (event) => {
    dispatch(setAtt({ key: "admitted_status", value: event.target.value }))
  }

  return (
    <FormControl>
      <Select sx={{ flex: 1 }} size="small" value={admitted_status} onChange={onChange}>
        {ADMITTED_STATUSES.map((status, index) => (
          <MenuItem key={`si_${status}`} value={index}>
            <Box sx={{ display: "flex", gap: 1, alignItems: "center", color: ADMITTED_STATUSES_COLOR[status] + ".main", fontWeight: "bold", pr: 1 }}>
              {ADMITTED_STATUSES_ICON[status]}
              {ADMITTED_STATUSES_LABEL[status]}
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
