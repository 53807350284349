import { useKeycloak } from "@react-keycloak/web"
import { useEffect } from "react"
import NotLogged from "./NotLogged"

/**
 * Component InitKeycloak
 *
 * Handle Keycloak initialisation
 *
 * @param children
 * @constructor
 */
export default function InitKeycloak({ children }) {
  const { initialized, keycloak } = useKeycloak()

  // Hook to update the keycloak token
  useEffect(() => {
    if (keycloak && initialized) {
      keycloak.onTokenExpired = () => keycloak.updateToken(300)
    }
    return () => {
      if (keycloak) keycloak.onTokenExpired = () => {}
    }
  }, [initialized, keycloak])

  if (!initialized) {
    // IF keycloak not initialized => Display backdrop loading circle
    return <NotLogged initializing={true} />
  } else {
    if (keycloak.authenticated) {
      return children
    } else {
      return <NotLogged />
    }
  }
}
