import { Box, Button, Input, Typography, useMediaQuery, useTheme } from "@mui/material"
import { SendMode } from "./SendMode"
import { UploadRounded } from "@mui/icons-material"
import { fetchConfig, getAdForm, postFile } from "../../../../redux/slices/adFormSlice"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useSnackbar } from "notistack"

export function SendModes() {
  const { secret, formId } = useParams()
  const {
    config: { email, mail },
    dbReg: { _id: regId },
  } = useSelector(getAdForm)
  const dispatch = useDispatch()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("sm"))

  const isMailEmpty = !mail.line1 && !mail.zip && !mail.city

  const onChangeFile = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0]
      const formData = new FormData()
      formData.append(file.name, file)
      dispatch(postFile(regId, secret, formData)).then((result) => {
        if (result.text === "error") {
          return enqueueSnackbar(
            "Une erreur est survenue lors de l'envoi du fichier. Cela peut être dû à un fichier trop volumineux (max. 10Mo). Vérifiez et réessayez.",
            {
              variant: "error",
              persist: true,
              action: (snackbarId) => (
                <Button color="white" onClick={() => closeSnackbar(snackbarId)}>
                  {"Fermer"}
                </Button>
              ),
            }
          )
        }
        if (result.text === "ok") {
          enqueueSnackbar("Fichier envoyé !", { variant: "success" })
          dispatch(fetchConfig(formId, secret))
        }
      })
    }
  }

  const sendModes = () => {
    let modes = [
      {
        title: "Transfert",
        content: (
          <form encType="multipart/form-data">
            <Button disableElevation variant="contained" endIcon={<UploadRounded />} component="label">
              Upload
              <Input accept=".pdf, .jpg, .png" type="file" onChange={onChangeFile} sx={{ display: "none" }} />
            </Button>
          </form>
        ),
      },
    ]
    if (email)
      modes.push({
        title: "Email",
        content: <Typography color={"primary"}>{email}</Typography>,
      })
    if (!isMailEmpty)
      modes.push({
        title: "Voie postale",
        content: (
          <Typography textAlign={"center"}>
            {mail?.line1} <br />
            {mail?.line2 && (
              <>
                {mail?.line2}
                <br />
              </>
            )}{" "}
            {`${mail?.zip ?? ""} ${mail?.city ?? ""}`}
          </Typography>
        ),
      })
    return modes
  }

  return (
    <>
      <Typography variant={matches ? "h6" : "body1"}>{"Modes d'envoi"}</Typography>
      <Box sx={{ display: "flex", gap: 2, justifyContent: "space-evenly" }}>
        {sendModes().map(({ title, content }, index) => (
          <SendMode key={index} title={title} content={content} />
        ))}
      </Box>
    </>
  )
}
