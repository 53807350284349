import { FormControl, MenuItem, Select, Skeleton, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { getWorkshopsDb } from "../../../../redux/slices/workshopsSlice"
import { isEmpty } from "lodash"
import { getLocalRegistration, setChoice } from "../../../../redux/slices/localRegistrationSlice"

export function ChangeWorkshop({ order }) {
  const workshops = useSelector(getWorkshopsDb)
  const dispatch = useDispatch()
  const registration = useSelector(getLocalRegistration)

  if (isEmpty(registration) || workshops.length < 1)
    return (
      <Typography fontWeight={"bold"} sx={{ mr: 2 }}>
        <Skeleton width={100} sx={{ borderRadius: "6px" }} />
      </Typography>
    )

  const workshop = registration?.choices.find((choice) => choice.order === order)?.workshop

  const availableWorkshops =
    registration && workshops.length > 0
      ? workshops.filter(
          (ws) =>
            !registration.choices
              .map((choice) => choice.workshop._id)
              .filter((id) => id !== workshop._id)
              .includes(ws._id)
        )
      : []

  const setWorkshop = (event) => {
    dispatch(setChoice({ workshop: workshops.find((ws) => ws._id === event.target.value), order }))
  }

  return (
    <FormControl variant={"standard"} fullWidth sx={{ mr: 1 }} size="small">
      <Select disableUnderline sx={{ "& > .MuiInput-input": { pb: 0 } }} value={workshop._id} onChange={setWorkshop}>
        {availableWorkshops.map((ws, index) => (
          <MenuItem key={index} value={ws._id}>
            {ws.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
