import MagicTable from "../../components/magicTable/MagicTable"
import { Card, CardContent, IconButton, Tooltip } from "@mui/material"
import { SyncRounded } from "@mui/icons-material"
import ActivitiesSelect from "./ActivitiesSelect"
import { useDispatch, useSelector } from "react-redux"
import { fetchRegistrations, getExport } from "../../redux/slices/exportSlice"

import { useKeycloak } from "@react-keycloak/web"
import EventsSelect from "./EventsSelect"
import { useEffect } from "react"
import ExportButton from "./ExportButton"

export default function ExportTable() {
  const dispatch = useDispatch()
  const { keycloak } = useKeycloak()
  const { events, registrations } = useSelector(getExport)

  useEffect(() => {
    dispatch(fetchRegistrations(keycloak.token))
  }, [events.selected])

  const tools = [
    <EventsSelect key={3} />,
    <ActivitiesSelect key={2} />,
    <Tooltip key={1} title={"Recharger les demandes d'inscription"}>
      <IconButton sx={{ ml: 2 }} onClick={() => dispatch(fetchRegistrations(keycloak.token))}>
        <SyncRounded color="primary" />
      </IconButton>
    </Tooltip>,
    <ExportButton key={0} />,
  ]

  return (
    <Card sx={{ width: "100%" }}>
      <CardContent sx={{ pt: 1, pb: "0px!important" }}>
        <MagicTable
          toolbar={{ title: "Demandes d'inscriptions", tools: tools }}
          rows={registrations.rows ? [...registrations.rows] : []}
          cols={registrations.cols ? [...registrations.cols] : []}
          footer={{ pagination: true }}
        />
      </CardContent>
    </Card>
  )
}
