import { Box, Card, CardContent, CardHeader, TextField, Typography } from "@mui/material"
import { HiveRounded, NumbersRounded, TextFieldsRounded } from "@mui/icons-material"
import { useSelector } from "react-redux"
import { useState } from "react"
import { useQuery } from "@tanstack/react-query"

import { useKeycloak } from "@react-keycloak/web"
import { useSnackbar } from "notistack"
import _ from "lodash"
import apiClient from "../../../../configs/axiosCfg"
import { getInputsError, getUserContext } from "../../../../redux/slices/appSlice"
import { getEventLocal } from "../../../../redux/slices/eventSlice"

export default function AttributesInputs() {
  const { keycloak } = useKeycloak()
  const { enqueueSnackbar } = useSnackbar()
  const event = useSelector(getEventLocal)
  const userRole = useSelector(getUserContext).role
  const [activity, setActivity] = useState("")
  const inputsError = useSelector(getInputsError)

  // const getActivities = useQuery(
  useQuery(
    ["getActivity"],
    () =>
      apiClient.get(`/activities/${event.activityId}`, {
        headers: { Authorization: "Bearer " + keycloak.token },
      }),
    {
      onError: (error) => {
        console.error(error)
        enqueueSnackbar("Une erreur est survenue en récupérant les activités jeunes", {
          variant: "error",
        })
      },
      onSuccess: (result) => {
        if (result.data) {
          setActivity(result.data)
        }
      },
      enabled: userRole <= 1 && !!event.activityId,
    }
  )
  return (
    <Card elevation={0} sx={{ p: 1 }}>
      <CardHeader title={"Attributs"} titleTypographyProps={{ color: "shade.main" }} />
      {_.isArray(inputsError) ? (
        <CardContent sx={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
          <Box sx={{ flex: 1, display: "flex", alignItems: "flex-end" }}>
            <TextFieldsRounded
              sx={{
                color: "primary.bg",
                mr: 1,
                my: 0.5,
              }}
            />
            <TextField disabled label="Nom de l'évènement" value={event.name ?? ""} type="text" variant="standard" fullWidth />
          </Box>
          {userRole <= 1 && (
            <Box sx={{ flex: 1, display: "flex", alignItems: "flex-end" }}>
              <HiveRounded sx={{ color: "primary.bg", mr: 1, my: 0.5 }} />
              <TextField disabled value={activity.name ?? ""} type="text" variant="standard" label={"Activité jeunes"} fullWidth />
            </Box>
          )}
          <Box sx={{ flex: 1, display: "flex", alignItems: "flex-end" }}>
            <NumbersRounded
              sx={{
                color: "primary.bg",
                mr: 1,
                my: 0.5,
              }}
            />
            <TextField label="Nombre de choix max." value={event.selections ?? 3} disabled type="number" variant="standard" fullWidth />
          </Box>
          <Box sx={{ flex: 1, display: "flex", alignItems: "flex-end" }}>
            <NumbersRounded
              sx={{
                color: "primary.bg",
                mr: 1,
                my: 0.5,
              }}
            />
            <TextField label="Nombre de validation(s) max." disabled value={event.validations ?? 1} type="number" variant="standard" fullWidth />
          </Box>
        </CardContent>
      ) : (
        <CardContent>
          <Typography textAlign={"center"} />
          Loading...
        </CardContent>
      )}
    </Card>
  )
}
