import { Chip } from "@mui/material"
import { cloneElement } from "react"

export default function InfoChip(props) {
  const { icon, color = "default", label, chipColor = false, sx = {} } = props
  let computedSx = {
    px: 0.5,
    py: 2,
    ...sx,
  }
  if (chipColor) computedSx = { ...computedSx, backgroundColor: `${color}.bg`, color: `${color}.main` }
  return <Chip color={color} icon={cloneElement(icon, { fontSize: "small" })} label={label} sx={computedSx} />
}
