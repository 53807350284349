import { useNavigate, useParams } from "react-router-dom"
import { useKeycloak } from "@react-keycloak/web"
import { useDispatch, useSelector } from "react-redux"
import { fetchWorkshops, getWorkshopsDb, getWorkshopsLocal, putWorkshops, setWorkshops } from "../../../../redux/slices/workshopsSlice"
import { getEventDb } from "../../../../redux/slices/eventSlice"
import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { Box, Button, Typography } from "@mui/material"
import BasicConfirm from "../../../../components/dialogs/BasicConfirm"
import { isEqual } from "lodash"
import AttributesInputs from "./AttributesInputs"
import ArchiveButton from "./ArchiveButton"
import DeleteButton from "./DeleteButton"
import { WorkshopsList } from "./WorkshopsList"

export default function ChangeEvent() {
  const { eventId } = useParams()
  const navigate = useNavigate()
  const { keycloak } = useKeycloak()
  const dispatch = useDispatch()
  const workshops = useSelector(getWorkshopsLocal)
  const workshopsDb = useSelector(getWorkshopsDb)
  const eventDb = useSelector(getEventDb)
  // eslint-disable-next-line no-unused-vars
  const { enqueueSnackbar } = useSnackbar()
  const [errorMessage, setErrorMessage] = useState("")
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

  const dbLocalEqual = isEqual(workshopsDb, workshops)

  useEffect(() => {
    dispatch(fetchWorkshops(eventId, keycloak.token))
    return () => {
      dispatch(setWorkshops([]))
    }
  }, [])

  const handleClose = () => {
    navigate("../workshops")
  }

  const handleConfirmSaveEvent = (confirm) => {
    setConfirmDialogOpen(false)
    setErrorMessage("")
    if (confirm) {
      const workshopsToPut = workshops.filter((ws, index) => !isEqual(ws, workshopsDb[index]))
      dispatch(putWorkshops({ workshops: workshopsToPut }, eventId, keycloak.token)).then((result) => {
        if (result === "error") {
          return enqueueSnackbar(`Une erreur est survenue lors de la mise à jour des ateliers`, {
            variant: "warning",
          })
        }
        enqueueSnackbar("Ateliers mis à jour", { variant: "success" })
        handleClose()
      })
    }
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 4, mb: 4 }}>
      <AttributesInputs />
      <WorkshopsList />
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2, zIndex: 0 }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <Typography fontWeight={"bold"} color={`error.main`}>
            {errorMessage}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <Button variant="text" color="inherit" onClick={() => handleClose()} sx={{ top: dbLocalEqual ? -74 : 0, transition: "top 0.3s" }}>
            Annuler
          </Button>
          <Button onClick={() => setConfirmDialogOpen(true)} variant="contained" sx={{ top: dbLocalEqual ? -74 : 0, transition: "top 0.4s" }}>
            Valider
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1, mt: -4 }}>
        <ArchiveButton />
        {eventDb.archived && <DeleteButton />}
      </Box>
      <BasicConfirm open={confirmDialogOpen} onClose={handleConfirmSaveEvent} content={"Êtes-vous sûr de vouloir modifier l'évènement ?"} />
    </Box>
  )
}
