import { CakeRounded, SchoolRounded, WcRounded } from "@mui/icons-material"
import { Box, Chip, Typography } from "@mui/material"

export const WS_FILTERS_LABEL = {
  age: "Âge",
  grade: "Degré HarmoS",
  gender: "Genre",
}

export const WS_FILTERS_ICON = {
  age: <CakeRounded />,
  grade: <SchoolRounded />,
  gender: <WcRounded />,
}

export const GENDERS_ENUM = {
  0: "Indifférent",
  1: "Garçon",
  2: "Fille",
}

export const WS_FILTERS_CHIPS = (workshop) => {
  let filtersChips = []
  workshop["gender"] && filtersChips.push(<Chip key="genderFilter" label={GENDERS_ENUM[workshop["gender"]]} icon={WS_FILTERS_ICON["gender"]} />)
  workshop["age_min"] &&
    workshop["age_max"] &&
    filtersChips.push(<Chip key="ageFilter" label={`${workshop["age_min"]} - ${workshop["age_max"]}`} icon={WS_FILTERS_ICON["age"]} />)

  workshop["grade_min"] &&
    workshop["grade_max"] &&
    filtersChips.push(<Chip key="gradeFilter" label={`${workshop["grade_min"]} - ${workshop["grade_max"]}`} icon={WS_FILTERS_ICON["grade"]} />)

  return filtersChips
}

export const filterExplanations = (
  <Box sx={{ p: 2 }}>
    <Typography paragraph sx={{ lineHeight: 1, textAlign: "justify" }} color={"#ffffff"}>
      {"Les filtres permettent de vous aider dans l'évaluation des demandes d'inscription."}
    </Typography>
    <Typography paragraph sx={{ lineHeight: 1, textAlign: "justify" }} color={"#ffffff"}>
      {"Les données d'une demande d'inscription qui ne seront pas en adéquation avec les filtres seront mises en évidence."}
    </Typography>
    <Typography paragraph sx={{ lineHeight: 1, textAlign: "justify" }} color={"#ffffff"}>
      Laissez les champs sur 0 si vous ne voulez pas les activer.
    </Typography>
  </Box>
)
