import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useDispatch } from "react-redux"
import { fetchUsers, resetUsers } from "../../../../redux/slices/usersSlice"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { useKeycloak } from "@react-keycloak/web"
import ManagersList from "./ManagersList"
import UsersList from "./UsersList"

export default function UsersRole() {
  const { eventId } = useParams()
  const { keycloak } = useKeycloak()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery(useTheme().breakpoints.down("xl"))

  useEffect(() => {
    dispatch(fetchUsers(eventId, keycloak.token))
    return () => {
      dispatch(resetUsers())
    }
  }, [])

  return (
    <Box>
      <Typography textAlign={"center"} color={"shade.main"} variant="body2">
        {"Tous les utilisateurs affichés sur cette page ont accès à l'évènement"}
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: isMobile ? 2 : 4, mb: 4 }}>
        <ManagersList />
        <UsersList />
      </Box>
    </Box>
  )
}
