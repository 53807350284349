import { useKeycloak } from "@react-keycloak/web"
import { useDispatch, useSelector } from "react-redux"
import {
  deletePermissionActivity,
  fetchActivities,
  getActivities,
  postPermissionActivity,
  putActivity,
  setDbActivity,
} from "../../../../redux/slices/activitiesSlice"
import { useSnackbar } from "notistack"
import { differenceBy, forEach, isEqual } from "lodash"
import { Button, CardActions } from "@mui/material"

export function ActionsButtons() {
  const { keycloak } = useKeycloak()
  const { localActivity, dbActivity, archived } = useSelector(getActivities)
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const handlePutActivity = () => {
    const { _id, ...activity } = localActivity
    dispatch(putActivity(_id, { activity }, keycloak.token)).then((result) => {
      if (result.text === "ok") {
        return enqueueSnackbar("Mise à jour de l'activité effectuée", { variant: "success" })
      }
      if (result.text === "error") {
        return enqueueSnackbar("Erreur lors de la mise à jour de l'activité", {
          variant: "error",
        })
      }
    })
  }

  const handleDeletePermission = (user) => {
    dispatch(deletePermissionActivity(localActivity._id, user.permission._id, keycloak.token)).then((result) => {
      if (result.text === "ok") {
        dispatch(fetchActivities(keycloak.token, archived))
        return enqueueSnackbar(`Droits retirés pour ${user.name}`, {
          variant: "warning",
        })
      }
      if (result.text === "error") {
        return enqueueSnackbar("Erreur lors de la suppression des droits des managers", {
          variant: "error",
        })
      }
    })
  }

  const handleAddPermission = (user) => {
    dispatch(postPermissionActivity(localActivity._id, { user }, keycloak.token)).then((result) => {
      if (result.text === "ok") {
        dispatch(fetchActivities(keycloak.token, archived))
        return enqueueSnackbar(`Droits accordés à ${user.name}`, { variant: "success" })
      }
      if (result.text === "error") {
        return enqueueSnackbar("Erreur lors de l'ajout des managers", {
          variant: "error",
        })
      }
    })
  }

  const handleConfirm = () => {
    if (!isEqual(localActivity, dbActivity)) {
      if (localActivity.name !== dbActivity.name) handlePutActivity()
      forEach(differenceBy(localActivity.managers, dbActivity.managers, "email"), handleAddPermission)
      forEach(differenceBy(dbActivity.managers, localActivity.managers, "email"), handleDeletePermission)
    }
    dispatch(setDbActivity(null))
  }

  return (
    <CardActions sx={{ justifyContent: "flex-end" }}>
      <Button variant="text" onClick={() => dispatch(setDbActivity(null))}>
        Cancel
      </Button>
      <Button variant="contained" onClick={handleConfirm}>
        Appliquer
      </Button>
    </CardActions>
  )
}
