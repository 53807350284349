import { Box, Card, CardContent, CardHeader, TextField, Typography, useMediaQuery, useTheme } from "@mui/material"
import { getMainForm, jumpState, setRegField } from "../../../redux/slices/mainFormSlice"
import { useDispatch, useSelector } from "react-redux"
import { Lang, useFormInputValidation } from "react-form-input-validation"
import { translateRegLabel } from "../../../utils/constants/registrations"
import Stepper from "./Stepper"
import { MuiPhoneInput } from "../../../components/inputs/MuiPhoneInput"
import GenderSF from "../inputs/GenderSF"
import * as React from "react"

export default function ChildStep({ index }) {
  const { childCount, regs, config } = useSelector(getMainForm)
  const dispatch = useDispatch()

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("sm"))

  // same as GlobalStep but with regs instead of mainForm
  const getVal = (v) => {
    const reduxValue = regs[index][v.split("_")[0]][v.split("_")[1]]
    if (reduxValue) return reduxValue
    const sessionValue = JSON.parse(sessionStorage.getItem(`childStep${index}`))?.[v]
    if (sessionValue) return sessionValue
    return ""
  }

  const partValueReducer = (part) => Object.keys(config.mainForm.child[part]).reduce((a, v) => ({ ...a, [v]: getVal(v) }), {})

  const partConfigReducer = (part) =>
    Object.keys(config.mainForm.child[part]).reduce((a, v) => ({ ...a, [v]: config.mainForm.child[part][v].config }), {})

  const [fields, errors, form] = useFormInputValidation(
    Object.keys(config.mainForm.child).reduce((a, v) => ({ ...a, ...partValueReducer(v) }), {}), // manipulate the config object to create the initial values object
    Object.keys(config.mainForm.child).reduce((a, v) => ({ ...a, ...partConfigReducer(v) }), {}) // manipulate the config object to create the validation rules object
  )

  form.useLang("fr")
  form.setMessages(Lang.fr, { ...form.getMessages(Lang.fr), required: "Ce champ est requis" })
  form.setMessages(Lang.fr, { ...form.getMessages(Lang.fr), min: "Doit être supérieur à 0" })

  const onChange = (event) => {
    sessionStorage.setItem(`childStep${index}`, JSON.stringify(fields))
    form.validate(event)
  }

  const nextStep = (event) => {
    form.validate(event).then((isValid) => {
      if (isValid) {
        Object.keys(fields).forEach((field) => {
          dispatch(
            setRegField({
              index: index,
              field: field.split("_")[0],
              subField: field.split("_")[1],
              value: fields[field],
            })
          )
        })
        dispatch(jumpState(1))
      }
    })
    // event
    // dispatch(jumpState(1))
  }

  return (
    <form onChange={onChange} noValidate autoComplete="off" onSubmit={nextStep}>
      <Stepper />
      <Box sx={{ my: 2 }} />
      <Card sx={{ p: 1 }}>
        <CardHeader
          title={
            <>
              <Typography variant={matches ? "inherit" : "h6"} gutterBottom>
                {`Enfant ${childCount > 1 ? index + 1 : ""}`}
              </Typography>
              <Typography variant="sub6">{`Veuillez saisir les informations personnelles de l'enfant ${childCount > 1 ? index + 1 : ""}`}</Typography>
            </>
          }
        />
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
        >
          {Object.keys(config.mainForm.child).map((field, index) => (
            <Box key={index} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Typography variant="h6" sx={{ mb: 2, textTransform: "capitalize" }}>
                {translateRegLabel[field]}
              </Typography>
              <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", justifyContent: "space-between" }}>
                {Object.keys(config.mainForm.child[field]).map((subField, index) => {
                  const input = config.mainForm.child[field][subField]
                  return input?.special === "phone" ? (
                    <MuiPhoneInput
                      key={index}
                      error={!!errors[subField]}
                      field={subField}
                      fields={fields}
                      required={input?.config?.includes("required")}
                      form={form}
                      sx={{ width: matches ? "48%" : "100%", textTransform: "capitalize" }}
                    />
                  ) : input?.special === "gender" ? (
                    <GenderSF
                      key={index}
                      sx={{ width: matches ? input.width ?? "48%" : "100%" }}
                      name={subField}
                      label={translateRegLabel[subField.split("_")[1]]}
                      value={fields[subField] ?? ""}
                      onBlur={form.handleBlurEvent}
                      onchange={form.handleChangeEvent}
                      error={!!errors[subField]}
                      helperText={errors[subField] ?? " "}
                      required={input?.config?.includes("required")}
                      InputLabelProps={{ sx: { textTransform: "capitalize" } }}
                    />
                  ) : (
                    <TextField
                      key={index}
                      sx={{ width: matches ? input.width ?? "48%" : "100%" }}
                      name={subField}
                      type={input.type}
                      label={translateRegLabel[subField.split("_")[1]]}
                      InputLabelProps={{
                        sx: { textTransform: "capitalize" },
                        shrink: subField === "child_birthdate" || !!fields[subField],
                      }}
                      value={fields[subField] ?? ""}
                      onBlur={form.handleBlurEvent}
                      onChange={form.handleChangeEvent}
                      error={!!errors[subField]}
                      helperText={errors[subField] ?? " "}
                      required={input.config.includes("required")}
                    />
                  )
                })}
              </Box>
            </Box>
          ))}
        </CardContent>
      </Card>
      {!matches && <Box sx={{ mt: 8 }} />}
    </form>
  )
}
