import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material"
import { useEffect, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import apiClient from "../../../configs/axiosCfg"
import { useKeycloak } from "@react-keycloak/web"
import EventsTable from "./eventsTable/EventsTable"
import { longDateDisplay } from "../../../utils/dates"
import { Search } from "@mui/icons-material"

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Nom de l'activité",
  },
  {
    id: "dates",
    numeric: false,
    disablePadding: true,
    label: "Dates",
  },
  {
    id: "Demandes en attente",
    numeric: false,
    disablePadding: true,
    label: "Demandes en attente",
  },
]

export default function EventsList(props) {
  const { archived = false } = props
  const { keycloak } = useKeycloak()
  // const [dateRanges, setDateRanges] = useState({ from: null, to: null })
  const [selectedtime, setSelectedtime] = useState("")
  const [searchName, setSearchName] = useState("")

  const computeQueryUrl = () => {
    let url = "/events"
    let params = []
    if (selectedtime === "past") params.push(`to=${new Date().toISOString()}`)
    else if (selectedtime === "active") params.push(`from=${new Date().toISOString()}`)
    // if (dateRanges.to) params.push(`to=${dateRanges.to}`)
    if (archived) params.push(`archived=${archived}`)
    if (params.length > 0) {
      url += `?${params.join("&")}`
    }
    return url
  }

  const { status, error, data, refetch } = useQuery(["getEventsList"], () =>
    apiClient.get(computeQueryUrl(), { headers: { Authorization: "Bearer " + keycloak.token } })
  )

  useEffect(() => {
    setSearchName("")
    refetch()
  }, [archived, selectedtime])

  const handleToggleTime = (event) => {
    if (event.target.value === selectedtime) {
      setSelectedtime("")
    } else {
      setSelectedtime(event.target.value)
    }
  }

  const listActions = [
    <ToggleButtonGroup key={0} size="small" sx={{ height: 40 }} color="heig" value={selectedtime} onClick={handleToggleTime}>
      <ToggleButton value={"past"}>Passés</ToggleButton>
      <ToggleButton value={"active"}>Actifs</ToggleButton>
    </ToggleButtonGroup>,
    <TextField
      key={1}
      placeholder={"Rechercher..."}
      value={searchName}
      onChange={(event) => setSearchName(event.target.value.trim())}
      size={"small"}
      color="shade"
      sx={{
        alignItems: "center",
        ml: 1,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
    />,
  ]

  let rows = []

  switch (status) {
    case "loading":
      return <CircularProgress color="heig" />
    case "error":
      return <Typography color="heig.main">{`Erreur : ${JSON.stringify(error.response)}`}</Typography>
    case "success":
      if (data.data.length > 0) rows = data.data.filter((row) => row.name.toLowerCase().includes(searchName.toLowerCase()))
      if (rows.length > 0) rows = rows.map((row) => ({ ...row, dates: longDateDisplay(row.start, row.end) }))
      return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
          <Card elevation={0}>
            <CardHeader title={"Évènements"} sx={{ filter: "none", background: "white", px: 1 }} action={listActions} />
            <CardContent sx={{ p: 0 }}>
              {rows.length > 0 ? (
                <EventsTable headCells={headCells} rows={rows} />
              ) : (
                <Typography sx={{ p: 1, mt: 3 }} variant="h6" textAlign={"center"}>
                  Aucun évènement à afficher
                </Typography>
              )}
            </CardContent>
          </Card>
          <Box></Box>
        </Box>
      )
  }
}
