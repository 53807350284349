import { Box, Divider, Typography } from "@mui/material"

export default function CustomDTPInput(props) {
  const { error, InputProps, inputRef, inputProps, label, width = "auto" } = props
  return (
    <Box sx={{ width: width }}>
      <Typography variant="body2" sx={{ mb: -1 }}>
        {label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
          alignItems: "center",
          pt: 1,
          pb: 0.3,
        }}
      >
        <Typography>{inputProps.value ? inputProps.value : inputProps.placeholder}</Typography>
        {InputProps.endAdornment}
      </Box>
      <Divider ref={inputRef} color={error ? "error.main" : "#AAAAAA"} sx={{ mb: 1 }} />
    </Box>
  )
}
