import { cloneDeep, intersection, isEqual } from "lodash"
import {
  basicConsentBloc,
  contentHeader,
  dietBloc,
  docBlocsData,
  docHead,
  docSign,
  personBloc,
} from "../components/inputs/createConsentFile/docBlocs"
import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
import PDFMerger from "pdf-merger-js"
import { CONSENT_FIELDS } from "./constants/forms"
import { slugify } from "./utils"

pdfMake.vfs = pdfFonts.pdfMake.vfs

export const getEditedFormProperties = (formDb, formLocal) => {
  let formToSave = {}
  Object.keys(formLocal).forEach((property) => {
    if (!isEqual(formDb[property], formLocal[property])) {
      // A GARDER !!!! PERMET DE RÉCUPÉRER LA DIFFÉRENCE JUSQU'AU NIVEAU 2
      // if (isPlainObject(formLocal[property])) {
      //   Object.keys(formLocal[property]).forEach((subProperty) => {
      //     if (!isEqual(formDb[property][subProperty], formLocal[property][subProperty])) {
      //       formToSave[property] = {
      //         ...formToSave[property],
      //         [subProperty]: formLocal[property][subProperty],
      //       }
      //     }
      //   })
      // } else {
      formToSave[property] = formLocal[property]
      // }
    }
  })
  return { form: { ...formToSave, event: formLocal.event } }
}

export const isFileNeeded = (inputs, reg) =>
  Object.keys(inputs).filter((input) => CONSENT_FIELDS.includes(input) && inputs[input] === 1 && reg?.[input] === true)

export const eventNeedFile = (inputs) => CONSENT_FIELDS.some((field) => !!inputs[field])
export const regNeedFile = (reg) => CONSENT_FIELDS.some((field) => !!reg[field])
export const regNeededFields = (reg) => CONSENT_FIELDS.filter((field) => !!reg[field])

export const cleanProperties = (obj) => {
  let newObj = cloneDeep(obj)
  Object.keys(newObj).forEach((k) => {
    newObj[k] = typeof newObj[k] == "string" ? newObj[k].trim() : newObj[k]
  })
  return newObj
}

export const getDocDef = (event, logos, child, neededFields, filename) => {
  let content = []

  content.push(contentHeader(event.name, logos))
  content.push(personBloc(docBlocsData.child.title, child ?? docBlocsData.child.person))
  content.push(personBloc(docBlocsData.parent.title, docBlocsData.parent.person))

  if (neededFields.includes("risks_consent"))
    content.push(basicConsentBloc(docBlocsData.risks_consent.title, docBlocsData.risks_consent.description, docBlocsData.risks_consent.checkLabel))

  if (neededFields.includes("media_consent"))
    content.push(basicConsentBloc(docBlocsData.media_consent.title, docBlocsData.media_consent.description, docBlocsData.media_consent.checkLabel))

  if (neededFields.includes("transportation"))
    content.push(basicConsentBloc(docBlocsData.transportation.title, docBlocsData.transportation.description, docBlocsData.transportation.checkLabel))

  if (neededFields.includes("diet")) content.push(dietBloc())

  content.push(docSign(event.name, event.start, event.end))

  return {
    ...docHead(filename),
    content,
  }
}

export const getConsentUnil = async (unilDoc) => {
  let file
  switch (unilDoc) {
    case "mint":
      file = await fetch("/unil_mint.pdf")
      break
    default:
      file = await fetch("/consentUnil.pdf")
  }
  return await file.arrayBuffer()
}

export const getConsentPDF = async (event, child, neededFields, logos, unilDoc) => {
  if (!neededFields.length) return null
  const pdfName = slugify(`consent-${event.name}-${child.firstname}-${child.lastname}`)
  const merger = new PDFMerger()
  const intersectionNF = intersection(CONSENT_FIELDS, neededFields)
  if (intersectionNF.length > 0)
    pdfMake.createPdf(getDocDef(event, logos, child, intersectionNF, pdfName)).getBuffer(async (pdf) => {
      await merger.add(pdf)
      if (neededFields.includes("unil_survey")) await merger.add(await getConsentUnil(unilDoc))
      await merger.save(pdfName)
    })
  else if (neededFields.includes("unil_survey")) {
    await merger.add(await getConsentUnil())
    await merger.saver(pdfName)
  }
}
