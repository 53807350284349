import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import WrapperApiAdPeopleToken from "../../../components/WrapperApiAdPeopleToken"
import AutocompleteAdPeople from "../../../components/inputs/AutoCompleteAdPeople"
import _ from "lodash"
import { useDispatch, useSelector } from "react-redux"
import { addManager, deleteManager, getLocalActivity } from "../../../redux/slices/activitiesSlice"

export function AddManagers() {
  const dispatch = useDispatch()
  const localActivity = useSelector(getLocalActivity)
  const isMobile = useMediaQuery(useTheme().breakpoints.down("md"))

  const handleAddManager = (newManager) => {
    if (newManager)
      if (!localActivity.managers.map((man) => man.email).includes(newManager.email))
        dispatch(addManager(newManager))
  }

  const handleDeleteManager = (managerEmail) => dispatch(deleteManager(managerEmail))

  return (
    <Card sx={{ p: 1 }}>
      <CardHeader title={"Manager(s) pour la nouvelle activité"} />
      <CardContent
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          flexWrap: "wrap",
          gap: 4,
        }}
      >
        <WrapperApiAdPeopleToken>
          <AutocompleteAdPeople
            callback={handleAddManager}
            textInputLabel="Ajouter un collaborateur"
          />
        </WrapperApiAdPeopleToken>
        {!_.isEmpty(localActivity?.managers) ? (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {localActivity.managers.map((manager, mIndex) => (
              <Chip
                key={mIndex}
                label={manager.name}
                onDelete={() => handleDeleteManager(manager.email)}
              />
            ))}
          </Box>
        ) : (
          <Typography textAlign="center">Aucun manager</Typography>
        )}
      </CardContent>
    </Card>
  )
}
