import { EventRounded } from "@mui/icons-material"
import { Box, Grid, Typography } from "@mui/material"
import { workshopDateFormDisplay } from "../../utils/dates"

export default function WorkshopDateRange({ start, end }) {
  const dates = workshopDateFormDisplay(start, end)
  return (
    <Box>
      <Grid container lineHeight={1} wrap="nowrap">
        <Grid item>
          <EventRounded sx={{ width: "16px", height: "16px", mr: 0.5, ml: "-2px" }} color="shade" />
        </Grid>
        <Grid item>
          <Typography variant="caption" color={"shade.main"} sx={{ ":first-letter": { textTransform: "capitalize" } }}>
            {dates[0]}
          </Typography>
        </Grid>
      </Grid>
      {dates.length > 1 && (
        <Grid container lineHeight={1} wrap="nowrap" mt={-0.5}>
          <Grid item>
            <Box sx={{ width: "16px", height: "16px", mr: 0.5, ml: "-2px" }} />
          </Grid>
          <Grid item>
            <Typography variant="caption" color={"shade.main"} sx={{ ":first-letter": { textTransform: "capitalize" } }}>
              {dates[1]}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  )
}
