import { Box, Table, TableBody, TableCell, TableContainer, TableRow, ThemeProvider, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { getComparator } from "../../utils/tablesHelpers"
import MagicBody from "./MagicBody"
import MagicHead from "./MagicHead"
import MagicToolbar from "./MagicToolbar"
import MagicFooter from "./MagicFooter"
import { MTTheme } from "../../utils/theme/magicTable"

export default function MagicTable({
  toolbar = null,
  cols = null,
  rows = null,
  colsOptions = null,
  footer = null,
  addRow = null,
  small = false,
  cellFullLength = false,
  sx = {},
}) {
  /////////////////////////// Sorting MHead ////////////////////////////////////
  const [sortBy, setSortBy] = useState(6) // Sorting : column
  const [orderBy, setOrderBy] = useState("desc") // Sorting : Order
  const [tableSorted, setTableSorted] = useState(false)
  const [openSR, setOpenSR] = useState(false) // true if at least one SR is open

  const colsSorting = (event, property) => {
    const isAsc = sortBy === property && orderBy === "asc"
    setOrderBy(isAsc ? "desc" : "asc")
    setSortBy(property)
  }

  // Callback called when openCollapse state of MagicBody updated
  const retrieveOpenSR = (ocSR) => {
    setOpenSR(ocSR)
    tableSorted && setTableSorted(false)
  }

  // Update table display and signal MagicBody to close sub rows
  useEffect(() => {
    !tableSorted && setTableSorted(true)
  }, [sortBy, orderBy, rows])

  //////////////////////////////////////////////////////////////////////////////
  /////////////////////////// Pagination MFooter ///////////////////////////////
  const [page, setPage] = useState(0) // Pagination
  const [rowsPerPage, setRowsPerPage] = useState(footer?.pagination ? 10 : 100) // Pagination

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  //////////////////////////////////////////////////////////////////////////////

  const isSubRows = rows.filter((row) => !!row.subRow).length > 0

  const isActionsRows = rows.filter((row) => !!row.actions).length > 0

  return (
    <Box id={`MT-container`} sx={sx}>
      <ThemeProvider theme={MTTheme}>
        {/* TOOLBAR */}
        {!!toolbar && <MagicToolbar title={toolbar.title} tools={toolbar.tools} small={small} />}
        {/* TABLE */}
        {!!cols && !!rows ? (
          <>
            <TableContainer>
              <Table sx={{ borderCollapse: "separate", borderSpacing: "0px 4px", minWidth: "400px" }} size={small ? "small" : "medium"}>
                <MagicHead
                  cols={cols}
                  addRow={addRow}
                  rowActionsCol={isActionsRows}
                  isSubRows={isSubRows}
                  orderBy={orderBy}
                  colsSorting={colsSorting}
                  sortBy={sortBy}
                  colsOptions={colsOptions}
                />
                {rows.length > 0 ? (
                  <MagicBody
                    rows={rows.sort(getComparator(orderBy, sortBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                    colsOptions={colsOptions}
                    resetOpenSR={tableSorted && openSR}
                    openSRCallback={retrieveOpenSR}
                    cellFullLength={cellFullLength}
                  />
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">Aucune donnée à afficher</TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            {!!footer && (
              <MagicFooter
                pagination={footer.pagination ? footer.pagination : false}
                rowsPerPage={rowsPerPage}
                page={page}
                setRowsPerPage={handleChangeRowsPerPage}
                setPage={handleChangePage}
                count={rows.length}
                tools={footer.tools ? footer.tools : []}
                small={small}
              />
            )}
          </>
        ) : (
          <Typography>You must pass cols and rows to Magic Table</Typography>
        )}
      </ThemeProvider>
    </Box>
  )
}
