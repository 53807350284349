import { useKeycloak } from "@react-keycloak/web"
import apiClient from "../../configs/axiosCfg"
import { useSelector } from "react-redux"
import { useSnackbar } from "notistack"
import { FileDownloadRounded } from "@mui/icons-material"
import { Button } from "@mui/material"
import { getRegistration } from "../../redux/slices/registrationSlice"

import axios from "axios"

export default function ConsentFileDownload() {
  const { keycloak } = useKeycloak()
  const { enqueueSnackbar } = useSnackbar()
  const registration = useSelector(getRegistration)

  const handleDownload = async () => {
    try {
      const { data } = await axios({
        method: "get",
        url: `${apiClient.defaults.baseURL}registrations/${registration?._id}/file`,
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
        },
      })
      const link = document.createElement("a")
      link.href = URL.createObjectURL(new Blob([data]))
      link.setAttribute("download", registration.consent_filename)
      link.style.display = "none"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (err) {
      enqueueSnackbar("Une erreur est survenue lors du téléchargement du fichier", {
        variant: "error",
      })
      console.error(err)
    }
  }

  return (
    <Button
      disableElevation
      onClick={handleDownload}
      size="small"
      startIcon={<FileDownloadRounded />}
      disabled={!registration?.consent_filename}
      variant="contained"
    >
      Signée
    </Button>
  )
}
