import { Box, Card, CardContent } from "@mui/material"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useKeycloak } from "@react-keycloak/web"
import { useSnackbar } from "notistack"
import EventStats from "./EventStats"
import RegsTable from "../../../../components/registrationsTable/RegsTable"
import RegistrationDetails from "../../../../components/registrationDetailsDialog/RegistrationDetails"
import { fetchRegistrations, getRegistrationsState, resetRegistrations } from "../../../../redux/slices/registrationsSlice"
import { useParams } from "react-router-dom"
import RegsStatusSelect from "../../../../components/inputs/RegsStatusSelect"
import SearchInput from "../../../../components/inputs/SearchInput"

export default function EventRegistrations() {
  const { eventId } = useParams()
  const dispatch = useDispatch()
  const { selectedReg } = useSelector(getRegistrationsState)
  const { enqueueSnackbar } = useSnackbar()
  const { keycloak } = useKeycloak()

  useEffect(() => {
    dispatch(fetchRegistrations(`/events/${eventId}/registrations`, keycloak.token)).then((result) => {
      if (result === "error")
        return enqueueSnackbar("Une erreur est survenue lors de la récupération des inscriptions", {
          variant: "error",
        })
    })
    return () => {
      dispatch(resetRegistrations())
    }
  }, [])

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 4, mb: 4 }}>
      <EventStats />
      <Card>
        <CardContent sx={{ pt: 1, pb: "0px!important" }}>
          <RegsTable tools={[<RegsStatusSelect key={0} />, <SearchInput key={1} />]} />
        </CardContent>
      </Card>
      {selectedReg && <RegistrationDetails />}
    </Box>
  )
}
