import { createSlice } from "@reduxjs/toolkit"
import apiClient from "../../configs/axiosCfg"

const initialState = {}

export const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    setRegistration: (state, action) => {
      return action.payload
    },
    setAttribute: (state, action) => {
      return { ...state, [action.payload.key]: action.payload.value }
    },
  },
})

export const { setRegistration, setAttribute } = registrationSlice.actions

export const getRegistration = (state) => state.registration

export const fetchRegistration = (regId, kToken) => async (dispatch) =>
  apiClient
    .get(`/registrations/${regId}`, {
      headers: { Authorization: "Bearer " + kToken },
    })
    .then((response) => {
      dispatch(setRegistration(response.data))
    })
    .catch((error) => {
      console.error(error.response.data)
      return error
    })
// dispatch(pushOne(response.data))

export const postConsentFile = (regId, body, kToken) => async () =>
  await apiClient
    .post(`/registrations/${regId}/file`, body, {
      headers: { Authorization: "Bearer " + kToken },
    })
    .then((res) => {
      return { status: res.status, text: "ok" }
    })
    .catch((error) => {
      console.error(error)
      return { message: error, text: "error" }
    })

export default registrationSlice.reducer
