import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material"

export default function TshirtSizeRF({ value, important, onChange, error }) {
  return (
    !!important && (
      <FormControl error={!!error} required={!!important} name={"tshirt_size"}>
        <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
          <FormLabel sx={{ fontWeight: "bold" }}>Taille de t-shirt</FormLabel>
          <Typography sx={{ fontWeight: "bold" }}>:</Typography>
          <Select
            size="small"
            sx={{ width: "10ch" }}
            value={value ?? "?"}
            name={"tshirt_size"}
            onChange={onChange}
          >
            <MenuItem value={"XS"}>XS</MenuItem>
            <MenuItem value={"S"}>S</MenuItem>
            <MenuItem value={"M"}>M</MenuItem>
            <MenuItem value={"L"}>L</MenuItem>
            <MenuItem value={"XL"}>XL</MenuItem>
          </Select>
        </Box>
        <FormHelperText sx={{ my: -1 }} color={"error"}>
          {error ?? " "}
        </FormHelperText>
      </FormControl>
    )
  )
}
