import { FileUploadRounded } from "@mui/icons-material"
import { Button, Input } from "@mui/material"
import { useSnackbar } from "notistack"
import { useDispatch, useSelector } from "react-redux"
import { fetchRegistration, getRegistration, postConsentFile } from "../../redux/slices/registrationSlice"
import { useKeycloak } from "@react-keycloak/web"
import { find, isUndefined } from "lodash"
import { useEffect, useState } from "react"

export default function ConsentFileUpload() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const { keycloak } = useKeycloak()
  const registration = useSelector(getRegistration)
  const [reg, setReg] = useState(undefined)

  useEffect(() => {
    setReg(
      registration.multi
        ? find([registration, ...registration.multi], (reg) => reg?.admitted_status >= 1 && reg?.status === 2)
        : registration?.admitted_status >= 1 && registration?.status === 2
        ? registration
        : undefined
    )
  }, [registration])

  const onChangeFile = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0]
      const formData = new FormData()
      formData.append(file.name, file)
      dispatch(postConsentFile(reg._id, formData, keycloak.token)).then((result) => {
        if (result.text === "error") {
          return enqueueSnackbar(
            "Une erreur est survenue lors de l'envoi du fichier. Cela peut être dû à un fichier trop volumineux (max. 10Mo). Vérifiez et réessayez.",
            {
              variant: "error",
              persist: true,
              action: (snackbarId) => (
                <Button color="white" onClick={() => closeSnackbar(snackbarId)}>
                  {"Fermer"}
                </Button>
              ),
            }
          )
        }
        if (result.text === "ok") {
          enqueueSnackbar("Fichier sauvegardé !", { variant: "success" })
          dispatch(fetchRegistration(reg._id, keycloak.token))
        }
      })
    }
  }

  return (
    <form encType="multipart/form-data">
      <Button disableElevation component="label" variant="contained" size="small" startIcon={<FileUploadRounded />} disabled={isUndefined(reg)}>
        Upload
        <Input accept=".pdf, .jpg, .png" type="file" onChange={onChangeFile} sx={{ display: "none" }} />
      </Button>
    </form>
  )
}
