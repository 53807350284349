import { Box, Typography } from "@mui/material"
import PropTypes from "prop-types"
import { BR_LARGE } from "../../../../../../../../utils/theme/app"

export default function IconNumber(props) {
  const { num, color } = props
  return (
    <Box
      sx={{
        borderRadius: BR_LARGE,
        backgroundColor: color ? `${color}.main` : "primary.main",
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "inherit",
      }}
    >
      <Typography
        variant="body2"
        sx={{ verticalAlign: "middle", width: "3ch", display: "flex", justifyContent: "center" }}
        color={color ? `${color}.bg` : "primary.bg"}
      >
        {num}
      </Typography>
    </Box>
  )
}

IconNumber.propTypes = {
  num: PropTypes.any,
  color: PropTypes.string,
}
