/* eslint-disable no-unused-vars */
import { Box } from "@mui/material"
import { useSelector } from "react-redux"
import { getUserContext } from "../../redux/slices/appSlice"
import { isEmpty } from "lodash"
import ActionIconButton from "./ActionIconButton"
import { getEventDb } from "../../redux/slices/eventSlice"
import { useParams } from "react-router-dom"

export default function HeaderActions({ actions }) {
  const { role: userRole, permissions } = useSelector(getUserContext)
  const { eventId } = useParams()
  const { activityId } = useSelector(getEventDb)

  if (isEmpty(actions)) return null

  const actionsForUser = () => {
    if (userRole === 0) return () => true
    if (eventId) {
      if (userRole === 1) {
        const activityPerm = permissions.filter((p) => p.type === "activity" && p.resource === activityId)?.[0]
        if (activityPerm) {
          return (a) => a.user_role >= 1 && a.resource_role <= activityPerm.role
        }
      }
      if (userRole <= 2) {
        const eventPerm = permissions.filter((p) => p.type === "event" && p.resource === eventId)?.[0]
        if (eventPerm) {
          return (a) => a.user_role >= 2 && a.resource_role <= eventPerm.role
        }
      }
      return () => false
    } else {
      return (a) => a.user_role >= userRole
    }
  }

  return (
    <Box
      id="PH-actions"
      sx={{
        flex: "auto",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "stretch",
        gap: 0.5,
      }}
    >
      {actions.filter(actionsForUser()).map((action, index) => (
        <ActionIconButton key={index} action={action} />
      ))}
    </Box>
  )
}
