import { createSlice } from "@reduxjs/toolkit"
import { cloneDeep } from "lodash"
import apiClient from "../../configs/axiosCfg"

const initialState = {
  config: {},
  dbReg: {},
}

export const adFormSlice = createSlice({
  name: "adForm",
  initialState,
  reducers: {
    initState: () => {
      return initialState
    },
    setField: (state, { payload: { superField, field, newValue } }) => {
      const newState = cloneDeep(state)
      if (!superField) newState[field] = newValue
      else newState[superField][field] = newValue
      return newState
    },
  },
})

export const { initState, setField } = adFormSlice.actions

export const getAdForm = (state) => state.adForm

export const fetchConfig = (formId, secret) => async (dispatch) =>
  apiClient
    .get(`/forms/${formId}/admissions/${secret}`, {
      headers: {
        "x-api-secret": "HZkByMDYwbZiBM3hYbNjNGT2ex3kW2DZf7J3EMFB4Ti4X9VmaARKwvtdPKKyEALf33nkT7A9YENcmvmhUu2gJX3BEupdrRzqZtLf",
      },
    })
    .then((response) => {
      if (response.status === 204) {
        return { status: 204, text: "empty" }
      }
      dispatch(setField({ field: "config", newValue: response.data.form }))
      dispatch(setField({ field: "dbReg", newValue: response.data.registration }))

      return { text: "ok", status: response.status }
    })
    .catch((error) => {
      return {
        text: "error",
        error,
      }
    })

export const putReg = (regId, body) => async (dispatch) =>
  apiClient
    .put(`/registrations/${regId}/admissions`, body, {
      headers: {
        "x-api-secret": "HZkByMDYwbZiBM3hYbNjNGT2ex3kW2DZf7J3EMFB4Ti4X9VmaARKwvtdPKKyEALf33nkT7A9YENcmvmhUu2gJX3BEupdrRzqZtLf",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch(setField({ field: "dbReg", newValue: response.data }))
        return "ok"
      } else return "error"
    })
    .catch((error) => {
      console.error(error.response.data)
      return "error"
    })

export const postFile = (regId, secret, body) => async () =>
  apiClient
    .post(`/registrations/${regId}/admissions/${secret}/file`, body, {
      headers: {
        "x-api-secret": "HZkByMDYwbZiBM3hYbNjNGT2ex3kW2DZf7J3EMFB4Ti4X9VmaARKwvtdPKKyEALf33nkT7A9YENcmvmhUu2gJX3BEupdrRzqZtLf",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return { status: response.status, text: "ok" }
      } else return { status: response.status, text: "error" }
    })
    .catch((error) => {
      console.error(error)
      return { message: error.status, text: "error" }
    })

export default adFormSlice.reducer
