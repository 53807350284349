import { Card, CardContent, CardHeader } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { getActivities, setLocalActivity } from "../../../../redux/slices/activitiesSlice"
import AttributesInputs from "./AttributesInputs"
import { Managers } from "./Managers"
import { useEffect } from "react"
import { ActionsButtons } from "./ActionsButtons"

export default function EditActivityCard() {
  const dispatch = useDispatch()
  const { dbActivity } = useSelector(getActivities)

  useEffect(() => {
    dispatch(setLocalActivity(dbActivity))
  }, [dbActivity])

  return (
    <Card sx={{ p: 1, ml: 4, minWidth: "400px" }}>
      <CardHeader title={"Modifier l'activité"} />
      <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <AttributesInputs />
        <Managers />
      </CardContent>
      <ActionsButtons />
    </Card>
  )
}
