import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { getRegistration } from "../../../../redux/slices/registrationSlice"
import { isEmpty } from "lodash"
import { useEffect } from "react"
import { fetchWorkshops, getWorkshopsDb } from "../../../../redux/slices/workshopsSlice"
import { useKeycloak } from "@react-keycloak/web"
import { useNavigate, useParams } from "react-router-dom"
import { ChangeWorkshop } from "./ChangeWorkshop"

export default function Choices({ editMode }) {
  const { eventId } = useParams()
  const registration = useSelector(getRegistration)
  const workshops = useSelector(getWorkshopsDb)
  const dispatch = useDispatch()
  const { keycloak } = useKeycloak()
  const navigate = useNavigate()

  useEffect(() => {
    if (workshops.length === 0) dispatch(fetchWorkshops(eventId, keycloak.token))
  }, [])

  const isSelected = (order) => {
    if (!registration) return false
    if (registration?.selected_choice === order) return true
    if (registration.multi) if (registration.multi.length > 0) if (registration.multi.map((reg) => reg?.selected_choice).includes(order)) return true
    return false
  }

  const onClickWorkshop = (choice) => {
    if (editMode) return
    console.log("onClickWorkshop", choice)
    navigate(`/dashboard/events/${eventId}/workshops/${choice.workshop._id}`)
  }

  return !isEmpty(registration) && workshops.length > 0 ? (
    <Card>
      <CardHeader title={"Choix des ateliers"} />
      <CardContent sx={{ pt: 1, pb: "16px!important", display: "flex", flexWrap: "wrap", gap: 2 }}>
        {!isEmpty(registration) &&
          registration.choices.map((choice, index) => {
            const selected = isSelected(choice.order)
            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  gap: 1,
                  backgroundColor: selected ? "success.bg" : "primary.bg",
                  borderRadius: 1,
                  alignItems: "center",
                  cursor: !editMode ? "pointer" : "default",
                }}
                onClick={() => onClickWorkshop(choice)}
              >
                <Box
                  sx={{
                    borderRadius: 1,
                    backgroundColor: selected ? "success.main" : "primary.main",
                    color: "white!important",
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minWidth: "4ch",
                    height: "4ch",
                    width: "4ch",
                  }}
                >
                  {choice.order}
                </Box>
                {editMode ? (
                  <ChangeWorkshop order={choice.order} />
                ) : (
                  <Typography fontWeight={"bold"} sx={{ mr: 2 }}>
                    {choice.workshop.name}
                  </Typography>
                )}
              </Box>
            )
          })}
      </CardContent>
    </Card>
  ) : null
}
