import { Box, FormControl, FormHelperText, TextField, Typography } from "@mui/material"
import { useState } from "react"
import { isEmpty } from "lodash"

export function ReasonTF({ value, setValue, numStatus }) {
  const [error, setError] = useState(false)

  const onChange = (event) => {
    setValue(event.target.value)
    if (isEmpty(event.target.value)) setError(true)
    else setError(false)
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Typography fontWeight={"bold"} noWrap overflow={"auto"} textOverflow={"ellipsis"} gutterBottom>
        {"Raison"}
      </Typography>
      <FormControl>
        <TextField multiline fullWidth value={value} size="small" onChange={onChange} required error={error} />
        <FormHelperText sx={{ color: error ? "error.main" : "primary.main" }}>
          {error
            ? "Veuillez entrer une raison"
            : [3, 4].includes(numStatus)
            ? "La raison sera intégrée dans le mail de notification"
            : "La raison ne sera pas intégrée dans le mail de notification"}
        </FormHelperText>
      </FormControl>
    </Box>
  )
}
