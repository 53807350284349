import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { Box, Typography } from "@mui/material"

export const DateInput = ({ value, onChange, color }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        onChange={(newDate) => onChange(newDate.toISOString())}
        value={value}
        OpenPickerButtonProps={{ size: "small" }}
        InputAdornmentProps={{ sx: { "& .MuiButtonBase-root": { color: color } } }}
        renderInput={({ InputProps, inputProps, label, inputRef }) => (
          <Box ref={inputRef} sx={{ overflow: "clip" }}>
            <Typography variant="body2" sx={{ mb: -1 }}>
              {label}
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: "space-between",
                alignItems: "center",
                pt: 1,
              }}
            >
              <Typography>
                {inputProps.value ? inputProps.value : inputProps.placeholder}
              </Typography>
              {InputProps.endAdornment}
            </Box>
          </Box>
        )}
        inputFormat="dd.MM.yyyy"
        handleCloseMenu={() => {}}
      />
    </LocalizationProvider>
  )
}
