import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { useState } from "react"

export default function ConfirmButton({ title = null, content = null, callback = () => {}, sx = {}, disableElevation = false }) {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

  const handleClick = (confirm) => {
    setConfirmDialogOpen(false)
    callback(confirm)
  }

  return (
    <>
      <Button sx={sx} disableElevation={disableElevation} variant="contained" onClick={() => setConfirmDialogOpen(true)}>
        Valider
      </Button>
      <Dialog open={confirmDialogOpen} keepMounted={false}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
          <DialogContentText>{content ?? "Êtes-vous sûr ?"}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={() => handleClick(false)}>
            Annuler
          </Button>
          <Button variant="text" color="error" onClick={() => handleClick(true)}>
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
