import { max } from "lodash"
import { TableCell, TableRow } from "@mui/material"
import NumberIcon from "../../../../../../components/NumberIcons"

export default function StatsHeader({ stats }) {
  const maxCols = max(Object.values(stats).map((stat) => Object.keys(stat).length))

  if (maxCols === 1)
    return (
      <TableRow>
        <TableCell sx={{ px: 1, py: 0 }} />
        <TableCell sx={{ px: 1, py: 0 }} align="center">
          <NumberIcon color="black" num="1" />
        </TableCell>
      </TableRow>
    )

  if (maxCols <= 4)
    return (
      <TableRow>
        <TableCell sx={{ px: 1, py: 0 }} />
        <TableCell sx={{ px: 1, py: 0 }} align="center">
          <NumberIcon color="black" num="T" />
        </TableCell>
        {Array.from(Array(maxCols)).map((num, numIndex) => {
          return (
            <TableCell sx={{ px: 1, py: 0 }} key={numIndex} align="center">
              <NumberIcon color="black" num={numIndex + 1} />
            </TableCell>
          )
        })}
      </TableRow>
    )

  return (
    <TableRow>
      <TableCell sx={{ px: 1, py: 0 }} />
      <TableCell sx={{ px: 1, py: 0 }} align="center">
        <NumberIcon color="black" num="T" />
      </TableCell>
      <TableCell sx={{ px: 1, py: 0 }} key={0} align="center">
        <NumberIcon color="black" num="1" />
      </TableCell>
      <TableCell sx={{ px: 1, py: 0 }} key={1} align="center">
        <NumberIcon color="black" num="2" />
      </TableCell>
      <TableCell sx={{ px: 1, py: 0 }} key={2} align="center">
        <NumberIcon color="black" num="3" />
      </TableCell>
      <TableCell sx={{ px: 1, py: 0 }} key={3} align="center">
        <NumberIcon color="black" num="N" />
      </TableCell>
    </TableRow>
  )
}
