import { Box, Button, Toolbar, Typography } from "@mui/material"
import { getRegistrationsState, setAttribute } from "../../../redux/slices/registrationsSlice"
import { useDispatch, useSelector } from "react-redux"
import StatusButton from "./statusButton/StatusButton"
import { useParams } from "react-router-dom"

export default function RTStatusToolbar({ small = false }) {
  const { workshopId } = useParams()
  const { selectionsRegs } = useSelector(getRegistrationsState)
  const dispatch = useDispatch()

  const buttons = workshopId ? [1, 2, 3, 4, 5] : [3, 4, 5]

  return (
    <Toolbar
      id={"RTToolbar"}
      variant={small ? "dense" : "regular"}
      sx={{ justifyContent: "space-between", pr: "0px!important", alignItems: "center" }}
    >
      {/* SELECTED NUMBERS */}
      <Typography
        id={"RTT-TOPBAR_TITLE"}
        fontWeight={"bold"}
        color={selectionsRegs.length > 0 ? "primary" : "#ffffff"}
        variant={small ? "h6" : "h5"}
        sx={{ flex: 1 }}
      >
        {`${selectionsRegs.length} sélectionnée${selectionsRegs.length > 1 ? "s" : ""}`}
      </Typography>
      {/* STATUS BUTTONS */}
      <Box sx={{ flex: 2, display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "shade.bg",
            px: 1,
            borderRadius: 1,
          }}
        >
          <Typography fontWeight={"bold"} sx={{ mr: 1 }}>
            Statut à appliquer
          </Typography>

          <Box>
            {buttons.map((numStatus) => (
              <StatusButton key={numStatus} numStatus={numStatus} />
            ))}
          </Box>
        </Box>
      </Box>
      {/* STOP BUTTON */}
      <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
        <Button
          size="small"
          color="error"
          variant="contained"
          disableElevation
          onClick={() => dispatch(setAttribute({ key: "selectionsRegs", value: [] }))}
        >
          Arrêter la modification
        </Button>
      </Box>
    </Toolbar>
  )
}
