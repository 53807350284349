import { createSlice } from "@reduxjs/toolkit"
import apiClient from "../../configs/axiosCfg"

const initialState = {
  db: {},
  local: {},
}

export const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    initForm: (state, action) => {
      return { db: action.payload, local: action.payload }
    },
    resetLocal: (state) => {
      return { ...state, local: state.db }
    },
    setLocalAttribute: (state, action) => {
      return { ...state, local: { ...state.local, [action.payload.key]: action.payload.value } }
    },
    setLocalInput: (state, action) => {
      return {
        ...state,
        local: {
          ...state.local,
          inputs: { ...state.local.inputs, [action.payload.key]: action.payload.value },
        },
      }
    },
  },
})

export const { initForm, setLocalAttribute, setLocalInput, resetLocal } = formSlice.actions

export const getFormDb = (state) => state.form.db
export const getFormLocal = (state) => state.form.local

export const fetchForm = (eventId, kToken) => async (dispatch) =>
  apiClient
    .get(`/events/${eventId}/form`, {
      headers: { Authorization: "Bearer " + kToken },
    })
    .then((response) => {
      dispatch(initForm(response.data))
      return response
    })
    .catch((error) => {
      console.error(error.response.data)
      return "error"
    })

export const saveFormLocal = (formId, body, kToken) => async (dispatch) =>
  apiClient
    .put(`/forms/${formId}`, body, {
      headers: { Authorization: "Bearer " + kToken },
    })
    .then((response) => {
      dispatch(initForm(response.data))
      return "ok"
    })
    .catch((error) => {
      // TODO Gérer les accès forbidden
      console.error(error.response.data)
      return "error"
    })

export const postForm = (body, kToken) => async () =>
  apiClient
    .post(`/forms`, body, {
      headers: { Authorization: "Bearer " + kToken },
    })
    .then((response) => {
      return response.data._id
    })
    .catch((error) => {
      // TODO Gérer les accès forbidden
      console.error(error.response.data)
      return "error"
    })

export default formSlice.reducer
