import { Box, Checkbox, Typography } from "@mui/material"

export const BasicCheck = ({ label, value = "", checked = false, onClick = () => {} }) => {
  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: checked ? "primary.bgLight" : "#ffffff",
        borderRadius: 1,
        filter: `drop-shadow(0 0rem 1mm lightgray)`,
        alignItems: "center",
        "& :hover": {
          cursor: "pointer",
        },
      }}
      onClick={() => onClick(value)}
    >
      <Checkbox checked={checked} size="small" />
      <Typography sx={{ mr: 1.2, fontWeight: "normal" }} color={checked ? "primary" : ""}>
        {label}
      </Typography>
    </Box>
  )
}
