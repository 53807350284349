import { Box, Chip } from "@mui/material"
import _ from "lodash"

export const managersCells = (managers) => (
  <Box
    sx={{
      display: "flex",
      gap: 1,
    }}
  >
    {!_.isArray(managers) ? "n/d" : _.isEmpty(managers) ? "Aucun manager" : managers.map((man, mIndex) => <Chip key={mIndex} label={man.name} />)}
  </Box>
)
