import { Box, Button, CircularProgress, Container, CssBaseline, Paper, Typography } from "@mui/material"
import pkg from "../../package.json"
import { useKeycloak } from "@react-keycloak/web"
import PropTypes from "prop-types"
import { BR_LARGE } from "../utils/theme/app"

// Component vars
const title = "Ateliers jeunes"
const subtitle = "Interface de gestion"

export default function NotLogged(props) {
  const { initializing = false, error = null } = props
  const { keycloak } = useKeycloak()

  return (
    <Container id="NotLoggedContainer" data-cy="root-router">
      <CssBaseline component="main" />
      <Paper
        elevation={104}
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 4,
          maxWidth: "550px",
          margin: "auto",
          marginTop: "10%",
          borderRadius: BR_LARGE,
          gap: 2,
        }}
        // style={{ overflow: "unset!important", filter: "drop-shadow(0 0rem 4mm lightgray)" }}
      >
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
          <img
            alt="Logo HEIG-VD"
            src="https://storage.googleapis.com/visual-identity/logo/2020-slim.svg"
            style={{ maxWidth: "100px", objectFit: "contain" }}
          />
          <Box sx={{}}>
            <Typography variant="h5" sx={{ textTransform: "uppercase" }} noWrap>
              {title}
            </Typography>
            <Typography variant="h6">{subtitle}</Typography>
            <Typography variant="overline" color="heig.main">
              {error ?? ""}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center">
          {initializing ? (
            <CircularProgress color="heig" />
          ) : (
            <Button variant="contained" size="large" color="heig" style={{ borderRadius: 8 }} onClick={error ? keycloak.logout : keycloak.login}>
              {error ? "Se déconnecter" : "Se connecter"}
            </Button>
          )}
        </Box>
        <Box display="flex" justifyContent="right" mb={-2}>
          <Typography variant="button">{`Version : ${pkg.version}`}</Typography>
        </Box>
      </Paper>
    </Container>
  )
}

NotLogged.propTypes = {
  initializing: PropTypes.bool,
  error: PropTypes.string,
}
