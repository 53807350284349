import { Box, Card, CardContent, CardHeader, CircularProgress, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { getRegistration } from "../../../../redux/slices/registrationSlice"
import { isEmpty, isString, isUndefined } from "lodash"
import { GENDER, translateRegLabel } from "../../../../utils/constants/registrations"
import { formatDate } from "../../../../utils/dates"
import TypoField from "./TypoField"
import Field from "./Field"
import { BR_LARGE } from "../../../../utils/theme/app"

// eslint-disable-next-line no-unused-vars
const DETAILS_REG_INFOS_SCHEMA = [
  {
    name: "child",
    fields: [
      { input: "firstname", type: "text" },
      { input: "lastname", type: "text" },
      { input: "gender", type: "selector" },
      { input: "birthdate", type: "date" },
      { input: "email", type: "text" },
      { input: "phone", type: "text" },
      { input: "tshirt_size", type: "text" },
    ],
  },
  {
    name: "parent",
    fields: [
      { input: "firstname", type: "text" },
      { input: "lastname", type: "text" },
      { input: "email", type: "text" },
      { input: "phone", type: "text" },
      { input: "newsletters", type: "bool" },
      { input: undefined, type: undefined },
      { input: undefined, type: undefined },
    ],
  },
  {
    name: "address",
    fields: [
      { input: "line1", type: "text" },
      { input: "line2", type: "text" },
      { input: "zip", type: "number" },
      { input: "city", type: "text" },
      { input: undefined, type: undefined },
      { input: undefined, type: undefined },
      { input: undefined, type: undefined },
    ],
  },
  {
    name: "school",
    fields: [
      { input: "grade", type: "number" },
      { input: "path", type: "text" },
      { input: "class", type: "selector" },
      { input: "name", type: "text" },
      { input: "zip", type: "number" },
      { input: "city", type: "text" },
      { input: undefined, type: undefined },
    ],
  },
]

export default function Infos({ editMode = false }) {
  const registration = useSelector(getRegistration)

  if (isEmpty(registration)) {
    return <CircularProgress color="primary" />
  }

  const handleDataDisplay = ({ input, type }, name) => {
    const data = name ? registration?.[name]?.[input] : registration?.[input]
    if (!data) return ""
    if (input === "gender") return GENDER[data]
    if (type === "bool") return data ? "Oui" : "Non"
    if (type === "date") if (isString(data) && data.includes(":") && data.includes("T") && data.includes("Z")) return formatDate(new Date(data))
    return data
  }

  return (
    <Card>
      <CardHeader title={"Informations personnelles"} />
      <CardContent sx={{ pt: "8px!important", pb: "16px!important" }}>
        {!isEmpty(registration) ? (
          <>
            <Box
              sx={{
                display: "flex",
                borderRadius: BR_LARGE,
                border: "1px solid whitesmoke",
                overflow: "clip",
              }}
            >
              {DETAILS_REG_INFOS_SCHEMA.map(({ name, fields }) => (
                <Box
                  key={name}
                  sx={{
                    flex: 1,
                    background: "whitesmoke",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      py: 1.5,
                      backgroundColor: "whitesmoke",
                      borderRight: `1px solid white`,
                      borderLeft: `1px solid white`,
                    }}
                    variant="h6"
                    textAlign={"center"}
                    textTransform={"capitalize"}
                  >
                    {translateRegLabel[name]}
                  </Typography>
                  <Box>
                    {fields.map(({ input, type }, index) => (
                      <TypoField
                        editMode={editMode}
                        key={index}
                        title={translateRegLabel[input]}
                        content={
                          editMode && !isUndefined(input) ? (
                            <Field field={{ input, type }} name={["newsletters", "tshirt_size"].includes(input) ? undefined : name} />
                          ) : (
                            handleDataDisplay({ input, type }, ["newsletters", "tshirt_size"].includes(input) ? undefined : name)
                          )
                        }
                        bgColor={index % 2 ? "whitesmoke" : "#ffffff"}
                        borderColor={!(index % 2) ? "whitesmoke" : "#ffffff"}
                      />
                    ))}
                  </Box>
                </Box>
              ))}
            </Box>
            <Box sx={{ mt: 2 }}>
              <TypoField
                editMode={editMode}
                title={translateRegLabel["comments"]}
                content={editMode ? <Field field={{ input: "comments", type: "text" }} /> : handleDataDisplay({ input: "comments", type: "text" })}
                bgColor={"white"}
                titleWidth="13ch"
              />
            </Box>
          </>
        ) : (
          <Typography>Pas de données à afficher...</Typography>
        )}
      </CardContent>
    </Card>
  )
}
