import { Button, MobileStepper, useMediaQuery, useTheme } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { getMainForm, jumpState } from "../../../redux/slices/mainFormSlice"

export default function Stepper() {
  const {
    activeStep,
    childCount,
    processStatus,
    config: { mainForm },
  } = useSelector(getMainForm)
  const dispatch = useDispatch()

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("sm"))

  const stepsCount = 3 + (mainForm?.others ? 1 : 0) + childCount * 2

  return (
    <MobileStepper
      sx={{ borderRadius: matches ? "12px" : 0 }}
      LinearProgressProps={{
        sx: matches
          ? { height: "10px", borderRadius: "3px" }
          : { height: "10px", borderRadius: 0, position: "absolute", width: "100%", mt: -8, mx: -1 },
        variant: "determinate",
        value: (activeStep / stepsCount) * 100,
      }}
      backButton={
        <Button disabled={!(processStatus < 2 && activeStep > 0)} onClick={() => dispatch(jumpState(-1))}>
          {"Retour"}
        </Button>
      }
      nextButton={
        <Button disabled={!(processStatus < 1 && activeStep < stepsCount)} variant="contained" disableElevation type={"submit"}>
          {stepsCount - 1 <= activeStep ? (stepsCount === activeStep ? "Confirmé" : "Confirmer") : "Suivant"}
        </Button>
      }
      variant="progress"
      position={matches ? "static" : "bottom"}
      steps={stepsCount}
      activeStep={activeStep}
      elevation={101}
    />
  )
}
