import { Card, CardContent, CardHeader, Switch, TextField, Typography } from "@mui/material"
import MagicTable from "../../../components/magicTable/MagicTable"
import { useDispatch, useSelector } from "react-redux"
import DateRangeField from "../../../components/dateRangeField/DateRangeField"
import FiltersField from "./filtersField/FiltersField"
import ActionMenuItem from "../../../components/inputs/ActionMenuItem"
import { useSnackbar } from "notistack"
import { addInputError, getInputsError, removeInputError } from "../../../redux/slices/appSlice"
import { cols, colsOptions } from "../../../utils/constants/workshops"
import { deleteWorkshop, duplicateWorkshop, getWorkshopsLocal, newWorkshop, setWSAttribute } from "../../../redux/slices/workshopsSlice"

export function AddWorkshops({ isNewEvent = false }) {
  const workshops = useSelector(getWorkshopsLocal)
  const inputsError = useSelector(getInputsError)
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const changeWSAttribute = (event) => {
    switch (event.target.name.split("-")[1]) {
      case "name":
        if (!event.target.value) dispatch(addInputError(event.target.name))
        else dispatch(removeInputError(event.target.name))
        break
      case "place":
        if (!event.target.value) dispatch(addInputError(event.target.name))
        else dispatch(removeInputError(event.target.name))
        break
      case "start":
        if (!event.target.value) dispatch(addInputError(event.target.name))
        else dispatch(removeInputError(event.target.name))
        break
      case "end":
        if (!event.target.value) dispatch(addInputError(event.target.name))
        else dispatch(removeInputError(event.target.name))
        break
      case "capacity":
        if (!event.target.value) dispatch(addInputError(event.target.name))
        else if (event.target.value < 1) dispatch(addInputError(event.target.name))
        else dispatch(removeInputError(event.target.name))
        break
      default:
        break
    }
    const index = parseInt(event.target.name.split("-")[0])
    const name = event.target.name.split("-")[1]
    dispatch(setWSAttribute({ index: index, key: name, value: event.target.value }))
  }

  const changeCheckedWSAttribute = (event) => {
    if (!event.target.checked && workshops.filter((ws) => !!ws.available).length === 1)
      return enqueueSnackbar("Au moins un atelier doit rester disponible", { variant: "warning" })

    const index = parseInt(event.target.name.split("-")[0])
    const name = event.target.name.split("-")[1]
    dispatch(setWSAttribute({ index: index, key: name, value: event.target.checked }))
  }

  const handleDuplicate = (index) => {
    dispatch(duplicateWorkshop(index))
  }

  const handleDelete = (index, value) => {
    if (value)
      if (workshops.filter((ws) => !ws.deleted).length <= 1)
        return enqueueSnackbar("L'évènement doit au moins avoir 1 atelier", { variant: "warning" })
    if (isNewEvent) dispatch(deleteWorkshop(index))
    else dispatch(setWSAttribute({ index, key: "deleted", value }))
  }

  const workshopsToRows = () =>
    workshops.map((ws, index) => ({
      cells: [
        <TextField
          key="0"
          size="small"
          placeholder="Nom de l'atelier"
          name={`${index}-name`}
          value={ws.name}
          onChange={changeWSAttribute}
          type="text"
          InputProps={
            ws.deleted
              ? {
                  sx: {
                    color: "error.main",
                    textDecorationLine: "line-through",
                  },
                }
              : !ws._id
              ? { sx: { color: "success.main" } }
              : null
          }
          variant="standard"
          error={inputsError.includes(`${index}-name`)}
        />,
        <TextField
          key="1"
          size="small"
          placeholder="Lieu"
          name={`${index}-place`}
          value={ws.place}
          onChange={changeWSAttribute}
          type="text"
          variant="standard"
          error={inputsError.includes(`${index}-place`)}
        />,
        <DateRangeField key="2" index={index} start={ws.start} end={ws.end} changeWSAttribute={changeWSAttribute} />,
        <TextField
          key="3"
          size="small"
          name={`${index}-capacity`}
          value={ws.capacity}
          onChange={changeWSAttribute}
          inputProps={{ sx: { textAlign: "right" } }}
          sx={{ maxWidth: "6ch" }}
          type="number"
          variant="standard"
          error={inputsError.includes(`${index}-capacity`)}
        />,
        <FiltersField key="4" ws={ws} changeWSAttribute={changeWSAttribute} index={index} />,
        <Switch key="5" name={`${index}-available`} checked={ws.available} onChange={changeCheckedWSAttribute} />,
      ],
      actions: [
        <ActionMenuItem key="duplicate-line" action="duplicate" onClickItem={() => handleDuplicate(index)} />,
        <ActionMenuItem
          key={`${ws.deleted ? "restore" : "delete"}-line`}
          action={ws.deleted ? "restore" : "delete"}
          onClickItem={() => handleDelete(index, !ws.deleted)}
        />,
      ],
    }))

  return (
    <Card sx={{ p: 1 }}>
      <CardHeader title="Ateliers" />
      <CardContent sx={{ py: "8px!important" }}>
        {workshops ? (
          <MagicTable rows={workshopsToRows()} cols={cols} addRow={() => dispatch(newWorkshop())} colsOptions={colsOptions} />
        ) : (
          <Typography textAlign={"center"}>Loading</Typography>
        )}
      </CardContent>
    </Card>
  )
}
