import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material"
import { getRegistrationsState, setAttribute } from "../../redux/slices/registrationsSlice"
import { CircleOutlined } from "@mui/icons-material"
import {
  STATUSES,
  STATUSES_COLOR,
  STATUSES_ICON,
  STATUSES_LABEL,
} from "../../utils/constants/statuses"
import { useDispatch, useSelector } from "react-redux"

export default function RegsStatusSelect({ forWorkshops = false }) {
  const { selectedStatus } = useSelector(getRegistrationsState)
  const dispatch = useDispatch()

  const statusesToMap = forWorkshops ? STATUSES.slice(0, 4) : STATUSES

  return (
    <FormControl>
      <Select
        sx={{ height: "40px" }}
        size="small"
        value={selectedStatus}
        onChange={(event) =>
          dispatch(setAttribute({ key: "selectedStatus", value: event.target.value }))
        }
      >
        <MenuItem value={-1}>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            {<CircleOutlined color="shade" />}
            <Typography fontWeight={"bold"} color="shade">
              Tous
            </Typography>
          </Box>
        </MenuItem>
        {statusesToMap.map((status, index) => (
          <MenuItem key={index} value={index}>
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              {STATUSES_ICON[status]}
              <Typography fontWeight={"bold"} color={`${STATUSES_COLOR[status]}.main`}>
                {STATUSES_LABEL[status]}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
