import { TableCell } from "@mui/material"

const sx = {
  maxWidth: "19ch",
  whiteSpace: "nowrap",
  alignItems: "center",
  overflowX: "clip",
  textOverflow: "ellipsis",
  "&:hover": {
    overflowX: "auto",
    textOverflow: "clip",
  },
}

export default function RTCell({ cell, align, style, onClickCell }) {
  return (
    <TableCell align={align ?? "left"} style={style} onClick={onClickCell} sx={sx}>
      {cell}
    </TableCell>
  )
}
