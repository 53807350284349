import { useDispatch, useSelector } from "react-redux"
import { getNotifications, setNotificationsAttribute, toggleError } from "../../../../../redux/slices/notificationsSlice"
import { Box, TextField } from "@mui/material"
import { TextFieldsRounded } from "@mui/icons-material"

export default function EmailInput({ label, placeholder, input, children }) {
  const dispatch = useDispatch()
  const notifications = useSelector(getNotifications)
  const isError = notifications.errors.includes(input)

  const handleChange = (event) => {
    dispatch(toggleError({ input, isGood: /\S+@\S+\.\S+/.test(event.target.value) || event.target.value === "" }))
    dispatch(setNotificationsAttribute({ key: input, value: event.target.value }))
  }

  return (
    <Box sx={{ flex: 1, display: "flex", alignItems: "start", gap: 1 }}>
      <TextFieldsRounded
        sx={{
          color: "primary.main",
          my: 0.5,
        }}
      />
      <TextField
        label={label}
        error={isError}
        sx={{ width: "250px" }}
        placeholder={placeholder}
        variant="standard"
        value={notifications.local?.[input] ?? ""}
        onChange={handleChange}
        helperText={isError ? "L'adresse email n'est pas valide" : ""}
      />
      {children}
    </Box>
  )
}
