import { Box, Chip } from "@mui/material"
import { useSelector } from "react-redux"
import { getEventDb } from "../../../../redux/slices/eventSlice"
import { longDateDisplay } from "../../../../utils/dates"

export default function EventInfos() {
  const { start, end, selections, validations } = useSelector(getEventDb)

  return (
    <Box sx={{ display: "flex", gap: 2 }}>
      <Chip sx={{ fontSize: "1rem", textTransform: "capitalize" }} label={longDateDisplay(start, end) ?? "---"} />
      <Chip sx={{ fontSize: "1rem" }} label={`${selections ?? "-"} sélection${selections > 1 ? "s" : ""} maximum`} />
      <Chip sx={{ fontSize: "1rem" }} label={`${validations ?? "-"} validation${validations > 1 ? "s" : ""} maximum`} />
    </Box>
  )
}
