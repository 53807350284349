import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip } from "@mui/material"
import { VisibilityRounded } from "@mui/icons-material"
import { useState } from "react"

export default function ShowDetails({ title = "", content = "" }) {
  const [openDialog, setOpenDialog] = useState(false)

  const open = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setOpenDialog(true)
  }

  const close = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setOpenDialog(false)
  }

  return (
    <>
      <Tooltip title={"Voir le texte entier"}>
        <IconButton onClick={open}>
          <VisibilityRounded color="primary" fontSize="small" />
        </IconButton>
      </Tooltip>
      <Dialog open={openDialog} keepMounted={false}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="primary" onClick={close}>
            fermer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
