import { GenderInput } from "./GenderInput"
import { isUndefined } from "lodash"
import { DateInput } from "./DateInput"
import { Box, Checkbox, TextField } from "@mui/material"
import { LibraryAddCheckRounded, NumbersRounded, TextFieldsRounded } from "@mui/icons-material"
import { getLocalRegistration, setAttribute, setContextAttribute } from "../../../../redux/slices/localRegistrationSlice"
import { useDispatch, useSelector } from "react-redux"
import { TshirtSizeInput } from "./TshirtSizeInput"

export default function Field({ name, field: { input, type }, color }) {
  const localRegistration = useSelector(getLocalRegistration)
  const dispatch = useDispatch()

  const handleChange = (value) => {
    let newValue = value
    if (!value) newValue = null
    else if (type === "number") newValue = Math.abs(Math.round(value)) || 1

    if (isUndefined(name)) return dispatch(setAttribute({ key: input, value: newValue }))
    else return dispatch(setContextAttribute({ context: name, key: input, value: newValue }))
  }

  const value = isUndefined(name) ? localRegistration?.[input] : localRegistration?.[name]?.[input]

  if (input === "gender") return <GenderInput color={color} value={value} onChange={(newValue) => handleChange(newValue)} />
  if (input === "tshirt_size") return <TshirtSizeInput color={color} value={value} onChange={(newValue) => handleChange(newValue)} />
  if (type === "date") return <DateInput color={color} value={value} onChange={(newValue) => handleChange(newValue)} />
  if (type === "bool")
    return (
      <Box
        sx={{
          flex: 1,
          pr: 0.5,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Checkbox sx={{ p: 0 }} size="small" checked={!!value} onChange={() => handleChange(!value)} />
        <LibraryAddCheckRounded fontSize="small" sx={{ color: color }} />
      </Box>
    )
  return (
    <TextField
      fullWidth
      type={type}
      InputProps={{
        disableUnderline: true,
        endAdornment:
          !isUndefined(input) && type === "number" ? (
            <NumbersRounded
              sx={{
                color: color,
              }}
              fontSize="small"
            />
          ) : (
            <TextFieldsRounded
              sx={{
                color: color,
              }}
              fontSize="small"
            />
          ),
      }}
      sx={{ pr: 0.5 }}
      inputProps={{ style: { padding: 0 } }}
      size="small"
      variant="standard"
      value={value ?? ""}
      onChange={(event) => handleChange(event.target.value)}
    />
  )
}
