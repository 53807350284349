import { Box, Typography } from "@mui/material"
import {
  STATUSES,
  STATUSES_COLOR,
  STATUSES_ICON,
  STATUSES_LABEL,
} from "../../../../../../../../utils/constants/statuses"
import { isEmpty } from "lodash"
import { useSelector } from "react-redux"
import { getRegsByStatus } from "../../../../../../../../redux/slices/registrationsSlice"

export default function Header({ numStatus }) {
  const statusRegs = useSelector(getRegsByStatus)[numStatus]

  const status = STATUSES[numStatus]

  let regsCountStatus = "-"
  if (statusRegs && !isEmpty(statusRegs))
    regsCountStatus = Object.values(statusRegs).reduce((a, b) => a + b)

  return (
    <Box
      id={`StatsStatus-Header-${numStatus}`}
      sx={{ display: "flex", width: "100%", justifyContent: "space-between", gap: 4, p: 1 }}
    >
      <Typography fontWeight="bold" color={`${STATUSES_COLOR[status]}.main`} width="10ch" noWrap>
        {STATUSES_LABEL[status]}
      </Typography>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Typography fontWeight="bold" width="3ch" textAlign={"right"}>
          {regsCountStatus}
        </Typography>
        {STATUSES_ICON[status]}
      </Box>
    </Box>
  )
}
