import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import "./index.css"
import { ReactKeycloakProvider } from "@react-keycloak/web"
import keycloakCfg from "./configs/keycloak"
import { Provider } from "react-redux"
import { store } from "./redux/store"
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactKeycloakProvider authClient={keycloakCfg}>
        <App />
      </ReactKeycloakProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
)
