import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"
import { useKeycloak } from "@react-keycloak/web"
import { useSnackbar } from "notistack"
import { useDispatch, useSelector } from "react-redux"
import { getActivities, putActivity } from "../../../redux/slices/activitiesSlice"

export default function ArchiveActivity(props) {
  const { open, handleClose, actId } = props
  const dispatch = useDispatch()
  const { keycloak } = useKeycloak()
  const { archived } = useSelector(getActivities)

  const { enqueueSnackbar } = useSnackbar()

  const handleConfirm = () => {
    dispatch(putActivity(actId, { activity: { archived: !archived } }, keycloak.token)).then(
      (result) => {
        if (result.text === "error") {
          return enqueueSnackbar(
            `Une erreur est survenue au moment ${
              archived ? "de remettre" : "d'archiver"
            } l'activité`,
            { variant: "error" }
          )
        }
        if (result.text === "ok") {
          return enqueueSnackbar(`Activité ${archived ? "remise" : "archivée"}`, {
            variant: "success",
          })
        }
      }
    )
    handleClose()
  }

  return (
    <Dialog open={open} keepMounted={false}>
      <DialogTitle id="alert-dialog-title" variant="h5" color={"warning.main"}>
        {!archived ? "Archiver une activité" : "Remettre une activité"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{`Souhaitez-vous vraiment ${
          !archived ? "archiver" : "remettre"
        } l'activité ?`}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={handleClose}>
          Annuler
        </Button>
        <Button variant="text" color="warning" onClick={handleConfirm}>
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  )
}
