import { TableCell } from "@mui/material"
import { COL_OPTIONS } from "../../utils/theme/themeConstants"

// const isDataLengthMore20Chars = (value) => (typeof value !== "string" ? false : value.length > 20)
const isDataLengthMore20Chars = (value) => (typeof value !== "string" ? false : false)

export default function MagicCell({ data, colOptions = COL_OPTIONS, style, onClickCell, fullLength = false }) {
  return (
    <TableCell
      align={colOptions ? colOptions.align : "left"}
      style={{ ...style }}
      onClick={onClickCell}
      sx={{ whiteSpace: "nowrap", alignItems: "center" }}
    >
      {fullLength ? data : isDataLengthMore20Chars(data) ? data.substring(0, 19) + "..." : data}
    </TableCell>
  )
}
