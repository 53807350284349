import { Button } from "@mui/material"
import { DeleteRounded } from "@mui/icons-material"
import { deleteEvent, getEventDb } from "../../../../redux/slices/eventSlice"
import { useDispatch, useSelector } from "react-redux"
import { useState } from "react"
import BasicConfirm from "../../../../components/dialogs/BasicConfirm"
import { useKeycloak } from "@react-keycloak/web"
import { useSnackbar } from "notistack"
import { useNavigate } from "react-router-dom"

export default function DeleteButton() {
  const { name } = useSelector(getEventDb)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const dispatch = useDispatch()
  const { keycloak } = useKeycloak()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const handleConfirmDeleteEvent = (confirm) => {
    if (confirm) {
      dispatch(deleteEvent(keycloak.token)).then((response) => {
        if (response === "error") {
          return enqueueSnackbar("Une erreur est survenue lors de la suppression de l'évènement", {
            variant: "error",
          })
        } else {
          enqueueSnackbar(`Évènement supprimé`, { variant: "success" })

          navigate("/dashboard/events")
        }
      })
    }
    setConfirmDialogOpen(false)
  }

  return (
    <>
      <Button
        onClick={() => setConfirmDialogOpen(true)}
        disabled={confirmDialogOpen}
        color="error"
        startIcon={<DeleteRounded />}
      >{`Supprimer`}</Button>
      <BasicConfirm
        open={confirmDialogOpen}
        onClose={handleConfirmDeleteEvent}
        title={`Supprimer ${name}`}
        content={`Êtes-vous sûr de vouloir supprimer l'évènement ?`}
      />
    </>
  )
}
