import { translateRegLabel } from "../../../../utils/constants/registrations"
import { Box, Typography } from "@mui/material"

export default function StringBlock({ context, obj }) {
  return (
    <Box sx={{ ml: 1 }}>
      <Typography variant={"h6"} fontWeight={"bold"} color={"primary"} sx={{ textTransform: "capitalize" }}>
        {translateRegLabel[context]}
      </Typography>
      <Typography paragraph textAlign={"justify"} mt={2}>
        {obj[context]}
      </Typography>
    </Box>
  )
}
