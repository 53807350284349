import { Box, FormControl, ListItemText, MenuItem, Select } from "@mui/material"
import { EditRounded, VisibilityRounded } from "@mui/icons-material"
import BasicConfirm from "../../../../../components/dialogs/BasicConfirm"
import { useState } from "react"

export default function ResourceRoleSelect({ value, setValue }) {
  const [localValue, setLocalValue] = useState(value)
  const [openConfirmResourceRoleChange, setOpenConfirmResourceRoleChange] = useState(false)

  const confirmResourceRoleChangeCallback = (confirm) => {
    setOpenConfirmResourceRoleChange(false)
    if (confirm) {
      setValue(localValue)
    }
  }

  const onChange = (event) => {
    setLocalValue(event.target.value)
    setOpenConfirmResourceRoleChange(true)
  }

  return (
    <FormControl variant="outlined" size="small">
      <Select value={value} onChange={onChange} size="small">
        <MenuItem value={0}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              mr: 2,
            }}
          >
            <VisibilityRounded fontSize="small" color="shade" />
            <ListItemText>{"Viewer"}</ListItemText>
          </Box>
        </MenuItem>
        <MenuItem value={1}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2, mr: 2 }}>
            <EditRounded fontSize="small" color="shade" />
            <ListItemText>{"Editor"}</ListItemText>
          </Box>
        </MenuItem>
      </Select>
      <BasicConfirm
        title={"modifier le rôle"}
        content={"Êtes-vous sûr de vouloir modifier le rôle de l'utilisateur sur cet évènement ? "}
        open={openConfirmResourceRoleChange}
        onClose={confirmResourceRoleChangeCallback}
      />
    </FormControl>
  )
}
