import { Box, Typography } from "@mui/material"
import PropTypes from "prop-types"

export default function NumberIcon(props) {
  const { num, color } = props
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          verticalAlign: "middle",
          borderRadius: 1,
          px: 1,
          backgroundColor: color ? `${color}.main` : "primary.main",
          color: color ? `${color}.bg` : "primary.bg",
        }}
      >
        {num}
      </Typography>
    </Box>
  )
}

NumberIcon.propTypes = {
  num: PropTypes.any,
  color: PropTypes.string,
}
