/* eslint-disable no-unused-vars */
import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material"
import { getRegistration } from "../../../../redux/slices/registrationSlice"
import { useDispatch, useSelector } from "react-redux"
import StatusLine from "./StatusLine"
import React, { useEffect, useState } from "react"
import { isEmpty } from "lodash"
import { fetchForm, initForm } from "../../../../redux/slices/formSlice"
import { useParams } from "react-router-dom"
import { useKeycloak } from "@react-keycloak/web"
import { useSnackbar } from "notistack"

export default function Status({ editMode = false }) {
  const { eventId } = useParams()
  const dispatch = useDispatch()
  const { keycloak } = useKeycloak()
  const registration = useSelector(getRegistration)
  const { enqueueSnackbar } = useSnackbar()

  const [otherRegistrations, setOtherRegistrations] = useState([])

  useEffect(() => {
    dispatch(fetchForm(eventId, keycloak.token)).then((res) => {
      if (res === "error") {
        enqueueSnackbar("Erreur récupération informations formulaire", {
          variant: "error",
        })
      }
    })
    return () => {
      dispatch(initForm({}))
    }
  }, [])

  useEffect(() => {
    if (registration?.multi) {
      const data = []
      data.push(
        <Typography key={"otherRegs"} variant="h6" sx={{ opacity: 0.6 }}>
          {"Autres demandes"}
        </Typography>
      )
      registration.multi.forEach((reg, index) => {
        data.push(<StatusLine key={index} registration={reg} otherReg />)
      })
      setOtherRegistrations(data)
    }
  }, [registration])

  return (
    <Card>
      <CardHeader title={"Statuts et feuille de consentement"} />
      <CardContent sx={{ pt: 0, pb: "16px!important", display: "flex", gap: 4, flexDirection: "column" }}>
        <StatusLine registration={registration} editMode={editMode} />
        {!isEmpty(otherRegistrations) && <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>{otherRegistrations}</Box>}
      </CardContent>
    </Card>
  )
}
