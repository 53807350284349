import { useEffect } from "react"
import { matchPath, Outlet, useLocation, useNavigate, useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { fetchWorkshop, setWorkshop } from "../../../../../redux/slices/workshopSlice"
import { useKeycloak } from "@react-keycloak/web"
import { useSnackbar } from "notistack"

export default function Workshop() {
  const { eventId, workshopId } = useParams()
  const location = useLocation()
  const dispatch = useDispatch()
  const { keycloak } = useKeycloak()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(fetchWorkshop(eventId, workshopId, keycloak.token)).then((result) => {
      if (result.text === "error") {
        if (result.status === 404) {
          return navigate(`/dashboard/events/${eventId}/workshops/404`, { replace: true })
        }
        return enqueueSnackbar("Une erreur est survenue lors de la récupération de l'atelier", {
          variant: "error",
        })
      }
    })
    if (matchPath(location.pathname, `/dashboard/events/${eventId}/workshops/${workshopId}`)) return navigate(`registrations`, { replace: true })

    return dispatch(setWorkshop({}))
  }, [location, eventId, workshopId])

  return <Outlet />
}
