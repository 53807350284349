import { Box, Button, keyframes, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import AttributesInputs from "./AttributesInputs"
import { AddWorkshops } from "./AddWorkshops"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getEventLocal, postEvent, setEvent } from "../../../redux/slices/eventSlice"
import { useSnackbar } from "notistack"
import { setInputsError } from "../../../redux/slices/appSlice"
import BasicConfirm from "../../../components/dialogs/BasicConfirm"
import { useKeycloak } from "@react-keycloak/web"
import { initEvent, initWorkshop } from "../../../utils/constants/redux"
import { getWorkshopsLocal, setWorkshops } from "../../../redux/slices/workshopsSlice"

const slidein = keyframes`
  0% { transform: translate(0, 0) ; }
  25% { transform: translate(5px, 0) ; }
  50% { transform: translate(0, 0) ; }
  75% { transform: translate(-5px, 0) ; }
  100% { transform: translate(0, 0) ; }
`

export default function NewEvent() {
  const navigate = useNavigate()
  const { keycloak } = useKeycloak()
  const dispatch = useDispatch()
  const workshops = useSelector(getWorkshopsLocal)
  const event = useSelector(getEventLocal)
  const { enqueueSnackbar } = useSnackbar()
  // const inputsError = useSelector(getInputsError)
  const [errorMessage, setErrorMessage] = useState("")
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
  const [btnError, setBtnError] = useState(false)

  useEffect(() => {
    dispatch(setEvent(initEvent))
    dispatch(setWorkshops([initWorkshop]))
    dispatch(setInputsError([]))
    return () => {
      dispatch(setEvent({}))
      dispatch(setWorkshops([]))
      dispatch(setInputsError([]))
    }
  }, [])

  const handleClose = () => {
    navigate("../list")
  }

  const handleError = () => {
    setBtnError(true)
    setTimeout(() => setBtnError(false), 500)
  }

  // TODO Mettre en erreur tous les inputs qui bloque l'enregistrement
  const checkAttributes = () => {
    if (!event.name || !event.activityId || !event.selections || !event.validations) return "Toutes les données n'ont pas été saisies."
    if (!workshops || workshops?.length < 1) return "Il vous faut au moins saisir un atelier."
    if (workshops.length < event.selections) return "Le nombre de sélections doit être inférieur ou égal au nombre d'ateliers"
    if (!workshops.every((ws) => ws.name && ws.capacity && ws.start && ws.end)) return "Il manque des données dans les ateliers."
    const wsNames = workshops.map((ws) => ws.name)
    if (!wsNames.every((nameToFilter) => wsNames.filter((name) => name === nameToFilter).length === 1)) return "Le nom d'un atelier doit être unique."
    return false
  }

  const handleCancelNewEvent = (confirm) => {
    setCancelDialogOpen(false)
    if (confirm) {
      handleClose()
    }
  }

  const handleConfirmSaveEvent = (confirm) => {
    setConfirmDialogOpen(false)
    setErrorMessage("")
    if (confirm) {
      const attributesError = checkAttributes()
      if (attributesError) {
        handleError()
        setErrorMessage(attributesError)
        return enqueueSnackbar("L'évènement ne peut pas être sauvegardé", { variant: "warning" })
      }
      dispatch(postEvent(keycloak.token)).then((result) => {
        if (result === "error") {
          return enqueueSnackbar("Une erreur est survenue lors de l'enregistrement de l'évènement", { variant: "warning" })
        }
        enqueueSnackbar("Évènement sauvegardé !", { variant: "success" })
        handleClose()
      })
    }
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
      <AttributesInputs />
      <AddWorkshops isNewEvent />

      <Box sx={{ mb: 4, display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <Typography color={`error.main`}>{errorMessage}</Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <Button variant="text" color="inherit" onClick={() => setCancelDialogOpen(true)}>
            Annuler
          </Button>
          <Button
            color={btnError ? "error" : "primary"}
            sx={btnError ? { color: "error", animation: `0.1s 5 ${slidein}` } : {}}
            onClick={() => setConfirmDialogOpen(true)}
            variant="contained"
          >
            Créer
          </Button>
        </Box>
      </Box>
      <BasicConfirm open={confirmDialogOpen} onClose={handleConfirmSaveEvent} content={"Êtes-vous sûr de vouloir sauvegarder l'évènement ?"} />
      <BasicConfirm
        open={cancelDialogOpen}
        onClose={handleCancelNewEvent}
        content="Êtes-vous sûr de vouloir abandonner la création d'un nouvel évènement ? Les données saisies seront perdues."
      />
    </Box>
  )
}
