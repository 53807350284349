import { Box, Toolbar, Typography } from "@mui/material"

export default function MagicToolbar({ title = "Magic Table", tools = [], small = false }) {
  return (
    <Toolbar id={"MToolbar-container"} variant={small ? "dense" : "regular"} sx={{ justifyContent: "space-between", mx: -2, alignItems: "center" }}>
      <Typography id={"MToolbar-TOPBAR_TITLE"} variant={small ? "body" : "h5"}>
        {title ? title : "Magic Table"}
      </Typography>
      <Box id={"MToolbar-tools-container"} sx={{ display: "flex", alignItems: "center" }}>
        {tools}
      </Box>
    </Toolbar>
  )
}
