import { ACTIONS_ICON, ACTIONS_LABEL } from "../../../../../utils/constants/actions"
import { IconButton, Tooltip } from "@mui/material"
import { useState } from "react"
import BasicConfirm from "../../../../../components/dialogs/BasicConfirm"

export default function DeleteUserButton({ onClick = () => {}, isManager = false }) {
  const [openConfirmDeleteUser, setOpenConfirmDeleteUser] = useState(false)

  const confirmDeleteUser = (confirm) => {
    setOpenConfirmDeleteUser(false)
    if (confirm) {
      onClick()
    }
  }

  return isManager ? (
    <IconButton sx={{ color: "white!important" }} size="small" disabled>
      {ACTIONS_ICON["remove"]}
    </IconButton>
  ) : (
    <>
      <Tooltip title={ACTIONS_LABEL["remove"]}>
        <IconButton
          color={"black"}
          sx={{ "&:hover": { color: "error.main" } }}
          onClick={() => setOpenConfirmDeleteUser(true)}
        >
          {ACTIONS_ICON["remove"]}
        </IconButton>
      </Tooltip>
      <BasicConfirm
        title={"Supprimer les droits"}
        content={
          "Êtes-vous sûr de vouloir retirer à l'utilisateur tous ses droits sur cet évènement ?"
        }
        open={openConfirmDeleteUser}
        onClose={confirmDeleteUser}
      />
    </>
  )
}
