import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material"
import FormInputs from "./FormInputs"
import HelpTooltip from "../HelpTooltip"

export default function MoreInfosForm({ expanded, handleExpanded }) {
  return (
    <Accordion expanded={expanded === "moreInfosForm"} onChange={handleExpanded("moreInfosForm")}>
      <AccordionSummary>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Typography variant="h5">{"Formulaire d'informations complémentaires"}</Typography>
          <HelpTooltip />
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <FormInputs />
      </AccordionDetails>
    </Accordion>
  )
}
