import { Box, Card, CardContent, useMediaQuery, useTheme } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { getMainForm, jumpState } from "../../../../redux/slices/mainFormSlice"
import Stepper from "../Stepper"
import { CheckCardHeader } from "./CheckCardHeader"
import Section from "./Section"
import * as React from "react"

export default function CheckStep() {
  const mainForm = useSelector(getMainForm)
  const dispatch = useDispatch()

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("sm"))

  const nextStep = (event) => {
    event.preventDefault()
    dispatch(jumpState(1))
  }

  return (
    <form noValidate autoComplete="off" onSubmit={nextStep}>
      <Stepper />
      <Box sx={{ my: 2 }} />
      <Card sx={{ p: 1 }}>
        <CheckCardHeader />
        <CardContent sx={{ display: "flex", gap: 4, flexDirection: "column", mb: 2 }}>
          <Section
            title={"Informations générales"}
            blocks={[
              {
                name: "parent",
                obj: mainForm,
              },
              {
                name: "address",
                obj: mainForm,
              },
              {
                name: "comments",
                obj: mainForm,
              },
            ]}
          />
          {mainForm.regs.map((reg, index) => (
            <Section
              title={`Enfant ${mainForm.regs.length > 1 ? index + 1 : ""}`}
              key={`reg-${index}`}
              blocks={[
                {
                  name: "child",
                  obj: mainForm.regs[index],
                },
                {
                  name: "school",
                  obj: mainForm.regs[index],
                },
                {
                  name: "choices",
                  obj: mainForm.regs[index],
                },
              ]}
            />
          ))}
        </CardContent>
      </Card>
      {!matches && <Box sx={{ mt: 8 }} />}
    </form>
  )
}
