import { createSlice } from "@reduxjs/toolkit"

import apiClient from "../../configs/axiosCfg"

const initialState = {
  activities: {
    items: [],
    selected: [],
  },
  events: {
    items: [],
    selected: [],
  },
  registrations: [],
}

export const exportSlice = createSlice({
  name: "export",
  initialState,
  reducers: {
    setItems: (state, action) => {
      state[action.payload.type].items = action.payload.items
    },
    setSelected: (state, action) => {
      state[action.payload.type].selected = action.payload.selected
    },
    setEventSelected: (state, action) => {
      state.events.selected = action.payload.selected.map((eventId) => state.events.items.find((event) => event._id === eventId))
    },
    setRegistrations: (state, action) => {
      state.registrations = action.payload
    },
  },
})

export const { setItems, setRegistrations, setSelected, setEventSelected } = exportSlice.actions
export const getActivities = (state) => state.export.activities
export const getEvents = (state) => state.export.events
export const getRegistrations = (state) => state.export.registrations

export const getExport = (state) => state.export

export const fetchActivities = (kToken) => async (dispatch) =>
  apiClient
    .get(`/activities/export`, {
      headers: { Authorization: "Bearer " + kToken },
    })
    .then((response) => {
      if (response.status === 204) return { status: response.status, text: "ok" }
      dispatch(setItems({ type: "activities", items: response.data }))
      return { status: response.status, text: "ok" }
    })
    .catch((error) => {
      console.error(error.response.data)
      return { status: error.toJSON().status, text: "error" }
    })

export const fetchEvents = (kToken) => async (dispatch, getState) =>
  apiClient
    .post(
      `/events/export`,
      { activities: getState().export.activities.selected.length > 0 ? getState().export.activities.selected : getState().export.activities.items },
      {
        headers: { Authorization: "Bearer " + kToken },
      }
    )
    .then((response) => {
      if (response.status === 204) return { status: response.status, text: "ok" }
      dispatch(setItems({ type: "events", items: response.data }))
      const updatedSelected = getState().export.events.selected.filter((event) => response.data.map((e) => e._id).includes(event))
      dispatch(setSelected({ type: "events", selected: updatedSelected }))
      return { status: response.status, text: "ok" }
    })
    .catch((error) => {
      console.error(error.response.data)
      return { status: error.toJSON().status, text: "error" }
    })

export const fetchRegistrations = (kToken) => async (dispatch, getState) =>
  apiClient
    .post(
      `/registrations/export`,
      { events: getState().export.events.selected.length > 0 ? getState().export.events.selected : getState().export.events.items },
      {
        headers: { Authorization: "Bearer " + kToken },
      }
    )
    .then((response) => {
      if (response.status === 204) return { status: response.status, text: "ok" }
      dispatch(setRegistrations(response.data))
      return { status: response.status, text: "ok" }
    })
    .catch((error) => {
      console.error(error.response.data)
      return { status: error.toJSON().status, text: "error" }
    })

export default exportSlice.reducer
