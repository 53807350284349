import { Box, CircularProgress, Typography } from "@mui/material"
import { useSearchParams } from "react-router-dom"
import { useEffect } from "react"
import { FormHeader } from "../FormHeader"
import NotFound from "../../NotFound"
import { useMutation } from "@tanstack/react-query"
import apiClient from "../../configs/axiosCfg"
import { ObjectId } from "bson"

export default function ConfirmMain() {
  const [searchParams] = useSearchParams()

  const isSafeToFetch = () => searchParams.get("secret") && searchParams.get("registration") && ObjectId.isValid(searchParams.get("registration"))

  const { data, status, mutate } = useMutation(
    ["putConfirm"],
    () =>
      apiClient.put(
        `/registrationsToValid/${searchParams.get("registration")}?valid=true&secret=${searchParams.get("secret")}`,
        {},
        {
          headers: {
            "x-api-secret": "HZkByMDYwbZiBM3hYbNjNGT2ex3kW2DZf7J3EMFB4Ti4X9VmaARKwvtdPKKyEALf33nkT7A9YENcmvmhUu2gJX3BEupdrRzqZtLf",
          },
        }
      ),
    {
      retry: false,
    }
  )

  useEffect(() => {
    if (isSafeToFetch())
      mutate({
        onError: (err) => {
          console.error(err)
        },
      })
  }, [searchParams])

  return !isSafeToFetch() ? (
    <NotFound />
  ) : (
    <Box id="MainFormContainer" sx={{ minHeight: "inherit", minWidth: "inherit", display: "flex", flexDirection: "column" }}>
      {/*<FormHeader eventName={"Ateliers jeunes"} logos={["heig"]} title={"Confirmation d'inscription"} />*/}
      <FormHeader eventName={data?.data?.event ?? "Ateliers jeunes"} logos={data?.data?.logos ?? ["heig"]} title={"Confirmation d'inscription"} />
      {status === "error" ? (
        <Typography textAlign={"center"} color={"error"} variant="h6">
          {"Une erreur s'est produite lors de la confirmation. Veuillez réessayer plus tard."}
        </Typography>
      ) : status === "success" ? (
        <Typography textAlign={"center"} variant="h3">
          {data?.data?.message ?? ""}
        </Typography>
      ) : (
        <CircularProgress sx={{ m: "auto" }} color="heig" size={"3rem"} />
      )}
    </Box>
  )
}
