import { createSlice } from "@reduxjs/toolkit"
import apiClient from "../../configs/axiosCfg"
import { notificationsInitState } from "../../utils/constants/redux"

const initialState = {
  db: notificationsInitState,
  local: notificationsInitState,
  errors: [],
}

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    initNotifications: (state, action) => {
      return { db: action.payload, local: action.payload, errors: [] }
    },
    setNotifications: (state, action) => {
      return { ...state, local: action.payload }
    },
    setNotificationsAttribute: (state, action) => {
      return { ...state, local: { ...state.local, [action.payload.key]: action.payload.value } }
    },
    toggleSendToContact: (state) => {
      return {
        ...state,
        local: {
          ...state.local,
          send_to_contact: !state.local.send_to_contact,
        },
      }
    },
    toggleNotifyEnrollment: (state) => {
      return {
        ...state,
        local: {
          ...state.local,
          notify_enrollment: !state.local.notify_enrollment,
        },
      }
    },
    handleClickUserCheckbox: (state, action) => {
      return {
        ...state,
        local: {
          ...state.local,
          users_email: state.local.users_email.includes(action.payload)
            ? state.local.users_email.filter((user) => user !== action.payload)
            : [...state.local.users_email, action.payload],
        },
      }
    },
    toggleError: (state, { payload: { input, isGood } }) => {
      const isError = state.errors.includes(input)
      if (isError && isGood) return { ...state, errors: state.errors.filter((error) => error !== input) }
      if (!isError && !isGood) return { ...state, errors: [...state.errors, input] }
    },
    resetLocalNotifcations: (state) => {
      return { ...state, local: state.db }
    },
  },
})

export const {
  initNotifications,
  setNotifications,
  setNotificationsAttribute,
  toggleSendToContact,
  handleClickUserCheckbox,
  resetLocalNotifcations,
  toggleNotifyEnrollment,
  toggleError,
} = notificationsSlice.actions

export const getNotificationsLocal = (state) => state.notifications.local
export const getNotificationsDb = (state) => state.notifications.db
export const getNotifications = (state) => state.notifications

export const fetchNotifications = (eventId, kToken) => async (dispatch) =>
  apiClient
    .get(`/events/${eventId}/notifications`, {
      headers: { Authorization: "Bearer " + kToken },
    })
    .then((response) => {
      dispatch(initNotifications(response.data))
      return { status: response.status, text: "ok" }
    })
    .catch((error) => {
      console.error(error.response.data)
      return { status: error.toJSON().status, text: "error" }
    })

export const putNotifications = (eventId, kToken) => async (dispatch, getState) =>
  apiClient
    .put(
      `/events/${eventId}/notifications`,
      { notifications: getState().notifications.local },
      {
        headers: { Authorization: "Bearer " + kToken },
      }
    )
    .then((response) => {
      dispatch(initNotifications(response.data))
      return { status: response.status, text: "ok" }
    })
    .catch((error) => {
      console.error(error.response.data)
      return { status: error.toJSON().status, text: "error" }
    })

export default notificationsSlice.reducer
