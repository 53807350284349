/* eslint-disable no-unused-vars */
import { useKeycloak } from "@react-keycloak/web"
import { Box, CircularProgress, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { fetchUserContext, getUserContext, setUserContext } from "../redux/slices/appSlice"
import { useEffect } from "react"
import { useSnackbar } from "notistack"
import NotLogged from "./NotLogged"

/**
 * Component InitContext
 *
 * Handle userCard context
 *
 * @returns JSX.Element
 */
export default function InitContext({ children }) {
  const { keycloak } = useKeycloak()
  const userContext = useSelector(getUserContext)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchUserContext(keycloak.token)).then((result) => {
      if (result === "error") {
        return enqueueSnackbar(
          "Une erreur est survenue lors de la récupération des données utilisateurs",
          { variant: "error" }
        )
      }
    })
    return () => {
      dispatch(setUserContext({ state: 0 }))
    }
  }, [])

  if (userContext.status === 0)
    return (
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}
      >
        <CircularProgress color="heig" size={"3rem"} />
      </Box>
    )

  if (userContext.status === -1) return <NotLogged error="Accès refusé" />

  if (userContext.status === -2)
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 4 }}>
        <Typography variant="h4">{`Erreur : Le serveur ne répond pas`}</Typography>
      </Box>
    )

  return children
}
