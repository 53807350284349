import { Card, CardContent, CardHeader } from "@mui/material"
import { ContactEmail } from "./ContactEmail"
import Feedback from "./Feedback"

export default function Registrations() {
  return (
    <Card sx={{ p: 1, zIndex: 1 }}>
      <CardHeader title={"Demandes d'inscription"} />
      <CardContent sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        <ContactEmail />
        <Feedback />
      </CardContent>
    </Card>
  )
}
