import { matchPath, matchRoutes, Outlet, useLocation, useNavigate, useParams } from "react-router-dom"
import { useEffect } from "react"
import { useKeycloak } from "@react-keycloak/web"
import { useDispatch, useSelector } from "react-redux"
import { fetchEvent, getEventDb, initEvent } from "../../../redux/slices/eventSlice"
import { getUserContext } from "../../../redux/slices/appSlice"
import { routes } from "../../../configs/router"
import { useSnackbar } from "notistack"
import { checkUserRole } from "../../../utils/checkUserRole"

export default function Event() {
  const { eventId } = useParams()
  const { activityId } = useSelector(getEventDb)

  const { keycloak } = useKeycloak()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const location = useLocation()
  const navigate = useNavigate()
  const { role: userRole, permissions } = useSelector(getUserContext)
  const { app_role } = matchRoutes(routes({ eventId }), location).at(-1).route

  useEffect(() => {
    if (location.pathname.split("/").length === 5 && app_role > -1 && !checkUserRole(userRole, app_role, permissions, activityId))
      navigate("./404", { replace: true })
  }, [userRole, app_role])
  useEffect(() => {
    dispatch(fetchEvent(eventId, keycloak.token)).then((result) => {
      if (result.text === "error") {
        if (result.status !== 404)
          enqueueSnackbar("Une erreur est survenue lors de la récupération de l'évènement", {
            variant: "error",
          })
        return navigate("../404", { replace: true })
      }
    })

    return () => {
      dispatch(initEvent({}))
    }
  }, [eventId]) // la dépendance location permet de refresh l'event à chaque fois qu'on change de route

  useEffect(() => {
    if (matchPath(location.pathname, `/dashboard/events/${eventId}`)) return navigate("workshops", { replace: true })
  }, [location])

  return <Outlet />
}
