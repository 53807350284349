import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material"
import { isEmpty, isString } from "lodash"
import { GENDER, translateRegLabel } from "../../../../utils/constants/registrations"
import { formatDate } from "../../../../utils/dates"

export default function ObjectBlock({ context, obj, ...props }) {
  const isMobile = useMediaQuery(useTheme().breakpoints.down("md"))

  const handleDataDisplay = (label, data) => {
    if (!data) return "-"
    if (label === "gender") return GENDER[data]
    if (isString(data)) {
      if (data.includes(":") && data.includes("T") && data.includes("Z")) return formatDate(new Date(data))
      if (label === "birthdate") return formatDate(new Date(data))
    }
    return data
  }

  return (
    <Box sx={{ width: "inherit", mx: 1 }} {...props}>
      <Box sx={{ textAlign: "left", display: "flex", flexDirection: "column", gap: 2 }}>
        <Typography variant={"h6"} fontWeight={"bold"} color={"primary"} sx={{ textTransform: "capitalize" }}>
          {translateRegLabel[context] ?? "Infos"}
        </Typography>
        <Grid container spacing={2}>
          {!isEmpty(obj[context]) ? (
            Object.keys(obj[context]).map((label, index) => (
              <Grid item xs={isMobile ? 12 : 6} sx={{ display: "flex", gap: 2 }} key={`registration-${context}-${index}`}>
                <Typography sx={{ width: "7rem", textTransform: "capitalize" }} fontWeight={"bold"} noWrap>
                  {translateRegLabel[label]} :
                </Typography>
                <Box>
                  <Typography sx={{ textAlign: "justify" }}>{handleDataDisplay(label, obj[context][label])}</Typography>
                </Box>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography>Pas de données à afficher...</Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  )
}
