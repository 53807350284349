import { AppBar, Box, Button, Divider, IconButton, Toolbar as MuiToolbar, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { isEmpty, isEqual } from "lodash"
import { fetchRegistration, getRegistration } from "../../../redux/slices/registrationSlice"
import { ACTIONS_ICON } from "../../../utils/constants/actions"
import { getLocalRegistration, putLocalRegistration, setLocalRegistration } from "../../../redux/slices/localRegistrationSlice"
import { getRegToSave } from "../../../utils/constants/registrations"
import { useSnackbar } from "notistack"
import { useKeycloak } from "@react-keycloak/web"
import { fetchRegistrations, getRegistrationsState } from "../../../redux/slices/registrationsSlice"
import { useParams } from "react-router-dom"

export default function Toolbar({ userCanEdit = false, editMode = false, setEditMode, onClose }) {
  const { eventId, workshopId } = useParams()
  const dispatch = useDispatch()
  const { keycloak } = useKeycloak()
  const { enqueueSnackbar } = useSnackbar()

  const registration = useSelector(getRegistration)
  const localRegistration = useSelector(getLocalRegistration)
  const { selectedReg } = useSelector(getRegistrationsState)

  // On set/unset LocalRegistration quand on change de mode
  const handleEditMode = (bool) => {
    if (userCanEdit) {
      if (bool && !editMode) dispatch(setLocalRegistration(registration))
      if (!bool && editMode) dispatch(setLocalRegistration({}))
      setEditMode(bool)
    }
  }

  const handleSaveChanges = () => {
    if (!isEqual(registration, localRegistration)) {
      dispatch(putLocalRegistration(selectedReg, getRegToSave(registration, localRegistration), keycloak.token)).then((result) => {
        if (result.text === "error") {
          return enqueueSnackbar("Une erreur est survenue lors de la mise à jour de la demande d'inscription", {
            variant: "error",
          })
        }
        handleEditMode(false)
        dispatch(fetchRegistration(registration._id, keycloak.token))
        enqueueSnackbar("Données sauvegardées", { variant: "success" })
        dispatch(fetchRegistrations(`/events/${eventId}${workshopId ? `/workshops/${workshopId}` : ""}/registrations`, keycloak.token))
      })
    }
  }

  const fullName = () => registration["child"]["lastname"] + " " + registration["child"]["firstname"]

  return (
    <AppBar color="white" position={"sticky"} elevation={104} sx={{ borderEndEndRadius: 0, borderEndStartRadius: 0 }}>
      <MuiToolbar sx={{ justifyContent: "space-between", gap: 4 }}>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Typography variant="h5" textTransform="capitalize">
            {isEmpty(registration) ? "---" : fullName()}
          </Typography>
          <Divider flexItem orientation={"vertical"} />
          <Typography variant="h6" color={`${editMode ? "warning" : "primary"}.main`}>
            {editMode ? "Édition" : "Détails"}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 1 }}>
          {editMode && userCanEdit ? (
            <>
              <Button color="inherit" onClick={() => handleEditMode(false)}>
                Annuler
              </Button>
              <Button color="warning" variant="contained" onClick={handleSaveChanges} disableElevation sx={{ height: "40px" }}>
                Sauvegarder les modifications
              </Button>
            </>
          ) : (
            <>
              {userCanEdit && <IconButton onClick={() => handleEditMode(true)}>{ACTIONS_ICON["edit"]}</IconButton>}
              <Button color="primary" variant="contained" onClick={onClose} disableElevation>
                Fermer
              </Button>
            </>
          )}
        </Box>
      </MuiToolbar>
    </AppBar>
  )
}
