import { useEffect, useState } from "react"
import { Box, Dialog, Typography } from "@mui/material"
import { useKeycloak } from "@react-keycloak/web"
import { useDispatch, useSelector } from "react-redux"
import { fetchRegistration, getRegistration, setRegistration } from "../../redux/slices/registrationSlice"
import { formatDateHours } from "../../utils/dates"
import { getRegistrationsState, setAttribute } from "../../redux/slices/registrationsSlice"
import { getUserContext } from "../../redux/slices/appSlice"
import Toolbar from "./components/Toolbar"
import Status from "./blocs/status/Status"
import Choices from "./blocs/choices/Choices"
import OthersRegs from "./blocs/otherRegs/OthersRegs"
import LinkedRegs from "./blocs/LinkedRegs"
import Infos from "./blocs/infos/Infos"
import MoreInfos from "./blocs/infos/MoreInfos"
import { setLocalRegistration } from "../../redux/slices/localRegistrationSlice"
import WarningFilters from "./blocs/WarningFilters"

export default function RegistrationDetails() {
  const dispatch = useDispatch()
  const { keycloak } = useKeycloak()
  const { selectedReg } = useSelector(getRegistrationsState)
  const registration = useSelector(getRegistration)
  const [editMode, setEditMode] = useState(false)
  const { permissions: userPerms, role } = useSelector(getUserContext)
  const [userCanEdit, setUserCanEdit] = useState(false)

  useEffect(() => {
    if (selectedReg) {
      dispatch(fetchRegistration(selectedReg, keycloak.token))
    }
  }, [selectedReg])

  useEffect(() => {
    setUserCanEdit(role < 2 || userPerms.filter(({ resource, role }) => resource === registration.eventId && role === 1).length > 0)
  }, [registration])

  useEffect(() => {
    return () => {
      dispatch(setRegistration({}))
    }
  }, [])

  const onClose = () => {
    dispatch(setAttribute({ key: "selectedReg", value: "" }))
    dispatch(setRegistration({}))
    dispatch(setLocalRegistration({}))
    setEditMode(false)
  }

  return (
    <Dialog PaperProps={{ sx: { backgroundColor: "whitesmoke", overflow: "clip" } }} open={!!selectedReg} onClose={onClose} maxWidth={"xl"} fullWidth>
      <Toolbar editMode={editMode} userCanEdit={userCanEdit} setEditMode={setEditMode} onClose={onClose} />
      <Box sx={{ overflow: "auto", px: 2 }}>
        <WarningFilters />
        <Box sx={{ display: "flex", justifyContent: "space-between", my: 1 }}>
          <Typography variant="body2" color={"whitesmoke"}>
            {`${registration._id}--${registration.admission_secret}--${registration.multi_registration}--${registration.multi_order}`}
          </Typography>
          <Typography variant="body2" color={"gray"}>
            {`Date d'inscription : ${formatDateHours(new Date(registration.createdAt))}`}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 3,
            flexDirection: "column",
            justifyContent: "space-evenly",
            mb: 3,
          }}
        >
          <Status editMode={editMode} />
          <Choices editMode={editMode} />
          <LinkedRegs />
          <OthersRegs />
          <Infos editMode={editMode} />
          <MoreInfos editMode={editMode} />
        </Box>
      </Box>
    </Dialog>
  )
}

// <Typography variant="body2" color={"gray"}>
//   {`I|${registration._id}|L|${registration.linkId}|M|${registration.multi_registration}/${registration.multi_order}`}
// </Typography>
