import { Box, Card, CardActionArea, Typography } from "@mui/material"
import PropTypes from "prop-types"
import { STATUSES, STATUSES_COLOR, STATUSES_ICON, STATUSES_LABEL } from "../../../../utils/constants/statuses"
import { cloneElement } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getEventDb } from "../../../../redux/slices/eventSlice"
import { useQuery } from "@tanstack/react-query"
import apiClient from "../../../../configs/axiosCfg"
import { useSnackbar } from "notistack"
import { useKeycloak } from "@react-keycloak/web"
import { setAttribute } from "../../../../redux/slices/registrationsSlice"
import { useNavigate } from "react-router-dom"

export default function EventStats({ inWorkshops = false }) {
  const event = useSelector(getEventDb)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { enqueueSnackbar } = useSnackbar()
  const { keycloak } = useKeycloak()

  const { status: queryStatus, data } = useQuery(
    ["getStats"],
    () =>
      apiClient.get(`/events/${event._id}/stats`, {
        headers: { Authorization: "Bearer " + keycloak.token },
      }),
    {
      onError: (error) => {
        console.error(error)
        enqueueSnackbar("Une erreur est survenue lors de la récupération des statistiques", {
          variant: "error",
        })
      },
      enabled: !!event?._id,
    }
  )

  const onClickCard = (statusIndex) => {
    if (inWorkshops) {
      navigate("../registrations")
    }
    dispatch(setAttribute({ key: "searchText", value: "" }))
    dispatch(setAttribute({ key: "selectedStatus", value: statusIndex }))
  }

  return (
    <Card sx={{ display: "flex" }}>
      {STATUSES.map((status, statusIndex) => [
        <CardActionArea key={statusIndex} sx={{ flex: 1, p: 1 }} onClick={() => onClickCard(statusIndex)}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 3, p: 1 }}>
            <Box sx={{ display: "flex", justifyContent: "start", alignItems: "center", gap: 1 }}>
              {cloneElement(STATUSES_ICON[status], { style: { fontSize: "28px" } })}
              <Typography color={`${STATUSES_COLOR[status]}.main`}>{STATUSES_LABEL[status]}</Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Typography variant="h4" minWidth="3ch" sx={{ display: "flex", justifyContent: "end" }}>
                {queryStatus === "success" ? data.data.regsByStatus[statusIndex] : "-"}
              </Typography>
            </Box>
          </Box>
        </CardActionArea>,
        statusIndex === STATUSES.length - 1 ? (
          <div key={statusIndex + "-null"} />
        ) : (
          <Box key={statusIndex + "-divider"} sx={{ border: "1px lightgray dashed", my: 1 }} />
        ),
      ])}
    </Card>
  )
}

EventStats.propTypes = {
  data: PropTypes.object,
}

// <Box sx={{ display: "flex", gap: 1 }}>
//     {STATUSES_ICON[status]}
//     <Typography color={`${STATUSES_COLOR[status]}.main`}>{safeData[status]}</Typography>
// </Box>
