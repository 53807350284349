import { getAge } from "../../utils/tablesHelpers"
import { ADMITTED_STATUSES, ADMITTED_STATUSES_ICON, STATUSES, STATUSES_ICON } from "../../utils/constants/statuses"
import { capitalize, join } from "lodash"

// Pour afficher le choix sélectionné
// _.isInteger(reg["selected_choice"])
//   ? reg.choices[reg.choices.map((choice) => choice.order).indexOf(reg.selected_choice)]
//       ?.workshop.name
//   : "-",

export const getColsAndOptions = (selectedStatus) => {
  switch (selectedStatus) {
    case -1:
      return {
        cols: ["Enfant", "Âge", "Classe", "École", "Statut", "Date"],
        colsOptions: [{ align: "left" }, { align: "right" }, { align: "right" }, { align: "left" }, { align: "center" }, { align: "right" }],
      }
    case 0:
      return {
        cols: ["Enfant", "Âge", "Classe", "École", "Date"],
        colsOptions: [{ align: "left" }, { align: "right" }, { align: "right" }, { align: "left" }, { align: "right" }],
      }
    case 1:
      return {
        cols: ["Enfant", "Âge", "Classe", "École", "Date"],
        colsOptions: [{ align: "left" }, { align: "right" }, { align: "right" }, { align: "left" }, { align: "right" }],
      }
    case 2:
      return {
        cols: ["Enfant", "Âge", "Classe", "École", "Admission", "Date"],
        colsOptions: [{ align: "left" }, { align: "right" }, { align: "right" }, { align: "left" }, { align: "center" }, { align: "right" }],
      }
    case 3:
      return {
        cols: ["Enfant", "Âge", "Classe", "École", "Raison", "Date"],
        colsOptions: [{ align: "left" }, { align: "right" }, { align: "right" }, { align: "left" }, { align: "left" }, { align: "right" }],
      }
    case 4:
      return {
        cols: ["Enfant", "Âge", "Classe", "École", "Raison", "Date"],
        colsOptions: [{ align: "left" }, { align: "right" }, { align: "right" }, { align: "left" }, { align: "left" }, { align: "right" }],
      }
    case 5:
      return {
        cols: ["Enfant", "Âge", "Classe", "École", "Raison", "Date"],
        colsOptions: [{ align: "left" }, { align: "right" }, { align: "right" }, { align: "left" }, { align: "left" }, { align: "right" }],
      }
    default:
      return {
        cols: ["Enfant", "Âge", "Classe", "École", "Statut", "Date"],
        colsOptions: [{ align: "left" }, { align: "right" }, { align: "right" }, { align: "left" }, { align: "center" }, { align: "right" }],
      }
  }
}

const concatClass = (school) =>
  school
    ? join(
        [school?.grade, school?.path?.toUpperCase(), school?.class].filter((e) => !!e),
        "-"
      )
    : "-"

export const formatRegsListToRows = (regsList, selectedStatus, callbackClick, start) => {
  switch (selectedStatus) {
    case -1:
      return regsList.map((reg) => ({
        id: reg?._id,
        callbackClick,
        cells: [
          capitalize(reg?.child?.lastname) + " " + capitalize(reg?.child?.firstname) ?? "-",
          getAge(reg?.child?.birthdate, start) ?? "-",
          concatClass(reg?.school) ?? "-",
          reg?.school?.name ?? "-",
          STATUSES_ICON[STATUSES[reg?.status]] ?? "-",
          reg?.createdAt ?? "-",
        ],
        warnings: reg?.warnings,
        comments: reg?.comments,
        // actions: <RDIconButton reg={reg} />,
      }))
    case 0:
      return regsList.map((reg) => ({
        id: reg?._id,
        callbackClick,
        cells: [
          capitalize(reg?.child?.lastname) + " " + capitalize(reg?.child?.firstname) ?? "-",
          getAge(reg?.child?.birthdate, start) ?? "-",
          concatClass(reg?.school) ?? "-",
          reg?.school?.name ?? "-",
          reg?.createdAt ?? "-",
        ],
        warnings: reg?.warnings,
        comments: reg?.comments,
        // actions: <RDIconButton reg={reg} />,
      }))
    case 1:
      return regsList.map((reg) => ({
        id: reg?._id,
        callbackClick,
        cells: [
          capitalize(reg?.child?.lastname) + " " + capitalize(reg?.child?.firstname) ?? "-",
          getAge(reg?.child?.birthdate, start) ?? "-",
          concatClass(reg?.school) ?? "-",
          reg?.school?.name ?? "-",
          reg?.createdAt ?? "-",
        ],
        warnings: reg?.warnings,
        comments: reg?.comments,
        // actions: <RDIconButton reg={reg} />,
      }))
    case 2:
      return regsList.map((reg) => ({
        id: reg?._id,
        callbackClick,
        cells: [
          capitalize(reg?.child?.lastname) + " " + capitalize(reg?.child?.firstname) ?? "-",
          getAge(reg?.child?.birthdate, start) ?? "-",
          concatClass(reg?.school) ?? "-",
          reg?.school?.name ?? "-",
          ADMITTED_STATUSES_ICON[ADMITTED_STATUSES[reg?.admitted_status]] ?? "-",
          reg?.createdAt ?? "-",
        ],
        warnings: reg?.warnings,
        comments: reg?.comments,
        // actions: <RDIconButton reg={reg} />,
      }))
    case 3:
      return regsList.map((reg) => ({
        id: reg?._id,
        callbackClick,
        cells: [
          capitalize(reg?.child?.lastname) + " " + capitalize(reg?.child?.firstname) ?? "-",
          getAge(reg?.child?.birthdate, start) ?? "-",
          concatClass(reg?.school) ?? "-",
          reg?.school?.name ?? "-",
          reg?.reason ?? "-",
          reg?.createdAt ?? "-",
        ],
        warnings: reg?.warnings,
        comments: reg?.comments,
        // actions: <RDIconButton reg={reg} />,
      }))
    case 4:
      return regsList.map((reg) => ({
        id: reg?._id,
        callbackClick,
        cells: [
          capitalize(reg?.child?.lastname) + " " + capitalize(reg?.child?.firstname) ?? "-",
          getAge(reg?.child?.birthdate, start) ?? "-",
          concatClass(reg?.school) ?? "-",
          reg?.school?.name ?? "-",
          reg?.reason ?? "-",
          reg?.createdAt ?? "-",
        ],
        warnings: reg?.warnings,
        comments: reg?.comments,
        // actions: <RDIconButton reg={reg} />,
      }))
    case 5:
      return regsList.map((reg) => ({
        id: reg?._id,
        callbackClick,
        cells: [
          capitalize(reg?.child?.lastname) + " " + capitalize(reg?.child?.firstname) ?? "-",
          getAge(reg?.child?.birthdate, start) ?? "-",
          concatClass(reg?.school) ?? "-",
          reg?.school?.name ?? "-",
          reg?.reason ?? "-",
          reg?.createdAt ?? "-",
        ],
        warnings: reg?.warnings,
        comments: reg?.comments,
        // actions: <RDIconButton reg={reg} />,
      }))
    default:
      return regsList.map((reg) => ({
        id: reg?._id,
        callbackClick,
        cells: [
          capitalize(reg?.child?.lastname) + " " + capitalize(reg?.child?.firstname) ?? "-",
          getAge(reg?.child?.birthdate, start) ?? "-",
          concatClass(reg?.school) ?? "-",
          reg?.school?.name ?? "-",
          STATUSES_ICON[STATUSES[reg?.status]] ?? "-",
          reg?.createdAt ?? "-",
        ],
        warnings: reg?.warnings,
        comments: reg?.comments,
        // actions: <RDIconButton reg={reg} />,
      }))
  }
}
