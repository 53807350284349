import { useDispatch, useSelector } from "react-redux"
import { addManager, deleteManager, getActivities } from "../../../../redux/slices/activitiesSlice"
import { Box, Chip, Divider, Typography } from "@mui/material"
import { isEmpty } from "lodash"
import WrapperApiAdPeopleToken from "../../../../components/WrapperApiAdPeopleToken"
import AutocompleteAdPeople from "../../../../components/inputs/AutoCompleteAdPeople"

export function Managers() {
  const { localActivity } = useSelector(getActivities)
  const dispatch = useDispatch()

  const handleAddManager = (newManager) => {
    if (newManager) dispatch(addManager(newManager))
  }

  const handleDeleteManager = (managerEmail) => dispatch(deleteManager(managerEmail))

  return (
    <Box sx={{ flex: "auto", display: "flex", flexDirection: "column", gap: 2 }}>
      <Divider>Gestion des managers</Divider>
      {!isEmpty(localActivity?.managers) ? (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, my: 1 }}>
          {localActivity.managers.map((manager, mIndex) => (
            <Chip key={mIndex} label={manager.name} onDelete={() => handleDeleteManager(manager.email)} />
          ))}
        </Box>
      ) : (
        <Typography textAlign="center" sx={{ my: 1 }}>
          Aucun manager
        </Typography>
      )}

      <WrapperApiAdPeopleToken>
        <AutocompleteAdPeople callback={handleAddManager} textInputLabel="Ajouter un collaborateur" />
      </WrapperApiAdPeopleToken>
    </Box>
  )
}
