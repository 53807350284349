// Modules imports
import MenuIcon from "@mui/icons-material/Menu"
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material"
import * as React from "react"
import { Navigate } from "react-router-dom"
import AuthToolbarItem from "./AuthToolbarItem"
import { TOPBAR_SUBTITLE, TOPBAR_TITLE } from "../../utils/constants/topbar"
import { useDispatch, useSelector } from "react-redux"
import { isDrawerOpened, openDrawer } from "../../redux/slices/appSlice"

/**
 * Component Topbar
 *
 * HOC that displays topbar and children passed in props
 *
 * @param {*} props
 * -
 * - children : children to display
 *
 * @returns JSX.Element
 */
export default function Topbar() {
  const drawerOpened = useSelector(isDrawerOpened)
  const dispatch = useDispatch()

  const onClickDrawerIcon = () => dispatch(openDrawer(!drawerOpened))

  const onClickHomeIcon = () => Navigate("/")

  return (
    <Box id="TopbarContainer" data-cy="root-router" sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        color="white"
        elevation={0}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          borderBottom: "2px whitesmoke dashed",
          filter: "none",
        }}
      >
        <Toolbar sx={{ display: "flex", ml: -2, justifyContent: "space-between", gap: 4 }}>
          <Box display="flex" alignItems="center">
            <IconButton color="inherit" id="sidebarToggleButton" onClick={onClickDrawerIcon}>
              <MenuIcon />
            </IconButton>
            <IconButton sx={{ py: 0, height: "32px" }} onClick={onClickHomeIcon} style={{ backgroundColor: "transparent" }}>
              <img
                style={{ height: "inherit", objectFit: "contain" }}
                src={"https://storage.googleapis.com/visual-identity/logo/2020-slim.png"}
                alt="Logo HEIG-VD"
              />
            </IconButton>
            <Box sx={{ display: "flex", flexDirection: "column", textAlign: "left", ml: 1 }}>
              <Typography variant="topbarTitle" noWrap>
                {TOPBAR_TITLE}
              </Typography>
              <Typography variant="topbarSubtitle" noWrap>
                {TOPBAR_SUBTITLE}
              </Typography>
            </Box>
          </Box>
          <AuthToolbarItem />
        </Toolbar>
      </AppBar>
    </Box>
  )
}
