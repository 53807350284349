import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material"
import { getRegistration } from "../../../redux/slices/registrationSlice"
import { useSelector } from "react-redux"
import { capitalize, isEmpty } from "lodash"
import { formatDateCH, getAge } from "../../../utils/tablesHelpers"
import { STATUSES, STATUSES_ICON } from "../../../utils/constants/statuses"
import { getEventDb } from "../../../redux/slices/eventSlice"
import TableReg from "../components/TableReg"

const header = [
  {
    label: "Enfant",
    align: "left",
  },
  {
    label: "Âge",
    align: "right",
  },
  {
    label: "Statut",
    align: "center",
  },
  {
    label: "Atelier / Raison",
    align: "left",
  },
  {
    label: "Créée le",
    align: "right",
  },
]

export default function LinkedRegs() {
  const { linkedRegs } = useSelector(getRegistration)
  const { start } = useSelector(getEventDb)

  const computeDetails = ({ status, reason, selected_choice, choices }) => {
    if (!status) return "-"
    return [1, 2].includes(status) ? choices[choices.map((choice) => choice.order).indexOf(selected_choice)].workshop.name : reason
  }

  const mapRegs = () =>
    linkedRegs.map((reg) => ({
      regId: reg._id,
      cells: [
        capitalize(reg?.child?.lastname) + " " + capitalize(reg?.child?.firstname) ?? "-",
        getAge(reg?.child?.birthdate, start),
        STATUSES_ICON[STATUSES[reg?.status]] ?? "-",
        computeDetails(reg),
        formatDateCH(reg?.createdAt),
      ],
    }))

  return (
    !isEmpty(linkedRegs) && (
      <Card>
        <CardHeader
          title={
            <Box>
              <Typography variant="inherit">{"Demandes d'inscriptions liées"}</Typography>
              <Typography variant="sub6">{"Recherche par email du parent"}</Typography>
            </Box>
          }
        />
        <CardContent sx={{ pt: 0, pb: "8px!important", display: "flex", gap: 2, flexWrap: "wrap" }}>
          <TableReg header={header} rows={mapRegs()} />
        </CardContent>
      </Card>
    )
  )
}
