/* eslint-disable no-unused-vars */
import { Box, Button, Card, CardActions, CardContent, CardHeader, Typography } from "@mui/material"

import { useDispatch, useSelector } from "react-redux"
import { Lang, useFormInputValidation } from "react-form-input-validation"
import { getAdForm, putReg } from "../../../redux/slices/adFormSlice"
import TshirtSizeRF from "./TshirtSizeRF"
import { useSnackbar } from "notistack"
import { useState } from "react"
import { useParams } from "react-router-dom"
import BFInput from "./BFInput"
import { ArrowForwardRounded } from "@mui/icons-material"

const getFieldsInput = (dbInputs) => {
  const fields = {}
  if (dbInputs.tshirt_size > 0)
    fields.tshirt_size = {
      input: "tshirt_size",
      type: "text",
      special: "tshirt",
    }
  if (dbInputs.diet > 0)
    fields.diet = {
      input: "diet",
      type: "boolean",
      label: "Régime alimentaire",
      text: "Mon enfant a des allergies et/ou un régime particulier",
      optional: true,
    }
  if (dbInputs.transportation > 0)
    fields.transportation = {
      input: "transportation",
      type: "boolean",
      label: "Transport",
      text: "Je souhaite que mon enfant souhaite prenne le transport mis à disposition",
      optional: true,
    }
  if (dbInputs.unil_survey > 0)
    fields.unil_survey = {
      input: "unil_survey",
      type: "boolean",
      label: "Enquête UNIL",
      text: "Je souhaite participer à une enquête de l'UNIL",
      optional: true,
    }

  if (dbInputs.media_consent > 0)
    fields.media_consent = {
      input: "media_consent",
      type: "boolean",
      label: "Consentement photos",
      text: "Je suis d'accord que mon enfant soit pris en photo",
      optional: true,
    }
  if (dbInputs.risks_consent > 0)
    fields.risks_consent = {
      input: "risks_consent",
      type: "boolean",
      label: "Consentement aux risques",
      text: "J’accepte les risques éventuels liés aux ateliers",
    }
  return fields
}

export default function SimpleInfos() {
  const { secret } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const {
    config: { inputs },
    dbReg,
  } = useSelector(getAdForm)
  const dispatch = useDispatch()
  const [disabledSubmit, setDisabledSubmit] = useState(false)

  const adForm = useSelector(getAdForm)
  const fieldsInput = getFieldsInput(inputs)

  const getInputValidators = ({ input, type, special = "", optional = false }) => {
    let config = !optional && inputs[input] === 1 ? "required|" : ""
    if (special) {
      if (special === "tshirt") config += "in:XS,S,M,L,XL"
    } else {
      if (type === "boolean") config += "array|in:ok"
    }
    return config
  }

  const [fields, errors, form] = useFormInputValidation(
    Object.keys(fieldsInput).reduce(
      (a, v) => ({
        ...a,
        [v]: adForm[v] ?? undefined,
      }),
      {}
    ),
    Object.keys(fieldsInput).reduce(
      (a, v) => ({
        ...a,
        [v]: getInputValidators(fieldsInput[v]),
      }),
      {}
    )
  )

  form.useLang("fr")
  form.setMessages(Lang.fr, { ...form.getMessages(Lang.fr), required: "Ce champ est requis" })

  const nextStep = (event) => {
    setDisabledSubmit(true)
    form.validate(event).then((isValid) => {
      if (isValid) {
        const formattedFields = Object.keys(fields).reduce(
          (a, fieldName) => ({
            ...a,
            [fieldName]: fieldsInput[fieldName].type === "boolean" ? fields[fieldName]?.[0] === "ok" : fields[fieldName],
          }),
          {}
        )
        dispatch(
          putReg(dbReg._id, {
            registration: {
              ...formattedFields,
              admission_secret: secret,
            },
          })
        ).then((result) => {
          if (result === "ok") {
            enqueueSnackbar("Informations enregistrées.", { variant: "success" })
          }
          if (result === "error") {
            enqueueSnackbar("Une erreur est survenue lors de l'enregistrement des informations", {
              variant: "error",
            })
          }
          setDisabledSubmit(false)
        })
      } else setDisabledSubmit(false)
    })
  }

  return (
    <Card sx={{ p: 1 }}>
      <CardHeader
        title={
          <>
            <Typography variant="inherit" gutterBottom>
              {"Informations supplémentaires"}
            </Typography>
            <Typography variant="sub6">{"Nous avons encore besoin de quelques informations pour compléter l'inscription."}</Typography>
            <Typography noWrap sx={{ mt: 2, color: "primary.main" }}>
              <ArrowForwardRounded fontSize="inherit" sx={{ verticalAlign: "text-top", mr: 1 }} />
              {`Formulaire destiné à ${dbReg?.child?.firstname} ${dbReg?.child?.lastname}`}
            </Typography>
          </>
        }
      />

      <form noValidate autoComplete="off" onSubmit={nextStep}>
        <CardContent sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
          {inputs["tshirt_size"] > 0 && (
            <TshirtSizeRF
              value={fields["tshirt_size"] ?? ""}
              onChange={form.handleChangeEvent}
              error={errors["tshirt_size"]}
              important={inputs["tshirt_size"] === 1 && !fieldsInput["tshirt_size"].optional}
            />
          )}
          {Object.values(fieldsInput)
            .filter((input) => input.type === "boolean")
            .map((input) => (
              <BFInput
                key={input.input}
                label={input.label}
                text={input.text}
                name={input.input}
                onChange={form.handleChangeEvent}
                error={errors[input.input]}
                important={inputs[input.input] === 1 && !fieldsInput[input.input].optional}
              />
            ))}
        </CardContent>
        <CardActions>
          <Box sx={{ flex: 1, mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button disabled={disabledSubmit} variant="contained" size="large" disableElevation type={"submit"}>
              {"Valider les informations"}
            </Button>
          </Box>
        </CardActions>
      </form>
    </Card>
  )
}
