import { createSlice } from "@reduxjs/toolkit"
import { initWorkshop } from "../../utils/constants/redux"
import apiClient from "../../configs/axiosCfg"
import { unset } from "lodash"

const initialState = {
  db: [],
  local: [],
}

export const workshopsSlice = createSlice({
  name: "workshops",
  initialState,
  reducers: {
    initWorkshops: (state, action) => {
      return { db: action.payload, local: action.payload }
    },
    setWorkshops: (state, action) => {
      return { ...state, local: action.payload }
    },
    setWorkshop: (state, action) => {
      const workshops = [...state.local]
      workshops[action.payload.index] = action.payload.value
      return { ...state, local: workshops }
    },
    newWorkshop: (state) => {
      return { ...state, local: [...state.local, initWorkshop] }
    },
    deleteWorkshop: (state, action) => {
      return {
        ...state,
        local: state.local.filter((ws, index) => index !== action.payload),
      }
    },
    setWSAttribute: (state, action) => {
      return {
        ...state,
        local: state.local.map((ws, index) => (index === action.payload.index ? { ...ws, [action.payload.key]: action.payload.value } : ws)),
      }
    },
    duplicateWorkshop: (state, action) => {
      const newWorkshop = { ...state.local[action.payload] }
      if (newWorkshop._id) unset(newWorkshop, "_id")
      return { ...state, local: [...state.local, newWorkshop] }
    },
  },
})

export const { initWorkshops, setWorkshops, newWorkshop, deleteWorkshop, setWSAttribute, duplicateWorkshop, setWorkshop } = workshopsSlice.actions

export const getWorkshopsLocal = (state) => state.workshops.local
export const getWorkshopsDb = (state) => state.workshops.db

export const fetchWorkshops = (eventId, kToken) => async (dispatch) =>
  apiClient
    .get(`/events/${eventId}/workshops`, {
      headers: { Authorization: "Bearer " + kToken },
    })
    .then((response) => {
      if (response.status === 204) return "empty"
      dispatch(initWorkshops(response.data))
      return "ok"
    })
    .catch((error) => {
      console.error(error.response.data)
      return "error"
    })

export const postWorkshop = (body, kToken) => async () =>
  apiClient
    .post(`/workshops`, body, {
      headers: { Authorization: "Bearer " + kToken },
    })
    .then(() => {
      return "ok"
    })
    .catch((error) => {
      console.error(error.response.data)
      return "error"
    })

export const putWorkshops = (body, eventId, kToken) => async () =>
  apiClient
    .put(`/workshops`, body, {
      headers: { Authorization: "Bearer " + kToken },
    })
    .then(() => {
      return "ok"
    })
    .catch((error) => {
      console.error(error.response.data)
      return "error"
    })

export default workshopsSlice.reducer
