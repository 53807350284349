import { Box, Card, CardContent, CardHeader, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material"
import { decrementChildCount, getMainForm, incrementChildCount, jumpState } from "../../../redux/slices/mainFormSlice"
import { useDispatch, useSelector } from "react-redux"
import { AddRounded, RemoveRounded } from "@mui/icons-material"
import Stepper from "./Stepper"
import { useEffect } from "react"

export default function SetChildCountStep() {
  const { childCount } = useSelector(getMainForm)

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("sm"))

  const dispatch = useDispatch()

  const nextStep = (event) => {
    event.preventDefault()
    dispatch(jumpState(1))
  }

  const onChangeCount = (num) => {
    if (num === 1) dispatch(incrementChildCount())
    else dispatch(decrementChildCount())
    sessionStorage.setItem("childCount", (childCount + num).toString())
  }

  // set the childCount in the redux store from the session storage if it exists and on component mount
  useEffect(() => {
    const count = sessionStorage.getItem("childCount")
    if (count) Array.from(Array(parseInt(count) - childCount)).map(() => dispatch(incrementChildCount()))
  }, [])

  return (
    <form noValidate autoComplete="off" onSubmit={nextStep}>
      <Stepper />
      <Box sx={{ my: 2 }} />
      <Card sx={{ p: 1 }}>
        <CardHeader
          title={
            <>
              <Typography variant={matches ? "inherit" : "h6"} gutterBottom>
                {"Nombre d'enfant(s) à inscrire"}
              </Typography>
              <Typography variant="sub6">{"Veuillez saisir le nombre d'enfant(s) que vous souhaitez inscrire aux ateliers "}</Typography>
            </>
          }
        />
        <CardContent sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 2, mb: 2 }}>
          <Box
            display="flex"
            alignItems="center"
            gap={2}
            sx={{
              filter: "drop-shadow(0 0rem 1mm lightgray)",
              backgroundColor: "white!important",
              borderRadius: 1,
            }}
          >
            <IconButton color="error" onClick={() => onChangeCount(-1)}>
              <RemoveRounded />
            </IconButton>
            <Typography width={"3ch"} textAlign={"center"}>
              {childCount}
            </Typography>
            <IconButton color="success" onClick={() => onChangeCount(1)}>
              <AddRounded />
            </IconButton>
          </Box>
        </CardContent>
      </Card>
    </form>
  )
}
