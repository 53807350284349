import { cloneElement, useEffect, useState } from "react"
import apiClient from "../configs/axiosCfg"
import keycloak from "../configs/keycloak"

export default function WrapperApiAdPeopleToken({ children }) {
  const [token, setToken] = useState(null)

  useEffect(() => {
    apiClient
      .get("/users/apiAdPeopleToken", { headers: { Authorization: "Bearer " + keycloak.token } })
      .then((res) => setToken(res.data.token))
      .catch((err) => console.error(err))
  }, [!token])

  return token && cloneElement(children, { apiAdPeopleToken: token })
}
