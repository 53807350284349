import { Card, CardContent, Collapse, Container, Icon, Typography } from "@mui/material"
import SimpleInfos from "./simpleInfos/SimpleInfos"
import ConsentStep from "./consentStep/ConsentStep"
import { useSelector } from "react-redux"
import { getAdForm } from "../../redux/slices/adFormSlice"
import { eventNeedFile } from "../../utils/forms"
import { CheckCircleRounded } from "@mui/icons-material"
import { isEmpty } from "lodash"

export function FormContent() {
  const {
    dbReg: { admitted_status, consent_filename },
    config: { inputs },
  } = useSelector(getAdForm)

  const isFormDone = admitted_status > 0
  const fileSent = !isEmpty(consent_filename)

  return (
    <Container maxWidth={"md"} sx={{ p: 3, display: "flex", flexDirection: "column", gap: 2 }}>
      <Collapse in={!isFormDone} unmountOnExit mountOnEnter>
        <SimpleInfos />
      </Collapse>
      <Collapse in={isFormDone} unmountOnExit mountOnEnter>
        {eventNeedFile(inputs) && !fileSent ? (
          <ConsentStep />
        ) : (
          <Card>
            <CardContent sx={{ display: "flex", gap: 2, justifyContent: "center", p: "1rem!important", alignItems: "center" }}>
              <Typography variant="h4" color={"success.main"}>
                {"Inscription complète"}
              </Typography>
              <Icon>
                <CheckCircleRounded color="success" />
              </Icon>
            </CardContent>
          </Card>
        )}
      </Collapse>
    </Container>
  )
}
